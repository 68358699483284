import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Ace, Acl, Identity, prettyIdentityType } from '@/accessControl/itemAclModal.utilities';
import { ACLPermissionName, HOME_SCREEN_TABS, PERMISSIONS } from '@/main/app.constants';
import { AceCheckbox } from '@/accessControl/AceCheckbox.molecule';
import { IdentityOption, SelectIdentity } from '@/core/SelectIdentity.molecule';
import { AcePopover } from '@/accessControl/AcePopover.molecule';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { GetLinks } from '@/accessControl/GetLinks.molecule';
import { AclModalMainTableRow } from '@/accessControl/AclModalMainTableRow.molecule';
import { CorporateDriveMessage } from '@/accessControl/CorporateDriveMessage.molecule';
import { sqHomeScreenStore } from '@/core/core.stores';
import { EVERYONE_USERGROUP } from '@/administration/administration.constants';
import { Button } from '@seeqdev/qomponents';

type AclModalMainTabProps = {
  item?: any;
  fetchInherited?: any;
  loading: boolean;
  onClose?: any;
  saving: boolean;
  aclModified?: any;
  displayAceEntries: Ace[];
  currentUserId: string;
  setPermissions: (permission: ACLPermissionName, ace: Ace) => void;
  newAce: Ace;
  isAclFromDatasource: boolean;
  addItemAce: () => void;
  itemAcl: Acl;
  setIdentity: (Identity: Identity) => void;
  userHasManagePermission: boolean;
  workbookId: string;
  worksheetId?: string;
  includeLinksAndCorporateMessage: boolean;
  folderId?: string;
  showOnlyFolderLinks?: boolean;
  itemId: string;
  itemName: string;
  isAclModalLocationInHeader: boolean;
  isItemAncestorNotCorporate: boolean;
  isVantage: boolean;
};

export const AclModalMainTab: React.FunctionComponent<AclModalMainTabProps> = ({
  item,
  loading,
  onClose,
  fetchInherited,
  saving,
  aclModified,
  displayAceEntries,
  currentUserId,
  setPermissions,
  newAce,
  isAclFromDatasource,
  addItemAce,
  setIdentity,
  itemAcl,
  userHasManagePermission,
  workbookId,
  worksheetId,
  includeLinksAndCorporateMessage,
  folderId,
  showOnlyFolderLinks,
  itemId,
  itemName,
  isAclModalLocationInHeader,
  isItemAncestorNotCorporate,
  isVantage,
}) => {
  const { t } = useTranslation();

  const validIdentitySelected = _.has(newAce, 'identity.id');

  const tableHeader = (
    <div className="flexColumnContainer fakeTableHeader">
      <div className="min-width-450">{t('ACCESS_CONTROL.HEADER.NAME')}</div>
      <div className="min-width-70">{t('ACCESS_CONTROL.HEADER.TYPE')}</div>
      {_.map(
        [
          'ACCESS_CONTROL.ACCESS_LEVELS.READ',
          'ACCESS_CONTROL.ACCESS_LEVELS.WRITE',
          'ACCESS_CONTROL.ACCESS_LEVELS.MANAGE',
        ],
        (level) => (
          <div className="text-center width-75" key={level}>
            {t(level)}
          </div>
        ),
      )}
    </div>
  );

  const popover = (ace: Ace) => (
    <div id={`${ace.id}_popover`} className="ml5">
      <AcePopover ace={ace} />
    </div>
  );

  const tableBody = (
    <div className="flexRowContainer fakeTableWrapper">
      {_.map(displayAceEntries, (ace, index) => {
        return (
          <div key={ace.id} className="fakeTableStriped">
            <AclModalMainTableRow
              ace={ace}
              index={index}
              popover={popover}
              currentUserId={currentUserId}
              setPermissions={setPermissions}
              isAclFromDatasource={isAclFromDatasource}
              displayAceEntries={displayAceEntries}
              isItemAncestorNotCorporate={isItemAncestorNotCorporate}
              includeLinksAndCorporateMessage={includeLinksAndCorporateMessage}
              userHasManagePermission={userHasManagePermission}
            />

            {includeLinksAndCorporateMessage &&
              ace.identity?.name === EVERYONE_USERGROUP &&
              ace.permissions?.read &&
              ace.identity.isEnabled &&
              isItemAncestorNotCorporate &&
              sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE && (
                <CorporateDriveMessage
                  item={item}
                  itemId={itemId}
                  itemName={itemName}
                  isAclModalLocationInHeader={isAclModalLocationInHeader}
                  aclModified={aclModified}
                  fetchInherited={fetchInherited}
                  onClose={onClose}
                />
              )}
          </div>
        );
      })}
    </div>
  );

  const newAceRow = userHasManagePermission && (
    <div id="specNewEntryRow" className="flexColumnContainer flexAlignCenter mt5">
      <div className="width-400 min-width-400 mr50">
        <SelectIdentity
          idForLabel="identityName"
          setIdentity={setIdentity}
          allowGroups={true}
          identity={newAce.identity as IdentityOption}
          includeAllProperties={true}
          showDirectory={true}
          startEditable={true}
          placeholder="ACCESS_CONTROL.USER_PLACEHOLDER"
          clearIdentityWhenEmpty={true}
          maxHeight="450px"
          positionFixed={true}
        />
      </div>

      <div className="text-valign-middle min-width-70">
        {newAce.identity?.type ? prettyIdentityType(newAce.identity.type, t) : ''}
      </div>
      {_.map(PERMISSIONS, (perm) => (
        <div key={`${perm}_new`} className="text-center text-valign-middle min-width-75 footerCheckbox">
          <AceCheckbox name={perm} ace={newAce} setPermissions={setPermissions} isAclFromDatasource={false} />
        </div>
      ))}
      <div className="text-right text-valign-middle min-width-50">
        <Button
          id="specAddPermissionButton"
          type="button"
          extraClassNames="mr-3"
          size="sm"
          variant="theme"
          disabled={!validIdentitySelected}
          onClick={addItemAce}
          label={t('ADD')}
        />
      </div>
    </div>
  );

  return (
    <>
      {loading && (
        <div className="flexRowContainer flexCenter mt30 mb15" data-testid="loadingACLMain">
          <span className="mb5">{t('ITEM_ACL.LOADING')}</span>
          <IconWithSpinner spinning={true} />
        </div>
      )}
      {saving && (
        <div className="flexRowContainer flexCenter mb15">
          <span className="mb5">{t('ITEM_ACL.SAVING')}</span>
          <IconWithSpinner spinning={true} />
        </div>
      )}

      {!saving && !loading && (
        <div id="aclFormGroup" className="flexRowContainer flexFill mt15 overflowVisible">
          {t('ITEM_ACL.LABEL')}
          {itemAcl.permissionsInheritanceDisabled && (
            <span className="sq-text-warning cursorPointer link-no-underline">
              {t('ITEM_ACL.ADVANCED.DISABLED_INDICATOR')}
            </span>
          )}

          {isAclFromDatasource && <div className="text-italic sq-text-warning">{t('ITEM_ACL.FROM_DATASOURCE')}</div>}

          <div className="flexRowContainer mt10">
            {tableHeader}
            {tableBody}
            {!isAclFromDatasource && newAceRow}
          </div>

          <div className="flexRowContainer mt30">
            {((includeLinksAndCorporateMessage && worksheetId) || showOnlyFolderLinks) && (
              <GetLinks
                workbookId={workbookId}
                worksheetId={worksheetId}
                folderId={folderId}
                showOnlyFolderLinks={showOnlyFolderLinks}
                hasViewOnly={!isVantage}
                hasPresentation={!isVantage}
                isVantage={isVantage}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
