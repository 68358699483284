import _ from 'lodash';
import { logError, logInfo } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';
import { close as socketClose, waitForOpen } from '@/utilities/socket.utilities';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { waitForAppQuiescence, waitForPluginQuiescence } from '@/utilities/utilities';
import { cancelAll } from '@/requests/pendingRequests.utilities';
import { getStorageSafely } from '@/utilities/storage.utilities';
import { Visualization, VisualizationData } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { onAllServerRequestsCanceled } from '@/services/notifier.service';
import { PERSISTENCE_LEVELS } from '@/core/flux.service';
import { sqStateSynchronizer } from '@/core/core.stores';
import { HeadlessCategory, HeadlessJobFormat } from '@/services/headlessCapture.constants';
import { goTo, navigateToHeadlessRenderStandby } from '@/main/routing.utilities';
import { isSystemTest } from '@/core/utilities';

/**
 * @file Requests and manages screenshots.
 *
 * This service has some functions which only run when the app is running in the headless browser used by
 *   NodeJS to capture screenshots. The 'callback' mechanism of that browser is used to send
 *   messages to NodeJS to inform it when the screenshot is ready to be taken.
 */

// Screenshot rendering only supports a small subset of paths within the application, if the headless browser is
// redirected to a page that isn't allowed the capture should fail. The load-error page is allowed because it has
// special handling for failing the headless capture with a relevant message.
export const ALLOWED_SCREENSHOT_MODE_PATHS = /^\/(present|headless-capture-standby|load-error|report-template)/;

let captureMetadata: { category?: HeadlessCategory; jobFormat?: HeadlessJobFormat } = {};

/**
 * Tells headless capture browser that the app is ready to capture a screenshot.
 * This function does nothing unless the app is being run in the headless capture browser.
 */
export function notifyCapture() {
  function waitForQuiescence() {
    return Promise.all([waitForAppQuiescence(), waitForPluginQuiescence()]);
  }

  if (headlessRenderMode() || isSystemTest()) {
    // Wait for the app to settle (i.e. no more http requests or timers to fire), then wait 100ms and wait for
    // the app to settle again. This is an attempt to handle views that request data asynchronously some
    // (short) time after the view is first loaded.
    waitForQuiescence()
      .then(() => {
        return new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
      })
      .then(waitForQuiescence)
      .then(() => {
        window.seeqHeadlessCapture?.();
        // @ts-ignore Used by playwright to know when page is settled
        window.isSeeqPageSettled = true;
      });
  }
}

/**
 * Tells the headless capture browser that the page is loading.
 * This function does nothing unless the app is being run in the headless capture browser.
 */
export function notifyLoading() {
  if (headlessRenderMode()) {
    window.seeqHeadlessLoading();
  }
}

/**
 * Tells the headless capture browser that the page encountered an unrecoverable error
 * This function does nothing unless the app is being run in the headless capture browser.
 */
export function notifyError(...errors: string[]) {
  if (headlessRenderMode()) {
    window.seeqHeadlessError(...errors);
  }
}

export function notifyWarning(warning: string) {
  if (headlessRenderMode()) {
    window.seeqHeadlessWarning(warning);
  }
}

/**
 * Tells the headless capture browser that the currently-loading workbook has been cancelled by a user or
 * administrator.  This function does nothing unless the app is being run in the headless capture browser.
 */
export function notifyCancellation(...errors: string[]) {
  if (headlessRenderMode()) {
    window.seeqHeadlessCancellation(...errors);
  }
}

/**
 * Gets the category of headless capture being requested
 */
export function headlessCaptureMetadata() {
  return captureMetadata;
}

const DEFAULT_VISUALIZATION: VisualizationData = { visualization: Visualization.NONE };
// Exposed for testing
export const DEFAULT_GET_VISUALIZATION_DATA = () => Promise.resolve(DEFAULT_VISUALIZATION);

/**
 * Attaches callbacks to window that puppeteer can call as well as a state change handler that closes the socket
 * and clears local storage. Does nothing if not in screenshot render mode. See custom-typings.d.ts (Window) for
 * more detailed description of the callbacks attached
 */
export function initializeHeadlessCaptureMode() {
  if (!headlessRenderMode()) {
    return;
  }

  window.seeqGetVisualizationData = DEFAULT_GET_VISUALIZATION_DATA;

  // Provides a hook that the headless capture browser can use to cancel all requests from this browser
  window.seeqInternalPageCleanup = () => {
    navigateToHeadlessRenderStandby();
    window.seeqGetVisualizationData = DEFAULT_GET_VISUALIZATION_DATA;
    logInfo("Cleaning up internal state via 'window.seeqInternalCleanup'");
    return (
      cancelAll()
        .catch((e) => logError(formatMessage`Error cancelling all requests: ${e}`))
        // Not all screenshots require the socket to be open, but the socket service will get into a bad state if
        // the socket is closed before it is fully established - to prevent this wait for the socket to be open before
        // capturing the screenshot
        .then(() => waitForOpen())
        .catch((e) => logError(formatMessage`Error waiting for socket to open: ${e}`))
        .then(() => {
          socketClose();
          getStorageSafely().clear();

          // Re-initialize all stores. This is necessary since some stores have special logic to retain certain
          /* information even when they are rehydrated; search for 'saveState' for examples. */ _.forEach(
            PERSISTENCE_LEVELS,
            (persistenceLevel) => {
              sqStateSynchronizer.initialize(persistenceLevel);
            },
          );
        })
        .catch((e) => logError(formatMessage`Error cleaning up page state: ${e}`))
    );
  };

  window.seeqNavigate = (url) => {
    if (!_.isString(url) || !_.startsWith(url, '/')) {
      throw new Error(`seeqNavigate: A relative url is required, but got '${url}'`);
    }
    if (url && !ALLOWED_SCREENSHOT_MODE_PATHS.test(url.replace(/^#!/, ''))) {
      notifyError(`Could not load path not allowed in screenshot render mode: ${url}`);
    }
    setTimeout(() => {
      goTo(url);
    }, 10);
  };

  onAllServerRequestsCanceled(() => {
    notifyError('Administrator canceled all requests');
  });
}

/**
 * Fetches the category of headless capture being requested so that it can be accessed synchronously in the page load
 */
export async function fetchHeadlessCaptureMetadata() {
  if (!headlessRenderMode()) {
    return Promise.resolve();
  }

  const [category, jobFormat] = await Promise.all([window.seeqHeadlessCategory(), window.seeqJobFormat()]);
  if (!isHeadlessCategory(category)) {
    throw new Error(`Error: '${category}' category is unexpected; the frontend enum likely needs to be updated`);
  }
  if (!isHeadlessJobFormat(jobFormat)) {
    throw new Error(`Error: '${jobFormat}' jobFormat is unexpected; the frontend enum likely needs to be updated`);
  }
  captureMetadata = {
    category,
    jobFormat,
  };
}

/**
 * Test if {@param category} is a HeadlessCategory
 */
function isHeadlessCategory(category: unknown): category is HeadlessCategory {
  return _.isString(category) && Object.values<string>(HeadlessCategory).includes(category);
}

/**
 * Test if {@param jobFormat} is a HeadlessJobFormat
 */
function isHeadlessJobFormat(jobFormat: unknown): jobFormat is HeadlessJobFormat {
  return _.isString(jobFormat) && Object.values<string>(HeadlessJobFormat).includes(jobFormat);
}
