import { ITEM_TYPES } from '@/trendData/trendData.constants';

export interface Item {
  id: string;
  itemType: ITEM_TYPES;
  name: string;
  assets?: Item[];
}

export interface Signal extends Item {
  signalMetadata?: {
    valueUnitOfMeasure: string;
  };
  valueUnitOfMeasure?: string;
  sourceValueUnitOfMeasure?: string;
}

export interface ThresholdMetric extends Item {
  definition: string;
}

export function isThresholdMetric(item: Item | ThresholdMetric): item is ThresholdMetric {
  return (item as ThresholdMetric).definition !== undefined;
}
