// @ts-strict-ignore
import React, { ReactNode, useEffect, useState } from 'react';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, ButtonWithPopover } from '@seeqdev/qomponents';
import { ColorPicker } from '@/workbook/ColorPicker.organism';
import _ from 'lodash';

interface textFormatterPopoverIF {
  backgroundColor: string;
  textAlign: string;
  textColor: string;
  textStyle: string[];
  columnKey: string;
  colors?: string[];
  onClose?: () => void;
  setTextAlign: (columnKey: string, alignment: string) => void;
  setTextBackground?: (columnKey: string | number, color: string) => void;
  setTextColor?: (columnKey: string | number, color: string) => void;
  setTextStyle: (columnKey: string, weight: string[]) => void;
  placement: TooltipPosition;
  show?: boolean;
  target: ReactNode;
  title: string;
}

/**
 * Popover with selections for formatting text, e.g. bold/italic, colors, alignment
 */
export const TextFormatterPopover: React.FunctionComponent<textFormatterPopoverIF> = (props) => {
  const {
    backgroundColor,
    textAlign,
    textColor,
    textStyle = [],
    colors,
    columnKey,
    setTextAlign = () => {},
    setTextBackground,
    setTextColor,
    setTextStyle = () => {},
    onClose = () => {},
    placement,
    show = false,
    target,
    title,
  } = props;

  const { t } = useTranslation();

  const [showPopover, setShowPopover] = useState(show);

  useEffect(() => {
    if (show) {
      openPopover();
    }
  }, [show]);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.getAttribute('data-itemid') === columnKey) {
        return;
      }
      closePopover();
    };

    // add when mounted
    document.querySelector('#mainView')?.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const openPopover = () => {
    if (showPopover) {
      closePopover();
    }
  };

  const closePopover = () => {
    setShowPopover(false);
    onClose();
  };

  const handleTextStyleClick = (value) => {
    return textStyle.includes(value) ? textStyle.filter((v) => v !== value) : [...textStyle, value];
  };

  return (
    <ButtonWithPopover
      isPortal
      onInteractOutside={() => {
        onClose && onClose();
        setShowPopover(false);
      }}
      trigger={target}
      isOpen={show}
      placement={placement}>
      <div id="textFormatterPopover" data-testid="textFormatterPopover">
        <div className="popover-header">{t(title)}</div>
        <div className="popover-body p10">
          <div className="flexColumnContainer flexSpaceBetween">
            {setTextBackground ? (
              <div className="flexColumnContainer mb10">
                <span className="mr10">{t('TABLE_BUILDER.BACKGROUND_COLOR')}</span>
                <div>
                  <ColorPicker
                    color={backgroundColor}
                    colors={colors}
                    itemId={columnKey}
                    notifyOnSelect={(columnKey, color) => setTextBackground(columnKey, color)}
                    placement="bottom"
                    dataTestId="backgroundColorPicker"
                  />
                </div>
              </div>
            ) : null}
            {setTextColor ? (
              <div className="flexColumnContainer mb10">
                <span className="mr10">{t('TABLE_BUILDER.TEXT_COLOR')}</span>
                <div>
                  <ColorPicker
                    color={textColor}
                    colors={colors}
                    itemId={columnKey}
                    notifyOnSelect={(columnKey, color) => setTextColor(columnKey, color)}
                    placement="bottom"
                    dataTestId="textColorPicker"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="flexColumnContainer mb10">
            <ButtonGroup
              testId="textStyleGroup"
              onChange={(value) => setTextStyle(columnKey, handleTextStyleClick(value))}
              buttons={[
                {
                  variant: 'button',
                  buttonProps: {
                    size: 'sm',
                    icon: 'fa-bold',
                    value: 'bold',
                    isActive: _.includes(textStyle, 'bold'),
                  },
                },
                {
                  variant: 'button',
                  buttonProps: {
                    size: 'sm',
                    icon: 'fa-italic',
                    value: 'italic',
                    isActive: _.includes(textStyle, 'italic'),
                  },
                },
                {
                  variant: 'button',
                  buttonProps: {
                    size: 'sm',
                    icon: 'fa-underline',
                    value: 'underline',
                    isActive: _.includes(textStyle, 'underline'),
                  },
                },
                {
                  variant: 'button',
                  buttonProps: {
                    size: 'sm',
                    icon: 'fa-strikethrough',
                    value: 'line-through',
                    isActive: _.includes(textStyle, 'line-through'),
                  },
                },
                {
                  variant: 'button',
                  buttonProps: {
                    icon: 'fa-minus',
                    value: 'overline',
                    size: 'sm',
                    isActive: _.includes(textStyle, 'overline'),
                  },
                },
              ]}
            />
          </div>
          <div className="flexColumnContainer">
            <ButtonGroup
              testId="textAlignGroup"
              onChange={(value) => setTextAlign(columnKey, value)}
              buttons={[
                {
                  variant: 'button',
                  buttonProps: {
                    size: 'sm',
                    icon: 'fa-align-left',
                    value: 'left',
                    isActive: textAlign === 'left',
                  },
                },
                {
                  variant: 'button',
                  buttonProps: {
                    size: 'sm',
                    icon: 'fa-align-center',
                    value: 'center',
                    isActive: textAlign === 'center',
                  },
                },
                {
                  variant: 'button',
                  buttonProps: {
                    size: 'sm',
                    icon: 'fa-align-right',
                    value: 'right',
                    isActive: textAlign === 'right',
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
    </ButtonWithPopover>
  );
};
