import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon } from '@seeqdev/qomponents';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { FormError } from '@/core/FormError.atom';
import { IconWithPopoverTooltip } from '@/core/IconWithPopoverTooltip.molecule';

export interface FormFieldWrapperProps {
  testId?: string;
  children: React.ReactElement;
  label?: string | React.ReactNode;
  labelIcon?: string;
  labelIconAction?: () => void;
  labelIconTooltip?: string;
  labelClassNames?: string;
  id?: string;
  extraClassNames?: string;
  wrapperClassNames?: string;
  showError?: boolean;
  customErrorText?: string;
  customErrorParams?: Record<string, any>;
  tooltip?: string;
  tooltipPlacement?: TooltipPosition;
  onTooltipClick?: () => void;
  popoverContent?: React.ReactNode;
  errorClassNames?: string;
  warningText?: string;
}

export const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({
  label,
  extraClassNames,
  testId = 'formComponent',
  children,
  showError,
  customErrorText,
  customErrorParams = {},
  wrapperClassNames,
  tooltip,
  tooltipPlacement,
  onTooltipClick,
  popoverContent,
  id,
  errorClassNames,
  labelIcon,
  labelIconAction,
  labelIconTooltip,
  labelClassNames,
  warningText,
}) => {
  const { t } = useTranslation();

  const renderLabel = () => {
    if (typeof label !== 'string') {
      return label;
    }

    const labelTestId = `${testId}Label`;

    if (label && (tooltip || popoverContent)) {
      return (
        <div className="flexColumnContainer flexAlignCenter mb5" data-testid={labelTestId}>
          <span className={classNames('mr5', labelClassNames)}>{t(label)}</span>
          {tooltip ? (
            <Icon
              icon="fa-question-circle"
              large={true}
              tooltip={t(tooltip)}
              testId={`${labelTestId}_icon`}
              tooltipPlacement={tooltipPlacement}
              onClick={onTooltipClick}
            />
          ) : (
            <IconWithPopoverTooltip placement="top" testId={`${labelTestId}Popover`} content={popoverContent} />
          )}
        </div>
      );
    }

    return (
      <span className={classNames('mb5', labelClassNames)} data-testid={labelTestId}>
        {t(label)}
        {labelIcon && (
          <Icon
            icon={labelIcon}
            large={true}
            onClick={labelIconAction}
            tooltip={labelIconTooltip ? t(labelIconTooltip) : ''}
          />
        )}
      </span>
    );
  };

  const renderChildren = () => {
    if (!label && tooltip) {
      return (
        <div className="flexColumnContainer flexAlignCenter flexFill">
          {children}
          <Icon
            icon="fa-question-circle"
            large={true}
            tooltip={t(tooltip)}
            tooltipPlacement={tooltipPlacement}
            extraClassNames="ml5"
            onClick={onTooltipClick}
          />
        </div>
      );
    }

    return children;
  };

  return (
    <div id={id} className={classNames('flexRowContainer mb8', wrapperClassNames)} data-testid={testId}>
      {renderLabel()}
      <div className={extraClassNames} data-testid={`${testId}FieldWrapper`}>
        {renderChildren()}
      </div>
      {showError && <span data-testid="showerror" />}
      {showError && customErrorText && (
        <FormError
          errorText={customErrorText}
          errorParameters={customErrorParams}
          dataTestId={`${testId}Error`}
          extraClassNames={errorClassNames ?? 'mt5 mb0'}
        />
      )}
      {warningText && <p className="sq-text-warning mt5 mb0">{t(warningText)}</p>}
    </div>
  );
};
