import { SelectOption } from '@/formbuilder/SelectFormComponent';
import { LabelCategoryOutputV1 } from '@/sdk/model/LabelCategoryOutputV1';
import { AGGridTableTileProps } from '@/impactReports/tiles/AGGridTableTile.atom';
import { GrayBackgroundTitleAndNumberTileProps } from '@/impactReports/tiles/GrayBackgroundTitleAndNumberTileProps.atom';
import { AGGridCrossFilterChartTileProps } from '@/impactReports/tiles/AGGridCrossFilterChartTile.atom';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export const IMPACT_REPORT_DATE_FORMAT = 'MMM D, YYYY';

export const CALCULATED_IMPACT = 'calculatedImpact';
export const IMPACT_OWNER_FIRSTNAME = 'Impact Owner.properties.First Name';
export const IMPACT_OWNER_LASTNAME = 'Impact Owner.properties.Last Name';
export const MANAGER_ID = 'managerId';
export const IS_MANAGER = 'isManager';
export const MANAGER_FIRSTNAME = 'Manager.properties.First Name';
export const MANAGER_LASTNAME = 'Manager.properties.Last Name';

export const IMPACT_REPORT_CATEGORY_DISPLAY_NAME = 'Category Display Name';
export const IMPACT_REPORT_LABEL_ID = 'Label Id';

export const TRACK_IMPACT = 'Impact Report';

export interface ImpactReportConfiguration {
  showSite: boolean;
  showAssetPath: boolean;
}
export const OPEN_CREATE_MODAL_QUERY_PARAM = 'createImpact';
export const NOMINATE_IMPACT_QUERY_PARAM = 'nominateImpact';
export const IMPACT_REPORT_CONTEXT_CATEGORY = 'VCC_ImpactReportNominationCategory';
export const IMPACT_REPORT_NOMINATION_LABEL = 'VCC_ImpactReportNominationLabel';
export interface ImpactReportTile {
  type: 'chart' | 'table' | 'gauge' | 'box';
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
  id: string;
}

export const defaultLayoutDefinition = [
  {
    type: 'box',
    id: 'useCaseCountBox',
    i: 'useCaseCountBox',
    w: 1,
    x: 0,
    y: 0,
    h: 22,
    title: 'IMPACT_REPORT.TOTAL_USE_CASES',
    property: 'useCaseCount',
    format: false,
  },
  {
    type: 'box',
    id: 'totalImpactBox',
    i: 'totalImpactBox',
    w: 1,
    x: 1,
    y: 0,
    h: 22,
    title: 'IMPACT_REPORT.TOTAL_IMPACT',
    property: 'overallImpact',
    format: true,
  },
  {
    type: 'box',
    id: 'biggestImpactBox',
    i: 'biggestImpactBox',
    w: 1,
    x: 2,
    y: 0,
    h: 22,
    title: 'IMPACT_REPORT.BIGGEST_SINGLE_IMPACT',
    property: 'biggestImpact',
    format: true,
  },
  {
    type: 'gauge',
    id: 'gauge',
    i: 'gauge',
    w: 3,
    x: 3,
    y: 0,
    h: 22,
  },
  {
    type: 'table',
    id: 'table',
    i: 'table',
    w: 12,
    x: 0,
    y: Number.MAX_SAFE_INTEGER,
    h: 40,
  },
];

export type ImpactReportChartDefinition = ImpactReportTile & AGGridCrossFilterChartTileProps & { type: 'chart' };
export type ImpactReportTableDefinition = ImpactReportTile & AGGridTableTileProps & { type: 'table' };
export type ImpactReportBoxTileDefinition = ImpactReportTile & GrayBackgroundTitleAndNumberTileProps & { type: 'box' };
export type ImpactReportGaugeTileDefinition = ImpactReportTile & { type: 'gauge' };

export type ImpactReportDashboardContent =
  | ImpactReportChartDefinition
  | ImpactReportTableDefinition
  | ImpactReportBoxTileDefinition
  | ImpactReportGaugeTileDefinition;

export const IMPACT_REPORT_CATEGORY_PREFIX = 'VCC_';
export type Stages = 'identify' | 'categorize' | 'quantify' | 'monetize';
export const availableStages: Stages[] = ['identify', 'categorize', 'quantify', 'monetize'];
export const stageIcons = ['fa-lightbulb-on', 'fa-list', 'fa-calculator', 'fa-piggy-bank'];

export type ImpactType = 'oneTime' | 'continuous' | 'customCalc';
export const quantifyUnits = {
  'Time': ['d', 'h'],
  'Currency': ['$', 'M$', 'M£', 'M¤', 'M¥', 'M₩', 'M€', 'k$', 'k£', 'k¤', 'k¥', 'k₩', 'k€', '£', '¤', '¥', '₩', '€'],
  'Energy': [
    'BTU',
    'GJ',
    'GW·h',
    'J',
    'MBTU',
    'MJ',
    'MW·h',
    'Mcal',
    'W·h',
    'W·s',
    'cal',
    'ftlb',
    'kBTU',
    'kJ',
    'kW·h',
    'kcal',
  ],
  'Volume Flow Rate': [
    'L/h',
    'L/min',
    'L/s',
    'bbl/d',
    'bbl/h',
    'bbl/min',
    'cf/d',
    'cf/h',
    'cf/min',
    'cm³/min',
    'cm³/s',
    'gal/d',
    'gal/h',
    'gal/min',
    'gal/s',
    'mL/h',
    'mL/min',
    'mL/s',
    'm³/h',
    'm³/min',
    'm³/s',
  ],
  'Quantity': ['count', 'count/min'],
  'Power': ['BTU/h', 'GW', 'MBTU/d', 'MBTU/h', 'MJ/h', 'MW', 'Mcal/h', 'W', 'cal/s', 'hp', 'kW'],
  'Mass Flow Rate': [
    'Mlb/d',
    'g/min',
    'g/s',
    'kg/d',
    'kg/h',
    'kg/min',
    'kg/s',
    'klb/d',
    'klb/h',
    'lb/s',
    'lton/d',
    'ston/d',
    't/d',
  ],
  'Volume': [
    'L',
    'ML',
    'Mbbl',
    'Mcf',
    'Mgal',
    'bbl',
    'cf',
    'cm³',
    'dL',
    'gal',
    'hL',
    'kL',
    'kbbl',
    'kcf',
    'kgal',
    'mL',
    'm³',
    'nL',
  ],
  'Area': ['acre', 'cm²', 'ft²', 'ha', 'in²', 'km²', 'mi²', 'mm²', 'm²', 'yd²'],
  'Length': ['cm', 'ft', 'in', 'km', 'm', 'mi', 'mm', 'nmi', 'sxi', 'yd', 'µm'],
  'Temperature': ['°C', '°F'],
};

export interface ImpactReportCategory {
  category: LabelCategoryOutputV1;
  options: SelectOption<string>[] | undefined;
}

export interface ImpactReportUseCase {
  [SeeqNames.ImpactReport.ImpactOwner]: string;
  [SeeqNames.ImpactReport.ImpactName]: string;
  [IMPACT_OWNER_FIRSTNAME]: string | undefined;
  [IMPACT_OWNER_LASTNAME]: string | undefined;
  [IS_MANAGER]?: boolean;
  [MANAGER_ID]?: string;
  [SeeqNames.ImpactReport.ImpactStatus]?: Stages;
  [SeeqNames.ImpactReport.AssignedToWorksheet]?: string;
  [SeeqNames.ImpactReport.AssignedToWorkbook]?: string;
  [SeeqNames.MaterializedTables.DatumIdColumn]?: string;
  [SeeqNames.MaterializedTables.ItemIdColumn]?: string;
}

export const instructions =
  '<p>The <strong>Description</strong> field should clearly describe the technical problem or challenge your team is facing. This may include issues with systems, processes, or equipment. Be as specific as possible, focusing on the following points:</p><ol class="ak-ol" data-indent-level="2" start="1"><li><p><strong data-renderer-mark="true">Problem Overview</strong>: Provide a concise explanation of the issue you are trying to solve. Mention the system, process, or equipment involved, and explain the challenge (e.g., pressure fluctuations, overloads, nuisance alarms).</p></li><li><p data-renderer-start-pos="3269"><strong>Impact</strong>: Describe the potential or actual negative impact of the issue. This could include safety risks, financial costs, production delays, or regulatory concerns.</p></li><li><p data-renderer-start-pos="3436"><strong>Specific Technical Details</strong>: Include any technical metrics or specifications related to the issue (e.g., pressure thresholds, system overloads, number of alarms, temperature limits). Be as detailed as possible so the problem is clearly understood.</p></li><li><p><strong data-renderer-mark="true">Action Items</strong>: Outline the steps or actions that will be taken to address the technical challenge. Specify who is responsible for each action, deadlines, and any tools or resources required for resolution or implementation.</p></li></ol>';

export interface FlaggedItem {
  name: string;
  workbookId?: string;
  worksheetId: string;
  owner?: string;
  labelId?: string;
}
