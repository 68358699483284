import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@seeqdev/qomponents';

interface FormWarningProps {
  warningText: string;
  extraClassNames?: string;
}

export const FormWarning: React.FunctionComponent<FormWarningProps> = (props) => {
  const { warningText, extraClassNames } = props;
  const { t } = useTranslation();

  return (
    <Alert dismissible={false} extraClassNames={extraClassNames} variant="warning">
      {t(warningText)}
    </Alert>
  );
};
