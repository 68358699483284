import _ from 'lodash';

/**
 * Splits a uniqueID into its constituent parts: conditionId and capsuleId
 *
 * @param id - the uniqueId
 * @see .getUniqueId()
 */
export function splitUniqueId(id: string): {
  /** The id of the condition to which the capsule  */
  conditionId: string;
  /** The id of a capsule that is supplied by the backend */
  capsuleId: string;
} {
  const [conditionId, capsuleId] = id.split('_');

  return { conditionId, capsuleId };
}

/**
 * Computes the zones of for a chart item that are selected and therefore colored appropriately.
 *
 * @param {Object} condition - The condition to use for calculating zones
 * @param {String} condition.color - The color of the condition on the trend.
 * @param {Object[]} condition.capsules - The capsules of the condition.
 * @param {String[]} selectedIds - Array of selected capsule ids
 *
 * @return {any[]} Array of two zones per selected capsule, one zone up to the start time with no special color
 * and then a zone with the item's color going up to the end time of the capsule.
 */

export function computeChartZones(condition: any, selectedIds: string[]): any[] {
  return _.transform(condition.capsules, function (zones, capsule: any) {
    if (_.includes(selectedIds, capsule.id)) {
      // to ensure zero length capsules can show as selected we have to slightly alter the end of the zone that is
      // used to show the capsule as selected
      const endTime = capsule.startTime === capsule.endTime ? capsule.endTime + 1 : capsule.endTime;
      zones.push({ value: capsule.startTime });
      zones.push({ value: endTime, color: condition.color });
    }
  });
}
