import _ from 'lodash';
import { ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { BaseItemStore } from '@/trendData/baseItem.store';
import { PROPS_TO_DEHYDRATE } from '@/trendData/baseItem.constants';
import { InitializeMode } from '@/core/flux.service';
import { ConditionMonitorMetadataOutputV1 } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ConditionItem } from '@/trendData/trendData.types';
import { ReactSelectOption } from '@/core/IconSelect.molecule';
import { t } from 'i18next';

/**
 * A store for containing Conditions. Conditions are used to generate capsules.
 *
 * This store is augmented with additional functionality from BaseItemStore.
 */
export class TrendConditionStore extends BaseItemStore<ConditionItem> {
  static readonly storeName = 'sqTrendConditionStore';
  initialize(initializeMode: InitializeMode) {
    super.initialize(initializeMode);
  }

  /**
   * Adds a Condition item.
   *
   * @param {Object} payload - Object container for arguments
   */
  private addCondition = (payload: {
    /** ID of the new Condition */
    id: string;
    /** Name of the new Condition */
    name: string;
    /** Color hex code (e.g. #CCCCCC) */
    color: string;
    /** Lane for the new Condition */
    lane: number;
    /** Represents the thickness of capsules in new Condition */
    lineWidth: number;
  }) => {
    const props = _.pick(payload, ['color', 'lane', 'lineWidth']);
    this.state.push('items', this.createCondition(payload.id, payload.name, props));
  };

  /**
   * Private helper function to add a condition to the store using the specified properties.
   *
   * @param {String} id - ID to use for the new Condition
   * @param {String} name - Name to use for the new Condition
   * @param {Object} props - Object containing properties to apply to the new calculation
   *
   * @returns Newly created Condition object.
   */
  private createCondition = (id: string, name: string, props: any) => {
    return this.createItem(
      id,
      name,
      ITEM_TYPES.CONDITION,
      _.assign(
        {
          dataStatus: ITEM_DATA_STATUS.INITIALIZING,
        },
        props,
      ),
    );
  };

  /*
   Find the number of items in the same lane, then the options are the lane numbers from 1 to the number of
   items in the lane, plus the unoverlay option
   */
  public getOverlayOptions(lane: number): ReactSelectOption[] {
    const itemsInLane = this.items.filter((condition) => condition.lane === lane);
    if (itemsInLane.length <= 1) {
      return [];
    }

    const laneNumbers = _.range(1, itemsInLane.length + 1);
    const options: ReactSelectOption[] = laneNumbers.map((laneNumber, index) => ({
      text: `${laneNumber}${
        (index === 0 && ` ${t('DETAILS_PANE.SEND_TO_BACK')}`) ||
        (index === laneNumbers.length - 1 && ` ${t('DETAILS_PANE.SEND_TO_FRONT')}`) ||
        ''
      }`,
      value: laneNumber,
    }));

    return options.concat([{ text: 'DETAILS_PANE.DO_NOT_OVERLAY', value: undefined }]);
  }

  /**
   * Generates the overlays for all conditions in the same lane as the specified item, accounting for adjustments
   * that need to be made due to the addition of the specified item.
   */
  getConditionOverlays(
    item: ConditionItem,
    overlay: number | undefined,
  ): { id: string; overlay: number | undefined }[] {
    const otherConditionsInLane = this.items.filter(
      (condition) => condition.id !== item.id && condition.lane === item.lane,
    );
    const updates = [{ id: item.id, overlay }];

    if (!overlay) {
      return updates.concat(otherConditionsInLane.map((condition) => ({ id: condition.id, overlay: undefined })));
    }

    const conditionInLaneWithSameOverlay = otherConditionsInLane.find((condition) => condition.overlay === overlay);
    const conditionsWithoutOverlay = otherConditionsInLane
      .filter((condition) => !condition.overlay)
      .concat(conditionInLaneWithSameOverlay ?? []);

    if (conditionsWithoutOverlay.length > 0) {
      const existingOverlays = [overlay].concat(
        otherConditionsInLane.filter((condition) => !!condition.overlay).map((condition) => condition.overlay!),
      );
      const possibleOverlays = _.range(1, otherConditionsInLane.length + 2).filter(
        (overlay) => !existingOverlays.includes(overlay),
      );
      conditionsWithoutOverlay.forEach((condition) => {
        updates.push({ id: condition.id, overlay: possibleOverlays.shift() });
      });
    }

    return updates;
  }

  protected readonly handlers = {
    ...super.baseHandlers,
    TREND_ADD_CAPSULE_SET: this.addCondition,
    TREND_SET_MONITORED_CONDITION_IDS: ({
      conditionIds,
      conditionMonitorMetadataList,
    }: {
      conditionIds: string[];
      conditionMonitorMetadataList: ConditionMonitorMetadataOutputV1[];
    }) => {
      const initialIdMap = _.reduce(
        conditionIds,
        (ids, conditionId) => {
          ids.set(conditionId, { notifications: [], vantages: [] });

          return ids;
        },
        new Map<string, { notifications: string[]; vantages: string[] }>(),
      );
      const idMap = conditionMonitorMetadataList
        .flatMap((conditionMonitorMetadata) =>
          conditionMonitorMetadata.conditionIds.map((id) => ({
            conditionId: id,
            conditionMonitorMetadata,
          })),
        )
        .reduce((ids, { conditionId, conditionMonitorMetadata }) => {
          const monitoredCondition = ids.get(conditionId);
          if (monitoredCondition) {
            conditionMonitorMetadata.workbook?.type === SeeqNames.Types.Vantage
              ? monitoredCondition.vantages.push(conditionMonitorMetadata.id)
              : monitoredCondition.notifications.push(conditionMonitorMetadata.id);
          }

          return ids;
        }, initialIdMap);

      idMap.forEach(({ notifications, vantages }, conditionId) => {
        this.setProperties({ id: conditionId, notifications, vantages });
      });
    },
  };

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated items.
   */
  dehydrate() {
    return {
      items: _.chain(this.state.get('items'))
        .filter(this.shouldDehydrateItem)
        .map((item) => _.pick(item, PROPS_TO_DEHYDRATE as any))
        .value(),
    };
  }

  /**
   * Re-creates the conditions.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: any) {
    this.state.set(
      'items',
      _.map(dehydratedState.items, (item) => this.createCondition(item.id, item.name, _.omit(item, 'id', 'name'))),
    );
  }
}
