import _ from 'lodash';
import { sqWorkbenchStore } from '@/core/core.stores';
import { AceOutputV1 } from '@/sdk/model/AceOutputV1';
import { auditingAllUsersCanRead } from '@/services/systemConfiguration.utilities';
import { CapabilityInheritanceOutputV1, IdentityPreviewV1, sqUserGroupsApi } from '@/sdk';
import { CapabilityEnum } from '@/sdk/model/CapabilityInheritanceOutputV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export interface Capability extends IdentityPreviewV1 {
  capability: CapabilityEnum;
}

let capabilities: Capability[] | undefined;

/**
 * Returns a list of all Capability user groups
 */
export const getAllCapabilities = async (): Promise<Capability[]> => {
  if (capabilities) return capabilities;

  return await sqUserGroupsApi.getCapabilityUserGroups({}).then(({ data }) => {
    capabilities = data.items?.map((cap) => {
      let capabilityEnum: CapabilityEnum;
      if (cap.name === SeeqNames.Capabilities.Administration.DataId) {
        capabilityEnum = CapabilityEnum.Admins; // For Administrators, the name doesn't match the CapabilityEnum :|
      } else {
        capabilityEnum = CapabilityEnum[cap.name as keyof typeof CapabilityEnum];
      }
      return { ...cap, capability: capabilityEnum };
    });
    return capabilities!;
  });
};

export const getAdministratorGroupId = async (): Promise<string> => {
  const capabilities = await getAllCapabilities();
  const admin = capabilities.find((cap) => cap.capability === CapabilityEnum.Admins);
  return admin?.id ?? (await Promise.reject('Administrators not found'));
};

export const isCapabilityGroup = async (groupID: string) => {
  const capabilities = await getAllCapabilities();
  return capabilities?.some((capability) => capability.id === groupID);
};

const userHasCapability = (capabilityToTest: CapabilityEnum): boolean => {
  return (
    _.findIndex(
      sqWorkbenchStore.currentUser.capabilityGrants,
      (capability: CapabilityInheritanceOutputV1) => capability.capability === capabilityToTest,
    ) >= 0
  );
};

/**
 * Determines if the Audit Trail logs can be viewed by the current user
 *
 * @return true if the current user can view Audit Trail Logs,
 * otherwise false even if the setting is null or undefined
 */
export function canReadAuditTrail(): boolean {
  return (
    // TODO CRAB-41986: Remove the config check once the config is removed
    auditingAllUsersCanRead() === true || userHasCapability(CapabilityEnum.AuditTrailCapability)
  );
}

/**
 * Determines if the current user can read the item
 *
 * @param item - an item
 * @returns true if the user can read the item, false otherwise
 */
export function canReadItem(item: any): boolean {
  return isAdmin() || !!item?.effectivePermissions?.read;
}

/**
 * Determines if the current user can write the item
 *
 * @param item - an item
 * @returns true if the user can write the item, false otherwise
 */
export function canWriteItem(item: any): boolean {
  return (isAdmin() || !!item?.effectivePermissions?.write) && !item?.isLocked;
}

/**
 * Determines if the current user can annotate the item
 *
 * @param item - an item
 * @returns true if the user can annotate the item, false otherwise
 */
export function canAnnotateItem(item: any): boolean {
  // Users in Seeq can annotate an item if they have permission to read it
  return isAdmin() || !!item?.effectivePermissions?.read;
}

/**
 * Determines if the current user can manage the item (i.e. view and change ACL permissions)
 *
 * @param item - an item
 * @returns true if the user can manage the item, false otherwise
 */
export function canManageItem(item: any): boolean {
  return isAdmin() || !!item?.effectivePermissions?.manage;
}

/**
 * Determines if a user has exclusive access
 *
 * @param acl - an access control list
 * @param userId - a user ID
 * @returns true if exclusive access; false otherwise
 */
export function userHasExclusiveAccess(acl: AceOutputV1[], userId = sqWorkbenchStore.currentUser.id): boolean {
  return !_.chain(acl)
    .reject((ace) => ace?.identity?.id === userId || !_.some(ace.permissions))
    .some()
    .value() as boolean;
}

/**
 * Determines if the specified workbook can be modified by the current user.
 *
 * @param  workbook - The workbook to test
 * @param  disallowArchived - if true prevent modification of archived workbooks
 * @return True if it can be modified, otherwise false
 */
export function canModifyWorkbook(workbook: any, disallowArchived = true): boolean {
  if (disallowArchived && workbook?.isArchived) {
    return false;
  }

  return canWriteItem(workbook);
}

export const isWorkbookLocked = (workbook: any): boolean => workbook?.locked;

/**
 * Allows the user to do something if they are an administrator.
 *
 * @return True if user is an administrator, false otherwise
 */
export function isAdmin(): boolean {
  return !!sqWorkbenchStore.currentUser.isAdmin;
}

/**
 * @return True if current user has the UserAdministrationCapability
 */
export function hasUserAdminCapability(): boolean {
  return userHasCapability(CapabilityEnum.UserAdministrationCapability);
}

/**
 * @return True if current user has the DatasourceAdministrationCapability
 */
export function hasDatasourceAdminCapability(): boolean {
  return userHasCapability(CapabilityEnum.DatasourceAdministrationCapability);
}

/**
 * @return True if current user has the AnalyticsAdministrationCapability
 */
export function hasAnalyticsAdminCapability(): boolean {
  return userHasCapability(CapabilityEnum.AnalyticsAdministrationCapability);
}

export function showAdministrationMenuItem(): boolean {
  return isAdmin() || hasUserAdminCapability() || hasDatasourceAdminCapability() || hasAnalyticsAdminCapability();
}

/**
 * Allows the user to do something if the value at the supplied property path matches the current user ID.
 *
 * @param userIdPropertyPath - property path to a user ID
 * @return True if the value at the supplied property path matches the current user ID, false otherwise
 */
export function hasCurrentUserId(item: any, userIdPropertyPath: string): boolean {
  return _.get(item, userIdPropertyPath) === sqWorkbenchStore.currentUser.id;
}
