import { sqItemsApi } from '@/sdk';
import { itemIconClass, randomUUID } from '@/utilities/utilities';
import { Icon, InputGroup, Modal } from '@seeqdev/qomponents';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';
import { SearchResultRemovable } from '@/core/SearchResultRemovable.molecule';
import classNames from 'classnames';
import { SelectItemSearchModal, SelectItemSearchModalProps } from '@/core/SelectItemSearchModal.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { SelectedSearchItem } from '@/search/search.types';
import { getAssetFromAncestors } from '@/utilities/httpHelpers.utilities';
import { ExplorerModalBody } from '@/explorer/ExplorerModalBody.molecule';
import { t } from 'i18next';

interface ModalProps {
  canSelectAll?: boolean;
  helpTextKey?: string;
}

interface OtherModalProps
  extends ModalProps,
    Omit<
      SelectItemSearchModalProps,
      'isMultiple' | 'body' | 'onClose' | 'onSelect' | 'footer' | 'selectAllCallback' | 'areAllSelected'
    > {
  selectionType: 'Other';
}

interface AnalysisModalProps extends ModalProps {
  selectionType: 'Analysis';
  title: string;
}

export interface SelectItemSearchProps {
  testId: string;
  value: SelectedSearchItem[] | undefined;
  onChange: (value: SelectedSearchItem[]) => void;
  notSelectedMessage: string;
  modalProps: OtherModalProps | AnalysisModalProps;
  isMultiple?: boolean;
  itemIcon?: React.ReactNode;
  getItemIcon?: (item: SelectedSearchItem) => React.ReactNode;
  showModal?: boolean;
  onModalClose?: () => void;
  extraClassNames?: string;
  extraValueClassNames?: string;
  isDisabled?: boolean;
  showAddIcon?: boolean;
}

const defaultItemIcon = ({ item, testId }: { item: SelectedSearchItem; testId: string }): React.ReactNode => {
  return (
    <Icon
      icon={itemIconClass(item)}
      testId={`${testId}-icon`}
      type="inherit"
      extraClassNames="sq-fairly-dark-gray pl5 pr10"
      large={true}
    />
  );
};

const getItem = async (id: string): Promise<SelectedSearchItem> => {
  try {
    const { data: item } = await sqItemsApi.getItemAndAllProperties({ id });

    return { id: item.id, name: item.name, properties: item.properties, datasource: item.datasource };
  } catch (e) {
    console.error(e);

    return { name: 'SEARCH_DATA.FETCHING_ITEM_FAILED', id: randomUUID(), properties: [] };
  }
};

const formatItemName = (item: SelectedSearchItem): string => {
  return item.name ?? t('SEARCH_DATA.FETCHING_ITEM');
};

const formatItemAncestors = (item: SelectedSearchItem): string => {
  return (
    (item.ancestors?.length ? getAssetFromAncestors(item.ancestors)?.formattedName : undefined) ??
    item.datasource?.name ??
    item.properties?.find(({ name }) => name === SeeqNames.Properties.DatasourceId)?.value ??
    ''
  );
};

interface NoSelectedItemResultProps {
  testId: string;
  notSelectedMessage: string;
}

const NoSelectedItemResult: React.FunctionComponent<NoSelectedItemResultProps> = ({ testId, notSelectedMessage }) => {
  const { t } = useTranslation();

  return (
    <div className="sq-placeholder-color" data-testid={`${testId}-notSelected`}>
      <span>{t(notSelectedMessage)}</span>
    </div>
  );
};

export const SelectItemSearch: React.FunctionComponent<SelectItemSearchProps> = ({
  testId,
  value,
  onChange,
  itemIcon,
  getItemIcon,
  showModal,
  isMultiple = true,
  onModalClose,
  notSelectedMessage,
  extraClassNames,
  extraValueClassNames,
  isDisabled,
  showAddIcon,
  modalProps,
}) => {
  const { t } = useTranslation();

  const [isSelectingItem, setIsSelectingItem] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Map<string, SelectedSearchItem>>();
  const [selectedItemsInModal, setSelectedItemsInModal] = useState<Map<string, SelectedSearchItem>>();

  const canShowModal = isSelectingItem || showModal;

  useEffect(() => {
    if (showModal) {
      setIsSelectingItem(true);
    }
  }, [showModal]);

  useAsyncEffect(async () => {
    if (!value) {
      return;
    }

    const items = value.filter((item) => item !== undefined).map((item) => selectedItems?.get(item.id) ?? item);

    const values = await Promise.all(
      items.map<Promise<[string, SelectedSearchItem]>>(async (item) =>
        !item.name ? [item.id, await getItem(item.id)] : [item.id, item],
      ),
    );

    const valuesMap = new Map(values);
    setSelectedItemsInModal(valuesMap);
    setSelectedItems(valuesMap);
  }, [value]);

  /** To determine whether to show "select all" or "unselect all" */
  const areAllSelected = (items: SelectedSearchItem[]) => !items?.some((item) => !selectedItemsInModal?.has(item.id));

  /** If all items are already in the list of selectedItems, unselect all, otherwise select All. */
  const selectAllCallback = (selectedSearchItems: SelectedSearchItem[]) =>
    setSelectedItemsInModal((selectedItems) => {
      const items = new Map(selectedItems);
      if (areAllSelected(selectedSearchItems)) {
        selectedSearchItems.forEach((item) => items.delete(item.id));
      } else {
        selectedSearchItems.forEach((item) => items.set(item.id, item));
      }

      return items;
    });

  const handleItemSelectInModal = (item: SelectedSearchItem) =>
    setSelectedItemsInModal((selectedItems) => {
      const itemId = item.id;
      if (isMultiple) {
        const items = new Map(selectedItems);
        if (items.has(itemId)) {
          items.delete(itemId);
        } else {
          items.set(itemId, item);
        }

        return items;
      }

      return new Map([[itemId, item]]);
    });

  const clearAndClose = () => {
    setIsSelectingItem(false);
    onModalClose?.();
  };

  const onSave = () => {
    onChange(Array.from(selectedItemsInModal?.values() ?? []));

    setSelectedItems(selectedItemsInModal);
    clearAndClose();
  };

  const onItemRemove = (itemId: string) => {
    if (!selectedItems) {
      return;
    }

    onChange(Array.from(selectedItems.values()).filter((item) => item.id !== itemId));
  };

  const onClear = () => onChange([]);

  const searchResult = () => {
    const item = selectedItems?.get(value?.[0]?.id ?? '');
    const ancestors = item ? formatItemAncestors(item) : undefined;

    return item ? (
      <div data-testid={`${testId}SingleResult`} className="flexColumnContainer flexSpaceBetween flexFill ptb2">
        <div className="flexColumnContainer flexCenter">
          {itemIcon ?? getItemIcon?.(item) ?? defaultItemIcon({ item, testId })}
        </div>
        <div className="flexFill" data-testid={`${testId}-selected`}>
          <div className="searchResultName">
            <span className="simple-word-break">{formatItemName(item)}</span>
          </div>
          {ancestors && <div className="xsmall pb2 sq-fairly-dark-gray text-italic simple-word-break">{ancestors}</div>}
        </div>
      </div>
    ) : (
      <NoSelectedItemResult testId={testId} notSelectedMessage={notSelectedMessage} />
    );
  };

  const searchResultMultiple = () => {
    const hasItems = !!selectedItems?.size;

    return (
      <div className="flexRowContainer flexSpaceBetween flexFill ptb2">
        {hasItems ? (
          Array.from(selectedItems.keys()).map((itemId) => {
            const item = selectedItems.get(itemId)!;

            return (
              <SearchResultRemovable
                key={itemId}
                itemId={itemId}
                itemIcon={itemIcon ?? getItemIcon?.(item) ?? defaultItemIcon({ item, testId })}
                itemName={formatItemName(item)}
                itemAncestors={formatItemAncestors(item)}
                onRemove={onItemRemove}
              />
            );
          })
        ) : (
          <NoSelectedItemResult testId={testId} notSelectedMessage={notSelectedMessage} />
        )}
      </div>
    );
  };

  return (
    <>
      <InputGroup
        field={
          <div
            className={classNames(
              'width-maximum pl6 flexColumnContainer flexAlignCenter cursorPointer multiSelectSearchResult',
              { disabledInputField: isDisabled },
              extraValueClassNames,
            )}
            onClick={() => {
              if (!isDisabled && (!isMultiple || (isMultiple && !value?.length))) {
                setIsSelectingItem(true);
              }
            }}
            data-testid={`${testId}-value`}>
            {isMultiple ? searchResultMultiple() : searchResult()}
          </div>
        }
        append={[
          (selectedItems?.size || 0) > 0
            ? {
                variant: 'button',
                buttonProps: {
                  icon: 'fa-trash',
                  iconStyle: 'theme',
                  testId: `${testId}-delete`,
                  onClick: onClear,
                },
              }
            : undefined,
          {
            variant: 'button',
            buttonProps: {
              icon: showAddIcon || isMultiple ? 'fa-plus' : 'fa-pencil',
              iconStyle: 'theme',
              testId: `${testId}-edit`,
              onClick: () => !isDisabled && setIsSelectingItem(true),
              disabled: isDisabled,
            },
          },
        ]}
      />

      {canShowModal && modalProps.selectionType === 'Other' && (
        <SelectItemSearchModal
          {...modalProps}
          isMultiple={isMultiple}
          body={
            modalProps?.helpTextKey ? (
              <div className="alert alert-info" data-testid={`${testId}-helpText`}>
                {t(modalProps?.helpTextKey)}
              </div>
            ) : (
              <></>
            )
          }
          onClose={clearAndClose}
          selectItems={Array.from(selectedItemsInModal?.values() ?? [])}
          onSelect={handleItemSelectInModal}
          testId={`${testId}-modal`}
          selectAllCallback={modalProps.canSelectAll ? selectAllCallback : undefined}
          areAllSelected={areAllSelected}
          onSubmit={onSave}
        />
      )}

      {canShowModal && modalProps.selectionType === 'Analysis' && (
        <Modal
          open={true}
          onClose={clearAndClose}
          testId="selectWorkbookModal"
          title={t(modalProps.title)}
          dialogClassName="modal-md"
          onSubmit={onSave}
          disableSubmitButton={!selectedItemsInModal?.size}>
          <div className="modalBody">
            <ExplorerModalBody
              show={true}
              setSelectedItem={handleItemSelectInModal}
              selectedItemIds={Array.from(selectedItemsInModal?.values() ?? []).map((item) => item.id)}
              isSelectable={true}
              selectOnlyWorkbooks={true}
              isMultiple={isMultiple}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
