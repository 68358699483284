import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  getPropertyForContent,
  VisualizationContentProps,
  VisualizationContentPropsTransformer,
} from '@/annotation/ckEditorPlugins/components/content.utilities';
import { sqContentApi } from '@/sdk/api/ContentApi';
import _ from 'lodash';
import { XyPlotContent } from '@/scatterPlot/XyPlotContent.organism';
import { ErrorBoundaryWithLogging } from '@/core/ErrorBoundary.atom';
import { forceRefreshContent } from '@/annotation/reportContent.utilities';
import { ErrorFallbackMinimal } from '@/core/ErrorFallback.organism';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { ChartViewContent } from '@/reportEditor/ChartViewContent.molecule';
import { CONTENT_LOADING_CLASS, IMAGE_BORDER_CLASS } from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';
import { applyContentUpgrade } from '@/annotation/ckEditorPlugins/components/interactiveContentUpgrader.utilities';
import { EmptyContent } from '@/reportEditor/EmptyContent.molecule';
import { ContentDisplayMode } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { TableBuilderContent } from '@/tableBuilder/TableBuilderContent.molecule';
import { PluginContent } from '@/plugin/PluginContent.molecule';
import type { ContentMeasurements } from '@/tableBuilder/tableBuilder.types';
import { TreemapContent } from '@/treemap/TreemapContent.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const renderComponent: (visualization: Visualization, props: any) => JSX.Element = (
  visualization: Visualization,
  props: any,
) => {
  const InnerComponent =
    {
      [Visualization.TABLE]: TableBuilderContent,
      [Visualization.XY_PLOT]: XyPlotContent,
      [Visualization.TREEMAP]: TreemapContent,
      [Visualization.TREND]: ChartViewContent,
      [Visualization.PLUGIN]: PluginContent,
      [Visualization.NONE]: EmptyContent,
    }[visualization] || null;
  if (_.isNull(InnerComponent)) {
    throw new Error('No visualization type found in React JSON blob');
  }
  return (
    <div
      data-testid={`innerContainer-${props.contentId}`}
      className={classNames('inheritMinHeightAndHeight flexRowContainer reactJsonContent', {
        tableVisualization:
          visualization === Visualization.TABLE &&
          (props.isSimpleMode ? !props.showChartView : !props.showConditionChartView),
      })}>
      <InnerComponent {...props} />
    </div>
  );
};

interface ReactJsonContentProps {
  contentId: string;
  style: any;
  displayMode: ContentDisplayMode;
  loading: boolean;
  target: any;
  src: string;
  border?: boolean;
  onLoad: (properties: { visualization: Visualization }) => void;
  onError: (error: string, errorCode: number) => void;
  onMeasurementsReady: (
    width: number | undefined,
    height: number | undefined,
    isInteractiveWithChart?: boolean,
  ) => void;
  showMenu?: () => void;
  error?: string;
  extraClassNames: string;
  errorClass?: string;
  propertyOverrides?: any;
  isInView: boolean | undefined;
  height?: number;
  width?: number;
  darkMode?: boolean;
  updateContentMeasurements: (measurements: ContentMeasurements) => void;
  editorId: string;
}

/**
 * Handles React JSON visualizations. The properties state object is expected to the props to a props only version of a
 * Analysis visualization.
 */
export const ReactJsonContent: React.FunctionComponent<ReactJsonContentProps> = ({
  contentId,
  extraClassNames,
  errorClass,
  style,
  displayMode,
  loading,
  target,
  src,
  onLoad,
  onError,
  border,
  onMeasurementsReady,
  showMenu = () => null,
  error,
  propertyOverrides,
  isInView,
  height = 0,
  width = 0,
  darkMode,
  updateContentMeasurements,
  editorId,
}) => {
  const [properties, setProperties] = useState<any | undefined>();
  const isFetchingBlob = useRef(false);
  const deferredFetchSrc = useRef<string>();

  const shouldNotFetchBlob = isInView === undefined;

  function fetchReactBlob(srcToFetch: string) {
    const queryParams = new URLSearchParams(srcToFetch.substring(srcToFetch.indexOf('?')));
    const params = {
      hash: queryParams.get('hash'),
    };
    isFetchingBlob.current = true;
    sqContentApi
      .getReactBlob({ id: contentId }, { params, headers: { [SeeqNames.API.Headers.Async]: true } })
      .then(({ data }) => {
        const untypedProperties = data as any;
        if (untypedProperties.visualization) {
          const propertiesForVisualizationType: any = VisualizationContentPropsTransformer[
            untypedProperties.visualization as Visualization
          ]({
            ...applyContentUpgrade(untypedProperties, untypedProperties.version ?? 0),
            isContent: true,
            darkMode,
            contentId,
            editorId,
            ...VisualizationContentProps[untypedProperties.visualization as Visualization],
          });

          setProperties(propertiesForVisualizationType);
          if (
            displayMode === ContentDisplayMode.PDF &&
            propertiesForVisualizationType.visualization === Visualization.TREND
          ) {
            propertiesForVisualizationType.onHighchartsLoad = () => onLoad(propertiesForVisualizationType);
          } else if (
            isInView &&
            propertiesForVisualizationType.visualization === Visualization.TABLE &&
            !(propertyOverrides?.showChartView || propertiesForVisualizationType.showChartView) &&
            !(propertyOverrides?.showConditionChartView || propertiesForVisualizationType.showConditionChartView)
          ) {
            propertiesForVisualizationType.onAgGridReady = _.debounce(
              () => onLoad(propertiesForVisualizationType),
              DEBOUNCE.LONG,
            );
          } else if (propertiesForVisualizationType.visualization === Visualization.PLUGIN) {
            propertiesForVisualizationType.onPluginReady = () => onLoad(propertiesForVisualizationType);
            propertiesForVisualizationType.onClick = showMenu;
          } else {
            onLoad(propertiesForVisualizationType);
          }
        }
      })
      .catch((error) => {
        onError(error.data?.statusMessage, error.status);
      })
      .finally(() => {
        isFetchingBlob.current = false;
        if (deferredFetchSrc.current) {
          fetchReactBlob(deferredFetchSrc.current);
          deferredFetchSrc.current = undefined;
        }
      });
  }

  useEffect(() => {
    if (shouldNotFetchBlob) {
      return;
    }

    if (isFetchingBlob.current) {
      /**
       * A getReactBlob is still ongoing which can happen in a live-doc scenario where the job is publishing
       * screenshot messages faster than the network can fetch them. It can also happen during a force refresh
       * content because that involves two successive calls to getReactBlob. The first is with a random and the
       * backend will return a 202 status with empty data and kick off a job. The job will return a new URL, but may
       * return before the 202 status is resolved. In both cases the most recent url that triggered this useEffect
       * is the one that must be fetched in order to show the most recent data and so it is queued up.
       */
      deferredFetchSrc.current = src;
      return;
    }
    fetchReactBlob(src);
  }, [src, darkMode, shouldNotFetchBlob]);

  const measurementsCallback = useCallback(() => {
    const content = sqReportStore.getContentById(contentId);
    if (
      properties.visualization === Visualization.TABLE &&
      !getPropertyForContent(
        properties.conditionTableData ? 'showConditionChartView' : 'showChartView',
        propertyOverrides,
        properties,
      )
    ) {
      // If it is a table, the width is calculated by ag-grid and should not be reset every time the content component is mounted
      // The content component is mounted when scrolled into view and vice versa (CRAB-42238)
      onMeasurementsReady(undefined, 0);
    } else {
      onMeasurementsReady(
        content?.width ?? target.current.clientWidth,
        content?.height ?? target.current.clientHeight,
        true, // All other interactive content types have a chart
      );
    }
  }, [properties, contentId]);

  const getStyle = (errorClass: string | undefined) => {
    const width = errorClass && errorClass !== CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR ? 'fit-content' : 'inherit';

    const styleOverrides: any = {
      maxHeight: undefined,
      maxWidth: undefined,
      width,
      height: 'inherit',
    };

    return {
      ...style,
      ...styleOverrides,
    };
  };

  const retry = () => forceRefreshContent(contentId);

  return (
    <div
      data-testid={`content-${contentId}`}
      data-seeq-content={contentId}
      data-visualization={properties?.visualization ?? 'none'}
      data-sub-visualization={properties?.subVisualization ?? 'none'}
      style={getStyle(errorClass)}
      ref={target}
      title={error}
      className={classNames('inheritMinHeight', extraClassNames, errorClass ?? '', {
        [IMAGE_BORDER_CLASS]: border,
        [CONTENT_LOADING_CLASS.SPINNER]: loading && !errorClass && !properties,
        [CONTENT_LOADING_CLASS.SPINNER_CORNER]: loading && !errorClass && properties,
        [CONTENT_LOADING_CLASS.LOADED]: !loading && !errorClass,
      })}>
      {!errorClass && properties && (
        <ErrorBoundaryWithLogging fallback={(error) => <ErrorFallbackMinimal error={error} retry={retry} />}>
          {!isInView && (
            <div
              data-testid={`notRenderedContainer-${contentId}`}
              className={CONTENT_LOADING_CLASS.NOT_RENDERED}
              // Retain the height of the parent when it scrolls out of view to avoid jumpiness
              style={{ width: target.current.clientWidth, height: target.current.clientHeight }}
            />
          )}
          {isInView &&
            renderComponent(properties.visualization, {
              ...properties,
              ...propertyOverrides,
              onContentLoad: measurementsCallback,
              afterChartUpdate: measurementsCallback,
              updateContentMeasurements,
            })}
        </ErrorBoundaryWithLogging>
      )}
    </div>
  );
};
