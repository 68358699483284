// @ts-strict-ignore
import _ from 'lodash';
import { API_TYPES, NUMBER_CONVERSIONS } from '@/main/app.constants';
import { sqConditionsApi } from '@/sdk/api/ConditionsApi';
import { sqSignalsApi } from '@/sdk/api/SignalsApi';
import { getMSPerPixelWidth } from '@/utilities/utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import i18next from 'i18next';
import {
  sqDurationStore,
  sqScatterPlotStore,
  sqTrendConditionStore,
  sqTrendSeriesStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import {
  EMPTY_XY_REGION,
  FX_LINE_SAMPLE_SPACING,
  FxLineMetadata,
  MAX_CAPSULE_SCATTER_PLOT_SAMPLES,
  SCATTER_PLOT_MODES,
  SCATTER_PLOT_VIEWS,
  ScatterPlotColorRange,
  ScatterPlotFormula,
  ScatterPlotSignal,
  XYPlotRegion,
} from '@/scatterPlot/scatterPlot.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { maxScatterPlotSamples } from '@/services/systemConfiguration.utilities';
import { catchItemDataFailure, getChartWidth } from '@/trendData/trend.actions';
import {
  computePredictionModel,
  computeSamples,
  computeScalar,
  computeTable,
  getPropertyAndStatisticsColumns,
} from '@/utilities/formula.utilities';
import { ITEM_DATA_STATUS } from '@/trendData/trendData.constants';
import { getCapsuleFormula } from '@/datetime/dateTime.utilities';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import { getRegressionModelFormula } from '@/utilities/predictionHelper.utilities';
import { ParametersMap, SPIKECATCHER_PER_PIXEL } from '@/utilities/formula.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { getMaxSeriesPixels } from '@/core/utilities';

export function getXItem() {
  const xItem = sqTrendSeriesStore.findItem(_.get(sqScatterPlotStore.xSignal, 'id'));

  // If the series is missing from the trend series store, sync up the Scatterplot store
  if (_.isUndefined(xItem) && sqScatterPlotStore.xSignal) {
    flux.dispatch('SCATTER_PLOT_REMOVE_X_SIGNAL');
  }

  return xItem;
}

export function getYItems() {
  return _.chain(sqScatterPlotStore.ySignals)
    .map((ySignal) => {
      const yItem = sqTrendSeriesStore.findItem(ySignal.id);

      // If the series is missing from the trend series store, sync up the Scatterplot store
      if (_.isUndefined(yItem)) {
        flux.dispatch('SCATTER_PLOT_REMOVE_Y_SIGNAL', { id: ySignal.id });
      }

      return yItem;
    })
    .reject(_.isUndefined)
    .value();
}

/**
 * Fetch all of the data needed for the Density plot view, and pass along the data so the store and plot are updated.
 */
export function fetchDensityPlot(): Promise<any> {
  if (
    sqWorksheetStore.view.key !== WORKSHEET_VIEW.SCATTER_PLOT ||
    sqScatterPlotStore.plotView !== SCATTER_PLOT_VIEWS.DENSITY_PLOT
  ) {
    return Promise.resolve();
  }
  const xItem = getXItem();
  const yItem = getYItems()[0];

  // If both plot series aren't specified, then clear previous data and return
  if (
    !sqScatterPlotStore.xSignal ||
    _.isEmpty(sqScatterPlotStore.ySignals) ||
    !_.isObject(xItem) ||
    !_.isObject(yItem) ||
    _.includes([xItem.dataStatus, yItem.dataStatus], ITEM_DATA_STATUS.REDACTED)
  ) {
    flux.dispatch('DENSITY_PLOT_CLEAR_DATA', {}, PUSH_IGNORE);
    return Promise.resolve();
  }

  flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: xItem.id }, PUSH_IGNORE);
  flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: yItem.id }, PUSH_IGNORE);

  const queryRangeCapsule = getCapsuleFormula(sqDurationStore.displayRange);
  cancelGroup('densityPlotData', true);

  const params = sqScatterPlotStore.getDensityPlotFormula(xItem.id, yItem.id, queryRangeCapsule);
  return computeTable(params)
    .then((results) => {
      flux.dispatch(
        'TREND_SERIES_RESULTS_SUCCESS',
        {
          id: xItem.id,
          samples: [],
          timingInformation: results.timingInformation,
          meterInformation: results.meterInformation,
          valueUnitOfMeasure: results.headers[0].units,
        },
        PUSH_IGNORE,
      );

      flux.dispatch(
        'TREND_SERIES_RESULTS_SUCCESS',
        {
          id: yItem.id,
          samples: [],
          timingInformation: results.timingInformation,
          meterInformation: results.meterInformation,
          valueUnitOfMeasure: results.headers[1].units,
        },
        PUSH_IGNORE,
      );

      _.map(results.data, (row) => (row[2] *= NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND));

      flux.dispatch(
        'DENSITY_PLOT_SET_DATA',
        {
          headers: results.headers,
          data: results.data,
        },
        PUSH_IGNORE,
      );
    })
    .catch((error) => {
      catchItemDataFailure(xItem.id, params.cancellationGroup, error);
      catchItemDataFailure(yItem.id, params.cancellationGroup, error);

      flux.dispatch('DENSITY_PLOT_CLEAR_DATA', {}, PUSH_IGNORE);
    });
}

export function fetchMinimapSignal(id) {
  const numPixels = _.min([getChartWidth(), getMaxSeriesPixels()]);
  const range = sqDurationStore.displayRange;
  return computeSamples({
    id,
    range,
    formula: `$series.spikeCatcher(${getMSPerPixelWidth(range.duration, numPixels)}ms)`,
    limit: numPixels * SPIKECATCHER_PER_PIXEL,
  });
}

/**
 * Fetches all function of x lines.
 */
export function fetchAllFxLines() {
  _.forEach(sqScatterPlotStore.fxLines, (line) => exposedForTesting.fetchFxLine(line));
}

/**
 * Sets whether to color the scatter plot by item color
 *
 * @param colorByItemColor - whether to color the scatter plot by item color
 */
export function setColorByItemColor(colorByItemColor: boolean) {
  flux.dispatch('SCATTER_PLOT_SET_COLOR_BY_ITEM_COLOR', { colorByItemColor });
}

/**
 * Adds a condition to use for colorizing the scatter plot chart.
 *
 * @param {Object} item - The item to add
 */
export function addColorCondition(item) {
  flux.dispatch('SCATTER_PLOT_ADD_COLOR_CONDITION', { id: item.id });
  exposedForTesting.fetchXYData();
}

/**
 * Adds a function of x line to the scatter plot chart.
 *
 * @param {Object} item - A signal item from the sqTrendSeriesStore
 */
export function addFxLine(item) {
  flux.dispatch('SCATTER_PLOT_ADD_FX_LINE', {
    ...item,
    ySignalId: _.head(sqScatterPlotStore.ySignals)?.id,
  });
  exposedForTesting.fetchFxLine(item);
}

/**
 * Update a function of x line to the scatter plot chart.
 *
 * @param {Object} item - A signal item from the sqTrendSeriesStore
 */
export function updateFxLine(item, key, newValue) {
  flux.dispatch('SCATTER_PLOT_UPDATE_FX_LINE', { item, key, newValue });
}

/**
 * Removes a condition from being used for colorizing the scatter plot chart.
 *
 * @param {Object} item - The item to remove
 */
export function removeColorCondition(item) {
  flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_CONDITION', { id: item.id });
  exposedForTesting.fetchXYData();
}

/**
 * Sets a signal to use to colorize the scatter plot chart.
 *
 * @param {Object} item - The item to use
 */
export function setColorSignal(item) {
  if (_.isNil(item)) {
    flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_SIGNAL');
  } else {
    flux.dispatch('SCATTER_PLOT_SET_COLOR_SIGNAL', { id: item.id });
  }
  if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
    exposedForTesting.fetchXYData();
  }
}

/**
 * Sets a capsule property to use to color the points on the scatter plot
 *
 * @param property - capsule property name
 */
export function setColorCapsuleProperty(property?: string) {
  if (_.isEmpty(property) || sqScatterPlotStore.colorCapsuleProperty === property) {
    flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_CAPSULE_PROPERTY');
  } else {
    flux.dispatch('SCATTER_PLOT_SET_COLOR_CAPSULE_PROPERTY', { property });
  }
  exposedForTesting.fetchXYData();
}

/**
 * Set the base color for the gradient used whe ncoloring points by a numeric capsule property
 *
 * @param color - the color to use as the base color for a numeric capsule property gradient
 */
export function setColorForCapsuleProperty(color: string) {
  flux.dispatch('SCATTER_PLOT_SET_COLOR_FOR_CAPSULE_PROPERTY', { color });
  exposedForTesting.fetchXYData();
}

/**
 * Clears the user-selected region
 */
export function clearSelectedRegion() {
  setSelectedRegion(EMPTY_XY_REGION);
}

export function clearRegionAndFetchPlot() {
  exposedForTesting.clearSelectedRegion();
  exposedForTesting.expandViewRegion();
  exposedForTesting.fetchPlot();
}

/**
 * Fetch all of the data needed for the Scatterplot view, and pass along the data so the store and plot are updated.
 */
export function fetchScatterPlot(): Promise<any> {
  if (
    sqWorksheetStore.view.key !== WORKSHEET_VIEW.SCATTER_PLOT ||
    sqScatterPlotStore.plotView !== SCATTER_PLOT_VIEWS.SCATTER_PLOT
  ) {
    return Promise.resolve();
  }

  exposedForTesting.fetchMinimapSignals();
  return exposedForTesting.fetchXYData();
}

/**
 * Fetch the X-Y table data used for the main Scatterplot display, and emit events so the signal data is stored in
 * the same way as it would be in the Trend view. This does not explicitly update the X-Y scatterplot display or the
 * mini-map. They will update automatically (via event listeners).
 */
export function fetchXYData(): Promise<any> {
  const xItem = getXItem();
  const yItems = getYItems();

  // If both plot series aren't specified, then clear previous data and return
  if (
    !sqScatterPlotStore.xSignal ||
    _.isEmpty(sqScatterPlotStore.ySignals) ||
    !_.isObject(xItem) ||
    _.isEmpty(yItems) ||
    _.includes(_.flatten([xItem.dataStatus, _.map(yItems, 'dataStatus')]), ITEM_DATA_STATUS.REDACTED)
  ) {
    flux.dispatch('SCATTER_PLOT_CLEAR_DATA', {}, PUSH_IGNORE);
    return Promise.resolve();
  }

  flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: xItem.id }, PUSH_IGNORE);
  _.forEach(yItems, (yItem) => flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: yItem.id }, PUSH_IGNORE));

  const queryRangeCapsule = getCapsuleFormula(sqDurationStore.displayRange);
  const { allDecoratedStatColumns, allDecoratedPropertyColumns } = getPropertyAndStatisticsColumns();

  const allPromises = _.chain(yItems)
    .map((yItem) => {
      const params = sqScatterPlotStore.getXyFormulaAndParameters(
        xItem.id,
        yItem.id,
        queryRangeCapsule,
        allDecoratedPropertyColumns,
        allDecoratedStatColumns,
        sqScatterPlotStore.colorConditionIds,
        sqScatterPlotStore.colorSignalId,
        sqScatterPlotStore.colorCapsuleProperty,
      );
      cancelGroup(params.cancellationGroup, true);

      return (
        exposedForTesting
          .getAdjustedSampleLimit(params)
          .then((sampleLimit) =>
            computeTable({
              formula: _.replace(params.xyTableFormula, '{sampleLimit}', sampleLimit as any),
              parameters: params.parameters,
              cancellationGroup: params.cancellationGroup,
            }),
          )
          .then((results) => {
            // Dispatch events for the signals so they get stored in the central location, for the details pane, etc.
            // Note that since a single request includes data from two different signals, the timing and meter
            // information is the same for both items used. No samples are stored on the trend items since they aren't
            // needed.
            flux.dispatch(
              'TREND_SERIES_RESULTS_SUCCESS',
              {
                id: xItem.id,
                samples: [],
                timingInformation: results.timingInformation,
                meterInformation: results.meterInformation,
                valueUnitOfMeasure: results.headers[1].units,
              },
              PUSH_IGNORE,
            );

            flux.dispatch(
              'TREND_SERIES_RESULTS_SUCCESS',
              {
                id: yItem.id,
                samples: [],
                timingInformation: results.timingInformation,
                meterInformation: results.meterInformation,
                valueUnitOfMeasure: results.headers[2].units,
              },
              PUSH_IGNORE,
            );

            // convert first column form nanoseconds (returned by backend) to milliseconds
            _.map(results.data, (row) => (row[0] /= NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND));

            return {
              headers: results.headers,
              data: results.data,
            };
          })
          // Must fetch X-Y data first since function of x lines requires the min and max
          .catch((error) => {
            // Swallow abort error, as they are about to be retried
            if (error?.xhrStatus !== 'abort') {
              catchItemDataFailure(xItem.id, params.cancellationGroup, error);
              catchItemDataFailure(yItem.id, params.cancellationGroup, error);
            }
          })
      );
    })
    .value();

  return Promise.all(allPromises)
    .then((results: { headers: any; data: any[] }[]) =>
      _.chain(results)
        .reject(_.isUndefined)
        .thru((results) => {
          if (results.length > 0) {
            // If at least one XY pair succeeds, then mark X as present
            flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id: xItem.id }, PUSH_IGNORE);
          }
          flux.dispatch('SCATTER_PLOT_SET_DATA', { results }, PUSH_IGNORE);
        })
        .value(),
    )
    .then(() => exposedForTesting.fetchAllFxLines());
}

export function fetchPlot(): Promise<any> {
  if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
    return exposedForTesting.fetchScatterPlot();
  } else if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
    return exposedForTesting.fetchDensityPlot();
  }
}

/**
 * Sets the signal on the x-y plot's x axis
 * xSignal - the signal that will be on the x axis
 */
export function setXSignal(xSignal) {
  // This method gets called multiple times when loading a Scatterplot. To avoid duplicated fetches of data from the
  // backend, cancel the update if nothing would change.
  if (seriesHasSameId(xSignal, sqScatterPlotStore.xSignal)) {
    return;
  }

  flux.dispatch('SCATTER_PLOT_SET_X_SIGNAL', { xSignal });
  clearRegionAndFetchPlot();
}

/**
 * Removes all y signals from the store.
 * TODO CRAB-27653: We shouldn't need to clear all the ySignals ever, but its necessary until a way to add and
 * remove multiple y signals is exposed to the user.
 */
export function clearYSignals() {
  _.forEach(sqScatterPlotStore.ySignals, (ySignal) =>
    flux.dispatch('SCATTER_PLOT_REMOVE_Y_SIGNAL', { id: ySignal.id }),
  );
}

/**
 * Removes the signal with the given id, assuming said signal is in the scatterplot store
 */
export function removeSignal(id: string) {
  const xSignalPresent = !!sqScatterPlotStore.xSignal;
  const ySignalCount = sqScatterPlotStore.ySignals.length;
  if (sqScatterPlotStore.xSignal?.id === id) {
    flux.dispatch('SCATTER_PLOT_REMOVE_X_SIGNAL');
  } else if (_.some(sqScatterPlotStore.ySignals, (ySignal) => ySignal.id === id)) {
    flux.dispatch('SCATTER_PLOT_REMOVE_Y_SIGNAL', { id });
    if (ySignalCount > 1 && sqScatterPlotStore.ySignals.length === 1) {
      exposedForTesting.setColorByItemColor(false);
    }
  }

  if (xSignalPresent !== !!sqScatterPlotStore.xSignal || sqScatterPlotStore.ySignals.length < ySignalCount) {
    clearRegionAndFetchPlot();
  }
}

/**
 * Sets the x and y signals for the x-y plot
 */
export function setSignals({
  xSignal,
  ySignals,
}: {
  xSignal: ScatterPlotSignal | null;
  ySignals: ScatterPlotSignal[];
}) {
  const previousNumberOfYSignals = _.size(sqScatterPlotStore.ySignals);
  flux.dispatch('SCATTER_PLOT_SET_SIGNALS', { xSignal, ySignals });

  if (previousNumberOfYSignals === 1 && _.size(ySignals) > 1) {
    exposedForTesting.setColorByItemColor(true);
  } else if (previousNumberOfYSignals > 1 && _.size(ySignals) === 1) {
    exposedForTesting.setColorByItemColor(false);
  }

  clearRegionAndFetchPlot();
}

/**
 * Sets the series on the x-y plot's y axis
 * ySignal - the signal that will be on the y axis
 */
export function setYSignal(ySignal: ScatterPlotSignal) {
  // This method gets called multiple times when loading a Scatterplot. To avoid duplicated fetches of data from the
  // backend, cancel the update if nothing would change.
  if (_.some(sqScatterPlotStore.ySignals, (signal) => seriesHasSameId(ySignal, signal))) {
    return;
  }

  const lengthBeforeAdding = sqScatterPlotStore.ySignals.length;
  flux.dispatch('SCATTER_PLOT_SET_Y_SIGNAL', { ySignal });
  const lengthAfterAdding = sqScatterPlotStore.ySignals.length;
  if (lengthBeforeAdding === 1 && lengthAfterAdding > 1) {
    exposedForTesting.setColorByItemColor(true);
  }

  clearRegionAndFetchPlot();
}

export function seriesHasSameId(series1: ScatterPlotSignal, series2: ScatterPlotSignal) {
  const neitherExists = !series1 && !series2;
  const bothExistAndIdMatches = series1 && series2 && series1.id === series2.id;
  return neitherExists || bothExistAndIdMatches;
}

/**
 * Flips the series on the x-y plot's x and y axes
 */
export function flipXAndY() {
  flux.dispatch('SCATTER_PLOT_FLIP_AXES');
  exposedForTesting.fetchAllFxLines();
}

/**
 * Sets the values of the scatter plot region selectors, as fraction of the display range.
 *
 * low - The value (between 0 and 1)
 * high - The value (between 0 and 1)
 */
export function setSelectors(low: number, high: number) {
  flux.dispatch('SCATTER_PLOT_SELECTORS', { low, high });
}

/**
 * Determines how big the sample limit should be when fetching the XYTable for the plot. Because the xyTable
 * operator translates the maxSamples parameter into lanes for spike catcher it means the number must be increased
 * based on the percentage of time the signal will be within the capsules. For example, if there are only capsules
 * for 25% of the time, the number of lanes will have to be increased by 4 since 75% of them will be ones that
 * can't be used. Not ideal for performance since it means passing over the signals twice, but we haven't come up
 * with a better algorithm.
 *
 * params - The scatter plot formula and parameters
 * returns How many samples to use for the currently visible capsules. If sampleLimitFormula is empty
 * this is immediately resolved with the default limit.
 */
export function getAdjustedSampleLimit(params: ScatterPlotFormula): Promise<any> {
  return params.sampleLimitFormula
    ? computeScalar({
        formula: params.sampleLimitFormula,
        parameters: params.parameters,
        cancellationGroup: params.cancellationGroup,
      })
        .then(({ value }) => value / 100)
        .then((capsuleOnFraction) =>
          capsuleOnFraction > 0
            ? Math.min(_.floor(maxScatterPlotSamples() / capsuleOnFraction), MAX_CAPSULE_SCATTER_PLOT_SAMPLES)
            : maxScatterPlotSamples(),
        )
    : Promise.resolve(maxScatterPlotSamples());
}

export function calculateData() {
  if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
    return exposedForTesting.calculateScatterPlotData();
  } else if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
    return exposedForTesting.calculateDensityPlotData();
  }
}

/**
 * Update the Density Plot based on existing data in the sqScatterPlotStore.
 */
export function calculateDensityPlotData() {
  // If both plot series aren't specified, then clear previous data and return
  if (!sqScatterPlotStore.xSignal || _.isEmpty(sqScatterPlotStore.ySignals)) {
    flux.dispatch('DENSITY_PLOT_CLEAR_DATA');
    return;
  }

  refreshScatterPlotView();
}

/**
 * Update the Scatterplot based on existing data in sqScatterPlotStore.
 */
export function calculateScatterPlotData() {
  // If both plot series aren't specified, then clear previous data and return
  if (!sqScatterPlotStore.xSignal || _.isEmpty(sqScatterPlotStore.ySignals)) {
    flux.dispatch('SCATTER_PLOT_CLEAR_DATA');
    return;
  }

  refreshScatterPlotView();
}

/**
 * Sets the condition that's used to filter what is displayed on the scatter plot
 *
 * plotMode - The desired plot mode (either SCATTER_PLOT_MODES.DISPLAY_RANGE or
 *   SCATTER_PLOT_MODES.CAPSULES)
 */
export function setPlotMode(plotMode: string) {
  if (!_.includes(_.values(SCATTER_PLOT_MODES), plotMode)) {
    throw new TypeError(`Invalid plotMode: ${plotMode}`);
  }

  if (plotMode !== sqScatterPlotStore.plotMode) {
    flux.dispatch('SCATTER_PLOT_SET_PLOT_MODE', { plotMode });

    // Clear previous series data to ensure we don't calculate data again until all series have been updated
    _.chain([sqScatterPlotStore.xSignal, sqScatterPlotStore.ySignals])
      .flatten()
      .map('id')
      .compact()
      .forEach((id) => flux.dispatch('TREND_SERIES_CLEAR_DATA', { id }))
      .value();

    // Then update the timeseries
    exposedForTesting.fetchScatterPlot();
  }
}

/**
 * Sets the connect mode
 *
 * connect - True to connect samples with a line, false to display them disconnected.
 */
export function setConnect(connect: boolean) {
  flux.dispatch('SCATTER_PLOT_SET_CONNECT', { connect });
}

/**
 * Sets whether or not to show tooltips/labels on scatter plot points when a user hovers over them
 *
 * showTooltips - whether or not ot show tooltips
 */
export function setShowTooltips(showTooltips: boolean) {
  flux.dispatch('SCATTER_PLOT_SET_SHOW_TOOLTIPS', { showTooltips });
}

export function setMarkerSize(markerSize: number) {
  flux.dispatch('SCATTER_PLOT_SET_MARKER_SIZE', { markerSize });
}

export function resetMarkerSize() {
  flux.dispatch('SCATTER_PLOT_SET_MARKER_SIZE', { markerSize: undefined });
}

/**
 * Sets the user-selected region
 */
export function setSelectedRegion(region: XYPlotRegion) {
  flux.dispatch('SCATTER_PLOT_SET_SELECTED_REGION', region);
}

/**
 * Sets the view region to the current selected region, and clears the selected region
 */
export function zoomToSelectedRegion() {
  exposedForTesting.zoomToRegion(sqScatterPlotStore.selectedRegion);
  exposedForTesting.clearSelectedRegion();
}

/**
 * Sets the view region to the specified region
 *
 * region - where to zoom to
 */
export function zoomToRegion(region: XYPlotRegion) {
  flux.dispatch('SCATTER_PLOT_SET_VIEW_REGION', region);
  if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
    exposedForTesting.debouncedFetchXYData();
  } else if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
    exposedForTesting.debouncedFetchDensityPlot();
  }
}

/**
 * Clears the custom view region, allowing Highcharts to decide how much to show (all included data points)
 */
export function expandViewRegion() {
  exposedForTesting.zoomToRegion(EMPTY_XY_REGION);
  exposedForTesting.clearSelectedRegion();
}

/**
 * Fetches and updates the minimap plot signals
 */
export function fetchMinimapSignals(): Promise<unknown> {
  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.SCATTER_PLOT) {
    return Promise.resolve();
  }

  flux.dispatch('SCATTER_PLOT_CLEAR_MINIMAP_SIGNALS');
  const signals = _.chain(exposedForTesting.getYItems())
    .concat(exposedForTesting.getXItem())
    .reject(_.isUndefined)
    .map((signal) => ({
      result: exposedForTesting.fetchMinimapSignal(signal.id),
      id: signal.id,
      color: signal.color,
    }))
    .value();
  return Promise.all(_.map(signals, (signal) => signal.result)).then((results) => {
    const newMinimapSignals = _.map(results, (result, index) => ({
      id: signals[index].id,
      color: signals[index].color,
      data: _.map(result.samples, (sample: any) => [
        sample.key / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
        _.isNumber(sample.value) ? sample.value : null,
      ]),
    }));
    flux.dispatch('SCATTER_PLOT_SET_MINIMAP_SIGNALS', { signals: newMinimapSignals }, PUSH_IGNORE);
  });
}

/**
 * When the investigation range updates, this is triggered.
 */
export function handleInvestigateRangeUpdate() {
  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.SCATTER_PLOT || headlessRenderMode()) {
    return;
  }

  if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
    if (sqScatterPlotStore.plotMode === SCATTER_PLOT_MODES.DISPLAY_RANGE) {
      // In Display Range plot mode, we want to only fetch and process data for the mini-map.
      exposedForTesting.fetchMinimapSignals();
    } else {
      // In Capsules plot mode, we want to fetch and process data for the X-Y plot and the mini-map.
      exposedForTesting.fetchScatterPlot();
    }
  } else if (sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
    exposedForTesting.fetchDensityPlot();
  }
}

/**
 * Auto-color points on the scatter plot by a newly calculated condition (created in the Condition from Scatter Plot
 * Selection tool) if it is a new condition and its parameter signals are providing the scatter plot data.
 *
 * @param {string} id - The id of the condition
 * @param {boolean} isNew - True if the condition was just created
 */
export function autoAddNewConditionForColoring(id, isNew) {
  if (isNew && sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
    sqConditionsApi.getCondition({ id }).then(({ data }) => {
      if (sqScatterPlotStore.isRelevantCondition(data.parameters)) {
        exposedForTesting.addColorCondition(sqTrendConditionStore.findItem(id));
      }
    });
  }
}

/**
 * Removes a function of x line from the scatter plot chart. Also clear any errors or warnings from its data
 * status since it is being removed
 *
 * @param {Object} item - The signal to remove
 */
export function removeFxLine(item) {
  flux.dispatch('SCATTER_PLOT_REMOVE_FX_LINE', { id: item.id });
  flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id: item.id });
}

/**
 * Associates the function of x line with the given y signal id
 *
 * @param item - The signal representing the fx line
 * @param ySignalId - The id being associated with
 */
export function setFxLineYSignalId(item, ySignalId: string) {
  flux.dispatch('SCATTER_PLOT_SET_FX_LINE_Y_SIGNAL_ID', {
    id: item.id,
    ySignalId,
  });
}

/**
 * Adds a color and time range to be used for colorizing the scatter plot chart based on the time ranges.
 *
 * @param {ScatterPlotColorRange} colorRange - The color range to add
 */
export function addColorRange(colorRange: ScatterPlotColorRange) {
  flux.dispatch('SCATTER_PLOT_ADD_COLOR_RANGE', colorRange);
}

/**
 * Updates a color range.
 *
 * @param {ScatterPlotColorRange} colorRange - The color range to update
 */
export function updateColorRange(colorRange: ScatterPlotColorRange | undefined) {
  flux.dispatch('SCATTER_PLOT_UPDATE_COLOR_RANGE', colorRange);
}

/**
 * Removes a color and time range from the scatter plot chart
 *
 * @param {string} id - The id of the range to remove
 */
export function removeColorRange(id) {
  flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_RANGE', { id });
}

export function switchToDensityPlot() {
  exposedForTesting.setPlotView(SCATTER_PLOT_VIEWS.DENSITY_PLOT);
  exposedForTesting.fetchDensityPlot();
}

export function switchToScatterPlot() {
  exposedForTesting.setPlotView(SCATTER_PLOT_VIEWS.SCATTER_PLOT);
  exposedForTesting.fetchScatterPlot();
}

/**
 * Switch between scatter plot and density plot
 *
 * @param plotView - one of the SCATTER_PLOT_VIEWS
 */
export function setPlotView(plotView) {
  flux.dispatch('SCATTER_PLOT_SET_PLOT_VIEW', { plotView });
}

/**
 * Set the number of bins to use when calculating data for the x-signal
 *
 * @param numXBins - number of bins for the x-signal
 */
export function setNumXBins(numXBins: number) {
  flux.dispatch('DENSITY_PLOT_SET_NUM_X_BINS', { numXBins });
  exposedForTesting.debouncedFetchDensityPlot();
}

/**
 * Set the number of bins to use when calculating data for the y-signal
 *
 * @param numYBins - number of bins for the y-signal
 */
export function setNumYBins(numYBins: number) {
  flux.dispatch('DENSITY_PLOT_SET_NUM_Y_BINS', { numYBins });
  exposedForTesting.debouncedFetchDensityPlot();
}

export function setShowColorModal(showColorModal: boolean) {
  flux.dispatch('SCATTER_PLOT_SET_SHOW_COLOR_MODAL', { showColorModal });
}

/**
 * Refreshes the view. Useful when store values have changed (e.g. coloring) and we need to update the view
 */
export function refreshScatterPlotView() {
  flux.dispatch('SCATTER_PLOT_REFRESH_VIEW', null, PUSH_IGNORE);
}

/**
 * Adds a new Prediction of Formula signal as a function of x line to scatter plot if it is a new signal and
 * matches the criteria for display.
 *
 * @param {string} id - The id of the signal
 * @param {boolean} isNew - True if the signal was just created
 */
export function autoAddNewSignalAsFxLine(id, isNew) {
  if (isNew && sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
    sqSignalsApi.getSignal({ id }).then(({ data }) => {
      if (sqScatterPlotStore.isValidFxSignal(data.parameters, data.formula)) {
        exposedForTesting.addFxLine(sqTrendSeriesStore.findItem(id));
      }
    });
  }
}

/**
 * Figures out the correct formula for a function of x line and then requests samples that will span the range of the
 * x-axis.
 *
 * @param {Object} item - The regression line item to fetch
 */
export function fetchFxLine(item) {
  if (!sqScatterPlotStore.isViewRegionSet() && sqScatterPlotStore.isScatterDataEmpty()) {
    return;
  }

  const cancellationGroup = `fetchRegressionLine-${item.id}`;
  const range = sqScatterPlotStore.getXAxisRange();
  // Amount of data requested is based on the range in the X-Axis and the width of the chart
  const maxSamples = Math.floor(getChartWidth() / FX_LINE_SAMPLE_SPACING);
  const step = (range.end - range.start) / maxSamples;

  const warnings = [];
  const addWarning = (message) =>
    warnings.push({
      formulaLogEntries: {
        fxLine: { logDetails: [{ message: i18next.t(message) }] },
      },
    });
  const metadata = {} as FxLineMetadata;

  flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: item.id }, PUSH_IGNORE);
  cancelGroup(cancellationGroup, true);
  return sqSignalsApi
    .getSignal({ id: item.id }, { cancellationGroup })
    .then(({ data }) => {
      if (!sqScatterPlotStore.isValidFxSignal(data.parameters, data.formula)) {
        return Promise.reject({
          data: { statusMessage: i18next.t('SCATTER.FX_LINE_ERROR_INPUT') },
        });
      }

      const [, scalingUnit] = sqScatterPlotStore.getScalingFactorAndUnit(range);
      let regressionModelFormula = getRegressionModelFormula(data.formula);

      if (regressionModelFormula) {
        if (sqScatterPlotStore.isRegressionFormulaOutsideRange(regressionModelFormula, sqDurationStore.displayRange)) {
          addWarning('SCATTER.FX_LINE_WARNING_TRAINING');
        }

        const parameters = _.transform(
          data.parameters,
          (memo, parameter) => {
            memo[parameter.name] = parameter.item.id;
          },
          {} as ParametersMap,
        );

        if (parameters.a !== sqScatterPlotStore.xSignal.id) {
          addWarning('SCATTER.FX_LINE_WARNING_X_AXIS');
        }

        // Multiplication of signals that have % UOM treats them as decimals (e.g. 10% * 10% = 5%) and this throws
        // the scale of the regression line off because timeSince()^2 is unitless. So, for now this workaround to
        // remove multiplication from the inputs when they are being multiplied (CRAB-16558)
        if (_.find(data.parameters, { name: 'a' }).item.valueUnitOfMeasure === '%') {
          regressionModelFormula = regressionModelFormula.replace(/\$a\^/g, '$a.setUnits("")^');
        }

        return computePredictionModel({
          formula: regressionModelFormula,
          parameters,
          cancellationGroup,
        }).then((model) => {
          metadata.rSquared = model.regressionOutput.rSquared;
          return {
            formula: sqScatterPlotStore.getFxLineFormulaFromModel(model, regressionModelFormula, step, scalingUnit),
            parameters: {} as ParametersMap,
            numberFormat: data.numberFormat,
          };
        });
      } else {
        const signalParameter = _.find(
          data.parameters,
          (parameter) => !_.includes([API_TYPES.CALCULATED_SCALAR, API_TYPES.LITERAL_SCALAR], parameter.item.type),
        );
        if (signalParameter.item.id !== sqScatterPlotStore.xSignal.id) {
          addWarning('SCATTER.FX_LINE_WARNING_X_AXIS');
        }

        const parameters = _.transform(
          data.parameters,
          (result, parameter) => {
            if (parameter.item.type === API_TYPES.CALCULATED_SCALAR) {
              result[parameter.name] = parameter.item.id;
            }
          },
          {} as ParametersMap,
        );

        return {
          formula: sqScatterPlotStore.getFxLineFormulaFromFunction(data.formula, signalParameter, step, scalingUnit),
          parameters,
          numberFormat: data.numberFormat,
        };
      }
    })
    .then(({ formula, parameters, numberFormat }) => {
      metadata.formula = formula;
      const [scalingFactor] = sqScatterPlotStore.getScalingFactorAndUnit(range);
      const scaledRange = {
        start: range.start * scalingFactor,
        end: range.end * scalingFactor,
      };
      return computeSamples({
        range: {
          start: new Date(scaledRange.start).toISOString(),
          end: new Date(scaledRange.end).toISOString(),
        },
        formula,
        parameters,
        limit: maxSamples,
        cancellationGroup,
      }).then((computedFormula) => ({
        computedFormula,
        numberFormat,
        scalingFactor,
      }));
    })
    .then(({ numberFormat, computedFormula: { samples }, scalingFactor }) => {
      flux.dispatch(
        'TREND_SET_DATA_STATUS_PRESENT',
        {
          id: item.id,
          warningCount: warnings.length,
          warningLogs: warnings,
        },
        PUSH_IGNORE,
      );
      flux.dispatch(
        'SCATTER_PLOT_SET_FX_DATA',
        {
          id: item.id,
          samples: _.map(samples, (sample) => ({
            key: sample.key / scalingFactor / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
            value: sample.value,
          })),
          metadata,
          numberFormat,
        },
        PUSH_IGNORE,
      );
    })
    .catch((e) => {
      flux.dispatch(
        'SCATTER_PLOT_SET_FX_DATA',
        {
          id: item.id,
          samples: [],
          metadata: {},
        },
        PUSH_IGNORE,
      );
      const error = _.isError(e) ? { data: { statusMessage: i18next.t(e.message) } } : e;
      catchItemDataFailure(item.id, cancellationGroup, error);
    });
}

export const exposedForTesting = {
  setConnect,
  setSelectedRegion,
  addColorRange,
  updateColorRange,
  removeColorRange,
  fetchScatterPlot,
  setXSignal,
  setColorByItemColor,
  flipXAndY,
  fetchAllFxLines,
  setSelectors,
  fetchMinimapSignals,
  handleInvestigateRangeUpdate,
  fetchDensityPlot,
  debouncedFetchXYData: _.debounce(fetchXYData, DEBOUNCE.MEDIUM),
  debouncedFetchDensityPlot: _.debounce(fetchDensityPlot, DEBOUNCE.MEDIUM),
  setPlotMode,
  clearSelectedRegion,
  zoomToSelectedRegion,
  zoomToRegion,
  expandViewRegion,
  addFxLine,
  fetchFxLine,
  setFxLineYSignalId,
  addColorCondition,
  removeFxLine,
  fetchXYData,
  setColorSignal,
  calculateDensityPlotData,
  switchToDensityPlot,
  fetchPlot,
  setSignals,
  removeSignal,
  getXItem,
  getYItems,
  getAdjustedSampleLimit,
  calculateScatterPlotData,
  fetchMinimapSignal,
  setPlotView,
};
