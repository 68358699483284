import React from 'react';
import { useTranslation } from 'react-i18next';
import { sqAiAssistantStore, sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { Button, Icon } from '@seeqdev/qomponents';
import { TrainingPromptDisplay } from './homescreen.constants';
import { doTrack } from '@/track/track.service';
import { setTrainingPromptDisplay } from '@/homescreen/homescreen.actions';
import { genAIEnabled } from '@/services/systemConfiguration.utilities';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import moment from 'moment-timezone';
import { warnToast } from '@/utilities/toast.utilities';
import { setAIAssistantDisplayed, setAIAssistantExternalPrompt } from '@/aiAssistant/aiAssistant.actions';
import { useAsyncEffect } from 'rooks';

/**
 * This component renders content of the Training "Get Started" prompt for the Home Screen Page.
 * It DOES NOT include the outer box with the dismiss button.
 */
export const TrainingPromptContent: React.FunctionComponent<{ isModal?: boolean; onClose?: () => void }> = ({
  isModal,
  onClose,
}) => {
  const { t } = useTranslation();
  const userFirstName = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser.firstName);
  const isAiAssistantDisplayed = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.displayed);
  const [branchCode, setBranchCode] = React.useState<string | null>(null);
  const steps =
    branchCode && branchCode.trim() !== ''
      ? [
          t('HOME_SCREEN.TRAINING.STEP_1', { branchCode: `<b>${branchCode}</b>` }),
          t('HOME_SCREEN.TRAINING.STEP_2', {
            website: '<a href="https://training.seeq.com">training.seeq.com</a>',
          }),
          t('HOME_SCREEN.TRAINING.STEP_3'),
        ]
      : [
          t('HOME_SCREEN.TRAINING.STEP_1_NO_CODE', {
            email: '<a href="mailto:training@seeq.com">training@seeq.com</a>',
          }),
          t('HOME_SCREEN.TRAINING.STEP_2', {
            website: '<a href="https://training.seeq.com">training.seeq.com</a>',
          }),
          t('HOME_SCREEN.TRAINING.STEP_3'),
        ];
  const [videoClickCount, setVideoClickCount] = React.useState(0);
  const [videoStartTime, setVideoStartTime] = React.useState(moment.now());

  React.useEffect(() => {
    doTrack('Training', 'home screen intro video shown');
  }, []);

  useAsyncEffect(async () => {
    try {
      const serviceUrl = sqLicenseManagementStore.license?.configuration?.['Seeq Usage']?.['Service URL'] ?? '';
      const accessKey = sqLicenseManagementStore.license?.configuration?.['Seeq Usage']?.['Access Key'] ?? '';
      if (serviceUrl && accessKey) {
        const customerPath = serviceUrl.match(/usage\.seeq\.com\/(.*)/)?.[1] ?? '';
        const url = `https://telemetry.seeq.com/usage/${customerPath}`;
        const branchCodeResult = await fetch(`${url}/trainingBranchCode`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessKey}`,
          },
        });
        if (branchCodeResult?.status === 200) {
          setBranchCode(await branchCodeResult.text());
        } else {
          setBranchCode(t('HOME_SCREEN.TRAINING.NO_CODE_ERROR'));
        }
      }
    } catch (e) {
      setBranchCode('');
    }
  }, []);

  return (
    <div className="flexRowContainer flexCenter width-100percent" data-testid="trainingPromptContent">
      <h1 className="text-bolder">{t('HOME_SCREEN.WELCOME')}</h1>
      <div className="mr20 ml20 text-center trainingPromptText">
        {t('HOME_SCREEN.NEW_USER_MESSAGE', { name: userFirstName })}
      </div>
      <div
        className={`${
          isModal ? 'flexRowContainer' : 'flexColumnContainer'
        } width-100percent lightGreyBorderTop pt-3 mt-3`}>
        <div className={`p-3 flexRowContainer align-items-center ${!isModal ? 'homeScreenVideo' : ''}`}>
          <video
            className="height-maximum width-maximum"
            controls={true}
            autoPlay={false}
            disablePictureInPicture={true}
            controlsList="nodownload"
            onClick={() => {
              if (videoClickCount % 2) {
                const endTime = moment.now();
                const duration = endTime - videoStartTime;
                doTrack('Training', 'Homescreen video played v2 for', moment(duration).format('mm:ss'));
              }

              if (!videoClickCount) {
                doTrack('Training', 'Homescreen video played v2');
              }
              setVideoClickCount(videoClickCount + 1);
              setVideoStartTime(moment.now());
            }}>
            <source src="https://telemetry.seeq.com/seeq-intro-video" />
          </video>
          <div className="mt5 xsmall text-italic">
            {t('VIDEO.TROUBLE_VIEWING')}
            <a
              className="ml5"
              href="https://support.seeq.com/kb/latest/cloud/get-started-with-seeq"
              target="_blank"
              rel="noopener noreferrer">
              {t('VIDEO.WATCH_HERE')}
            </a>
          </div>
        </div>
        <div className="flexRowContainer justify-content-center">
          {genAIEnabled() && (
            <div className="flexRowContainer flexAlignCenter mb10">
              <Button
                label={t('AI_ASSISTANT.GET_STARTED')}
                icon="fa-sparkles"
                variant="theme"
                onClick={() => {
                  if (!isAiAssistantDisplayed) {
                    setAIAssistantDisplayed(true);
                  }
                  doTrack('AiAssistant', 'get started prompt clicked');
                  setAIAssistantExternalPrompt({ prompt: t('AI_ASSISTANT.GET_STARTED_PROMPT'), agent: 'general' });
                  isModal && onClose && onClose();
                }}
              />
            </div>
          )}
          <div className="trainingPrompt">
            {branchCode && branchCode.trim() !== ''
              ? t('HOME_SCREEN.TRAINING.NEXT_BRANCH_PROMPT')
              : t('HOME_SCREEN.TRAINING.NEXT_PROMPT')}
          </div>
          {branchCode && branchCode.trim() && (
            <div className="trainingSubtitle">{t('HOME_SCREEN.TRAINING.NEXT_BRANCH_SUBTITLE')}</div>
          )}
          <ol className="trainingPromptText">
            {steps.map((step: string, idx: number) => (
              <li key={idx}>
                <ContainerWithHTML content={step} />
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

/**
 * This component renders the Training "Get Started" prompt for the Home Screen Page.
 * It includes the outer box with the dismiss button.
 */
export const TrainingPrompt: React.FunctionComponent = () => {
  return (
    <div className="ml20 mr20 mb20 flexRowContainer flexAlignCenter lightGreyBorder border-radius-4 positionRelative">
      <div className="positionAbsolute welcomeActions">
        <Icon
          onClick={() => {
            setTrainingPromptDisplay(TrainingPromptDisplay.NONE);
            doTrack('Training', 'get started prompt closed');
            warnToast({ messageKey: 'HOME_SCREEN.RESUME_TRAINING' });
          }}
          icon="fa-close"
          extraClassNames="fa-xlg cursorPointer hoverGray"
          testId="trainingDismissIcon"
          type="gray"
        />
      </div>
      <TrainingPromptContent />
    </div>
  );
};
