import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { ForecastType, ResultType } from '@/tools/signalForecast/signalForecast.constants';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { AnyProperty } from '@/utilities.types';
import _ from 'lodash';

export const DEFAULT_DURATION = { value: 1, units: 'day' };

export class SignalForecastStore extends BaseToolStore {
  static readonly storeName = 'sqSignalForecastsStore';
  type = TREND_TOOLS.SIGNAL_FORECAST;
  parameterDefinitions = {
    forecastSignal: { predicate: ['name', 'signal2'] },
    runCycleCondition: { predicate: ['name', 'condition'] },
    targetSignal: { predicate: (parameter: { name: string }) => !_.includes(['condition', 'signal2'], parameter.name) },
  };

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      trainingDuration: { value: 7, units: 'days' },
      blendWindow: { value: 0, units: 'min' },
      period: DEFAULT_DURATION,
      forecastDuration: DEFAULT_DURATION,
      griddingPeriod: DEFAULT_DURATION,
      forecastType: ForecastType.LINEAR,
      resultType: ResultType.FORECAST,
      forceThroughLastSample: false,
      resampleTargetSignal: false,
      isSeasonal: false,
      color: '',
    });
  }

  get targetSignal() {
    return this.state.get('targetSignal');
  }

  get runCycleCondition() {
    return this.state.get('runCycleCondition');
  }

  get forecastSignal() {
    return this.state.get('forecastSignal');
  }

  get forecastType() {
    return this.state.get('forecastType');
  }

  get period() {
    return this.state.get('period');
  }

  get griddingPeriod() {
    return this.state.get('griddingPeriod');
  }

  get resampleTargetSignal() {
    return this.state.get('resampleTargetSignal');
  }

  get isSeasonal() {
    return this.state.get('isSeasonal');
  }

  get color(): string {
    return this.state.get('color');
  }

  get forceThroughLastSample(): boolean {
    return this.state.get('forceThroughLastSample');
  }

  get resultType() {
    return this.state.get('resultType');
  }

  get trainingDuration() {
    return this.state.get('trainingDuration');
  }

  get forecastDuration() {
    return this.state.get('forecastDuration');
  }

  get blendWindow() {
    return this.state.get('blendWindow');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  setTrainingDuration(payload: { trainingDuration: ValueWithUnitsItem }) {
    this.state.set('trainingDuration', payload.trainingDuration);
  }

  setForecastDuration(payload: { forecastDuration: ValueWithUnitsItem }) {
    this.state.set('forecastDuration', payload.forecastDuration);
  }

  setBlendWindow(payload: { blendWindow: ValueWithUnitsItem }) {
    this.state.set('blendWindow', payload.blendWindow);
  }

  setForecastType(payload: { forecastType: ForecastType }) {
    this.state.set('forecastType', payload.forecastType);
  }

  setPeriod(payload: { period: ValueWithUnitsItem }) {
    this.state.set('period', payload.period);
  }

  setGriddingPeriod(payload: { griddingPeriod: ValueWithUnitsItem }) {
    this.state.set('griddingPeriod', payload.griddingPeriod);
  }

  setResampleTargetSignal(payload: { resampleTargetSignal: boolean }) {
    this.state.set('resampleTargetSignal', payload.resampleTargetSignal);
  }

  setColor(payload: { color: string }) {
    this.state.set('color', payload.color);
  }

  setForceThroughLastSample(payload: { forceThroughLastSample: boolean }) {
    this.state.set('forceThroughLastSample', payload.forceThroughLastSample);
  }

  setIsSeasonal(payload: { isSeasonal: boolean }) {
    this.state.set('isSeasonal', payload.isSeasonal);
  }

  setResultType(payload: { resultType: ResultType }) {
    this.state.set('resultType', payload.resultType);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    SF_SET_TRAINING_DURATION: this.setTrainingDuration,
    SF_SET_FORECAST_DURATION: this.setForecastDuration,
    SF_SET_BLEND_WINDOW: this.setBlendWindow,
    SF_SET_FORECAST_TYPE: this.setForecastType,
    SF_SET_COLOR: this.setColor,
    SF_SET_PERIOD: this.setPeriod,
    SF_SET_IS_SEASONAL: this.setIsSeasonal,
    SF_SET_RESAMPLE_TARGET_SIGNAL: this.setResampleTargetSignal,
    SF_SET_GRIDDING_PERIOD: this.setGriddingPeriod,
    SF_SET_FORCE_THROUGH_LAST_SAMPLE: this.setForceThroughLastSample,
    SF_SET_RESULT_TYPE: this.setResultType,
  };
}
