import { GraphQLInputV1, GraphQLOutputV1, sqGraphQLApi } from '@/sdk';
import { errorToast } from '@/utilities/toast.utilities';
import { MAX_TABLE_ROWS } from '@/main/app.constants';
import {
  MaterializedTableFormulaInput,
  MaterializedTablePropertyColumnInput,
} from '@/tableDefinitionEditor/materializedTable.types';

export const fetchMaterializedTable = async (
  tableDefinitionId: string,
  columnsToInclude?: string[],
  propertiesToInclude?: MaterializedTablePropertyColumnInput[],
  includeOverrides?: boolean,
  tableFormulas?: MaterializedTableFormulaInput[],
): Promise<GraphQLOutputV1> => {
  const GET_MATERIALIZED_TABLE_QUERY =
    'query GetTable($id: String!, $filter: FilterInput, $limit: Int!, $columnsToInclude: [String!], ' +
    '$propertiesToInclude: [PropertiesForItemUUIDColumnInput!], $includeOverrides: Boolean!, ' +
    '$tableFormulas: [TableFormulaInput!]) { table(id: $id, filter: $filter, limit: $limit, ' +
    'columnsToInclude: $columnsToInclude, propertiesToInclude: $propertiesToInclude, ' +
    'includeOverrides: $includeOverrides, tableFormulas: $tableFormulas) { rows headers { name type } hasMore } }';
  const inputObject: GraphQLInputV1 = {
    query: GET_MATERIALIZED_TABLE_QUERY,
    variables: {
      id: tableDefinitionId,
      limit: MAX_TABLE_ROWS,
      columnsToInclude,
      propertiesToInclude,
      includeOverrides,
      tableFormulas,
    },
  };
  try {
    const response = await sqGraphQLApi.graphql(inputObject);
    return response.data;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
    return {};
  }
};
