import React, { useEffect, useState } from 'react';
import {
  isImpactReportsToolEnabled,
  newHeaderExperienceEnabled,
  vantageEnabled,
} from '@/services/systemConfiguration.utilities';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import screenfullModule from 'screenfull';
import { ButtonWithDropdown, Icon } from '@seeqdev/qomponents';
import { HeaderLogo } from '@/header/HeaderLogo.atom';
import { HamburgerMenu } from '@/header/HamburgerMenu.atom';
import { ViewModeWorksheetActions } from '@/header/ViewModeWorksheetActions.molecule';
import { WorksheetWatchers } from '@/worksheets/WorksheetWatchers.atom';
import { AccessKeyModal } from '@/header/AccessKeyModal.organism';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { setUserPreferencesDisplay, setUserPreferencesTab, setUserProfileDisplay } from '@/workbench/workbench.actions';
import { ItemAclModal } from '@/accessControl/ItemAclModal.molecule';
import { WorkstepIcons } from '@/header/WorkstepIcons.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { EditableText } from '@/core/EditableText.atom';
import { renameWorksheet, setWorkbookName } from '@/workbook/workbook.actions';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { logout as logoutSqAuth } from '@/utilities/authentication.utilities';
import { doTrack } from '@/track/track.service';
import { canModifyWorkbook } from '@/services/authorization.service';
import { translateBreadcrumb } from '@/utilities/homescreen.utilities';
import { EditUserProfile } from '@/header/EditUserProfile.atom';
import { EditUserPreferencesModal } from '@/header/EditUserPreferencesModal.atom';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { getFolderLink, getWorkbooksLink, isDataLabAiAssistant, isWorksheet } from '@/main/routing.utilities';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRoutingData } from '@/main/useRoutingData.hook';
import { WORKBOOK_DISPLAY, WorkbookItemPreview } from '@/workbook/workbook.constants';
import { WrapperWithLink } from '@/core/WrapperWithLink.atom';
import { HeaderAIAssistant } from '@/aiAssistant/HeaderAIAssistant.atom';
import { PREFERENCE_TABS } from '@/workbench/workbench.store';
import {
  isEditWorkbookMode,
  isMobileDevice,
  isPresentationWorkbookMode,
  isViewOnlyWorkbookMode,
} from '@/utilities/utilities';
import { LayoutControls } from '@/header/LayoutControls.atom';
import { ImpactReportMenu } from '@/header/ImpactReportMenu.atom';
import { LoadingBar } from '@/main/LoadingBar.molecule';
// This module has really funky type of false | Screenfull - force it to a reasonable type
const screenfull = screenfullModule as screenfull.Screenfull;

const toggleFullScreen = () => {
  screenfull.toggle();
};

export const Header: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { isWorksheetDisplayed, workbookDisplayMode } = useRoutingData();
  const [modalShown, setModalShown] = useState<'accessControl' | 'accessKeys' | 'none'>('none');
  const ancestors = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.ancestors);
  const userHasManagePermission = useFluxPath(sqWorkbookStore, () => sqWorkbookStore?.effectivePermissions?.manage);
  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);

  const isEditMode = isEditWorkbookMode();
  const isViewMode = isViewOnlyWorkbookMode();
  const isPresentationMode = isWorksheetDisplayed && workbookDisplayMode === WORKBOOK_DISPLAY.PRESENT;
  const isAdminTool = _.includes(pathname, '/administration');
  const { name, owner, effectivePermissions, isReportBinder, isLocked, lockedChangeMade, isVantage, isAnalysis } =
    useFlux(sqWorkbookStore);
  const storeDisplayUserProfile = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.displayUserProfile);
  const storeDisplayUserPreferences = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.displayUserPreferences);
  const userTimeZone = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userTimeZone);

  const [toolDisplayName, setToolDisplayName] = useState('');

  useEffect(() => {
    if (!(location.pathname.includes('/worksheet') || location.pathname.includes('/view'))) {
      setToolDisplayName('');
    } else if (isVantage) {
      setToolDisplayName('Vantage');
    } else if (isAnalysis) {
      setToolDisplayName('Workbench');
    } else if (isReportBinder) {
      setToolDisplayName('Organizer');
    } else {
      setToolDisplayName('');
    }
  }, [isVantage, isAnalysis, isReportBinder, location]);

  const currentUserMissingInfo =
    !sqWorkbenchStore.currentUser.firstName ||
    !sqWorkbenchStore.currentUser.lastName ||
    !sqWorkbenchStore.currentUser.email;

  const displayUserProfile =
    storeDisplayUserProfile || (currentUserMissingInfo && !isPresentationMode && !headlessRenderMode());

  const displayUserPreferences =
    storeDisplayUserPreferences || (!userTimeZone && !isPresentationMode && !headlessRenderMode());

  const workbook = {
    name,
    owner,
    effectivePermissions,
  };
  const breadcrumbs = isEditMode && canModifyWorkbook(workbook) ? ancestors : [];

  const generateDropdownEntry = (
    { iconClass = '', translationKey = '', action = _.noop, display = false, divider = false, testId = '' },
    index: number,
  ) => {
    return {
      key: iconClass + index,
      icon: iconClass,
      itemExtraClassNames: 'sq-force-text-gray forceFont',
      labelTestId: `moreActions_${translationKey}`,
      iconTestId: `moreActionsIcon_${translationKey}`,
      onClick: action,
      label: t(translationKey),
      hasDivider: divider,
      testId,
    };
  };

  const actions = [
    {
      href: '#',
      iconClass: 'fa-cog',
      translationKey: 'USER.EDIT_PREFERENCES',
      action: () => {
        if ((isWorksheet() || isViewMode) && !isReportBinder) {
          setUserPreferencesTab(PREFERENCE_TABS.WORKSHEET);
        } else {
          setUserPreferencesTab(PREFERENCE_TABS.SYSTEM);
        }
        setUserPreferencesDisplay(true);
      },
      display: true,
      testId: 'editPreferences',
    },
    {
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fc-bell-cog',
      translationKey: 'NOTIFICATIONS_MANAGEMENT.TITLE',
      action: () => window.open('/notifications-management', 'SeeqNotificationsManagement'),
      display: true,
      testId: 'notifications',
    },
    {
      href: '#',
      iconClass: 'fa-envelope',
      translationKey: 'NOTIFICATIONS_HISTORY.TITLE',
      action: () => window.open('/notifications-history', 'SeeqNotificationsHistory'),
      display: true,
      testId: 'notifications-history',
    },
    {
      href: '#',
      iconClass: 'fc-vantage-notification',
      translationKey: 'VANTAGE.MANAGEMENT.TITLE',
      action: () => window.open('/vantage-management', 'SeeqVantageManagement'),
      display: vantageEnabled(),
      testId: 'vantages',
    },
    {
      href: '#',
      iconClass: 'fa-gauge-high',
      translationKey: 'USAGE.MENU_LABEL',
      action: () => window.open('/my-usage', 'UserUsage'),
      display: true,
      testId: 'user-usage',
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fa-key',
      translationKey: 'ACCESS_KEY.ACCESS_KEYS',
      action: () => setModalShown('accessKeys'),
      display: true,
      testId: 'accessKeys',
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fa-right-from-bracket',
      translationKey: 'LOGOUT',
      action: logout,
      display: true,
      testId: 'logout',
    },
  ];

  function logout() {
    logoutSqAuth(_.join(_.compact([pathname === '/' ? '' : pathname, searchParams.toString()]), '?'));
  }

  const getLink = (crumb: WorkbookItemPreview) =>
    crumb.id && !crumb.tabHash ? getFolderLink(crumb.id) : getWorkbooksLink(crumb.tabHash);

  /** The "Share button" in the toolbar is only shown when in a worksheet that is either locked or editable by this
   *  user (note that both Organizer Topics and Analyses contain "worksheets").
   */
  const showShareButton = () => {
    return isWorksheetDisplayed && ((canModifyWorkbook(workbook) && isEditMode) || isLocked);
  };

  return (
    <div className={`positionRelative ${newHeaderExperienceEnabled() ? 'newHeaderExperience' : ''}`}>
      <header className="mainHeader flexColumnContainer flexCenter hideInPdf" data-testid="header" id="header">
        <HeaderLogo />
        {newHeaderExperienceEnabled() && <div className="toolName">{toolDisplayName}</div>}
        <div className="flexGrowShrink flexRowContainer flexCenter flexNoOverflow">
          <div className="flexNoGrow flexNoOverflow flexColumnContainer flexCenter">
            {!!breadcrumbs.length && isWorksheetDisplayed && (
              <h5 className="mt0 mb2 fs11">
                <ul className="unstyled-list text-not-bold flexColumnContainer headerBreadcrumbs folderBreadcrumbs mt0 mb0 flexNoGrow">
                  {_.map(breadcrumbs, (crumb) => {
                    return (
                      <li className="ml2 " tabIndex={0} key={`crumbId_${crumb.id}`}>
                        {' '}
                        <WrapperWithLink link={getLink(crumb)}>
                          <span
                            className="text-white"
                            onClick={() => navigate(getLink(crumb))}
                            data-testid={translateBreadcrumb(crumb)}>
                            {translateBreadcrumb(crumb)}
                          </span>
                        </WrapperWithLink>
                      </li>
                    );
                  })}
                </ul>
              </h5>
            )}
          </div>
          <div
            id="workbookFullName"
            className="flexNoGrow flexNoOverflow flexColumnContainer flexNoGrowNoShrink flexCenter truncatedLabel max-height-23">
            {sqWorkbenchStore.stateParams.worksheetId && (
              <EditableText
                allowEditing={canModifyWorkbook(workbook) && isEditMode}
                onUpdate={(name) =>
                  setWorkbookName(sqWorkbenchStore.stateParams.workbookId, name, sqWorkbookStore.name)
                }
                value={workbook.name}
                inputClasses="workbookName truncatedLabel headerLabel max-height-23 mt0 mb0"
                textClasses={breadcrumbs.length ? 'fs14' : 'fs17'}
                testId="workbookName"
                id="workbookName"
              />
            )}
            {workbook.name && sqWorkbenchStore.stateParams.worksheetId && (
              <p className="truncatedLabel pl3 pr3 pb2 fs17 mt0 mb0">-</p>
            )}
            {sqWorkbenchStore.stateParams.worksheetId && (
              <EditableText
                allowEditing={canModifyWorkbook(workbook) && isEditMode}
                onUpdate={(name) => renameWorksheet(sqWorkbenchStore.stateParams.worksheetId, name)}
                value={
                  sqWorkbenchStore.stateParams.worksheetId &&
                  sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)
                }
                textClasses={breadcrumbs.length ? 'fs14' : 'fs17'}
                testId="worksheetName"
                id="worksheetName"
                autoWidth={true}
              />
            )}
          </div>
        </div>

        {sqWorkbenchStore.stateParams.worksheetId && <WorkstepIcons isEditMode={isEditMode} isViewMode={isViewMode} />}

        {isWorksheetDisplayed && lockedChangeMade ? (
          <HoverTooltip text="ACCESS_CONTROL.LOCKING.CHANGED_TOOLTIP" placement="bottom">
            <div>
              <Icon
                icon="fa-refresh"
                testId="lockRefreshIcon"
                type="white"
                extraClassNames="mr2 cursorPointer"
                onClick={() => window.location.reload()}
              />
            </div>
          </HoverTooltip>
        ) : null}
        {isLocked && sqWorkbenchStore.stateParams.worksheetId && (
          <div data-testid="toolbar-locked-message" className="mr12 flexColumnContainer">
            <HoverTooltip text="ACCESS_CONTROL.LOCKING.LOCKED_TOOLTIP" placement="bottom">
              <div className="flexColumnContainer">
                <span className="p3">{t('ACCESS_CONTROL.LOCKING.LOCKED')}</span>
                <Icon icon="fa-lock" testId="lockIcon" type="white" extraClassNames="ml2 mt5" />
              </div>
            </HoverTooltip>
          </div>
        )}
        <div className="flexColumnContainer">
          {!isLocked && (
            <ViewModeWorksheetActions isEditMode={isEditMode} isViewMode={isViewMode} isLocked={isLocked} />
          )}
        </div>
        {isEditMode && !isViewMode && (
          <span data-testid="worksheetWatchers">
            <WorksheetWatchers compactMode={false} worksheetId={sqWorkbenchStore.stateParams.worksheetId} />
          </span>
        )}
        {!isPresentationMode && !isViewOnlyWorkbookMode() && isImpactReportsToolEnabled() && (
          <div
            className="ml5 pr5 flexColumnContainer cursorPointer sq-navbar-default flexNoGrowNoShrink flexAlignCenter"
            id="valueCaptureNav">
            <ImpactReportMenu />
          </div>
        )}
        {showShareButton() && (
          <HoverTooltip placement="bottom" text="ACL_STATUS.TOOLTIP.MANAGE_ACCESS_CONTROL">
            <div
              data-testid="manageAccessControl"
              className="sq-navbar-default fs13 flexColumnContainer flexAlignCenter flexJustifyEnd ml5 mr9 flexNoGrowNoShrink"
              onClick={() => {
                doTrack('Access Control', 'open', 'from toolbar');
                setModalShown('accessControl');
              }}>
              <div className="cursorPointer">
                <Icon
                  icon="fa-kit fa-sharp-regular-user-group-circle-plus"
                  extraClassNames="fa-xlg mr7 cursorPointer sq-navbar-default link-no-underline"
                  type="inherit"
                />
                {!isMobileDevice() && <span>{t('ACCESS_CONTROL.SHARE')}</span>}
              </div>
            </div>
          </HoverTooltip>
        )}
        {modalShown === 'accessControl' && (
          <ItemAclModal
            itemId={stateParams.workbookId}
            workbookId={stateParams.workbookId}
            worksheetId={stateParams.worksheetId}
            folderId={stateParams.currentFolderId}
            includeLinksAndCorporateMessage={true}
            closeModal={() => setModalShown('none')}
            isAclModalLocationInHeader={true}
            userHasManagePermission={userHasManagePermission}
          />
        )}
        {!isPresentationMode && isWorksheet() && <LayoutControls />}
        {!isPresentationMode && !isAdminTool && !(isMobileDevice() && isViewMode) && <HeaderAIAssistant />}
        {!isPresentationMode && (
          <div
            className="ml5 pr12 flexColumnContainer cursorPointer sq-navbar-default flexNoGrowNoShrink flexAlignCenter"
            id="userMenu">
            <ButtonWithDropdown
              triggerIcon={
                <div className="flexColumnContainer flexCenter">
                  <Icon
                    icon="fa-user"
                    extraClassNames="fa-xlg flexNoGrowNoShrink mr5"
                    type="inherit"
                    testId="userProfileBtn"
                    id="userProfile"
                  />
                  {!isMobileDevice() && <span>{sqWorkbenchStore.currentUser.firstName}</span>}
                </div>
              }
              id="userProfile"
              dropdownItems={actions
                .filter((action) => action.display)
                .map((action, index) => generateDropdownEntry(action, index))}
            />
          </div>
        )}
        <div className="flexColumnContainer mt15 mb10 flexNoGrowNoShrink">
          <HamburgerMenu toggleFullScreen={toggleFullScreen} initialFullScreenState={screenfull.isFullscreen} />
        </div>

        {modalShown === 'accessKeys' && <AccessKeyModal show={true} onClose={() => setModalShown('none')} />}

        {displayUserProfile && (
          <EditUserProfile
            closeFn={() => {
              setUserProfileDisplay(false);
            }}
          />
        )}

        {displayUserPreferences && (
          <EditUserPreferencesModal
            closeFn={() => {
              setUserPreferencesDisplay(false);
              setUserPreferencesTab(PREFERENCE_TABS.SYSTEM);
            }}
          />
        )}
      </header>
      {newHeaderExperienceEnabled() &&
      !headlessRenderMode() &&
      !isPresentationWorkbookMode() &&
      !isDataLabAiAssistant() ? (
        <LoadingBar />
      ) : null}
    </div>
  );
};
