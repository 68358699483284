export enum ResultType {
  LOWER = 'lower',
  UPPER = 'upper',
  FORECAST = 'forecast',
}

export enum ForecastType {
  ARIMA = 'ARIMA',
  CONSTANT = 'CONSTANT',
  LINEAR = 'LINEAR',
  SPLICE = 'SPLICE',
}

export const FORECAST_TYPES = [
  {
    key: ForecastType.ARIMA,
    display: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.ARIMA',
    description: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.ARIMA_DESCRIPTION',
  },
  {
    key: ForecastType.CONSTANT,
    display: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.CONSTANT',
    description: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.CONSTANT_DESCRIPTION',
  },
  {
    key: ForecastType.LINEAR,
    display: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.LINEAR',
    description: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.LINEAR_DESCRIPTION',
  },
  {
    key: ForecastType.SPLICE,
    display: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.SPLICE',
    description: 'INVESTIGATE_TOOLS.SIGNAL_FORECAST.SPLICE_DESCRIPTION',
  },
];
