import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { PasswordExpiryStatusEnum } from '@/sdk/model/UserOutputV1';
import {
  getMaxPasswordAge,
  getPasswordExpirationGracePeriod,
  getPasswordExpirationWarningPeriod,
  isEnforcePasswordAgeEnabled,
  isSelfServicePasswordResetEnabled,
} from '@/services/systemConfiguration.utilities';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { Alert } from '@seeqdev/qomponents';

const DAY_IN_MS = 1000 * 60 * 60 * 24;

export const PASSWORD_WARNING_COMPONENT_ID = 'passwordWarningBanner';

export const PasswordWarning: React.FunctionComponent = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const { t } = useTranslation();
  const maxPasswordAgeInDays = getMaxPasswordAge();
  const passwordExpirationGracePeriodInDays = getPasswordExpirationGracePeriod();
  const passwordExpirationWarningPeriodInDays = getPasswordExpirationWarningPeriod();

  /**
   * Conditions for true:
   * <br>1. `EnforcePasswordAge` is enabled
   * <br>3. `MaxPasswordAge` > 0
   */
  const shouldEnforcePasswordAge = () => {
    return (
      isEnforcePasswordAgeEnabled() &&
      // max password age <= 0 is a bad configuration and cannot be enforced
      maxPasswordAgeInDays > 0
    );
  };

  const passwordAgeInDays = () => {
    const now = new Date().getTime();
    const lastPasswordChangedAt = new Date(currentUser.lastPasswordChangedAt).getTime();
    return Math.floor((now - lastPasswordChangedAt) / DAY_IN_MS);
  };

  const daysUntilPasswordExpires = () => {
    return maxPasswordAgeInDays - passwordAgeInDays();
  };

  const gracePeriodDurationRemaining = () => {
    if (passwordExpirationGracePeriodInDays <= 0) {
      return 0;
    }

    return maxPasswordAgeInDays + passwordExpirationGracePeriodInDays - passwordAgeInDays();
  };

  const visible = () => {
    if (!shouldEnforcePasswordAge()) {
      return false;
    }

    if (
      currentUser.passwordExpiryStatus === PasswordExpiryStatusEnum.EXPIRED ||
      currentUser.passwordExpiryStatus === PasswordExpiryStatusEnum.INGRACEPERIOD
    ) {
      return true;
    }

    return (
      passwordExpirationWarningPeriodInDays > 0 &&
      passwordAgeInDays() >= maxPasswordAgeInDays - passwordExpirationWarningPeriodInDays
    );
  };

  const getWarningContent = () => {
    const passwordExpiryStatus = currentUser.passwordExpiryStatus;
    let errorMessage = '';
    switch (passwordExpiryStatus) {
      case PasswordExpiryStatusEnum.EXPIRED:
        errorMessage = t('PASSWORD_WARNINGS.USER_PASSWORD_EXPIRED');
        break;
      case PasswordExpiryStatusEnum.INGRACEPERIOD:
        errorMessage = t('PASSWORD_WARNINGS.USER_PASSWORD_EXPIRY_GRACE_PERIOD_WARNING', {
          DAYS_LEFT_IN_GRACE_PERIOD: gracePeriodDurationRemaining(),
        });
        break;
      default:
        errorMessage = t('PASSWORD_WARNINGS.USER_PASSWORD_EXPIRING_SOON_WARNING', {
          DAYS_UNTIL_EXPIRES: daysUntilPasswordExpires(),
        });
    }

    return isSelfServicePasswordResetEnabled()
      ? errorMessage
      : `${errorMessage} ${t('PASSWORD_WARNINGS.CONTACT_ADMINISTRATOR_ACTION')}`;
  };

  const renderWarning = () => {
    return (
      <Alert
        id={PASSWORD_WARNING_COMPONENT_ID}
        testId={PASSWORD_WARNING_COMPONENT_ID}
        variant="warning"
        dismissible={false}
        extraClassNames="flexColumnContainer flexCenter warningBannerHeight max-height-50">
        <div className="flexFill ml20">
          <p>
            <ContainerWithHTML testId="passwordExpiryWarning" content={getWarningContent()} />
            {isSelfServicePasswordResetEnabled() && (
              <a href="/reset-password" target="_self" className="button ml-1" rel="noopener">
                {t('PASSWORD_WARNINGS.RESET_PASSWORD')}
              </a>
            )}
          </p>
        </div>
      </Alert>
    );
  };

  return visible() ? renderWarning() : <></>;
};
