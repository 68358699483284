import React from 'react';
import { Icon } from '@seeqdev/qomponents';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqAiAssistantStore, sqInvestigateStore } from '@/core/core.stores';
import { setAIAssistantDisplayed, setAIAssistantExternalPrompt } from '@/aiAssistant/aiAssistant.actions';
import { useTranslation } from 'react-i18next';

export const ToolPanelAiButton: React.FunctionComponent = () => {
  const currentTool = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.activeToolName);
  const isAiAssistantDisplayed = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.displayed);
  const { t } = useTranslation();

  return (
    <div className="pl5 pr5">
      <Icon
        icon="fa-sparkles"
        extraClassNames="cursorPointer"
        type="theme"
        iconPrefix="fa-solid"
        onClick={() => {
          if (!isAiAssistantDisplayed) {
            setAIAssistantDisplayed(true);
          }
          setAIAssistantExternalPrompt({
            prompt: t('AI_ASSISTANT.TELL_ME_MORE_ABOUT', { topic: currentTool }),
            agent: 'general',
          });
        }}
      />
    </div>
  );
};
