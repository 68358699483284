import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@seeqdev/qomponents';
import { CopyableInputField } from '@/core/CopyableInputField.molecule';
import { ButtonProps } from '@seeqdev/qomponents/dist/Button/Button.types';

interface GetLinkProps {
  testId: string;
  title: string;
  value: string;
  tooltip: string;
  readOnly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  extraButtons?: ButtonProps[];
}

export const GetLink: React.FunctionComponent<GetLinkProps> = ({
  testId,
  title,
  value,
  tooltip,
  readOnly = true,
  onChange = _.noop,
  extraButtons = [],
}) => {
  const { t } = useTranslation();

  return (
    <div className="flexRowContainer mt10">
      <div className="flexColumnContainer semi-bold pb5">
        <div className="min-width-390">
          {t(title)}
          <Icon
            icon="fa-info-circle"
            testId="title-tooltip"
            extraClassNames="ml5"
            tooltip={t(tooltip)}
            tooltipPlacement="right"
          />
        </div>
      </div>
      <div className="flexRowContainer">
        <CopyableInputField
          testId={testId}
          value={value}
          readOnly={readOnly}
          fieldTooltip=""
          notifyMessage="COPY_URL.SUCCESS"
          buttonTooltip="COPY_URL.TO_CLIPBOARD"
          onChange={onChange}
          extraButtons={[
            ...extraButtons.map((props) => ({
              variant: 'button' as const,
              buttonProps: props,
            })),
            {
              variant: 'button',
              buttonProps: {
                label: '',
                icon: 'fa-arrow-up-right-from-square',
                iconStyle: 'theme',
                tooltip: t('ITEM_ACL.OPEN_LINK'),
                tooltipOptions: { position: 'top' },
                testId: `${testId}-openLinkButton`,
                onClick: () => {
                  window.open(value, '_blank');
                },
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
