import { PersistenceLevel, Store } from '@/core/flux.service';
import type { AnyProperty } from '@/utilities.types';
import type { InstanceConfig, TableColumns, TableConfig } from '@/vantage/types/TableConfig.types';
import type { ExtensionSchema } from '@/vantage/types/Extension.types';
import type { SeeqUser } from '@/vantage/utilities';
import { TableDefinitionOutputV1 } from '@/sdk/model/TableDefinitionOutputV1';
import { getTableColumns } from '@/vantage/vantageWorkbook.utilities';
import { findConditionIds, findConditions } from '@/vantage/components/evidence_table/helpers/conditionFinders';

export class VantageWorkbookStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqVantageWorkbook';

  initialize(): void {
    this.state = this.immutable({
      workbookConfig: undefined,
      user: undefined,
      evidenceTableDefinition: undefined,
      tableColumns: this.monkey(
        ['evidenceTableDefinition'],
        ['workbookConfig'],
        (evidenceTableDefinition: TableDefinitionOutputV1 | undefined, workbookConfig: InstanceConfig | undefined) => {
          if (!evidenceTableDefinition || !workbookConfig) {
            return;
          }

          return getTableColumns(evidenceTableDefinition?.columnDefinitions, workbookConfig?.extensions);
        },
      ),
      conditionsList: this.monkey(['tableColumns'], (tableColumns: TableColumns | undefined) =>
        !tableColumns ? undefined : findConditions(tableColumns, findConditionIds(tableColumns)),
      ),
    });
  }

  get workbookConfig(): InstanceConfig | undefined {
    return this.state.get('workbookConfig');
  }

  get extensions(): ExtensionSchema[] | undefined {
    return this.workbookConfig?.extensions;
  }

  get tableConfig(): TableConfig | undefined {
    return this.workbookConfig?.tableConfig;
  }

  get tableIds(): AnyProperty<string> | undefined {
    return this.workbookConfig?.tableIds;
  }

  get workbookId(): string | undefined {
    return this.workbookConfig?.workbookId;
  }

  /**
   * Includes extinct events by default until the workbook config is loaded and we know it's safe to query on the "Last
   * Capsule State" column, which for some workbooks not exist.
   */
  get includeExtinct(): boolean {
    return this.workbookConfig?.includeExtinct ?? true;
  }

  get user(): SeeqUser | undefined {
    return this.state.get('user');
  }

  get evidenceTableDefinition(): TableDefinitionOutputV1 | undefined {
    return this.state.get('evidenceTableDefinition');
  }

  get tableColumns(): TableColumns | undefined {
    return this.state.get('tableColumns');
  }

  get conditionsList(): string[] | undefined {
    return this.state.get('conditionsList');
  }

  protected readonly handlers = {
    SET_EXTENSIONS: (extensions: ExtensionSchema[]) =>
      this.state.set('workbookConfig', { ...this.workbookConfig, extensions }),
    SET_WORKBOOK_CONFIG: (payload: { workbookConfig: InstanceConfig }) =>
      this.state.set('workbookConfig', payload.workbookConfig),
    SET_USER: (payload: { user: SeeqUser }) => this.state.set('user', payload.user),
    SET_EVIDENCE_TABLE_DEFINITION: (payload: { evidenceTableDefinition: TableDefinitionOutputV1 }) =>
      this.state.set('evidenceTableDefinition', payload.evidenceTableDefinition),
    SET_TABLE_COLUMNS: (payload: { tableColumns: TableColumns }) =>
      this.state.set('tableColumns', payload.tableColumns),
  };
}
