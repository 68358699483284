import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetLink } from '@/core/GetLink.atom';
import {
  getFolderLink,
  getPresentWorksheetLink,
  getShortViewWorksheetLink,
  getWorksheetLink,
} from '@/main/routing.utilities';
import { saveShareableWorkstep } from '@/vantage/view.utilities';
import { logError } from '@/utilities/logger';
import { useAsyncEffect } from 'rooks';

interface GetLinksProps {
  workbookId: string;
  worksheetId?: string;
  folderId?: string;
  showOnlyFolderLinks?: boolean;
  hasViewOnly: boolean;
  hasPresentation: boolean;
  isVantage?: boolean;
}

export const GetLinks: React.FunctionComponent<GetLinksProps> = ({
  workbookId,
  worksheetId,
  folderId,
  showOnlyFolderLinks,
  hasViewOnly,
  hasPresentation,
  isVantage,
}) => {
  const { t } = useTranslation();
  const [vantageDeepLink, setVantageDeepLink] = useState<string>('Loading...');

  useAsyncEffect(async () => {
    if (!isVantage || !worksheetId) {
      return;
    }

    try {
      const deepLinkUrl = await saveShareableWorkstep(workbookId, worksheetId);
      setVantageDeepLink(deepLinkUrl);
    } catch (error) {
      logError(`Failed to generate Vantage deep link: ${error}`);
    }
  }, [isVantage, workbookId, worksheetId]);

  const editFullPath = getWorksheetLink(workbookId, worksheetId, folderId, undefined, true);
  const viewFullPath = getShortViewWorksheetLink(worksheetId ?? '', true);
  const presentFullPath = getPresentWorksheetLink(workbookId, worksheetId ?? '', true);
  const folderPath = getFolderLink(folderId ?? '', undefined, true);

  const getLinks = [
    {
      title: 'ITEM_ACL.EDIT_LINK',
      testId: 'editLink',
      value: isVantage ? vantageDeepLink : editFullPath,
      tooltip: 'ITEM_ACL.EDIT_LINK_TOOLTIP',
      includeIf: true,
    },
    {
      title: 'ITEM_ACL.VIEW_LINK',
      testId: 'viewLink',
      value: viewFullPath,
      tooltip: 'ITEM_ACL.VIEW_LINK_TOOLTIP',
      includeIf: hasViewOnly,
    },
    {
      title: 'ITEM_ACL.PRESENTATION_LINK',
      testId: 'presentationLink',
      value: presentFullPath,
      tooltip: 'ITEM_ACL.PRESENTATION_LINK_TOOLTIP',
      includeIf: hasPresentation,
    },
  ];

  const folderLinks = [
    {
      title: 'ITEM_ACL.FOLDER_LINK',
      testId: 'folderLink',
      value: folderPath,
      tooltip: 'ITEM_ACL.FOLDER_LINK_TOOLTIP',
      includeIf: true,
    },
  ];

  return (
    <>
      <h4>{t('ITEM_ACL.GET_LINK')}</h4>
      {(showOnlyFolderLinks ? folderLinks : getLinks)
        .filter((link) => link.includeIf ?? true)
        .map((link) => (
          <GetLink
            key={link.testId}
            testId={link.testId}
            title={link.title}
            value={link.value}
            tooltip={link.tooltip}
          />
        ))}
    </>
  );
};
