import { sqStateSynchronizer, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { pushWorkstep } from '@/worksteps/worksteps.utilities';
import { getWorksheetLink } from '@/main/routing.utilities';
import { addWorksheetToWorkbook, setWorksheetProperty } from '@/workbook/workbook.actions';
import { CategorizedViews, SelectView } from '@/vantage/types/View.types';
import { StoreWorksheet } from '@/worksheet/worksheet.types';
import { sqItemsApi, sqWorkbooksApi } from '@/sdk';

const NO_HOME = '00000000-0000-0000-0000-000000000000';

export async function toggleHome(workbookId: string, worksheetId: string) {
  const {
    data: { home: currentHome },
  } = await sqItemsApi.getItemUserAttributes({ id: workbookId });
  let newHome = null;
  if (currentHome) {
    if (worksheetId === currentHome) {
      setWorksheetProperty(worksheetId, 'isHome', false);
      newHome = NO_HOME;
    } else {
      setWorksheetProperty(currentHome, 'isHome', false);
      setWorksheetProperty(worksheetId, 'isHome', true);
      newHome = worksheetId;
    }
  } else {
    setWorksheetProperty(worksheetId, 'isHome', true);
    newHome = worksheetId;
  }
  await sqItemsApi.setItemUserAttributes({ home: newHome }, { id: workbookId });
}

export async function togglePin(worksheetId: string) {
  const {
    data: { items: pinned },
  } = await sqItemsApi.getPinnedItems({ sourceId: undefined });
  const pinnedIds = pinned?.map(({ id }) => id) ?? [];
  const isPinned = pinnedIds.includes(worksheetId);
  setWorksheetProperty(worksheetId, 'isPinned', !isPinned);
  if (isPinned) {
    await sqItemsApi.unpinItem({ itemId: worksheetId });
  } else {
    await sqItemsApi.pinItem({ itemId: worksheetId });
  }
}

/**
 * Saves the current state as a new workstep on the worksheet and generates a URL to access it.
 *
 * @async
 * @function saveWorkstep
 * @param {string} workbookId - The unique identifier of the workbook.
 * @param {string} worksheetId - The unique identifier of the worksheet.
 * @returns {Promise<string>} - Resolves to the URL of the worksheet with the serialized state as the current workstep.
 */
export async function saveWorkstep(workbookId: string, worksheetId: string) {
  const newStoreState = sqStateSynchronizer.serializeWorksheetState();
  await pushWorkstep(workbookId, worksheetId, newStoreState);
  return getWorksheetLink(workbookId, worksheetId);
}

/**
 * Saves the current state as a new workstep on the worksheet and generates a URL to access it.
 * The active workstep is immediately reverted to the previous workstep, and the shareable workstep
 * URL has its date range fixed to the current state if they are relative.
 *
 * @async
 * @function saveShareableWorkstep
 * @param {string} workbookId - The unique identifier of the workbook.
 * @param {string} worksheetId - The unique identifier of the worksheet.
 * @returns {Promise<string>} - Resolves to the URL of the worksheet with the serialized state as the current workstep.
 */
export async function saveShareableWorkstep(workbookId: string, worksheetId: string) {
  const newStoreState = sqStateSynchronizer.serializeWorksheetState();
  if (newStoreState.stores.sqVantageTableStore.dateRange.lookBack?.num) {
    newStoreState.stores.sqVantageTableStore.dateRange.lookBack.num = 0;
  }
  if (newStoreState.stores.sqVantageTableStore.dateRange.lookFoward?.num) {
    newStoreState.stores.sqVantageTableStore.dateRange.lookFoward.num = 0;
  }
  const pushResult = await pushWorkstep(workbookId, worksheetId, newStoreState);
  const url = getWorksheetLink(workbookId, worksheetId, undefined, pushResult.current.id, true);
  await sqWorkbooksApi.setCurrentWorkstep({ workbookId, worksheetId, workstepId: pushResult.previous! });
  return url;
}

export const categorizeViews = (
  worksheets: StoreWorksheet[],
  translator: (label: string) => string,
): CategorizedViews[] => {
  const groupedOptions: CategorizedViews[] = [
    {
      context: 'home',
      label: translator('VANTAGE.EVIDENCE_TABLE.VIEW_DROPDOWN.OPTIONS.HOME'),
      options: [],
    },
    {
      context: 'pinned',
      label: translator('VANTAGE.EVIDENCE_TABLE.VIEW_DROPDOWN.OPTIONS.PINNED'),
      options: [],
    },
    {
      context: 'mine',
      label: translator('VANTAGE.EVIDENCE_TABLE.VIEW_DROPDOWN.OPTIONS.MINE'),
      options: [],
    },
    {
      context: 'other',
      label: translator('VANTAGE.EVIDENCE_TABLE.VIEW_DROPDOWN.OPTIONS.OTHER'),
      options: [],
    },
  ];
  worksheets.forEach((worksheet: StoreWorksheet) => {
    const formattedView: SelectView = {
      label: worksheet.name!,
      value: worksheet.worksheetId!,
      settings: {
        home: worksheet.isHome!,
        pinned: worksheet.isPinned!,
        mine: worksheet.creatorId?.toLowerCase() === sqWorkbenchStore.currentUser.id.toLowerCase(),
      },
      creator: worksheet.creatorName!,
    };
    if (formattedView.settings.home) {
      groupedOptions[0].options.push(formattedView);
    } else if (formattedView.settings.pinned) {
      groupedOptions[1].options.push(formattedView);
    } else if (formattedView.settings.mine) {
      groupedOptions[2].options.push(formattedView);
    } else {
      groupedOptions[3].options.push(formattedView);
    }
  });
  return groupedOptions;
};

export async function createView(name: string) {
  const worksheet = await addWorksheetToWorkbook(sqWorkbookStore.workbookId, { name });
  setWorksheetProperty(worksheet.worksheetId!, 'creatorId', sqWorkbenchStore.currentUser.id);
  setWorksheetProperty(worksheet.worksheetId!, 'creatorName', sqWorkbenchStore.currentUser.name);
  await sqItemsApi.setProperty(
    { value: sqWorkbenchStore.currentUser.id },
    {
      id: worksheet.worksheetId!,
      propertyName: 'creatorId',
    },
  );
  return saveWorkstep(sqWorkbookStore.workbookId, worksheet.worksheetId!);
}
