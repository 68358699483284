import _ from 'lodash';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { FormulaDocSummaryOutputV1 } from '@/sdk/model/FormulaDocSummaryOutputV1';
import { sqFormulasApi } from '@/sdk/api/FormulasApi';
import { FormulaErrorMessage } from '@/formula/FormulaErrorMessage';
import { findAtLeastOneChild, mapCalculationParamsToAssetChild } from '@/assetGroupEditor/assetGroup.utilities';
import { getAxiosInstance } from '@/requests/axios.utilities';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { AssetGroupAsset } from '@/assetGroupEditor/assetGroup.types';
import { FormulaEditorParam } from '@/formula/FormulaParametersTable.molecule';
import { FormulaErrorInterface } from '@/formula/formula.types';
import { Editor } from 'codemirror';

const LETTERS = _.map(_.range('a'.charCodeAt(0), 'z'.charCodeAt(0) + 1), _.ary(String.fromCharCode, 1)) as string[];

export function getNextFormulaIdentifier(parameters: string[]) {
  let identifier;
  let i = 0;

  while (!identifier) {
    identifier = getShortIdentifier(i++);
    if (_.includes(parameters, identifier)) {
      identifier = undefined;
    }
  }

  return identifier;
}

/**
 * Computes a short variable identifier given an index number.
 *
 * @param {Number} num - The sequential number of the variable, used to avoid duplicating identifiers.
 * @returns {String} The variable name
 */
function getShortIdentifier(num: number): string {
  const index = num % LETTERS.length;
  const repeat = Math.floor(num / LETTERS.length) + 1;
  return _.repeat(LETTERS[index], repeat);
}

/**
 * Returns the start, middle and end position for the term/word under/by the cursor.
 * Example:
 *   Month.JA
 *   ^ Start
 *        ^ Middle
 *          ^ End
 *
 * @param editor - Formula editor
 * @param matchStartingParenthesis - Whether to match any starting parenthesis after the term
 * @param [cursorPosition] - Index on current line to use as the cursor position, rather than the
 *   editor's current cursor position
 * @returns Object containing the start, middle and end position of the term under/by the cursor
 */
function getCursorTermStartMiddleAndEnd(editor: Editor, matchStartingParenthesis: boolean, cursorPosition?: number) {
  const editorPosition = getEditorPositionObject(editor);
  const line = editorPosition.line;

  let start;
  let middle = cursorPosition ?? editorPosition.cursor.ch;
  let end = cursorPosition ?? editorPosition.cursor.ch;

  // Move middle position to encompass entire term before cursor
  while (middle && /\w/.test(line.charAt(middle - 1))) {
    --middle;
  }

  // Move middle position to encompass any . or $ before term
  while (middle && /[.$]/.test(line.charAt(middle - 1))) {
    --middle;
  }

  // Move start position to encompass entire term before '.' or '$'
  start = middle;
  while (start && /[$\w]/.test(line.charAt(start - 1))) {
    --start;
  }

  // Move end position to encompass entire term after/by cursor
  while (end < line.length && /\w/.test(line.charAt(end))) {
    ++end;
  }

  if (matchStartingParenthesis) {
    // Move end position to encompass any parenthesis after term
    while (end < line.length && /\(/.test(line.charAt(end))) {
      ++end;
    }
  }

  return {
    start,
    middle,
    end,
  };
}

export function getEditorPositionObject(editor: Editor) {
  const cursor = editor.getCursor();

  return {
    cursor,
    line: editor.getLine(cursor.line),
    ch: cursor.ch,
  };
}

export function getAutocompleteHints(
  editor: Editor,
  constants: any[],
  operators: any[],
  parameters: FormulaEditorParam[],
) {
  if (!editor) return;
  const editorPosition = getEditorPositionObject(editor);
  const { start, middle, end } = getCursorTermStartMiddleAndEnd(editor, true);
  let suggestionList: string[] = [];
  const containsSuggestions: string[] = [];
  const prefix = editorPosition.line.slice(start, middle).toLowerCase();
  let term = editorPosition.line.slice(middle, end).toLowerCase();
  let startReplacementPos = start;

  // Attempt constant autocompletion first (based on the term before the '.')
  if (_.startsWith(term, '.')) {
    _.forEach(constants, (constant) => {
      if (_.startsWith(constant, _.toUpper(prefix + term))) {
        suggestionList.push(constant);
      }
      const constantSuffix = constant.split('.')[1];
      if (
        _.startsWith(constant, _.toUpper(prefix)) &&
        prefix.length > 0 &&
        constantSuffix.includes(_.toUpper(term.substring(1)))
      ) {
        containsSuggestions.push(constant);
      }
    });
  }

  if (_.size(suggestionList) === 0 && _.size(containsSuggestions) === 0) {
    // Fall back to function and variable autocompletion if no constants matched
    startReplacementPos = middle;
    if (_.startsWith(term, '.')) {
      // Function autocompletion
      term = term.substring(1); // Remove '.' from operator
      _.forEach(operators, (operator: any) => {
        // It's an operator (contains ()) and it matches our search term, so add it as a suggestion
        if (_.startsWith(operator.name.toLowerCase(), term) && operator.name.indexOf('()') !== -1) {
          suggestionList.push(`.${operator.name.substring(0, operator.name.indexOf('()') + 1)}`);
        }
        if (term.length > 0 && operator.name.toLowerCase().includes(term) && operator.name.indexOf('()') !== -1) {
          containsSuggestions.push(`.${operator.name.substring(0, operator.name.indexOf('()') + 1)}`);
        }
      });
    } else if (_.startsWith(term, '$')) {
      // Variable autocompletion
      term = term.substring(1); // Remove '$' from variable name
      _.forEach(parameters, (item: any) => {
        if (_.startsWith(item.identifier.toLowerCase(), term)) {
          suggestionList.push(`$${item.identifier}`);
        }
        if (term.length > 0 && item.identifier.toLowerCase().includes(term)) {
          containsSuggestions.push(`$${item.identifier}`);
        }
      });
    }
  }

  suggestionList = suggestionList.concat(containsSuggestions);

  return {
    list: _.uniq(suggestionList),
    from: {
      line: editorPosition.cursor.line,
      ch: startReplacementPos,
    },
    to: {
      line: editorPosition.cursor.line,
      ch: end,
    },
  };
}

export function getContextHelp(editor: Editor, operators: any[]) {
  if (!editor) return;

  const editorPosition = getEditorPositionObject(editor);
  const startMiddleAndEnd = getCursorTermStartMiddleAndEnd(editor, false);
  let term = editorPosition.line.substring(startMiddleAndEnd.middle, startMiddleAndEnd.end);

  // If term is empty, search backwards until we find a term to use
  if (term === '') {
    let position = startMiddleAndEnd.middle;
    let cursorTermPositions;
    while (term === '' && position >= 0) {
      position -= 1;
      cursorTermPositions = getCursorTermStartMiddleAndEnd(editor, false, position);
      term = editorPosition.line.substring(cursorTermPositions.middle, cursorTermPositions.end);
    }
  }

  if (_.startsWith(term, '.')) {
    term = term.substring(1); // Remove '.' from operator
  }

  const searchTerm = `${term.toLowerCase()}()`;
  const operatorsSuggestions = operators as FormulaDocSummaryOutputV1[];
  const matches = _.filter(operatorsSuggestions, (o) => (o.name as string).toLowerCase().startsWith(searchTerm));

  return _.size(matches) > 1 || (term && !_.size(matches)) ? term : matches;
}

export function getErrorMessage(error: FormulaErrorInterface, clearError: () => void) {
  const errorElement = document.createElement('div'); // this needs to be a node
  const root = createRoot(errorElement);
  root.render(<FormulaErrorMessage error={error} clearError={clearError} />);
  return errorElement;
}

export function runFormula(formula: string, parameters: string[]) {
  return sqFormulasApi
    .compileFormulaAndParameters({
      formula,
      parameters,
    })
    .catch(({ response }) => {
      const data = response.data;
      if (_.has(data, 'errors')) {
        const formError: FormulaErrorInterface = _.pick(_.first(data.errors), [
          'message',
          'column',
          'line',
        ]) as FormulaErrorInterface;
        return Promise.reject([{ ...formError }]);
      } else if (_.has(data, 'statusMessage')) {
        return Promise.reject([{ message: data.statusMessage, column: -1, line: -1 }]);
      }
    });
}

export function validateAssetGroupFormula(
  assets: AssetGroupAsset[],
  formula: string,
  parameters: FormulaEditorParam[],
  scopedToThisAsset?: AssetGroupAsset,
) {
  // before we run the formula we need to validate that the formula can execute - so we need to use inputs of an
  // asset and use its children to just run the formula; we need to find a valid child asset that can be used for
  // this validation - we do not have to use children from only one asset, we can just use any child that was assigned.
  // this prevents un-necessary errors if an asset group has "holes" (does not show green checkmarks for every cell)
  const children = _.chain(parameters)
    .map((parameter) => parameter.item.name)
    .map((columnName) =>
      scopedToThisAsset !== undefined
        ? findAtLeastOneChild([scopedToThisAsset], columnName)
        : findAtLeastOneChild(assets, columnName),
    )
    .compact()
    .value();

  let formulaParamsForFormulaRun: string[] = [];
  const { mappings, dependencies } = mapCalculationParamsToAssetChild({ children } as AssetGroupAsset, parameters);
  // If the formula is dependent on another asset group item that is not yet created we run the formula based on the
  // original item id that asset group item is based on
  // NOTE: you can not create a calculation column based on another unsaved calculation column - we do not support
  // that (yet)
  if (!_.isEmpty(dependencies)) {
    formulaParamsForFormulaRun = _.map(dependencies, (param) => `${param.variableName}=${param.originalItemId}`);
  }
  formulaParamsForFormulaRun = _.concat(
    formulaParamsForFormulaRun,
    _.map(mappings, (param) => `${param.name}=${param.id}`),
  );
  const formulaParametersActuallyUsed = _.filter(formulaParamsForFormulaRun, (param) => {
    // Negative lookbehind to ensure we're not in a single-line comment
    // Negative lookbehind to ensure we're not in a multi-line comment
    // Match the actual $variable pattern
    // Allow special characters in the variable name
    const pattern = /(?<!\/\/.*?)(?<!\/\*(?:[^*]|\*(?!\/))*)\$[a-zA-Z][\w-]*/g;
    return formula.match(pattern)?.includes(`$${param.split('=')[0]}`);
  });
  return runFormula(formula, formulaParametersActuallyUsed);
}

export function validateFormula(formula: string, parameters: FormulaEditorParam[]) {
  const formulaParamsForFormulaRun = _.map(parameters, (param) => `${param.identifier}=${param.item.id}`);
  return runFormula(formula, formulaParamsForFormulaRun);
}

export function addTextAtCursor(text: string) {
  if (window.codeMirrorEditor) {
    const selection = window.codeMirrorEditor.getSelection();

    if (selection.length > 0) {
      // Replace current selection
      window.codeMirrorEditor.replaceSelection(text);
    } else {
      // Nothing was selected, so insert at current cursor position
      const doc = window.codeMirrorEditor.getDoc();
      const cursor = doc.getCursor();

      doc.replaceRange(text, { line: cursor.line, ch: cursor.ch });
    }
    getEditorPositionObject(window.codeMirrorEditor);

    // Delay focus until after text insertion to prevent cursor loss
    setTimeout(() => window.codeMirrorEditor.focus(), 200);
  }
}

export function getFormulaDocumentation(documentationHref: string) {
  return getAxiosInstance().get(APPSERVER_API_PREFIX + documentationHref);
}
