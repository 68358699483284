// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';

interface WorksheetWatchersProps {
  worksheetId: string;
  compactMode: boolean;
  classes?: string;
}

export const MAX_WATCHERS_LIST_SIZE = 10;
export const WorksheetWatchers: React.FunctionComponent<WorksheetWatchersProps> = ({
  worksheetId,
  compactMode,
  classes,
}) => {
  const { t } = useTranslation();

  // A list of viewers containing information about the users viewing the page. Elements in the list are of the form:
  // {  name: the user's name, or username if not given
  //    username: the username of the user
  //    worksheetId: the id of the worksheet their viewing
  //    worksheetDisplay: the display mode they're using to view the worksheet
  // }
  const viewers = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.viewers);
  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);

  const [formattedViewers, setFormattedViewers] = useState([]);
  const [viewerCount, setViewerCount] = useState(0);

  const MODE_MAP = {
    [WORKBOOK_DISPLAY.EDIT]: 'JOURNAL.ENTRY.EDITING',
    [WORKBOOK_DISPLAY.VIEW]: 'JOURNAL.ENTRY.VIEWING',
    [WORKBOOK_DISPLAY.PRESENT]: 'JOURNAL.ENTRY.PRESENTING',
  };

  useEffect(() => {
    const formatViewers = () => {
      return (
        _.chain(viewers)
          .filter(['worksheetId', worksheetId])
          .filter((user) => user.username !== currentUser.username || worksheetId !== stateParams.worksheetId)
          .clone()
          // Sort the array so that we can filter users with multiple sessions on the same worksheet with the following
          // priority: EDIT > VIEW > PRESENTATION. That way EDIT users with a VIEW tab of the same worksheet will only
          // see one instance of themselves in the watchers list as an EDIT user, i.e. the one with the most privileges.
          .sortBy('worksheetDisplay', (display) => {
            const rank = {
              'WORKBOOK_DISPLAY.EDIT': 0,
              'WORKBOOK_DISPLAY.VIEW': 1,
              'WORKBOOK_DISPLAY.PRESENTATION': 2,
            };
            return rank[display];
          })
          .uniqBy('username')
          .map((viewer) => ({
            ...viewer,
            name: _.trim(viewer.name) || viewer.username,
          }))
          .sortBy('name')
          .thru((list) => {
            setViewerCount(list.length);
            return list.length > MAX_WATCHERS_LIST_SIZE ? list.slice(0, MAX_WATCHERS_LIST_SIZE) : list;
          })
          .value()
      );
    };
    setFormattedViewers(formatViewers());
  }, [viewers, worksheetId, stateParams.worksheetId, currentUser.username]);

  const watchersTooltip = `<span className="worksheet-watchers-tooltip">
      <p className="textAlignLeft mb0">{${t('WATCHERS_HELP')}}</p>
      <ul id="specWatcherList" className="pl15 textAlignLeft mt5 mb5">
        ${_.map(
          formattedViewers,
          (viewer, i) =>
            `<li key={${viewer.username}${i}}>
            ${viewer.name} (<span>{${t(MODE_MAP[viewer.worksheetDisplay])}}</span>)
          </li>`,
        )}
      </ul>
      ${viewerCount > MAX_WATCHERS_LIST_SIZE ? '<p className="text-center">...</p>' : ''}
    </span>`;

  return (
    formattedViewers.length > 0 && (
      <HoverTooltip placement="bottom" formattedText={watchersTooltip}>
        <span className={classNames('text-nowrap mr5 cursorPointer', classes)}>
          <Icon icon="fa-eye" type="inherit" extraClassNames={!compactMode && 'fa-xlg'} testId="watcherEyeball" />
          <sub data-testid="watcherCount">{viewerCount}</sub>
        </span>
      </HoverTooltip>
    )
  );
};
