import React from 'react';
import { ImpactReportUseCase } from '@/impactReports/impactReport.types';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqImpactReportStore, sqWorkbenchStore } from '@/core/core.stores';
import { Modal } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { ImpactReportDetail, ImpactReportDetailHeader } from '@/impactReports/ImpactReportDetail.page';
import { isAdmin } from '@/services/authorization.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

interface ImpactReportDetailModalProps {
  closeFn: () => void;
  details: ImpactReportUseCase;
  editCallbackFn?: () => void;
}

export const ImpactReportDetailModal: React.FunctionComponent<ImpactReportDetailModalProps> = ({
  closeFn,
  details,
  editCallbackFn,
}) => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);

  const { t } = useTranslation();
  const categories = useFluxPath(sqImpactReportStore, () => sqImpactReportStore.categories);

  // Edit and Delete is only shown for the owner of the impact or admin
  const getOnEditFunction = () => {
    if (details && editCallbackFn && (isAdmin() || details[SeeqNames.ImpactReport.ImpactOwner] === currentUser.id)) {
      return editCallbackFn;
    }
    return null;
  };

  return (
    <Modal
      title={t('IMPACT_REPORT.DETAIL')}
      size="4xl"
      submitButtonLabel={t('EDIT')}
      cancelButtonLabel={t('CLOSE')}
      hideFooterButtons={true}
      open={true}
      onSubmit={editCallbackFn ? () => editCallbackFn() : undefined}
      onClose={closeFn}
      customHeader={<ImpactReportDetailHeader {...details} />}>
      <ImpactReportDetail
        {...details}
        categories={categories}
        onClose={closeFn}
        onEdit={getOnEditFunction() ?? undefined}
      />
    </Modal>
  );
};
