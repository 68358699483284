import { PersistenceLevel, Store } from '@/core/flux.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { RowRulesResultV1, RulesResultV1, TableDefinitionInputV1, TableDefinitionOutputV1 } from '@/sdk';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import {
  columnDefinitionOutputToColumnDefinitionInput,
  processMaterializedTable,
  tableDefinitionOutputToTableDefinitionInput,
} from '@/tableDefinitionEditor/tableDefinition.utilities';
import {
  ItemTableCell,
  MaterializedTableItemColumnPropertyPair,
  MaterializedTableOutput,
  ProcessedMaterializedTable,
  RowLoadProgress,
  ScalingTableColumnDefinition,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { columnRuleOutputToColumnRuleInput } from '@/tableDefinitionEditor/columnRules/columnRule.utilities';
import { t } from 'i18next';
import { BatchActionEnum, TableTypeEnum } from '@/sdk/model/TableDefinitionInputV1';
import { SearchTypeEnum } from 'sdk/model/PropertySearchV1';
import _ from 'lodash';
import { GridApi } from '@ag-grid-community/core';
import {
  DATUM_ID_COLUMN,
  DEFAULT_COLUMN_DEFINITION_INPUTS,
  DEFAULT_PROPERTIES_FOR_ALL_ITEM_COLUMNS,
  ID_COLUMN,
} from '@/tableDefinitionEditor/tableDefinition.constants';

export class TableDefinitionStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqTableDefinitionStore';

  initialize() {
    this.state = this.immutable({
      id: '',
      subscriberId: '',
      name: '',
      searchTypeAI: undefined,
      description: '',
      scopedTo: undefined,
      batchAction: BatchActionEnum.UPDATEEXISTINGINSERTNEWCLEANUP,
      tableType: TableTypeEnum.SCALING,
      columns: [],
      hasUnsupportedSubscription: undefined,
      shouldSearchForItems: false,
      tableDefinitionInput: this.monkey(
        ['subscriberId'],
        ['name'],
        ['description'],
        ['scopedTo'],
        ['batchAction'],
        ['tableType'],
        ['columns'],
        function (
          subscriberId: string,
          name: string,
          description: string,
          scopedTo: string | undefined,
          batchAction: BatchActionEnum,
          tableType: TableTypeEnum,
          columnDefinitions: ScalingTableColumnDefinition[],
        ): TableDefinitionInputV1 {
          const columnDefinitionInputs =
            columnDefinitions.length === 0
              ? DEFAULT_COLUMN_DEFINITION_INPUTS
              : columnDefinitions.map((column) =>
                  columnDefinitionOutputToColumnDefinitionInput(column, columnDefinitions, { scopedTo }),
                );

          return {
            subscriptionId: subscriberId,
            name,
            description,
            scopedTo,
            batchAction,
            tableType,
            columnDefinitions: columnDefinitionInputs,
          };
        },
      ),
      tableDefinitionOutput: undefined,
      materializedTable: undefined,
      doTableReload: false,
      itemColumnsThatCouldBeUsedToAutoCreateTextColumns: this.monkey(
        ['columns'],
        (columns: ScalingTableColumnDefinition[]) => {
          return (
            columns
              .map((column, index) => ({ ...column, columnIndex: index + 1 }))
              .filter((column) => column.columnType === ColumnTypeEnum.UUID && column.propertyToDisplay)
              // filter out an item column if there is a text column whose first rule is item property and has the same property
              // as the item column is currently displaying
              .filter((itemColumnWithProperty) => {
                const textColumnWithItemPropertyRule = columns.find((col) => {
                  const getItemPropertyRule =
                    col.columnType === ColumnTypeEnum.TEXT &&
                    col.rules.length > 0 &&
                    col.rules[0].rule === SeeqNames.MaterializedTables.Rules.GetItemProperty
                      ? columnRuleOutputToColumnRuleInput(
                          col.rules[0],
                          columns.filter((columnDef) => columnDef.columnName !== col.columnName),
                          { scopedTo: this.scopedTo },
                        )
                      : undefined;
                  const isPropertyMatch =
                    getItemPropertyRule?.getItemProperty?.propertyName === itemColumnWithProperty.propertyToDisplay;
                  const inputColumnOfRuleIsItemColumn =
                    getItemPropertyRule?.getItemProperty?.columnIndex === itemColumnWithProperty.columnIndex;
                  return isPropertyMatch && inputColumnOfRuleIsItemColumn ? col : undefined;
                });
                return !textColumnWithItemPropertyRule;
              })
              .map((column) => ({
                columnName: column.columnName,
                propertyToDisplay: column.propertyToDisplay,
                displayName: column.displayName,
              })) as Required<Pick<ScalingTableColumnDefinition, 'columnName' | 'displayName' | 'propertyToDisplay'>>[]
          );
        },
      ),
      pendingTableIds: {},
      showEvaluationDetailsModal: false,
      evaluationResultsToView: undefined,
      evaluationDetailColumn: undefined,
      createdItemFinderId: undefined,
      allowHiddenColumnsAsInputs: false,
      agGridApi: undefined,
      rowLoadProgress: undefined,
      rowDataIsLoading: false,
      selectedColumns: [],
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get shouldSearchForItems(): boolean {
    return this.state.get('shouldSearchForItems');
  }

  get pendingTableIds(): Record<string, boolean> {
    return this.state.get('pendingTableIds');
  }

  get subscriberId(): string {
    return this.state.get('subscriberId');
  }

  get createdItemFinderId(): string {
    return this.state.get('createdItemFinderId');
  }

  get name(): string {
    return this.state.get('name');
  }

  get tableType(): TableTypeEnum {
    return this.state.get('tableType');
  }

  get searchTypeAI(): SearchTypeEnum | undefined {
    return this.state.get('searchTypeAI');
  }

  get description(): string {
    return this.state.get('description');
  }

  get scopedTo(): string | undefined {
    return this.state.get('scopedTo');
  }

  get batchAction(): BatchActionEnum {
    return this.state.get('batchAction');
  }

  get columns(): ScalingTableColumnDefinition[] {
    return this.state.get('columns');
  }

  get tableDefinitionInput(): TableDefinitionInputV1 {
    return this.state.get('tableDefinitionInput');
  }

  get tableDefinitionOutput(): TableDefinitionOutputV1 {
    return this.state.get('tableDefinitionOutput');
  }

  get materializedTable(): ProcessedMaterializedTable | undefined {
    return this.state.get('materializedTable');
  }

  get showEvaluationDetailsModal(): boolean {
    return this.state.get('showEvaluationDetailsModal');
  }

  get evaluationResultsToView(): RulesResultV1[] {
    return this.state.get('evaluationResultsToView');
  }

  get evaluationDetailColumn(): ScalingTableColumnDefinition {
    return this.state.get('evaluationDetailColumn');
  }

  get itemColumnsThatCouldBeUsedToAutoCreateTextColumns(): Required<
    Pick<ScalingTableColumnDefinition, 'columnName' | 'displayName' | 'propertyToDisplay'>
  >[] {
    return this.state.get('itemColumnsThatCouldBeUsedToAutoCreateTextColumns');
  }

  get hasUnsupportedSubscription(): boolean | undefined {
    return this.state.get('hasUnsupportedSubscription');
  }

  get doTableReload(): boolean {
    return this.state.get('doTableReload');
  }

  get allowHiddenColumnsAsInputs(): boolean {
    return this.state.get('allowHiddenColumnsAsInputs');
  }

  get agGridApi(): GridApi | undefined {
    return this.state.get('agGridApi');
  }

  get selectedColumns(): string[] {
    return this.state.get('selectedColumns');
  }

  getColumnDisplayName = (columnName: string): string => {
    if (columnName === DATUM_ID_COLUMN.columnName) {
      return this.tableDefinitionOutput.uniqueIDColumnName ?? t('SCALING.DEFAULT_DATUM_ID_COLUMN_NAME');
    }

    if (columnName === ID_COLUMN.columnName) {
      return t('SCALING.DEFAULT_ITEM_ID_COLUMN_NAME');
    }

    return columnName;
  };

  getColumnFromProcessedMaterializedTable = (columnName: string): ItemTableCell[] => {
    const materializedTable = this.materializedTable;
    if (!materializedTable) {
      return [];
    }
    const rows = materializedTable.rows;
    const rowsAsColumns = _.zip(...rows);
    const columnIndex = materializedTable.headers.findIndex((header) => header.name === columnName);
    return rowsAsColumns[columnIndex] as unknown as ItemTableCell[];
  };

  get rowLoadProgress(): RowLoadProgress | undefined {
    return this.state.get('rowLoadProgress');
  }

  get rowDataIsLoading(): boolean {
    return this.state.get('rowDataIsLoading');
  }

  protected readonly handlers = {
    TABLE_DEFINITION_SET_SUBSCRIBER_ID: ({ subscriberId }: { subscriberId: string | undefined }) => {
      this.state.set('subscriberId', subscriberId);
    },

    TABLE_DEFINITION_SET_SHOULD_SEARCH_FOR_ITEMS: ({ shouldSearchForItems }: { shouldSearchForItems: boolean }) => {
      this.state.set('shouldSearchForItems', shouldSearchForItems);
    },

    TABLE_DEFINITION_SET_NAME: ({ name }: { name: string }) => {
      this.state.set('name', name);
    },

    TABLE_DEFINITION_SET_SEARCH_TYPE_AI: ({ searchTypeAI }: { searchTypeAI: SearchTypeEnum }) => {
      this.state.set('searchTypeAI', searchTypeAI);
    },

    TABLE_DEFINITION_SET_DESCRIPTION: ({ description }: { description: string }) => {
      this.state.set('description', description);
    },

    TABLE_DEFINITION_SET_BATCH_ACTION: ({ batchAction }: { batchAction: BatchActionEnum }) => {
      this.state.set('batchAction', batchAction);
    },

    TABLE_DEFINITION_SET_DO_TABLE_RELOAD: ({ doTableReload }: { doTableReload: boolean }) => {
      this.state.set('doTableReload', doTableReload);
    },

    TABLE_DEFINITION_SET_TABLE_DEFINITION: ({ tableDefinition }: { tableDefinition: TableDefinitionOutputV1 }) => {
      this.state.set('tableDefinitionOutput', tableDefinition);

      this.state.set('id', tableDefinition.id);
      this.state.set('name', tableDefinition.name);

      const hasUnsupportedSubscription = tableDefinition.subscription?.type === SeeqNames.Types.ConditionMonitor;
      this.state.set('hasUnsupportedSubscription', hasUnsupportedSubscription);
      if (hasUnsupportedSubscription) {
        return;
      }

      const tableDefinitionInput = tableDefinitionOutputToTableDefinitionInput(tableDefinition);
      this.state.set('subscriberId', tableDefinitionInput.subscriptionId);
      this.state.set('description', tableDefinitionInput.description);
      this.state.set('scopedTo', tableDefinitionInput.scopedTo);
      this.state.set('batchAction', tableDefinitionInput.batchAction);
      this.state.set('tableType', tableDefinitionInput.tableType);
      const currentColumns: ScalingTableColumnDefinition[] = this.state.get('columns');
      const newColumnDefinitions = tableDefinition.columnDefinitions;
      const updatedColumns: ScalingTableColumnDefinition[] = newColumnDefinitions.map((newColumn) => {
        const existingColumn = currentColumns.find((column) => column.columnName === newColumn.columnName);
        const displayName = this.getColumnDisplayName(newColumn.columnName);
        const isUUIDColumn = newColumn.columnType === ColumnTypeEnum.UUID;
        const propertyToDisplayForNewColumn = isUUIDColumn ? SeeqNames.Properties.Name : undefined;
        const propertyToDisplay = existingColumn ? existingColumn.propertyToDisplay : propertyToDisplayForNewColumn;
        const hideColumnAsInput = newColumn.rules.some(
          (rule) => rule.rule === SeeqNames.MaterializedTables.Rules.SetItemProperty,
        );
        const additionalProperties = isUUIDColumn
          ? existingColumn?.additionalProperties ?? DEFAULT_PROPERTIES_FOR_ALL_ITEM_COLUMNS
          : undefined;
        const isHiddenFromDropdown = hideColumnAsInput || existingColumn?.isHiddenFromDropdown;
        return {
          displayName,
          propertyToDisplay,
          additionalProperties,
          isHiddenFromDropdown,
          ...newColumn,
        };
      });
      this.state.set('columns', updatedColumns);
    },

    TABLE_DEFINITION_SET_MATERIALIZED_TABLE: ({
      materializedTable,
    }: {
      materializedTable: MaterializedTableOutput;
    }) => {
      const columns: ScalingTableColumnDefinition[] = this.state.get('columns');
      const processedMaterializedTable = processMaterializedTable(materializedTable, columns);
      this.state.set('materializedTable', processedMaterializedTable);
    },

    TABLE_DEFINITION_SET_EVALUATION_RESULTS: ({
      evaluationResults,
    }: {
      evaluationResults: { [key: string]: RowRulesResultV1[] };
    }) => {
      const columns: ScalingTableColumnDefinition[] = this.state.get('columns');

      const updatedColumns: ScalingTableColumnDefinition[] = columns.map((newColumn) => {
        return {
          evaluationDetails: evaluationResults[newColumn.id.toLowerCase()],
          ...newColumn,
        };
      });
      this.state.set('columns', updatedColumns);
    },

    TABLE_DEFINITION_SET_PROPERTY_NAME_FOR_UUID_COLUMN: ({
      uuidColumnToPropertyNamePair,
    }: {
      uuidColumnToPropertyNamePair: MaterializedTableItemColumnPropertyPair;
    }) => {
      let doTableReload = true;
      const columns: ScalingTableColumnDefinition[] = this.state.get('columns');
      const newProperty = uuidColumnToPropertyNamePair.propertyName;
      const isIdProperty = newProperty === SeeqNames.Properties.Id;

      const updatedColumns = columns.map((column) => {
        if (column.columnName !== uuidColumnToPropertyNamePair.uuidColumn) {
          return column;
        }
        const additionalProperties = column.additionalProperties ? [...column.additionalProperties] : [];
        const newPropertyIsInAdditionalProperties = additionalProperties.includes(newProperty);
        if (newPropertyIsInAdditionalProperties || isIdProperty) {
          doTableReload = false;
        }
        if (!additionalProperties.includes(newProperty)) {
          additionalProperties.push(newProperty);
        }
        return {
          ...column,
          additionalProperties,
          propertyToDisplay: isIdProperty ? undefined : newProperty,
        };
      });
      this.state.set('columns', updatedColumns);
      this.handlers.TABLE_DEFINITION_SET_DO_TABLE_RELOAD({ doTableReload });
    },

    TABLE_DEFINITION_RESET: () => {
      this.state.set('id', '');
      this.state.set('subscriberId', '');
      this.state.set('name', '');
      this.state.set('tableType', TableTypeEnum.SCALING);
      this.state.set('description', '');
      this.state.set('scopedTo', undefined);
      this.state.set('materializedTable', undefined);
      this.state.set('doTableReload', false);
      this.state.set('batchAction', BatchActionEnum.UPDATEEXISTINGINSERTNEWCLEANUP);
      this.state.set('columns', []);
      this.state.set('createdItemFinderId', undefined);
      this.state.set('rowLoadProgress', undefined);
      this.state.set('rowDataIsLoading', false);
    },

    TABLE_DEFINITION_ADD_PENDING_TABLE_ID: ({ pendingTableId }: { pendingTableId: string }) => {
      this.state.set(['pendingTableIds', pendingTableId], true);
    },

    TABLE_DEFINITION_REMOVE_PENDING_TABLE_ID: ({ pendingTableId }: { pendingTableId: string }) => {
      this.state.unset(['pendingTableIds', pendingTableId]);
    },

    TABLE_DEFINITION_SET_SHOW_EVALUATION_DETAIL_MODAL: ({ show }: { show: boolean }) => {
      this.state.set('showEvaluationDetailsModal', show);
    },

    TABLE_DEFINITION_SET_EVALUATION_RESULTS_TO_VIEW: ({
      evaluationResults,
    }: {
      evaluationResults: RulesResultV1[];
    }) => {
      this.state.set('evaluationResultsToView', evaluationResults);
    },

    TABLE_DEFINITION_SET_EVALUATION_DETAIL_COLUMN: ({ column }: { column: ScalingTableColumnDefinition }) => {
      this.state.set('evaluationDetailColumn', column);
    },

    TABLE_DEFINITION_SET_CREATED_ITEM_FINDER_ID: ({
      createdItemFinderId,
    }: {
      createdItemFinderId: string | undefined;
    }) => {
      this.state.set('createdItemFinderId', createdItemFinderId);
    },

    TABLE_DEFINITION_SET_ALLOW_HIDDEN_COLUMNS_AS_INPUTS: ({
      allowHiddenColumnsAsInputs,
    }: {
      allowHiddenColumnsAsInputs: boolean;
    }) => {
      this.state.set('allowHiddenColumnsAsInputs', allowHiddenColumnsAsInputs);
    },

    TABLE_DEFINITION_SET_AG_GRID_API: ({ agGridApi }: { agGridApi: GridApi }) => {
      this.state.set('agGridApi', agGridApi);
    },

    TABLE_DEFINITION_SET_ROW_DATA_IS_LOADING: ({ rowDataIsLoading }: { rowDataIsLoading: boolean }) => {
      this.state.set('rowDataIsLoading', rowDataIsLoading);
    },

    REQUEST_SET_PROGRESS: (payload: {
      id: string;
      progress: Record<string, unknown>;
      timingInformation?: Record<string, unknown>;
      meterInformation?: Record<string, unknown>;
    }) => {
      if (payload.id === this.id) {
        this.state.set('rowLoadProgress', {
          progress: payload.progress ?? 0,
          timingInformation: payload.timingInformation,
          meterInformation: payload.meterInformation,
        });
      }
    },

    TABLE_DEFINITION_SET_SELECTED_COLUMNS: ({ selectedColumns }: { selectedColumns: string[] }) => {
      this.state.set('selectedColumns', selectedColumns);
    },
  };

  /**
   * Dehydrates the item by retrieving the current set parameters in view
   *
   * @returns {Object} An object with the state properties as JSON
   */
  dehydrate() {
    return _.pick(this.state.serialize(), ['id', 'subscriberId']);
  }

  /**
   * Rehydrates item from dehydrated state
   *
   * @param {Object} dehydratedState - State object that should be restored
   */
  rehydrate(dehydratedState: { id: string; subscriberId: string }) {
    this.state.merge(dehydratedState);
  }
}
