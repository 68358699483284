import { SCALING_TABLE_TYPE } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import { TableTypeEnum } from '@/sdk/model/TableDefinitionInputV1';

/**
 * Checks if search types are invalid.
 * An invalid result is defined as combining {@link SCALING_TABLE_TYPE} with other search types.
 */
export const hasInValidSearchTypes = (types: string[]) => types.length > 1 && types.includes(SCALING_TABLE_TYPE);

/*
 * Limit of items shown in the overview mode of the Search Widget
 */
export const LIMIT_IN_OVERVIEW = 8;

export const replaceScalingPseudoTypeWithBackendType = (searchTypes: string[] | string): string | string[] => {
  if (!searchTypes) {
    return searchTypes;
  }
  if (!_.isArray(searchTypes)) {
    return searchTypes === SCALING_TABLE_TYPE ? SeeqNames.Types.TableDefinition : searchTypes;
  }
  return _.map(searchTypes, (type) => (type === SCALING_TABLE_TYPE ? SeeqNames.Types.TableDefinition : type));
};

const SCALING_CSV_FILTER = `&&${SeeqNames.Properties.TableType}==${TableTypeEnum.SCALINGCSV}`;
const SCALING_FILTER = `&&${SeeqNames.Properties.TableType}==${TableTypeEnum.SCALING}`;

const transformSingleFilterToIncludeScaling = (filter: string): string[] => {
  return [filter.concat(SCALING_FILTER), filter.concat(SCALING_CSV_FILTER)];
};

export const addFilterForTableDefinitionSubTypes = (
  searchTypes: string[] | string,
  currentFilters: string[] | string,
): string | string[] => {
  if (!_.includes(searchTypes, SeeqNames.Types.TableDefinition)) {
    return currentFilters;
  }
  if (typeof currentFilters === 'string') {
    if (currentFilters.includes(SeeqNames.API.Flags.ExcludeGloballyScoped)) {
      return [currentFilters].concat(transformSingleFilterToIncludeScaling(''));
    }
    return transformSingleFilterToIncludeScaling(currentFilters);
  }

  const [potentialExcludeGloballyScoped, filters] = _.partition(
    currentFilters,
    (filter) => filter === SeeqNames.API.Flags.ExcludeGloballyScoped,
  );
  // If the search type is TableDefinition, but filters are empty, we still need to add the filters.
  const filtersToProcess = filters.length === 0 ? [''] : filters;
  return potentialExcludeGloballyScoped.concat(
    filtersToProcess.flatMap((filter) => transformSingleFilterToIncludeScaling(filter)),
  );
};
