import React, { useRef } from 'react';
import TopLoadingBar from 'react-top-loading-bar';
import { Icon } from '@seeqdev/qomponents';
import { sqAxiosStore } from '@/core/core.stores';
import { useFlux } from '@/core/hooks/useFlux.hook';

const LOADING_BAR_COLOR = '#2299DD';

export const OldLoadingBar = () => {
  const { pendingRequests } = useFlux(sqAxiosStore);
  const progress = useRef(100);
  const newProgress = 100 / (1 + pendingRequests);
  if (progress.current === 100 || progress.current < newProgress) {
    progress.current = newProgress;
  }

  return (
    <>
      <TopLoadingBar color={LOADING_BAR_COLOR} progress={progress.current} height={3} />
      {progress.current < 100 && (
        <Icon
          id="loading-bar-spinner"
          icon="fc-loading-notch"
          extraClassNames="fa-spin"
          type="color"
          color={LOADING_BAR_COLOR}
        />
      )}
    </>
  );
};
