import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ButtonWithDropdown, Icon } from '@seeqdev/qomponents';
import { specificWorkstepAction } from '@/worksteps/worksteps.actions';
import { useFlux } from '@/core/hooks/useFlux.hook';
import {
  sqStateSynchronizer,
  sqWorkbenchStore,
  sqWorkbookStore,
  sqWorksheetStore,
  sqWorkstepsStore,
} from '@/core/core.stores';
import { fetchAnnotations } from '@/annotation/annotation.actions';
import { WorkstepChronologyEntryV1 } from '@/sdk/model/WorkstepChronologyEntryV1';
import { formatTime } from '@/datetime/dateTime.utilities';
import { getWorksteps } from '@/worksteps/worksteps.utilities';
import { DirectionEnum } from '@/sdk/model/WorkstepChronologyOutputV1';

export type WorkstepButtonType = 'previous' | 'next' | 'last';

interface WorkstepIconsProps {
  isViewMode: boolean;
  isEditMode: boolean;
}

export const WorkstepIcons: React.FunctionComponent<WorkstepIconsProps> = ({ isViewMode, isEditMode }) => {
  const { previous, next, last, current, isError } = useFlux(sqWorkstepsStore);
  const previousAvailable = !_.isEmpty(previous);
  const nextAvailable = !_.isEmpty(next);
  const lastAvailable = !_.isEmpty(last) && last !== current.id;
  const { t } = useTranslation();

  const [beforeShown, setBeforeShown] = useState(false);
  const [afterShown, setAfterShown] = useState(false);
  const [chronology, setChronology] = useState<{ value: string; label: string }[]>();

  useEffect(() => {
    if (beforeShown || afterShown) {
      getWorksteps(
        sqWorkbenchStore.stateParams.workbookId,
        sqWorkbenchStore.stateParams.worksheetId,
        current.id,
        beforeShown ? DirectionEnum.Before : DirectionEnum.After,
      ).then((response) => {
        setChronology(mapChronology(response.entries, sqWorkbenchStore.userLanguage));
      });
    } else {
      setChronology(undefined);
    }
  }, [beforeShown, afterShown, current.id, current.createdAt]);

  /**
   * Navigates to the specified workstep if it's available.
   *
   * @param workstepId - A flag identifying if the desired workstep is available
   * @returns A promise that resolves when the workstep has been rehydrated or rejects if rehydration is already in
   *   progress.
   */
  const goToWorkstep = async (workstepId: string): Promise<any> => {
    const result = await sqStateSynchronizer.getWorkstepAndRehydrate(() =>
      specificWorkstepAction(
        sqWorkbenchStore.stateParams.workbookId,
        sqWorkbenchStore.stateParams.worksheetId,
        workstepId,
      ),
    );
    return sqStateSynchronizer.withPushDisabled(() => fetchAnnotations(result));
  };

  return (
    <div
      className={isViewMode ? 'flexSelfStretch' : 'flexColumnContainer flexJustifyEnd ml10 mr1 flexNoGrowNoShrink'}
      data-testid="workstepIcons">
      {isEditMode && !sqWorkbookStore.isReportBinder && !sqWorkbookStore.isVantage && (
        <div className="unselectable">
          {isError && (
            <Icon
              icon="fa-exclamation-triangle"
              extraClassNames="workstepButton fa-xlg pr10"
              type="danger"
              tooltip={t('WORKSTEPS.ERROR')}
              tooltipPlacement="bottom"
            />
          )}

          <Icon
            icon="fa-reply"
            extraClassNames={classNames(
              {
                'workstepButton': previousAvailable,
                'sq-icon-gray': !previousAvailable,
              },
              'fa-xlg pr2',
            )}
            type={previousAvailable ? 'theme-light' : 'gray'}
            onClick={() => (previousAvailable ? goToWorkstep(previous!) : null)}
            testId="previousWorkstep"
            tooltip={t('WORKSTEPS.PREVIOUS')}
            tooltipPlacement="bottom"
          />

          <ButtonWithDropdown
            disabled={!previousAvailable}
            onOpenChange={(show) => setBeforeShown(show || false)}
            extraClassNames="mr4"
            contentExtraClassNames="overflowYAuto max-height-400 workstepSelectItems"
            containerTestId="previousWorkstepPopover"
            triggerIcon={
              <div className="sq-icon-hover">
                <Icon
                  testId="previousWorkstepPulldown"
                  icon="fa-caret-down"
                  extraClassNames={classNames(
                    {
                      'workstepButton': previousAvailable,
                      'sq-icon-gray': !previousAvailable,
                    },
                    'verticalAlignTextBottom pr10 noLeftRadius',
                  )}
                  type={previousAvailable ? 'theme-light' : 'gray'}
                />
              </div>
            }
            dropdownItems={
              previousAvailable && beforeShown && chronology
                ? chronology.map(({ value, label }) => ({
                    label,
                    onClick: () => {
                      setBeforeShown(false);
                      goToWorkstep(value);
                    },
                  }))
                : []
            }
            tooltipPlacement="bottom"
          />

          <Icon
            icon="fa-share"
            extraClassNames={classNames(
              {
                'workstepButton': nextAvailable,
                'sq-icon-gray': !nextAvailable,
              },
              'fa-xlg',
            )}
            type={nextAvailable ? 'theme-light' : 'gray'}
            onClick={() => (nextAvailable ? goToWorkstep(next!) : null)}
            testId="nextWorkstep"
            tooltip={t('WORKSTEPS.NEXT')}
            tooltipPlacement="bottom"
          />

          <ButtonWithDropdown
            disabled={!nextAvailable}
            onOpenChange={(show) => setAfterShown(show || false)}
            extraClassNames="inlineBlock"
            contentExtraClassNames="overflowYAuto max-height-400 workstepSelectItems"
            triggerIcon={
              <div className="sq-icon-hover">
                <Icon
                  testId="nextWorkstepPulldown"
                  icon="fa-caret-down"
                  extraClassNames={classNames(
                    {
                      'workstepButton': nextAvailable,
                      'sq-icon-gray': !nextAvailable,
                    },
                    'verticalAlignTextBottom pr10 noLeftRadius',
                  )}
                  type={nextAvailable ? 'theme-light' : 'gray'}
                />
              </div>
            }
            placement="bottom"
            containerTestId="nextWorkstepPopover"
            dropdownItems={
              nextAvailable && afterShown && chronology
                ? chronology.map(({ value, label }) => ({
                    label,
                    onClick: () => {
                      setAfterShown(false);
                      goToWorkstep(value);
                    },
                  }))
                : []
            }
          />

          <Icon
            icon="fa-reply-all"
            extraClassNames={classNames(
              {
                'workstepButton': lastAvailable,
                'sq-icon-gray': !lastAvailable,
              },
              'fa-xlg pr3 pl9 fa-flip-horizontal',
            )}
            type={lastAvailable ? 'theme-light' : 'gray'}
            onClick={() => (lastAvailable ? goToWorkstep(last!) : null)}
            testId="lastWorkstep"
            tooltip={t('WORKSTEPS.LAST')}
            tooltipPlacement="bottom"
          />
        </div>
      )}
    </div>
  );
};

const removePeriod = (str: string) => (str.endsWith('.') ? str.slice(0, -1) : str);

const formatRelative = (rtf: Intl.RelativeTimeFormat, value: number, unit: Intl.RelativeTimeFormatUnit) => {
  const parts = rtf.formatToParts(-value, unit); // Notice the negative value here (time ago)
  const unitParts = parts.filter((part) => part.type === 'integer' || part.type === 'unit');
  const literalParts = parts.filter((part) => part.type === 'literal');

  let agoPrefix = '';
  let formattedValue = unitParts.map((part) => part.value).join('');
  let agoSuffix = '';

  if (literalParts.length === 2) {
    if (parts[0].type === 'literal') {
      agoPrefix = literalParts[0].value.trim();
      formattedValue += removePeriod(literalParts[1].value.trim());
    } else {
      formattedValue += removePeriod(literalParts[0].value.trim());
      agoSuffix = literalParts[1].value.trim();
    }
  } else if (literalParts.length === 1) {
    if (parts[0].type === 'literal') {
      agoPrefix = literalParts[0].value.trim();
    } else {
      const split = literalParts[0].value.trim().split(' ');
      agoSuffix = split.length > 0 ? split.slice(1).join(' ') : '';
      formattedValue += removePeriod(split[0]);
    }
  }

  return { agoPrefix, formattedValue, agoSuffix };
};

const formatTimeAgo = (rtf: Intl.RelativeTimeFormat, timeDelta: number, timestamp: number) => {
  const timeParts = [];
  let agoPrefix = '';
  let agoSuffix = '';

  if (timeDelta < 60) {
    const sec = formatRelative(rtf, timeDelta, 'second');
    timeParts.push(sec.formattedValue);
    agoPrefix = sec.agoPrefix;
    agoSuffix = sec.agoSuffix;
  } else if (timeDelta < 3600) {
    const min = Math.floor(timeDelta / 60);
    const minFormat = formatRelative(rtf, min, 'minute');
    timeParts.push(minFormat.formattedValue);
    agoPrefix = minFormat.agoPrefix;
    agoSuffix = minFormat.agoSuffix;

    const sec = timeDelta - min * 60;
    if (sec > 0) {
      const secFormat = formatRelative(rtf, sec, 'second');
      timeParts.push(secFormat.formattedValue);
    }
  } else if (timeDelta < 86400) {
    // Up to one day
    const hour = Math.floor(timeDelta / 3600);
    const hourFormat = formatRelative(rtf, hour, 'hour');
    timeParts.push(hourFormat.formattedValue);
    agoPrefix = hourFormat.agoPrefix;
    agoSuffix = hourFormat.agoSuffix;

    const min = Math.floor((timeDelta % 3600) / 60);
    if (min > 0) {
      const minFormat = formatRelative(rtf, min, 'minute');
      timeParts.push(minFormat.formattedValue);
    }
  } else if (timeDelta < 7 * 86400) {
    const day = Math.floor(timeDelta / 86400);
    const dayFormat = formatRelative(rtf, day, 'day');
    timeParts.push(dayFormat.formattedValue);
    agoPrefix = dayFormat.agoPrefix;
    agoSuffix = dayFormat.agoSuffix;

    const hour = Math.floor((timeDelta % 86400) / 3600);
    if (hour > 0) {
      const hourFormat = formatRelative(rtf, hour, 'hour');
      timeParts.push(hourFormat.formattedValue);
    }
  } else {
    return formatTime(timestamp, sqWorksheetStore.timezone, 'll LTS');
  }

  return `${agoPrefix ? `${agoPrefix} ` : ''}${timeParts.join(' ')}${agoSuffix ? ` ${agoSuffix}` : ''}`;
};

const mapChronology = (
  entries: WorkstepChronologyEntryV1[] | undefined,
  language: string,
  currentTime: number = Date.now(),
) => {
  // If there are no entries, return an empty array
  if (!entries) {
    return [];
  }

  // Deduplicate the entries to avoid showing too many options
  const minDeltaInMs = 5_000;
  const deduped: WorkstepChronologyEntryV1[] = [];
  let last: WorkstepChronologyEntryV1;
  entries.forEach((value, index) => {
    if (index === 0 || (value.createdAt || 0) <= (last.createdAt || 0) - minDeltaInMs) {
      deduped.push(value);
      last = value;
    }
  });

  // Create a relative time formatter
  const rtf = new Intl.RelativeTimeFormat(language, { numeric: 'always', style: 'narrow' });

  // Map the entries to the options
  const mapping = (entry: WorkstepChronologyEntryV1) => ({
    value: entry.workstepId!,
    label: formatTimeAgo(rtf, Math.floor((currentTime - (entry.createdAt || 0)) / 1000), entry.createdAt || 0),
  });
  return deduped.map(mapping);
};

export const mapChronologyExposedForTestingOnly = mapChronology;
