import React, { useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@seeqdev/qomponents';
import { successToast } from '@/utilities/toast.utilities';
import { AppendedProps } from '@seeqdev/qomponents/dist/InputGroup/InputGroup.types';

/**
 * Renders an input field with a button to copy the contents of the field to the clipboard.
 */
interface InputSelectOnFocusProps {
  /** an identifier to be used in tests */
  testId: string;
  /** true if the field should be read only, false otherwise */
  readOnly: boolean;
  /** string to display in the tooltip when hovering the input field */
  fieldTooltip: string;
  /** string to display in the tooltip when hovering the button */
  buttonTooltip: string;
  /** value to show in the input field */
  value?: string;
  /** notification message sent to user */
  notifyMessage?: string;
  extraButtons?: AppendedProps[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showError?: boolean;
  copyDisabled?: boolean;
  /** custom styling for the input field */
  extraClassNames?: string;
  /** custom styling for the copy button */
  buttonExtraClassNames?: string;
}

export const CopyableInputField: React.FunctionComponent<InputSelectOnFocusProps> = ({
  testId,
  readOnly,
  fieldTooltip,
  buttonTooltip,
  value,
  notifyMessage,
  extraButtons = [],
  onChange = _.noop,
  showError,
  copyDisabled,
  extraClassNames,
  buttonExtraClassNames,
}) => {
  const { t } = useTranslation();

  const inputValue = useRef<HTMLInputElement>(null);

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const copyToClipboard = () => {
    inputValue.current?.select();
    document.execCommand('copy');
    inputValue.current?.blur();
    if (window.getSelection) {
      window.getSelection?.()?.removeAllRanges();
    }

    if (!_.isNil(notifyMessage)) {
      successToast({ messageKey: notifyMessage });
    }
  };

  return (
    <InputGroup
      tooltip={t(fieldTooltip)}
      testId={testId}
      autoComplete="off"
      type="text"
      readonly={readOnly}
      extraClassNames={extraClassNames}
      ref={inputValue}
      value={value}
      onFocus={onFocus}
      onChange={onChange}
      showError={showError}
      append={
        value
          ? [
              ...extraButtons.map((btn, i) => {
                if (btn?.variant === 'element') {
                  return {
                    variant: 'element' as const,
                    element: btn?.element,
                  };
                } else if (btn?.variant === 'button') {
                  return {
                    variant: 'button' as const,
                    buttonProps: {
                      ...btn?.buttonProps,
                      key: `${testId || ''}-extraButton-${i}`,
                    },
                  };
                }
                return undefined;
              }),
              {
                variant: 'button',
                buttonProps: {
                  label: '',
                  iconStyle: 'theme',
                  icon: 'fa-copy',
                  tooltip: t(buttonTooltip),
                  tooltipOptions: { position: 'top' },
                  testId: `${testId}-copyButton`,
                  onClick: copyToClipboard,
                  disabled: copyDisabled,
                },
              },
            ]
          : []
      }
    />
  );
};
