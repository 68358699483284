import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Icon, TextArea } from '@seeqdev/qomponents';
import { instructions } from '@/impactReports/impactReport.types';
import _ from 'lodash';
import { useAI } from '@/impactReports/useAI.hook';
import { doTrack } from '@/track/track.service';

interface ImpactReportAIOverlayIF {
  updateCKContent: (description: string) => void;
  onClose: () => void;
  workbookId: string | undefined;
  worksheetId: string | undefined;
}

export const ImpactReportAIOverlay: React.FunctionComponent<ImpactReportAIOverlayIF> = ({
  updateCKContent,
  onClose,
  workbookId,
  worksheetId,
}) => {
  const { t } = useTranslation();
  const [spinning, setSpinning] = useState(false);
  const [displayCustomPrompt, setDisplayCustomPrompt] = useState(false);

  const { getImpactReportText, abortImpactReportTextQuery } = useAI();

  const [customPrompt, setCustomPrompt] = useState('');
  const generateImpactReportDescription = async () => {
    if (!workbookId || !worksheetId) return;
    const aiText = await getImpactReportText(workbookId, worksheetId, customPrompt);
    aiText && updateCKContent(aiText);
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const textArray = _.range(1, 10).map((val) => t(`IMPACT_REPORT.LOADING_PROMPT_${val}`));
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1 < textArray.length ? prevIndex + 1 : prevIndex));
    }, 8000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [textArray.length]);

  const closeAndAbort = () => {
    abortImpactReportTextQuery();
    doTrack('AiAssistant', 'AI content generation for Impact Report aborted');
    setCurrentIndex(0);
    onClose();
  };

  return (
    <Alert dismissible={true} variant="gray" extraClassNames="cKAIPromptOverlay p20" onClose={closeAndAbort}>
      {spinning ? (
        <div className="flexRowContainer flexCenter cKAIPromptContents">
          <span className="pb15">
            <Icon icon="fa-spinner" iconPrefix="fa-spin-pulse fa-xl fa-solid" />
          </span>
          <p className="animated-text slightFade">{textArray[currentIndex]}</p>
        </div>
      ) : (
        <div className="flexRowContainer flexAlignCenter cKAIPromptContents">
          {displayCustomPrompt ? (
            <div className="flexRowContainer">
              <div className="font-size-larger ml10 mb5">{t('IMPACT_REPORT.USE_CUSTOM_PROMPT')}</div>
              <div className="flexColumnContainer flexAlignBaseline">
                <TextArea
                  onChange={(event) => {
                    setCustomPrompt(event.currentTarget.value);
                  }}
                  value={customPrompt}
                  extraClassNames="bg-white width-300"
                  placeholder={`Enter your prompt here. You can get started with something like:"${t(
                    'IMPACT_REPORT.GENERATE_DESCRIPTION_PROMPT',
                  )}"`}
                />
                <div className="ml5 flexAlignBottom">
                  <Button
                    variant="theme"
                    onClick={async () => {
                      setSpinning(true);
                      await generateImpactReportDescription();
                      setSpinning(false);
                      setCurrentIndex(0);
                      doTrack('AiAssistant', 'Impact Report generated with custom prompt');
                      onClose();
                    }}
                    size="sm"
                    testId="submitPrompt"
                    extraClassNames="pl8 ai-button"
                    icon="fa-paper-plane sq-icon-white mn5"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <span className="font-size-large mb15">{t('JOURNAL.QUICK_START')}</span>
              <Button
                label={t('IMPACT_REPORT.FILL_WITH_AI')}
                icon="fa-sparkles"
                onClick={async () => {
                  setSpinning(true);
                  await generateImpactReportDescription();
                  setSpinning(false);
                  setCurrentIndex(0);
                  doTrack('AiAssistant', 'Impact Report generated with default prompt');
                  onClose();
                }}
                variant="theme"
              />
              <Button
                label={t('IMPACT_REPORT.USE_CUSTOM_PROMPT')}
                icon="fa-sparkles"
                extraClassNames="m20"
                onClick={() => {
                  setDisplayCustomPrompt(true);
                }}
                variant="theme"
              />
              <Button
                label={t('IMPACT_REPORT.FILL_WITH_TEMPLATE')}
                onClick={() => {
                  updateCKContent(instructions);
                  doTrack('AiAssistant', 'Impact Report generated with default template - not AI generated');
                  onClose();
                }}
                variant="theme"
              />
            </>
          )}
        </div>
      )}
    </Alert>
  );
};
