import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { getPresentWorksheetLink, getShortViewWorksheetLink, getWorksheetLink } from '@/main/routing.utilities';
import { base64guid, isEditWorkbookMode, isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { WrapperWithLink } from '@/core/WrapperWithLink.atom';
import { useDidMount } from 'rooks';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { sqWorkbooksApi } from '@/sdk';
import { sqWorkbookStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { ButtonWithPopover } from '@seeqdev/qomponents';
import { isSystemTest } from '@/core/utilities';

interface WorksheetThumbnailProps {
  worksheetId: string;
  workbookId: string;
  isActive: boolean;
  onClick?: () => void;
  tooltipPlacement?: 'right' | 'left';
}

const getThumbnailLink = (workbookId: string, worksheetId: string) => {
  if (isEditWorkbookMode()) {
    return getWorksheetLink(workbookId, worksheetId, undefined, undefined, true);
  } else if (isViewOnlyWorkbookMode()) {
    return getShortViewWorksheetLink(worksheetId, true);
  } else {
    return getPresentWorksheetLink(workbookId, worksheetId, true);
  }
};

export const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
export const WorksheetThumbnail: React.FunctionComponent<WorksheetThumbnailProps> = ({
  worksheetId,
  workbookId,
  isActive,
  onClick,
  tooltipPlacement = 'right',
}) => {
  const thumbnailLink = getThumbnailLink(workbookId, worksheetId);
  const thumbnailRef = useRef<HTMLDivElement>(null);
  const usingLatestThumbnail = useFluxPath(
    sqWorkbookStore,
    () => sqWorkbookStore.getWorksheet(worksheetId)?.usingLatestThumbnail,
  );

  const [src, setSrc] = React.useState<string>(
    `${APPSERVER_API_PREFIX}/workbooks/${workbookId}/worksheets/${worksheetId}/thumbnail`,
  );

  useEffect(() => {
    if (usingLatestThumbnail) {
      setSrc(
        `${APPSERVER_API_PREFIX}/workbooks/${workbookId}/worksheets/${worksheetId}/thumbnail?hash=${base64guid()}`,
      );
    }
  }, [workbookId, worksheetId, usingLatestThumbnail]);

  useDidMount(() => {
    if (isActive && thumbnailRef.current) {
      const container = document.getElementById('worksheetScrollArea');

      if (container && thumbnailRef.current) {
        const containerRect = container.getBoundingClientRect();
        const thumbnailRect = thumbnailRef.current.getBoundingClientRect();
        const offset = thumbnailRect.top - containerRect.top;
        container.scrollTo({ top: container.scrollTop + offset });
      }
    }
  });

  return (
    <div
      ref={thumbnailRef}
      onClick={onClick}
      data-testid={`${worksheetId}_thumbnailContainer`}
      className="cursorPointer flexRowContainer flexAlignCenter">
      <WrapperWithLink link={thumbnailLink}>
        <ButtonWithPopover
          placement={tooltipPlacement}
          hasArrow={true}
          isHoverEnabled={!isSystemTest()}
          isOpen={false}
          isPortal={true}
          hoverOpenDelay={1300}
          align="center"
          trigger={
            <img
              data-testid={`${worksheetId}_thumbnail`}
              className={classNames(
                'p3 screenshotBackground height-80 width-100',
                isActive ? 'activeWorksheetBorder' : 'darkGreyBorder',
              )}
              src={src}
              onError={() => {
                setSrc(EMPTY_IMAGE);
                sqWorkbooksApi.createWorksheetThumbnail({ workbookId, worksheetId });
              }}
            />
          }>
          <div className="p2">
            <img className="backgroundColorWhite" src={src} width="300" height="240" />
          </div>
        </ButtonWithPopover>
      </WrapperWithLink>
    </div>
  );
};
