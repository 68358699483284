import React, { Suspense, useEffect, useRef, useState } from 'react';
import {
  Actions,
  BorderNode,
  I18nLabel,
  IJsonModel,
  IJsonTabNode,
  IJsonTabSetNode,
  ITabSetRenderValues,
  Layout,
  Model,
  TabNode,
  TabSetNode,
} from 'flexlayout-react';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqAiAssistantStore } from '@/core/core.stores';
import { Icon } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';
import {
  setAIAssistantDisplayed,
  setAIAssistantMaximized,
  setAiAssistantRefreshHistory,
} from '@/aiAssistant/aiAssistant.actions';
import { doTrack } from '@/track/track.service';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { useLocation } from 'react-router-dom';
import { getWorkbookDisplayMode, isAIStandalone } from '@/main/routing.utilities';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { LoadingFallback } from '@/main/LoadingFallback.atom';
import { isMobileDevice } from '@/utilities/utilities';

const AiAssistant = React.lazy(() => import('@/defaultExportShims/AiAssistant'));

interface ResizableWindowWrapperProps {
  children?: React.ReactNode;
}

const workbenchTabSetId = 'workbenchTabSet';
const assistantTabSetId = 'assistantTabSet';
const workbenchTabId = 'main';
const assistantTabId = 'aiTab';
const emptyTabId = 'emptyTab';

const assistantTab: IJsonTabNode = {
  type: 'tab',
  name: 'AI Assistant',
  component: assistantTabId,
  id: assistantTabId,
  enableRenderOnDemand: true,
};

// FlexLayout requires a TabSet to have a selected tab and it renders the selected tab. The AiAssistant should not
// be loaded until it is rendered so this empty tab functions as the selected tab that is always rendered on load.
const emptyTab: IJsonTabNode = {
  type: 'tab',
  name: '',
  component: emptyTabId,
  id: emptyTabId,
};

const assistantTabSet: IJsonTabSetNode = {
  type: TabSetNode.TYPE,
  headerHeight: 30,
  enableTabStrip: false,
  id: assistantTabSetId,
  name: 'AI Assistant',
  weight: 23,
  minWidth: 450,
  enableClose: true,
  children: [emptyTab, assistantTab],
};

const json: IJsonModel = {
  global: {
    tabSetEnableSingleTabStretch: true,
    tabEnableRenderOnDemand: true,
    splitterSize: 3,
  },
  borders: [],
  layout: {
    id: 'root',
    type: 'row',
    weight: 100,
    children: [
      {
        type: TabSetNode.TYPE,
        id: workbenchTabSetId,
        enableClose: false,
        enableDrop: false,
        enableTabStrip: false,
        tabStripHeight: 0,
        weight: 75,
        children: [
          {
            type: 'tab',
            name: 'Seeq',
            component: workbenchTabId,
          },
        ],
      },
      assistantTabSet,
    ],
  },
};

/**
 * This component is the Wrapper component that facilitates the resizing and docking of the AI Assistant
 * It is built on top of https://github.com/caplin/FlexLayout
 *
 * FlexLayout is based on the notion of TabSets and Tabs.
 * TabSets are the containers for Tabs and can be resized and docked; Tabs on the other hand are simply the content
 * that is displayed within the TabSet. A TabSet can contain multiple Tabs but for our current usecase we limit a
 * TabSet to one Tab.
 *
 * The differentiation between Tab and TabSet is important as the Action of resizing and docking can only be applied
 * to a TabSet - not a Tab!
 *
 * Removing a TabSet is supported however there is no support for programmatically creating a new TabSet - which
 * means that to "remove" the AI Assistant from the TabSet we actually "maximize" the workbench TabSet.
 *
 * To "add" the AI Assistant back to the TabSet we "restore" the workbench TabSet. The same applies to "popping out"
 * into a modal - we don't delete the TabSet, we simply "maximize" the workbench TabSet and display the AI Assistant
 * in a react-rnd modal.
 *
 * The workbench TabSet does not have a TabStrip (the "header") and is not closable. It also does not support
 * dropping - this is so that we can not end up in a scenario where the AI Assistant is dropped into the workbench
 * TabSet and seemingly vanishes workbench (as there are no Tab Headers displayed in the workbench TabSet).
 *
 * The resizable and draggable modal is based on https://github.com/bokuweb/react-rnd; the implementation is pretty
 * straight forward and there were no got-cha's to be aware of.
 **/
export const ResizableWindowWrapper: React.FunctionComponent<ResizableWindowWrapperProps> = ({ children }) => {
  const aiAssistantDisplayed = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.displayed);
  // WARNING --- WARNING --- WARNING --- WARNING --- WARNING --- WARNING --- WARNING --- WARNING --- WARNING
  // do not call setLayoutModel directly - call setLayoutModelSafely instead. This is to ensure we prevent a
  // error where the AI Assistant is not displayed and seemingly goes missing from the DOM. Since we can't find a
  // repro this is a workaround to ensure the AI Assistant is always displayed.
  const [layoutModel, setLayoutModel] = useState<Model>();
  const assistantRef = useRef<HTMLDivElement>(null);
  const layoutRef = useRef<Layout>(null);
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const aiDisplayedRef = useRef<boolean>();
  const aiStandalone = isAIStandalone();
  useLocation();
  const isPresentationMode = getWorkbookDisplayMode() === WORKBOOK_DISPLAY.PRESENT;

  const setLayoutModelSafely = (model: Model | null) => {
    if (!model || !model.getNodeById(assistantTabId)) {
      const safeModel = Model.fromJson(json);
      if (aiAssistantDisplayed) {
        safeModel.doAction(Actions.selectTab(assistantTabId));
      } else {
        safeModel.doAction(Actions.maximizeToggle(workbenchTabSetId));
      }
      aiDisplayedRef.current = aiAssistantDisplayed;
      setLayoutModel(safeModel);
    }
  };

  useEffect(() => {
    if (!layoutModel) {
      setLayoutModelSafely(null);
    } else {
      if (displayModal) {
        setDisplayModal(false);
        aiDisplayedRef.current = false;
      } else if (aiAssistantDisplayed !== aiDisplayedRef.current) {
        layoutModel.doAction(Actions.maximizeToggle(workbenchTabSetId));
        if (aiAssistantDisplayed) {
          layoutModel.doAction(Actions.selectTab(assistantTabId));
        }
        setLayoutModelSafely(layoutModel);
        aiDisplayedRef.current = aiAssistantDisplayed;
      }
    }
  }, [aiAssistantDisplayed]);

  const popIntoModal = () => {
    if (!layoutModel) return;
    layoutModel.doAction(Actions.maximizeToggle(workbenchTabSetId));
    setLayoutModelSafely(layoutModel);
    setDisplayModal(true);
    setAiAssistantRefreshHistory(true);
  };

  const addBack = () => {
    if (!layoutModel) return;
    layoutModel.doAction(Actions.maximizeToggle(workbenchTabSetId));
    setLayoutModelSafely(layoutModel);
  };

  const factory = (node: TabNode) => {
    let component = node.getComponent();
    if (component === emptyTabId) {
      return <div />;
    } else if (component === assistantTabId) {
      return (
        <Suspense fallback={<LoadingFallback />}>
          <AiAssistant ref={assistantRef} />
        </Suspense>
      );
    } else {
      return children;
    }
  };

  const onRenderTabSet = (node: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => {
    if (node instanceof TabSetNode && node.getId() === assistantTabSetId) {
      renderValues.headerContent = (
        <div>
          <Icon icon="fa-sparkles" iconPrefix="fa-solid" type="text" extraClassNames="mr5" />
          {t('AI_ASSISTANT.AI_ASSISTANT')}
        </div>
      );

      if (!isMobileDevice())
        renderValues.headerButtons.push(
          <div
            key="popOut"
            onMouseDown={() => {
              popIntoModal();
              doTrack('AiAssistant', 'pop Ai Assistant into panel');
            }}>
            <Icon
              icon="fa-arrow-up-right-from-square"
              extraClassNames="cursorPointer mr10"
              tooltip={t('AI_ASSISTANT.POP_OUT')}
            />
          </div>,
        );
    }
  };

  return (
    <>
      {(headlessRenderMode() || isPresentationMode || aiStandalone) && <>{children}</>}
      {layoutModel && !headlessRenderMode() && !isPresentationMode && !aiStandalone && (
        <Layout
          icons={{
            closeTabset: (
              <div
                onMouseDown={() => {
                  setAIAssistantDisplayed(false);
                  doTrack('AiAssistant', 'Close Ai Assistant panel from dock');
                }}>
                <Icon icon="fa fa-close" large={true} tooltip={t('CLOSE')} />
              </div>
            ),
            maximize: (
              <div
                onMouseDown={() => {
                  doTrack('AiAssistant', 'toggle AI Assistant panel to fullscreen');
                  setAIAssistantMaximized(true);
                }}>
                <Icon icon="fa-arrow-up-right-and-arrow-down-left-from-center" tooltip={t('AI_ASSISTANT.MAXIMIZE')} />
              </div>
            ),
            restore: (
              <div
                onMouseDown={() => {
                  doTrack('AiAssistant', 'minimize AI Assistant panel');
                  setAIAssistantMaximized(false);
                }}>
                <Icon icon="fa-arrow-down-left-and-arrow-up-right-to-center" tooltip={t('AI_ASSISTANT.RESTORE')} />
              </div>
            ),
          }}
          i18nMapper={(id: I18nLabel) => {
            if (id === 'Move tabset') {
              return t('AI_ASSISTANT.CLICK_AND_DRAG');
            } else if (id === 'Close tabset') {
              return '';
            }
            return id;
          }}
          ref={layoutRef}
          model={layoutModel}
          onRenderTabSet={onRenderTabSet}
          factory={factory}
          realtimeResize={true}
        />
      )}
      {displayModal && (
        <Rnd
          className="aiAssistantModal aiModalBackground"
          default={{
            x: 200,
            y: 50,
            width: 450,
            height: 550,
          }}
          minWidth={450}
          minHeight={500}
          bounds="body">
          <div className="flexRowContainer height-maximum">
            <div className="p5 flexColumnContainer flexAlignCenter">
              <div className="flexFill aiToolbarTitle ml5">
                <Icon icon="fa-sparkles" iconPrefix="fa-solid" type="text" extraClassNames="mr5" />
                {t('AI_ASSISTANT.AI_ASSISTANT')}
              </div>
              <Icon
                tooltip={t('AI_ASSISTANT.DOCK')}
                icon="fa-border-top-left"
                extraClassNames="cursorPointer mr10"
                onClick={() => {
                  addBack();
                  setDisplayModal(false);
                }}
              />
              <Icon
                tooltip={t('CLOSE')}
                icon="fa-close"
                extraClassNames="cursorPointer mr5"
                onClick={() => {
                  setAIAssistantDisplayed(false);
                }}
              />
            </div>
            <div className="aiModalContentBackground flexRowContainer flexFill">
              <AiAssistant />
            </div>
          </div>
        </Rnd>
      )}
    </>
  );
};
