import type { AnyProperty } from '@/utilities.types';
import type { TableColumns } from '@/vantage/types/TableConfig.types';

export const findConditionIds = (columnsJSON: TableColumns): string[] => {
  const conditionIds: string[] = [];
  Object.keys(columnsJSON).forEach((columnName) => {
    if ((columnsJSON as AnyProperty)[columnName]?.meta?.type.toString().toLowerCase() === 'condition_id') {
      // keep the name of the condition_id column
      conditionIds.push(columnName);
    }
  });

  return conditionIds;
};

export const findConditions = (columnsJSON: TableColumns, conditionIds: string[]): string[] => {
  const conditions: string[] = [];
  Object.keys(columnsJSON).forEach((columnName) => {
    const column = (columnsJSON as AnyProperty)[columnName];
    if (column?.meta?.type.toString().toLowerCase() === 'reference') {
      if (conditionIds.includes(column?.meta?.referenced)) {
        // keep the name of the condition/steam column that references condition_id
        conditions.push(columnName);
      }
    }
  });

  return conditions;
};
