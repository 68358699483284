import { SeeqNames } from '@/main/app.constants.seeqnames';

export const SEARCH_PER_PAGE = 35;
export const ASSET_TREE_SEARCH_LIMIT = 1000;
export const MAX_RETURNED_RESULTS = 1000;
export type SearchPanes = 'main' | 'modal';
export type SearchModes =
  | 'overview'
  | 'search'
  | 'tree'
  | 'pinned'
  | 'recent'
  | 'asset-groups'
  | 'usages'
  | 'table-definitions';

export const SORT_BY_OPTIONS = {
  DEFAULT: '',
  NAME: 'Name',
  DESCRIPTION: 'Description',
};
export const SEARCH_BREADCRUMB = {
  name: 'SEARCH_DATA.SEARCH_RESULTS',
  id: 'SEARCH',
  type: 'VIEW_MODE',
};
export const HOME_BREADCRUMB = { id: '' };
export const SEARCH_USAGES_BREADCRUMB = {
  name: 'SEARCH_DATA.USAGES',
  id: 'ITEM_USAGES',
  type: 'VIEW_MODE',
};
export const SEARCH_RESULT_TYPES = {
  ITEMS: 'items',
  ASSETS: 'assets',
};

export const DATASOURCES_TO_EXCLUDE = ['Auth', 'LDAP'];

export const OPERATOR_OPTIONS = [
  { value: 'EQUALS', text: 'EQUALS' },
  { value: 'EQUALS_IGNORE_CASE', text: 'EQUALS_IGNORE_CASE' },
  { value: 'NOT_EQUALS', text: 'NOT_EQUALS' },
  { value: 'WITHIN', text: 'ITEM_FINDER.PROPERTY_OPERATORS.WITHIN' },
  { value: 'STRING_CONTAINS', text: 'ITEM_FINDER.PROPERTY_OPERATORS.STRING_CONTAINS' },
  { value: 'REGEX_MATCHES', text: 'ITEM_FINDER.PROPERTY_OPERATORS.REGEX_MATCHES' },
  { value: 'NOT_REGEX_MATCHES', text: 'ITEM_FINDER.PROPERTY_OPERATORS.NOT_REGEX_MATCHES' },
];

export const PARENT_PROPERTY = 'parent';
export const ASSET_NAME_PROPERTY = 'assetName';
export const ASSET_FULL_PATH_PROPERTY = 'assetFullPath';
export const ASSET_RELATED_PROPERTIES = [ASSET_NAME_PROPERTY, ASSET_FULL_PATH_PROPERTY];

export interface SearchPropertyOption {
  value: string;
  text: string;
}

export const BASE_SEARCH_PROPERTY_OPTIONS = [
  { value: SeeqNames.Properties.Name, text: 'ITEM_FINDER.PROPERTY_SELECT_NAME' },
  { value: SeeqNames.Properties.Description, text: 'ITEM_FINDER.PROPERTY_SELECT_DESCRIPTION' },
  { value: SeeqNames.Properties.DatasourceId, text: 'ITEM_FINDER.PROPERTY_SELECT_DATASOURCE_ID' },
  { value: SeeqNames.Properties.DatasourceClass, text: 'ITEM_FINDER.PROPERTY_SELECT_DATASOURCE_CLASS' },
];

export const DEFAULT_SEARCH_PROPERTY_OPTIONS: SearchPropertyOption[] = [...BASE_SEARCH_PROPERTY_OPTIONS];

export const ITEM_SEARCH_PROPERTY_OPTIONS = [
  ...BASE_SEARCH_PROPERTY_OPTIONS,
  { value: PARENT_PROPERTY, text: 'ITEM_SEARCH.PROPERTY.PARENT' },
];

export const FIND_ITEMS_EXTRA_SEARCH_PROPERTY_OPTIONS = [
  ...ITEM_SEARCH_PROPERTY_OPTIONS,
  { value: ASSET_NAME_PROPERTY, text: 'ITEM_SEARCH.PROPERTY.ASSET_NAME' },
  { value: ASSET_FULL_PATH_PROPERTY, text: 'ITEM_SEARCH.PROPERTY.ASSET_FULL_PATH' },
];
