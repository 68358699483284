import _ from 'lodash';
import { sqExportApi } from '@/sdk/api/ExportApi';
import { sqJobsApi } from '@/sdk/api/JobsApi';
import { sqSystemApi } from '@/sdk/api/SystemApi';
import { sqSubscriptionsApi } from '@/sdk/api/SubscriptionsApi';
import { UserGroupInputV1 } from '@/sdk/model/UserGroupInputV1';
import { sqRequestsApi } from '@/sdk/api/RequestsApi';
import { sqUserGroupsApi } from '@/sdk/api/UserGroupsApi';
import { errorToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { flux } from '@/core/flux.module';
import { ADMIN_LIMIT, CONFIGURATION_TAB_INDEX, JOBS_TAB_INDEX } from '@/administration/administration.constants';
import { CapabilityInheritanceOutputV1 } from '@/sdk';
import { CapabilityEnum } from '@/sdk/model/CapabilityInheritanceOutputV1';

/**
 * Makes required asynchronous calls to load user groups state.
 */
export function load() {
  flux.dispatch('ADMINISTRATION_CLEAR_GROUPS');

  return sqUserGroupsApi.getUserGroups({ limit: ADMIN_LIMIT }).then(({ data }) => {
    _.forEach(data.items, saveNewGroup);
  });
}

export function setActiveTabIndex(tab: number, setDefaultSearchParamsForTab = false) {
  flux.dispatch('ADMINISTRATION_SET_ACTIVE_TAB_INDEX', tab);
  resetSearchParams();

  if (setDefaultSearchParamsForTab) {
    switch (_.toNumber(tab)) {
      case JOBS_TAB_INDEX:
        return setSearchParams({ field: 'group', value: 'ScreenshotCapture' });
      case CONFIGURATION_TAB_INDEX:
        return setSearchParams({ field: 'advanced', value: false });
      default:
    }
  }
}

export function setSearchParams({ field, value }: { field: string; value: string | string[] | boolean }) {
  flux.dispatch('ADMINISTRATION_SET_SEARCH_PARAMS', { field, value });
}

export function resetSearchParams() {
  flux.dispatch('ADMINISTRATION_RESET_SEARCH_PARAMS');
}

export function saveNewGroup(group: UserGroupInputV1) {
  flux.dispatch('ADMINISTRATION_ADD_GROUP', group);
}

export function updateGroup(group: UserGroupInputV1) {
  flux.dispatch('ADMINISTRATION_UPDATE_GROUP', group);
}

export function setCapabilitiesInEditor(capabilitiesInEditor: CapabilityInheritanceOutputV1[]) {
  flux.dispatch('ADMINISTRATION_CAPABILITIES_IN_EDITOR', capabilitiesInEditor);
}

export function toggleCapabilitySelection(capability: CapabilityEnum) {
  flux.dispatch('ADMINISTRATION_CAPABILITIES_TOGGLE_SELECTION', capability);
}

/**
 * Remove groups
 * If no groups are selected a warning to select groups is displayed.
 *
 * @param {String[]} ids - The ids of the groups to remove
 * @returns {Promise} that resolves when the removal is complete
 */
export async function removeGroups(ids?: string[]): Promise<void> {
  if (_.isEmpty(ids)) {
    warnToast({ messageKey: 'ADMIN.GROUP.SELECTION_REQUIRED' });

    return;
  }

  try {
    await Promise.all(ids?.map((userGroupId) => sqUserGroupsApi.removeUserGroup({ userGroupId })) ?? []);
    flux.dispatch('ADMINISTRATION_REMOVE_GROUPS', ids);
    successToast({ messageKey: 'ADMIN.GROUP.REMOVED' });
  } catch (error) {
    errorToast({ httpResponseOrError: error, displayForbidden: true });
    load();
  }
}

export function refreshAdminContactInfo() {
  return sqSystemApi.getAdministratorContactInformation().then(({ data }) => {
    flux.dispatch('ADMINISTRATION_UPDATE_CONTACT_INFO', _.pick(data, ['name', 'email']));
  });
}

export function updateAdminContactInfo(adminContactInfo: { name: string; email: string }) {
  return sqSystemApi.setAdministratorContactInformation(adminContactInfo).then(() => {
    flux.dispatch('ADMINISTRATION_UPDATE_CONTACT_INFO', adminContactInfo);
  });
}

export function refreshRequests() {
  return sqRequestsApi.getRequests({ limit: ADMIN_LIMIT }).then(({ data }) => {
    return data;
  });
}

export function cancelRequests(ids: string[]) {
  const cancelled = _.map(ids, (requestId) => sqRequestsApi.cancelRequest({ requestId }));

  return Promise.all(cancelled);
}

export function refreshSubscriptions() {
  return sqSubscriptionsApi.getChannels({ limit: ADMIN_LIMIT }).then(({ data }) => data);
}

export function refreshJobs() {
  return sqJobsApi.getJobs({ limit: ADMIN_LIMIT });
}

export function stopJobs(jobs: { id: string; group: string }[]) {
  const stopped = _.map(jobs, (job) => sqJobsApi.deleteJob(job));

  return Promise.all(stopped);
}

export async function archiveExports(ids: string[]): Promise<void> {
  try {
    await Promise.all(ids.map((id) => sqExportApi.archiveExport({ id })));
    successToast({ messageKey: 'ADMIN.EXPORT.TRASHED' });
  } catch (error) {
    errorToast({ httpResponseOrError: error, displayForbidden: true });
    load();
  }
}
