// @ts-strict-ignore
import _ from 'lodash';
import { DISPLAY_MODE } from '@/main/app.constants';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { getDefaultMaxCapsuleDuration } from '@/services/systemConfiguration.utilities';

/**
 * A base store that adds standard functionality for all stores that represent a tool used to create calculated series
 * or conditions.
 */

export type ParameterDefinitions = {
  // The name to be used as the store export
  [name: string]: {
    // A predicate that can be used to find the corresponding parameter in the formula inputs
    predicate: any;
    // True if it is a collection of parameters, for use with multiselect
    multiple?: boolean;
  };
};

export abstract class BaseToolStore extends Store {
  type: string;
  parameterDefinitions: ParameterDefinitions;
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  readonly TOOL_ITEM_PROPS = [
    'id',
    'name',
    'redacted',
    'itemType',
    'valueUnitOfMeasure',
    'signalMetadata',
    'conditionMetadata',
  ];

  /**
   * Used to rehydrate the tool in order to edit the calculated series.
   *
   * @param {Object} payload - An object with the necessary state to populate the edit form.
   * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
   * @param {Object[]} payload.parameters - The parameters used in the formula
   * @param {String} payload.formula - The formula for the calculated item
   */
  baseToolRehydrateForEdit = (payload) => {
    if (payload.type === this.type) {
      const parameters = _.chain(payload.parameters)
        .reject('unbound')
        .map((parameter) => _.pick(parameter, ['item', 'name']))
        .value();
      this.initialize();
      // The UIConfig is intentionally merged first because in pre-R18 versions some of the parameters were
      // stored as ids in the stores. For example `signalToAggregate` in histograms may be in UIConfig as an ID,
      // but we can safely overwrite that with the item from the parameters. CRAB-11230
      this.state.merge(
        this.migrateSavedConfig(
          _.omit(payload, ['type', 'parameters', 'formula', 'signalMetadata', 'conditionMetadata']),
          parameters,
          payload.formula,
        ),
      );
      this.state.set('originalParameters', _.map(parameters, 'item'));
      _.forEach(this.parameterDefinitions, (definition, name) =>
        this.state.set(
          name,
          _.chain(parameters)
            .filter(definition.predicate)
            .map((parameter) => _.pick(parameter.item, this.TOOL_ITEM_PROPS))
            .thru((items) => (definition.multiple ? items : _.head(items)))
            .value(),
        ),
      );
    }
  };

  /**
   * Resets all parameters if switching to new instance of the form. Also clears state when closing to ensure extra
   * state doesn't hang around and populate worksteps.
   *
   * @param {Object} payload - Object containing state information
   * @param {String} payload.mode - The display mode being set, one of DISPLAY_MODE
   * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
   */
  protected reset = (payload) => {
    if (payload.mode === DISPLAY_MODE.NEW && (payload.type === this.type || payload.type === TREND_TOOLS.OVERVIEW)) {
      this.initialize();
    }
  };

  protected readonly handlers = {
    /**
     * Sets the name.
     *
     * @param {Object} payload - an Object representing state.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {String} payload.name - The name of the calculated series
     * @param {String} payload.id - The id of the calculated series
     */
    TOOL_SET_SEARCH_NAME: (payload) => {
      if (payload.type === this.type && (!payload.id || payload.id === this.state.get('id'))) {
        this.state.set('name', payload.name);
        this.state.commit();
      }
    },

    /**
     * Sets the maximum length/duration of a capsule for tools like Value Search and Composite Search.
     *
     * @param {Object} payload - an Object representing state.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {Number} payload.value - The number that indicates how long the duration is
     * @param {String} payload.units - The units that the value represents
     */
    TOOL_SET_MAXIMUM_DURATION: (payload) => {
      if (payload.type === this.type) {
        this.state.set('maximumDuration', {
          units: payload.units,
          value: payload.value,
        });
      }
    },

    /**
     * Sets the state of the expandable advanced parameters section.
     *
     * @param {Object} payload - an Object representing state.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {String} payload.collapsed - True if the advanced section is collapsed.
     */
    TOOL_SET_ADVANCED_PARAMETERS_COLLAPSED_STATE: (payload) => {
      if (payload.type === this.type) {
        this.state.set('advancedParametersCollapsed', payload.collapsed);
      }
    },

    /**
     * Sets or adds, if multiple, one of the input parameters.
     *
     * @param {Object} payload - an object representing state.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {String} payload.name - The name of the parameter. Must be defined as one of the parameterDefinitions
     * @param {Object} payload.item - The item to set
     */
    TOOL_SET_PARAMETER_ITEM: (payload) => {
      if (payload.type === this.type) {
        const definition = _.get(this.parameterDefinitions, payload.name);
        if (!definition) {
          throw new TypeError(`${payload.name} is not a valid parameter item`);
        }

        if (definition.multiple && _.isUndefined(this.state.get(payload.name))) {
          this.state.set(payload.name, []);
        }

        // For multi-entry mode, don't add an item if it is already in the list.
        if (
          definition.multiple &&
          _.includes(
            this.state.get(payload.name).map((i) => i.id),
            payload.item.id,
          )
        ) {
          return;
        }

        const item = _.isNil(payload.item) ? payload.item : _.pick(payload.item, this.TOOL_ITEM_PROPS);
        this.state[definition.multiple ? 'push' : 'set'](payload.name, item);
      }
    },

    /**
     * Unsets or removes, if multiple, one of the input parameters.
     *
     * @param {Object} payload - an object representing state.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {String} payload.name - The name of the parameter. Must be defined as one of the parameterDefinitions
     * @param {Object} payload.item - The item to remove
     */
    TOOL_UNSET_PARAMETER_ITEM: (payload) => {
      if (payload.type === this.type) {
        const definition = _.get(this.parameterDefinitions, payload.name);
        if (!definition) {
          throw new TypeError(`${payload.name} is not a valid parameter item`);
        }

        if (definition.multiple) {
          const index = _.findIndex(this.state.get(payload.name), ['id', payload.item.id]);
          if (index > -1) {
            this.state.splice(payload.name, [index, 1]);
          }
        } else {
          this.state.unset(payload.name);
        }
      }
    },

    TOOL_REHYDRATE_FOR_EDIT: this.baseToolRehydrateForEdit,

    INVESTIGATE_SET_DISPLAY_MODE: this.reset,
  };

  get baseHandlers() {
    return this.handlers;
  }

  get id() {
    return this.state.get('id');
  }

  get name() {
    return this.state.get('name');
  }

  get originalParameters() {
    return this.state.get('originalParameters');
  }

  get signalMetadata() {
    return this.state.get('signalMetadata');
  }

  get conditionMetadata() {
    return this.state.get('conditionMetadata');
  }

  get advancedParametersCollapsed() {
    return this.state.get('advancedParametersCollapsed');
  }

  get configParams() {
    return this.modifyConfigParams(
      _.assign(
        { type: this.type },
        _.omit(this.state.serialize(), ['id', 'name', 'originalParameters'].concat(_.keys(this.parameterDefinitions))),
      ),
    );
  }

  get maximumDuration() {
    return _.isUndefined(this.state.get('maximumDuration'))
      ? getDefaultMaxCapsuleDuration()
      : this.state.get('maximumDuration');
  }

  /**
   * Provides a hook that can be overridden by the derived store. Previous versions of the state will be passed
   * into the migrateSavedConfig function. By default it returns the state unchanged.
   *
   * @param {Object} config - The UIConfig state to populate the form
   * @param {Object[]} parameters - The parameters used in the formula
   * @param {String} parameters.name - The name of the parameter
   * @param {Object} parameters.item - The item that the parameter references
   * @param {String} formula - The formula for the calculated item
   */
  migrateSavedConfig(config, parameters, formula) {
    return _.identity(config);
  }

  /**
   * Used to rehydrate the tool in order to edit the calculated series.
   *
   * @param {Object} payload - An object with the necessary state to populate the edit form.
   * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
   * @param {Object[]} payload.parameters - The parameters used in the formula
   * @param {String} payload.formula - The formula for the calculated item
   */
  rehydrateForEdit(payload) {
    if (payload.type === this.type) {
      const parameters = _.chain(payload.parameters)
        .reject('unbound')
        .map((parameter) => _.pick(parameter, ['item', 'name']))
        .value();
      this.initialize();
      // The UIConfig is intentionally merged first because in pre-R18 versions some of the parameters were
      // stored as ids in the stores. For example `signalToAggregate` in histograms may be in UIConfig as an ID,
      // but we can safely overwrite that with the item from the parameters. CRAB-11230
      this.state.merge(
        this.migrateSavedConfig(
          _.omit(payload, ['type', 'parameters', 'formula', 'signalMetadata', 'conditionMetadata']),
          parameters,
          payload.formula,
        ),
      );
      this.state.set('originalParameters', _.map(parameters, 'item'));
      _.forEach(this.parameterDefinitions, (definition, name) =>
        this.state.set(
          name,
          _.chain(parameters)
            .filter(definition.predicate)
            .map((parameter) => _.pick(parameter.item, this.TOOL_ITEM_PROPS))
            .thru((items) => (definition.multiple ? items : _.head(items)))
            .value(),
        ),
      );
    }
  }

  /**
   * Provides a hook that can be overridden by the derived store. The configParams will be passed which is stored
   * in the UIConfig property and which can be modified by this function.
   *
   * @param {Object} config - The state that will be saved to UIConfig
   * @return {Object} The modified config
   */
  modifyConfigParams(config) {
    return _.identity(config);
  }

  /**
   * Small set of utility functions for building up formulas.
   */
  formulaBuilder = {
    /**
     * Create a variable reference.
     *
     * @param {String} name - The variable name
     * @param {String} [suffix] - Appended to the name if present
     * @returns {String} The variable name, e.g. $input
     */
    var(varName: string, suffix?): string {
      return `$${varName}${suffix || ''}`;
    },

    /**
     * Invoke an operator with arguments.
     *
     * @param operator - The name of the operator
     * @param args - The arguments to pass to the operator
     * returns The invocation of an operator, e.g. union($a, $b)
     */
    operator(operator: string, args: string[]): string {
      return `${operator}(${args.join(', ')})`;
    },

    /**
     * Formats a duration object as a string.
     *
     * @param {Object} duration - The duration
     * @param {String|Number} duration.value - The number that indicates how long the duration is
     * @param {String} duration.units - The units that the value represent
     * returns The formatted duration, e.g. 5min
     */
    duration(duration): string {
      return duration.value + duration.units;
    },
  };
}
