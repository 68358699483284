// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { FormError } from '@/core/FormError.atom';
import { ErrorWithBackground } from '@/core/ErrorWithBackground.atom';
import { FormWarning } from '@/core/FormWarning.atom';
import { Field } from 'react-final-form';
import { FORM_ERROR, FORM_WARNING, ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { canUseMemo } from '@/formbuilder/formbuilder.utilities';

export interface ErrorMessageIF extends ValidatingFormComponent<string> {
  component: 'ErrorMessageFormComponent';
  /** this can be FORM_ERROR or FORM_WARNING. if none is provided then a FORM_WARNING is displayed */
  type?: typeof FORM_ERROR | typeof FORM_WARNING;
  /** if true, "execute" button will be disabled as long as the error is displayed
   this should only be used for errors that the user can correct */
  failForm: boolean;
  /** if true, then a little "x" shows on red background - just a flavor of our many error messages */
  dismissible?: boolean;
  /** called when the dismissible error message is closed */
  onClose?: () => void;
  /** title of your error message, only applies to the dismissible error message */
  title?: string;
  /** clickable text that is displayed in FormWarning */
  linkText?: string;
  /** function called when the link is clicked */
  linkAction?: () => void;
  /** parameters passed into the translation for a FORM_ERROR */
  errorParameters?: Record<string, string | number>;
}

const ErrorMessageFormComponentUnwrapped: React.FunctionComponent<ErrorMessageIF> = (props) => {
  const {
    name,
    testId,
    value,
    dismissible,
    title,
    type,
    failForm,
    linkAction,
    onClose,
    errorParameters,
    extraClassNames,
  } = props;
  let content;

  if (dismissible) {
    content = (
      <div className="p10">
        <ErrorWithBackground dismissible={true} type={type} errorHeading={title} errorText={value} onClose={onClose} />
      </div>
    );
  } else if (type === FORM_ERROR) {
    content = (
      <FormError
        errorText={value}
        key={name}
        errorParameters={errorParameters}
        extraClassNames={classNames('width-100percent', extraClassNames)}
      />
    );
  } else {
    // FORM WARNING
    content = (
      <div onClick={linkAction}>
        <FormWarning extraClassNames={extraClassNames} warningText={value} />
      </div>
    );
  }

  if (failForm) {
    return (
      <Field name={name} validate={() => failForm}>
        {() => content}
      </Field>
    );
  }

  return <div data-testid={testId}>{content}</div>;
};

/**
 * Standardized error handling for FormBuilder components
 */
export const ErrorMessageFormComponent = React.memo(ErrorMessageFormComponentUnwrapped, canUseMemo);
