import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { generateTabHash } from '@/utilities/utilities';
import { LOAD_STATUS, WORKBOOK_DISPLAY, WorkbookItemPreview } from '@/workbook/workbook.constants';
import { AceOutputV1, ItemSearchPreviewV1, LockErrorOutputV1, TreeItemOutputV1 } from '@/sdk';
import { StoreWorksheet } from '@/worksheet/worksheet.types';
import { LockedMetadataIF } from '@/accessControl/AclModalLockTab.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export class WorkbookStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqWorkbookStore';

  initialize() {
    this.state = this.immutable({
      name: '',
      description: '',
      owner: '',
      createdAt: 0,
      updatedAt: 0,
      worksheets: [],
      workbookDisplay: undefined,
      pinned: [],
      pinnedLoadStatus: LOAD_STATUS.NOT_LOADED,
      recentlyAccessed: [],
      recentlyAccessedLoadStatus: LOAD_STATUS.NOT_LOADED,
      assetGroups: [],
      assetGroupsLoadStatus: LOAD_STATUS.NOT_LOADED,
      tableDefinitions: [],
      tableDefinitionsLoadStatus: LOAD_STATUS.NOT_LOADED,
      assetTrees: [],
      assetTreesLoadStatus: LOAD_STATUS.NOT_LOADED,
      isArchived: false,
      parentFolderId: '',
      ancestors: [],
      effectivePermissions: {},
      viewers: [],
      isLocked: undefined,
      lockedMetadata: undefined,
      lockedChangeMade: false,
      lockingErrors: [] as any[],
    });
  }

  get name() {
    return this.state.get('name');
  }

  get description() {
    return this.state.get('description');
  }

  get owner() {
    return this.state.get('owner');
  }

  get createdAt() {
    return this.state.get('createdAt');
  }

  get updatedAt() {
    return this.state.get('updatedAt');
  }

  // TODO: CRAB-30046- update this name
  get isReportBinder() {
    return this.state.get('type') === SeeqNames.Types.Topic;
  }

  get isVantage() {
    return this.state.get('type') === SeeqNames.Types.Vantage;
  }

  get isAnalysis() {
    return this.state.get('type') === SeeqNames.Types.Analysis;
  }

  get type() {
    return this.state.get('type');
  }

  getWorksheetName(worksheetId: string) {
    return this.getWorksheet(worksheetId)?.name;
  }

  getWorksheetCurrentWorkstepId(worksheetId: string) {
    return this.getWorksheet(worksheetId)?.workstep;
  }

  getWorksheetIndex(worksheetId: string | undefined) {
    return _.findIndex(
      this.state.get('worksheets'),
      (ws: StoreWorksheet) => ws.worksheetId?.toLowerCase() === worksheetId?.toLowerCase(),
    );
  }

  getWorksheet(worksheetId: string | undefined): StoreWorksheet | undefined {
    return this.state
      .get('worksheets')
      .find((ws: StoreWorksheet) => ws.worksheetId?.toLowerCase() === worksheetId?.toLowerCase());
  }

  get worksheets() {
    return this.state.get('worksheets');
  }

  get workbookId(): string {
    return this.state.get('workbookId');
  }

  get pinned() {
    return this.state.get('pinned');
  }

  get pinnedLoadStatus() {
    return this.state.get('pinnedLoadStatus');
  }

  get recentlyAccessed() {
    return this.state.get('recentlyAccessed');
  }

  get recentlyAccessedLoadStatus() {
    return this.state.get('recentlyAccessedLoadStatus');
  }

  get assetGroups() {
    return this.state.get('assetGroups');
  }

  get assetGroupsLoadStatus() {
    return this.state.get('assetGroupsLoadStatus');
  }

  get tableDefinitions(): ItemSearchPreviewV1[] {
    return this.state.get('tableDefinitions');
  }

  get tableDefinitionsLoadStatus() {
    return this.state.get('tableDefinitionsLoadStatus');
  }

  get assetTrees(): TreeItemOutputV1[] {
    return this.state.get('assetTrees');
  }

  get assetTreesLoadStatus() {
    return this.state.get('assetTreesLoadStatus');
  }

  get isLocked(): boolean {
    return this.state.get('isLocked') ?? false;
  }

  get lockedMetadata(): LockedMetadataIF | undefined {
    return this.state.get('lockedMetadata');
  }

  get lockedChangeMade(): boolean {
    return this.state.get('lockedChangeMade');
  }

  get lockingErrors() {
    return this.state.get('lockingErrors');
  }

  /**
   * It is important that no code assumes that workbookDisplay will be undefined if a workbook has not been set.
   * Otherwise that assumption would be incorrect when switching to $states that don't set the workbook.
   *
   * TODO CRAB-39412: this does not need to be a store state
   */
  get workbookDisplay(): WORKBOOK_DISPLAY | undefined {
    return this.state.get('workbookDisplay');
  }

  get acl() {
    return this.state.get('acl');
  }

  get isArchived() {
    return this.state.get('isArchived');
  }

  get parentFolderId() {
    return this.state.get('parentFolderId');
  }

  get isWorkbookLoaded() {
    return !_.isUndefined(this.state.get('workbookDisplay'));
  }

  get ancestors(): WorkbookItemPreview[] {
    return this.state.get('ancestors');
  }

  get effectivePermissions() {
    return this.state.get('effectivePermissions') || {};
  }

  get viewers() {
    return this.state.get('viewers');
  }

  get workbook() {
    return this.state.get();
  }

  protected readonly handlers = {
    WORKBOOK_SET: (payload: any) => {
      if (payload) {
        this.initialize();
        this.state.set('workbookId', payload.workbookId);
        this.state.set('name', payload.name);
        this.state.set('description', payload.description);
        this.state.set('owner', payload.owner);
        this.state.set('createdAt', payload.createdAt);
        this.state.set('updatedAt', payload.updatedAt);
        this.state.set('worksheets', payload.worksheets);
        this.state.set('workbookDisplay', payload.workbookDisplay);
        this.state.set('type', payload.type);
        if (!_.isUndefined(payload.pinned)) {
          this.state.set('pinned', payload.pinned);
        }
        if (!_.isUndefined(payload.recentlyAccessed)) {
          this.state.set('recentlyAccessed', payload.recentlyAccessed);
        }
        if (!_.isUndefined(payload.assetGroups)) {
          this.state.set('assetGroups', payload.assetGroups);
        }
        if (!_.isUndefined(payload.tableDefinitions)) {
          this.state.set('tableDefinitions', payload.tableDefinitions);
        }
        if (!_.isUndefined(payload.assetTrees)) {
          this.state.set('assetTrees', payload.assetTrees);
        }
        this.state.set('isArchived', payload.isArchived);
        this.state.set('parentFolderId', payload.parentFolderId);
        if (payload.ancestors) {
          payload.ancestors.forEach((ancestor: any) => {
            if (ancestor.translationKey) {
              ancestor.tabHash = generateTabHash(ancestor.translationKey);
            }
          });
        }
        this.state.set('ancestors', payload.ancestors);
        this.state.set('effectivePermissions', payload.effectivePermissions);
        this.state.set('isLocked', payload.isLocked);
        this.state.set('lockedMetadata', payload.lockedMetadata);
      }
    },

    /**
     * Sets one or more workbook properties
     *
     * @param payload - Key/value pairs of workbook properties
     */
    WORKBOOK_SET_PROP: (payload: any) => {
      this.state.merge(payload);
    },
    /**
     * Removes a worksheet from the array of worksheets
     *
     * @param payload - Object container for properties
     * @param payload.worksheetId - The worksheet unique ID
     */
    WORKBOOK_REMOVE_WORKSHEET: (payload: { worksheetId: string }) => {
      const index = _.findIndex(this.state.get('worksheets'), ['worksheetId', payload.worksheetId]);

      if (index > -1) {
        this.state.splice('worksheets', [index, 1]);
      }
    },
    /**
     * Sets the pinned items
     *
     * @param payload - Object container for arguments
     * @param payload.items - The array of pinned items
     */
    WORKBOOK_SET_PINNED: (payload: { items: ItemSearchPreviewV1[] }) => {
      this.state.set('pinned', payload.items);
    },

    /**
     * Sets the recently accessed items
     *
     * @param payload - Object container for arguments
     * @param payload.items - The array of recently accessed items
     */
    WORKBOOK_SET_RECENTLY_ACCESSED: (payload: { items: ItemSearchPreviewV1[] }) => {
      this.state.set('recentlyAccessed', payload.items);
    },

    /**
     * Sets the asset group items
     *
     * @param payload - Object container for arguments
     * @param payload.items - The array of asset groups
     */
    WORKBOOK_SET_ASSET_GROUPS: (payload: { items: TreeItemOutputV1[] }) => {
      this.state.set('assetGroups', payload.items);
    },

    /**
     * Sets the table definitions
     *
     * @param payload - Object container for arguments
     * @param payload.items - The array of table definitions
     */
    WORKBOOK_SET_TABLE_DEFINITIONS: (payload: { items: ItemSearchPreviewV1[] }) => {
      this.state.set('tableDefinitions', payload.items);
    },

    /**
     * Sets the asset tree items
     *
     * @param payload - Object container for arguments
     * @param payload.items - The array of asset trees
     */
    WORKBOOK_SET_ASSET_TREES: (payload: { items: TreeItemOutputV1[] }) => {
      this.state.set('assetTrees', payload.items);
    },

    /**
     * Sets the load status for pinned items
     *
     * @param payload - Object container for arguments
     * @param payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_PINNED_LOAD_STATUS: (payload: { loadStatus: keyof typeof LOAD_STATUS }) => {
      this.state.set('pinnedLoadStatus', payload.loadStatus);
    },

    /**
     * Sets the load status for recently accessed items
     *
     * @param payload - Object container for arguments
     * @param payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_RECENTLY_ACCESSED_LOAD_STATUS: (payload: { loadStatus: keyof typeof LOAD_STATUS }) => {
      this.state.set('recentlyAccessedLoadStatus', payload.loadStatus);
    },

    /**
     * Sets the load status for asset groups
     *
     * @param payload - Object container for arguments
     * @param payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_ASSET_GROUPS_LOAD_STATUS: (payload: { loadStatus: keyof typeof LOAD_STATUS }) => {
      this.state.set('assetGroupsLoadStatus', payload.loadStatus);
    },

    /**
     * Sets the load status for table definitions
     *
     * @param payload - Object container for arguments
     * @param payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_TABLE_DEFINITIONS_LOAD_STATUS: (payload: { loadStatus: keyof typeof LOAD_STATUS }) => {
      this.state.set('tableDefinitionsLoadStatus', payload.loadStatus);
    },

    /**
     * Sets the load status for asset trees
     *
     * @param payload - Object container for arguments
     * @param payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_ASSET_TREES_LOAD_STATUS: (payload: { loadStatus: keyof typeof LOAD_STATUS }) => {
      this.state.set('assetTreesLoadStatus', payload.loadStatus);
    },

    /**
     * Renames a worksheet
     *
     * @param payload - Object container for properties
     * @param payload.worksheetId - The worksheet unique ID
     * @param payload.name - Name for the worksheet
     */
    WORKBOOK_RENAME_WORKSHEET: (payload: { worksheetId: string; name: string }) => {
      const index = _.findIndex(this.state.get('worksheets'), ['worksheetId', payload.worksheetId]);

      if (index > -1) {
        this.state.set(['worksheets', index, 'name'], payload.name);
      }
    },

    /**
     * Move a worksheet to a new location on a Workbook
     *
     * @param  payload - Object container for properties
     * @param  payload.worksheetId - The ID of the worksheet to move
     * @param  payload.nextWorksheetId - The ID of the worksheet immediately after the new location
     */
    WORKBOOK_MOVE_WORKSHEET: (payload: { worksheetId: string; nextWorksheetId: string }) => {
      const cursor = this.state.select('worksheets');
      const index = _.findIndex(cursor.get(), ['worksheetId', payload.worksheetId]);

      if (index >= 0) {
        const worksheet = cursor.get(index);
        cursor.splice([index, 1]);

        const newIndex = _.findIndex(cursor.get(), ['worksheetId', payload.nextWorksheetId]);
        if (payload.nextWorksheetId && newIndex >= 0) {
          cursor.splice([newIndex, 0, worksheet]);
        } else {
          cursor.push(worksheet);
        }
      }
    },

    /**
     * Add a worksheet to the workbook if it is not already present
     *
     * @param worksheet - The worksheet to be added to the store
     * @param position - position where the worksheet will be added. When not provided, it will be added at
     * the end of worksheet list
     */
    WORKBOOK_ADD_WORKSHEET: ({ worksheet, position }: { worksheet: StoreWorksheet; position: number }) => {
      const cursor = this.state.select('worksheets');
      const index = _.findIndex(cursor.get(), ['worksheetId', worksheet.worksheetId]);
      if (index < 0) {
        if (!_.isUndefined(position) && position >= 0) {
          cursor.splice([position, 0, worksheet]);
        } else {
          cursor.push(worksheet);
        }
      }
    },

    /**
     * Sets a property on one of the worksheets
     *
     * @param payload - Object container for properties
     * @param payload.worksheetId - ID of the worksheet on which to set the property
     * @param payload.propertyName - Name of the property to set
     * @param payload.propertyValue - Value for the property
     */
    WORKBOOK_SET_WORKSHEET_PROPERTY: ({
      worksheetId,
      propertyName,
      propertyValue,
    }: {
      worksheetId: string;
      propertyName: string;
      propertyValue: string | number | boolean;
    }) => {
      const index = this.getWorksheetIndex(worksheetId);
      if (index >= 0) {
        this.state.set(['worksheets', index, propertyName], propertyValue);
      }
    },

    /**
     * Sets the ACL for workbook. This is used by the "Get Link" feature to display who has access to the current
     * workbook.
     *
     * @param  payload - Object container for properties
     * @param  payload.acl - the workbook ACL
     */
    WORKBOOK_SET_ACL: (payload: { acl: AceOutputV1[] }) => {
      this.state.set('acl', payload.acl);
    },

    /**
     * Clears the worksheets.
     */
    WORKBOOKS_ADD_WORKBOOK: () => {
      this.state.set('worksheets', []);
    },

    /**
     * Sets the workbook owner.
     *
     * @param payload - The new owner
     * @param payload.id - The ID of the new owner
     * @param payload.name - The name of the new owner
     */
    WORKBOOK_SET_OWNER: (payload: { id: string; name: string }) => {
      this.state.set('owner', payload);
    },

    /**
     * Sets the viewers of this workbook.
     *
     * @param payload - The new list of viewers
     * @param payload.id - ID of the user
     * @param payload.name - name of the user
     * @param payload.workbookDisplay - the display mode of the user
     * @param payload.worksheetId - ID of the worksheet the user is viewing
     */
    WORKBOOK_SET_VIEWERS: (payload: { id: string; name: string; workbookDisplay: string; worksheetId: string }[]) => {
      this.state.set('viewers', payload);
    },

    WORKBOOK_SET_TYPE: ({ type }: { type: string | undefined }) => {
      this.state.set('type', type);
    },

    WORKBOOK_SET_LOCKED_CHANGE_MADE: ({ lockedChangeMade }: { lockedChangeMade: boolean }) => {
      this.state.set('lockedChangeMade', lockedChangeMade);
    },

    WORKBOOK_SET_LOCKING_ERRORS: (payload: { lockingErrors: LockErrorOutputV1[] }) => {
      this.state.set('lockingErrors', payload.lockingErrors);
    },
  };
}
