import _ from 'lodash';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { BaseItemStore } from './baseItem.store';

export class TrendScalingColumnStore extends BaseItemStore {
  static readonly storeName = 'sqTrendScalingColumnStore';

  dehydrate() {
    return {
      items: _.chain(this.state.get('items'))
        .filter(super.shouldDehydrateItem)
        .map((item) => _.pick(item, ['id', 'name', 'tableName', 'tableId', 'sampleItemId', 'sampleItemName']))
        .value(),
    };
  }

  rehydrate(dehydratedState: any) {
    this.state.merge(_.omit(dehydratedState, ['items']));
    this.state.set(
      'items',
      _.map(dehydratedState.items, (item) => this.createColumn(item.id, item.name, _.omit(item, ['id', 'name']))),
    );
  }

  localHandlers = {
    TREND_ADD_SCALING_COLUMN: (payload: { id: string; name: string; props: any }) => {
      this.state.push('items', this.createColumn(payload.id, payload.name, payload.props));
    },
    TREND_CLEAR_SCALING_COLUMNS: () => {
      this.state.set('items', []);
    },
  };

  protected readonly handlers = { ...this.localHandlers, ...this.baseHandlers };

  /**
   * Creates a scaling column item.
   *
   * @param id - The id of the column
   * @param name - The name of the column
   * @param props[] - Array of properties to be set on the item.
   * @return {Object} an table item.
   */
  createColumn(id: string, name: string, props: any) {
    return this.createItem(
      id,
      name,
      ITEM_TYPES.SCALING_COLUMN,
      _.assign({
        tableName: props['tableName'],
        tableId: props['tableId'],
        sampleItemId: props['sampleItemId'],
        sampleItemName: props['sampleItemName'],
      }),
    );
  }
}
