import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import {
  COMPARISON_OPERATORS,
  COMPARISON_OPERATORS_SYMBOLS,
  PREDICATE_API,
  STRING_COMPARISON_OPERATORS,
} from '@/toolSelection/investigate.constants';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FORM_ERROR, FormElement } from '@/formbuilder/formBuilder.constants';
import { FormBuilder } from '@/formbuilder/FormBuilder.page';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { TextFieldFormComponent } from '@/formbuilder/TextFieldFormComponent.atom';
import { doTrack } from '@/track/track.service';
import { ButtonWithPopover } from '@seeqdev/qomponents';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';

interface FormattedThreshold {
  text: string;
  level: number;
  value: string;
  thresholdValue: any;
  label: JSX.Element;
}

interface Operator {
  text: string;
  value: string;
  label: JSX.Element;
}

interface StringValues {
  text: string;
  value: string;
  id: string;
  name: string;
}

export interface TableFilterPopoverProps {
  columnFilter: TableColumnFilter | undefined;
  columnKey: string;
  notifyOnClose?: () => void;
  setColumnFilter: (key: string, filter: TableColumnFilter | undefined) => void;
  isStringColumn?: boolean;
  isDurationColumn?: boolean;
  distinctStringValues?: string[];
  placement: TooltipPosition;
  thresholds?: ThresholdOutputV1[];
  show?: boolean;
  helpText?: string;
  trigger?: ReactNode;
  isInteractiveContent?: boolean;
  isWorkbookLocked?: boolean;
}

export const TableFilterPopover: React.FunctionComponent<TableFilterPopoverProps> = ({
  columnFilter,
  columnKey,
  notifyOnClose = () => {},
  setColumnFilter = () => {},
  isStringColumn = false,
  isDurationColumn = false,
  distinctStringValues,
  placement,
  show = false,
  helpText,
  thresholds,
  trigger,
  isInteractiveContent,
  isWorkbookLocked,
}) => {
  const { t } = useTranslation();

  const [showPopover, setShowPopover] = useState(show);
  const [operators, setOperators] = useState<Operator[]>([]);
  const [displayThresholds, setDisplayThresholds] = useState<FormattedThreshold[]>([]);
  const [displayDistinctStringValues, setDisplayDistinctStringValues] = useState<StringValues[]>([]);
  const [selectedStringValues, setSelectedStringValues] = useState<any[]>([]);
  const [filterOperator, setFilterOperator] = useState<string | undefined>(
    _.findKey(PREDICATE_API, (value) => value === columnFilter?.operator),
  );
  const [upperValue, setUpperValue] = useState<number | string | undefined | ValueWithUnitsItem>(undefined);
  const [lowerValue, setLowerValue] = useState<number | string | undefined | ValueWithUnitsItem>(undefined);
  const [advancedEntryCollapsed, setAdvancedEntryCollapsed] = useState(true);
  const [filterFormData, setFilterFormData] = useState<FormElement[]>([
    {
      component: 'FormGroup',
      name: 'simpleValueSearchGroup',
      testId: 'tableFilterForm',
      components: [],
    },
  ]);
  const [threshold, setThreshold] = useState<FormattedThreshold>();
  const operatorKeys = _.invert(COMPARISON_OPERATORS_SYMBOLS);
  const DEFAULT_STRING_FILTER = _.head(STRING_COMPARISON_OPERATORS);
  const formatOperators = (operators: string[]) =>
    _.map(operators, (operator) => ({
      text: operator,
      value: operator,
      label: (
        <>
          <span className="inlineBlock width-25 text-center text-bolder text-monospace" id={operator}>
            {operator}
          </span>
          <span className="pl5">{t(`VALUE_SEARCH.OPERATORS.${operatorKeys[operator]}`)}</span>
        </>
      ),
    }));

  useEffect(() => {
    // Update and format the operators given the type of column
    const operators = isStringColumn ? STRING_COMPARISON_OPERATORS : COMPARISON_OPERATORS;
    setOperators(formatOperators(operators));

    if (!filterOperator && isStringColumn) {
      setFilterOperator(DEFAULT_STRING_FILTER);
    }

    if (!isStringColumn) {
      setSelectedStringValues([]);
    }
  }, [isStringColumn]);

  const getSymbolFromPriority = (priorityLevel: number) => {
    if (isStringColumn) {
      return COMPARISON_OPERATORS_SYMBOLS.IS_MATCH;
    }
    return priorityLevel > 0 ? COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN : COMPARISON_OPERATORS_SYMBOLS.IS_LESS_THAN;
  };

  const formatThreshold = (threshold: ThresholdOutputV1): FormattedThreshold => ({
    text: threshold.priority!.name,
    level: threshold.priority!.level,
    value: `${threshold.priority!.level}_${threshold.value?.value}`,
    thresholdValue: threshold.value?.value,
    label: (
      <>
        <span
          className="colorPickerSwatch mr10"
          onClick={() => {}}
          data-itemid={columnKey}
          style={{ backgroundColor: threshold.priority!.color }}
          id="filterThresholdColor"
        />
        <span className="text-bolder text-monospace ">{threshold.priority!.name}</span>
        <span className="pl5">{threshold.value?.value}</span>
      </>
    ),
  });

  const formatThresholds = (thresholds: ThresholdOutputV1[]): FormattedThreshold[] => {
    return _.chain(thresholds)
      .reject(_.isUndefined)
      .map(formatThreshold)
      .reject((threshold) => _.isUndefined(threshold.thresholdValue))
      .uniqBy('value')
      .sortBy('value')
      .reverse()
      .value();
  };

  useEffect(() => {
    setDisplayThresholds(formatThresholds(thresholds!));
  }, [thresholds]);

  const formatDistinctStringValues = (stringValues: string[]) =>
    _.chain(stringValues)
      .reject(_.isUndefined)
      .reject(_.isEmpty)
      .map((value) => ({ text: value, value, id: value, name: value }))
      .sortBy('text')
      .value();

  useEffect(() => {
    setDisplayDistinctStringValues(formatDistinctStringValues(distinctStringValues!));
  }, [distinctStringValues]);

  useEffect(() => {
    setFilterOperator(_.findKey(PREDICATE_API, (value) => value === columnFilter?.operator));
  }, [columnFilter?.operator]);

  const setFilterValues = (filter: TableColumnFilter) => {
    if (_.isEmpty(filter?.values)) {
      setLowerValue(undefined);
      setUpperValue(undefined);
      setSelectedStringValues([]);
    } else if (
      filter.operator &&
      isOperatorBetween(_.findKey(PREDICATE_API, (value) => value === columnFilter?.operator))
    ) {
      setLowerValue(filter.values[0] as string | number | ValueWithUnitsItem);
      setUpperValue(filter.values[1] as string | number | ValueWithUnitsItem);
    } else if (filter.usingSelectedValues) {
      setSelectedStringValues(formatDistinctStringValues(filter.values as string[]));
    } else {
      setUpperValue(filter.values[0]);
    }
  };

  useEffect(() => {
    setFilterValues(columnFilter as TableColumnFilter);
    // Keep the advanced section open if we're using the input there, otherwise close it
    if (isStringColumn && !_.isEmpty(columnFilter?.values) && !columnFilter?.usingSelectedValues) {
      setAdvancedEntryCollapsed(false);
    } else if (!isStringColumn || _.isEmpty(columnFilter?.values)) {
      setAdvancedEntryCollapsed(true);
    }
  }, [columnFilter]);

  useEffect(() => {
    if (!columnFilter) {
      setThreshold(undefined);
    }
  }, [columnFilter]);

  useEffect(() => {
    if (threshold) {
      setFilterOperator(getSymbolFromPriority(threshold.level));
      setFilterValues({
        operator: getSymbolFromPriority(threshold.level),
        values: [threshold.thresholdValue?.toString(), undefined],
      });
    }
  }, [threshold]);

  useEffect(() => {
    if (!filterOperator && isStringColumn) {
      setFilterOperator(DEFAULT_STRING_FILTER);
    }

    if (!columnFilter || columnFilter.values.length !== 1 || !show) {
      return;
    }
    // Select a threshold when the popover opens only if it matches the existing column filter.
    if (!threshold && !_.isEmpty(thresholds)) {
      const matchingThreshold = _.find(thresholds, (thresh) => {
        const valueMatches = columnFilter.values[0] === thresh.value?.value;
        const operatorMatches =
          _.findKey(PREDICATE_API, (value) => value === columnFilter.operator) ===
          getSymbolFromPriority(thresh.priority?.level as number);
        return valueMatches && operatorMatches;
      });
      if (matchingThreshold) {
        setThreshold(formatThreshold(matchingThreshold));
      }
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      openPopover();
    }
  }, [show]);

  useEffect(() => {
    const handleClick = () => {
      closePopover();
    };

    // add when mounted
    document.querySelector('#mainView')?.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const openPopover = () => {
    if (showPopover) {
      closePopover();
    }
  };

  const closePopover = () => {
    setShowPopover(false);
    notifyOnClose();
  };

  const isOperatorBetween = (operator: string | undefined) =>
    _.includes([COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN, COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN], operator);

  const isSimpleLowerBoundGreater = (lowerValue: string, upperValue: string) => {
    if (_.isUndefined(lowerValue) || _.isUndefined(upperValue)) {
      return false;
    }
    const parsedLower = parseFloat(lowerValue);
    const parsedUpper = parseFloat(upperValue);
    return _.isFinite(parsedLower) && _.isFinite(parsedUpper) && parsedUpper < parsedLower;
  };

  const isSameBetweenValues = (operator: string, lowerValue: string, upperValue: string) => {
    if (_.isUndefined(lowerValue) || _.isUndefined(lowerValue)) {
      return false;
    }
    const parsedLower = parseFloat(lowerValue);
    const parsedUpper = parseFloat(upperValue);
    return (
      isOperatorBetween(operator) && _.isFinite(parsedLower) && _.isFinite(parsedUpper) && parsedUpper === parsedLower
    );
  };

  const isBadString = (input: string) => {
    if (!input) {
      return false;
    }
    try {
      ''.match(new RegExp(input[0]));
      return false;
    } catch {
      return true;
    }
  };

  const getColumnFilterToSubmit = useCallback(() => {
    const inputValues = _.reject([lowerValue, upperValue], _.isUndefined);
    let values;
    let usingSelectedValues;
    if (isStringColumn) {
      if (_.isEmpty(selectedStringValues)) {
        values = inputValues;
      } else {
        values = _.map(selectedStringValues, 'value');
        usingSelectedValues = true;
      }
    } else if (isDurationColumn) {
      values = _.chain(inputValues)
        .map((value: ValueWithUnitsItem) => {
          const parsedValue = parseFloat(value.value as unknown as string);
          if (_.isFinite(parsedValue) && !_.isUndefined(value.units)) {
            return { units: value.units, value: parsedValue };
          }
          return undefined;
        })
        .reject(_.isUndefined)
        .value();
    } else {
      values = _.chain(inputValues)
        .map((value) => {
          const parsedValue = parseFloat(value as string);
          if (_.isFinite(parsedValue)) {
            return parsedValue;
          }
          return undefined;
        })
        .reject(_.isUndefined)
        .value();
    }
    const operator = PREDICATE_API[filterOperator as keyof typeof PREDICATE_API];
    return { values, operator, usingSelectedValues };
  }, [lowerValue, upperValue, filterOperator, selectedStringValues]);

  const selectAStringValue = useCallback(
    (selectedValues: any) => {
      setSelectedStringValues(selectedValues);
    },
    [selectedStringValues],
  );

  const removeAStringValue = useCallback(
    (value: any) => {
      setSelectedStringValues(_.reject(selectedStringValues, { value: value.value }));
    },
    [selectedStringValues],
  );

  const isValueWithUnitsInvalid = ({ value, units }: ValueWithUnitsItem) =>
    _.isUndefined(units) ||
    _.isNil(value) ||
    (typeof value === 'string' && _.isEmpty(value)) ||
    value.toString().includes('e');

  const getPlaceholderForStringValueSelect = () => {
    // If raw value from store is undefined it means fetch has not completed
    if (_.isUndefined(distinctStringValues)) {
      return 'FILTER.LOADING';
    }

    return _.isEmpty(distinctStringValues) ? 'FILTER.NO_OPTIONS' : 'FILTER.STRING_SELECTION';
  };

  useEffect(() => {
    setFilterFormData([
      {
        component: 'FormGroup',
        name: 'simpleValueSearchGroup',
        testId: 'tableFilterForm',
        components: [
          {
            component: 'FormGroup',
            name: 'thresholdGroup',
            components: [
              {
                component: 'IconSelectFormComponent',
                name: 'existingThresholdSelect',
                includeIf: !_.isEmpty(displayThresholds),
                value: threshold,
                onChange: (t: FormattedThreshold) => setThreshold(t),
                validation: () => false,
                formattedOptions: true,
                placeholder: 'FILTER.SELECT_THRESHOLD',
                testId: 'existingThresholdSelect',
                selectOptions: displayThresholds,
                wrapperClasses: 'min-width-195',
                skipMemo: true,
                disabled: isWorkbookLocked,
              },
              {
                component: 'LabelFormComponent',
                includeIf: !_.isEmpty(displayThresholds),
                value: '',
                name: 'tableFilterThresholdLine',
                extraClassNames: 'tableFilterThresholdLine',
              },
              {
                component: 'IconSelectFormComponent',
                name: 'simpleFilterOperator',
                value: filterOperator,
                onChange: (operator) => {
                  if (!isOperatorBetween(operator.value) && isOperatorBetween(filterOperator)) {
                    setLowerValue(undefined);
                  }
                  setFilterOperator(operator.value);
                  setThreshold(undefined);
                },
                formattedOptions: true,
                placeholder: 'VALUE_SEARCH.SELECT_OPERATOR',
                testId: 'simpleFilterOperator',
                selectOptions: operators,
                wrapperClasses: 'min-width-195',
                disabled: isWorkbookLocked,
              },
            ],
          },
          {
            component: 'FormGroup',
            name: 'upperLimitGroup',
            includeIf: !isStringColumn,
            components: [
              {
                component: 'LabelFormComponent',
                includeIf: isOperatorBetween(filterOperator),
                value: 'VALUE_SEARCH.UPPER_LIMIT',
                name: 'upperFilterLimitLabel',
                extraClassNames: 'forceNoBottomMargin',
              },
              {
                component: 'TextFieldFormComponent',
                name: 'columnFilterValue',
                includeIf: !isDurationColumn,
                testId: 'columnFilterValue',
                type: 'text',
                validation: validateNumericColumn,
                value: upperValue,
                onChange: (value: string | number | undefined | ValueWithUnitsItem) => {
                  setUpperValue(value);
                  setThreshold(undefined);
                },
                placeholder: upperValuePlaceholder,
                skipMemo: true,
                disabled: isWorkbookLocked,
              },
              {
                component: 'ValueWithUnitsFormComponent',
                includeIf: isDurationColumn,
                min: 0,
                testId: 'columnFilterValueWithUnits',
                name: 'columnFilterValueWithUnits',
                required: true,
                validation: isValueWithUnitsInvalid,
                extendValidation: true,
                insideModal: true,
                fullWidth: true,
                largeValueInput: true,
                value: upperValue,
                onChange: (value: string | number | undefined | ValueWithUnitsItem) => {
                  setUpperValue(value);
                  setThreshold(undefined);
                },
                placeholder: upperValuePlaceholder,
                skipMemo: true,
                disabled: isWorkbookLocked,
              },
            ],
          },
          {
            component: 'FormGroup',
            name: 'lowerValueGroup',
            includeIf: isOperatorBetween(filterOperator),
            components: [
              {
                component: 'LabelFormComponent',
                includeIf: isOperatorBetween(filterOperator),
                value: 'VALUE_SEARCH.LOWER_LIMIT',
                name: 'lowerLimitLabel',
                extraClassNames: 'forceNoBottomMargin',
              },
              {
                component: 'TextFieldFormComponent',
                name: 'columnFilterLowerValue',
                includeIf: !isDurationColumn,
                testId: 'columnFilterLowerValue',
                type: 'text',
                value: lowerValue,
                validation: () => _.isEmpty(_.trim(lowerValue as string | undefined)) || isNaN(lowerValue as number),
                onChange: (value) => {
                  setLowerValue(value);
                  setThreshold(undefined);
                },
                placeholder: 'VALUE_SEARCH.LOWER_ENTRY_VALUE',
                skipMemo: true,
                disabled: isWorkbookLocked,
              },
              {
                component: 'ValueWithUnitsFormComponent',
                name: 'columnFilterLowerValueWithUnits',
                fullWidth: true,
                largeValueInput: true,
                insideModal: true,
                includeIf: isDurationColumn,
                required: true,
                validation: isValueWithUnitsInvalid,
                extendValidation: true,
                min: 0,
                testId: 'columnFilterLowerValueWithUnits',
                value: lowerValue,
                onChange: (value) => {
                  setLowerValue(value);
                  setThreshold(undefined);
                },
                skipMemo: true,
                disabled: isWorkbookLocked,
              },
            ],
          },
          {
            component: 'FormGroup',
            name: 'distinctStringValueGroup',
            includeIf: !isInteractiveContent,
            components: [
              {
                component: 'IconSelectFormComponent',
                name: 'distinctStringValueSelect',
                isMultipleSelect: true,
                disabled: _.isEmpty(displayDistinctStringValues) || isWorkbookLocked,
                isSearchable: true,
                selectOptions: displayDistinctStringValues,
                includeIf: isStringColumn,
                value: selectedStringValues,
                onChange: selectAStringValue,
                onRemove: removeAStringValue,
                validation:
                  isStringColumn && !_.isEmpty(displayDistinctStringValues)
                    ? (value) => _.isEmpty(value) && _.isEmpty(selectedStringValues) && _.isEmpty(upperValue)
                    : validateStringColumn,
                formattedOptions: false,
                placeholder: getPlaceholderForStringValueSelect(),
                testId: 'distinctStringValueSelect',
                wrapperClasses: 'min-width-195 mt10',
                skipMemo: true,
                skipTranslation: true,
              },
            ],
          },
          {
            component: 'DisplayOnlyFormElementWrapper',
            name: 'advancedStringFilterEntry',
            includeIf: isStringColumn || !_.isEmpty(displayDistinctStringValues),
            wrapperClasses: 'mt10',
            children: (
              <TextFieldFormComponent
                extraClassNames="flexFill"
                disabled={!_.isEmpty(selectedStringValues) || isWorkbookLocked}
                component="TextFieldFormComponent"
                name="columnFilterValue"
                size="sm"
                testId="columnFilterValueAdvanced"
                type="text"
                validation={
                  isStringColumn && !_.isEmpty(selectedStringValues)
                    ? () => false
                    : () => _.isEmpty(_.trim(upperValue as string | undefined))
                }
                value={upperValue as string | number | undefined}
                onChange={(value) => {
                  setUpperValue(value);
                  setThreshold(undefined);
                }}
                placeholder={upperValuePlaceholder}
                skipMemo={true}
              />
            ),
          },
          {
            component: 'ErrorMessageFormComponent',
            name: 'greaterLowerBoundError',
            includeIf: isSimpleLowerBoundGreater(lowerValue as string, upperValue as string),
            type: FORM_ERROR,
            failForm: true,
            value: 'VALUE_SEARCH.LIMIT_ERROR',
          },
          {
            component: 'ErrorMessageFormComponent',
            name: 'sameBetweenValues',
            includeIf: isSameBetweenValues(filterOperator!, lowerValue as string, upperValue as string),
            type: FORM_ERROR,
            failForm: true,
            value: 'VALUE_SEARCH.SAME_BETWEEN',
          },
          {
            component: 'ErrorMessageFormComponent',
            name: 'badStringInput',
            includeIf: isStringColumn && isBadString(upperValue as string) && _.isEmpty(selectedStringValues),
            type: FORM_ERROR,
            failForm: true,
            value: 'FILTER.BAD_STRING',
          },
          {
            component: 'LabelFormComponent',
            includeIf: !!helpText,
            value: helpText,
            name: 'filterHelpText',
            extraClassNames: 'forceNoBottomMargin text-muted',
          },
        ],
      },
    ] as FormElement[]);
  }, [
    upperValue,
    lowerValue,
    filterOperator,
    operators,
    displayThresholds,
    threshold,
    isDurationColumn,
    displayDistinctStringValues,
    selectedStringValues,
    isStringColumn,
    advancedEntryCollapsed,
    isWorkbookLocked,
  ]);

  const validateStringColumn = _.isEmpty(selectedStringValues)
    ? () => _.isEmpty(_.trim(upperValue as string | undefined))
    : _.noop;
  const validateNumericColumn = () =>
    isNaN(upperValue as number) || _.isEmpty(_.trim(upperValue as string | undefined));

  const limitOrThresholdPlaceholder = isStringColumn ? 'FILTER.STRING_LIMIT' : 'VALUE_SEARCH.THRESHOLD';

  const upperValuePlaceholder = isOperatorBetween(filterOperator)
    ? 'VALUE_SEARCH.UPPER_ENTRY_VALUE'
    : limitOrThresholdPlaceholder;

  return (
    <ButtonWithPopover
      trigger={trigger}
      isOpen={show}
      placement={placement}
      hasArrow
      isPortal
      onPointerDownOutside={notifyOnClose}>
      <div id="tableFilterPopover">
        <div className="popover-header">{t('FILTER.FILTER')}</div>
        <div className="p10 width-250" onClick={(e) => e.stopPropagation()}>
          <FormBuilder
            formDefinition={filterFormData}
            submitFn={() => {
              setColumnFilter(columnKey, getColumnFilterToSubmit());
              doTrack('FILTER.TABLE', 'filter', 'enabled');
              closePopover();
            }}
            closeFn={() => {
              setColumnFilter(columnKey, undefined);
              doTrack('FILTER.TABLE', 'filter', 'disabled');
              closePopover();
            }}
            cancelBtnLabel="REMOVE"
            submitBtnLabel="APPLY"
            saveAndCancel={true}
            hideCancel={isWorkbookLocked}
            hideSubmit={isWorkbookLocked}
            wrapInPanel={false}
          />
        </div>
      </div>
    </ButtonWithPopover>
  );
};
