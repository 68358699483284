import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core/dist/types/src/rendering/cellRenderers/iCellRenderer';
import { Icon } from '@seeqdev/qomponents';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export interface UseCaseNameCellProps {
  name: string;
  worksheetId: string;
  type: string;
}

/**
 * This cell-renderer renders a little "bubble" icon next to the use case name to indicate there is more to see and
 * encourage users to click on the cell to see more details.
 * It also includes the workbench icon that links to the associated worksheet in view mode.
 */
export const UseCaseNameCellRenderer: React.FunctionComponent<ICellRendererParams<any, UseCaseNameCellProps>> = (
  props,
) => {
  if (!props?.value) return null;
  const { value } = props;
  if (!value) return null;

  const handleClick = () => {
    window.open(`/view/${value.worksheetId}`, '_blank');
  };
  const type = value.type;

  const icon =
    // eslint-disable-next-line no-nested-ternary
    type === SeeqNames.Types.Vantage ? 'fc-vantage' : type === SeeqNames.Types.Analysis ? 'fc-analysis' : 'fc-report';
  return value.name ? (
    <div className="flexColumnContainer flexAlignCenter">
      <div className="flexFill">
        <Icon icon={icon} onClick={handleClick} extraClassNames="workbookIcon workbenchDisplay mr5" />
        {value.name}
      </div>
      <Icon icon="fa-comment-dots" extraClassNames="ml5 detailsBubble" />
    </div>
  ) : null;
};
