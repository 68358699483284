import _ from 'lodash';
import { isInvalidPixel, translateRegion } from '@/utilities/chartHelper.utilities';
import { ChartRegion, PixelTranslationFunction, XYPixelRegion } from '@/chart/chart.constants';
import { chartLanes } from '@/utilities/chartLanes';
import { Chart } from 'highcharts';

const REMOVE_BUTTON_PADDING = 20;

/**
 * Creates and returns a function that will draw a 2d box overlay in place of a capsule (time range specified in
 * tool). The function is designed to be called any time a region on the display range is present.
 *
 * @param getChart returns the chart to draw the selection on
 * @param getCapsuleRegions returns a list of capsules
 * @param zIndex constants to use for setting the zIndex of the selection and buttons
 * @param handlers handlers for the buttons
 * @param translate optional translation functions to convert XY coordinates into pixel coordinates
 * @param pickSelection optional handler for the "pick" button
 *
 * @return {function} - void function that draws or updates the selection overlay on a chart
 */
export function drawCapsuleRegion(
  getChart: () => Chart,
  getCapsuleRegions: () => ChartRegion[],
  zIndex: { selection: number; button: number },
  handlers: { openAnnotation: (id: string) => any },
  getItems: () => any[],
  translate: {
    x?: PixelTranslationFunction;
    y?: PixelTranslationFunction;
  } = {},
  pickSelection = { isPickingMode: () => false, pickSelection: _.noop },
) {
  let elements: {
    capsuleRegionRect: any;
    annotationRegionButton: any;
    regionId: string;
  }[] = [];

  return () => {
    const chart: Highcharts.Chart = getChart();
    const capsuleRegions = getCapsuleRegions();

    if (!_.isObject(chart)) {
      return;
    }

    // Use the x-axis to translate the x-values to pixel locations
    const xAxis = chart.xAxis[0];

    if (!_.isObject(xAxis) || !_.isObject(chart.plotBox)) {
      return;
    }

    if (!capsuleRegions) {
      return;
    }

    const yAxis = chart.yAxis[0];
    if (!translate.y && !_.isObject(yAxis)) {
      return;
    }

    elements = _.chain(capsuleRegions)
      .map((region) => translateRegion(chart, xAxis, yAxis, translate, region))
      .filter((region) => !isInvalidPixel(region))
      .zip(elements)
      .map(
        ([capsuleRegion, element]: [
          capsuleRegion: XYPixelRegion,
          element: {
            capsuleRegionRect: any;
            annotationRegionButton: any;
            regionId: string;
          },
        ]) => {
          const offset =
            _.find(chart.yAxis, { userOptions: { conditionId: capsuleRegion?.id.split('_')[0] } })?.pos ?? 0;
          if (!_.isObject(element)) {
            // It's not ideal to have this CSS hard-coded here rather than in a .css file, but we
            // haven't been able to figure out how to get Highcharts to use an externally-defined style.
            element = {
              regionId: capsuleRegion?.id,
              capsuleRegionRect: chart.renderer
                .rect(0, 0, 0, 0, 0)
                .css({
                  'stroke': '#39516b',
                  'stroke-width': 0.1,
                  'fill': 'rgba(0, 160, 89, 0.1)',
                })
                .attr({
                  class: 'highcharts-selected-region',
                  zIndex: zIndex.selection,
                })
                .add(),
              annotationRegionButton: chart.renderer
                .text('\ue903', 0, 0, true)
                .css({
                  cursor: 'pointer',
                  color: '#fff',
                  fontSize: '16px',
                  fontFamily: 'FontAwesome',
                })
                .attr({
                  class: 'fc cursorPointer specAnnotationIcon',
                  zIndex: zIndex.button,
                })
                .on('click', () => {
                  handlers.openAnnotation(element.regionId);
                })
                .add(),
            };
            elements.push(element);
          }
          if (!_.isObject(capsuleRegion)) {
            if (_.isObject(element.capsuleRegionRect)) {
              element.capsuleRegionRect.destroy();
            }

            if (_.isObject(element.annotationRegionButton)) {
              element.annotationRegionButton.destroy();
            }
            return;
          }

          const { xMinPixel, xMaxPixel, yMinPixel, yMaxPixel } = capsuleRegion;

          element.regionId = capsuleRegion.id;
          element.capsuleRegionRect.attr({
            x: xMinPixel + chart.plotLeft,
            y: chart.plotHeight + chart.plotTop - yMaxPixel,
            width: xMaxPixel - xMinPixel,
            height: yMaxPixel - yMinPixel,
          });

          const rowOffset = chartLanes.hasSignalsInLane(capsuleRegion.lane, getItems())
            ? 0
            : (capsuleRegion.yValue ?? 1) - 1;
          element.annotationRegionButton.attr({
            x: element.capsuleRegionRect.attr('x') + element.capsuleRegionRect.attr('width') - REMOVE_BUTTON_PADDING,
            y: element.capsuleRegionRect.attr('y') + REMOVE_BUTTON_PADDING + offset + rowOffset,
            dateTime: capsuleRegion.dateTime,
          });

          return element;
        },
      )
      .compact()
      .value() as unknown as {
      capsuleRegionRect: any;
      annotationRegionButton: any;
      regionId: string;
    }[];
  };
}
