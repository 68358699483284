import { flux } from '@/core/flux.module';
import { RowRulesResultV1, RulesResultV1, TableDefinitionOutputV1 } from '@/sdk';
import {
  MaterializedTableItemColumnPropertyPair,
  MaterializedTableOutput,
  ScalingTableColumnDefinition,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { GridApi } from '@ag-grid-community/core';
import { BatchActionEnum } from '@/sdk/model/TableDefinitionInputV1';
import { SearchTypeEnum } from '@/sdk/model/PropertySearchV1';
import { sqTableDefinitionStore } from '@/core/core.stores';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { errorToast } from '@/utilities/toast.utilities';
import { addTrendItem } from '@/trendData/trend.actions';
import { addBackingTable } from '@/tableBuilder/tableBuilder.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { setView } from '@/worksheet/worksheet.actions';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';

export function setSubscriptionId(id: string | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_SUBSCRIBER_ID', { subscriberId: id });
}

export function setShouldSearchForItems(shouldSearchForItems: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_SHOULD_SEARCH_FOR_ITEMS', { shouldSearchForItems });
}

export function setName(name: string) {
  flux.dispatch('TABLE_DEFINITION_SET_NAME', { name });
}

export function setSearchTypeAI(searchTypeAI: SearchTypeEnum | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_SEARCH_TYPE_AI', { searchTypeAI });
}

export function setDescription(description: string) {
  flux.dispatch('TABLE_DEFINITION_SET_DESCRIPTION', { description });
}

export function setBatchAction(batchAction: BatchActionEnum) {
  flux.dispatch('TABLE_DEFINITION_SET_BATCH_ACTION', { batchAction });
}

export function setTableDefinition(output: TableDefinitionOutputV1) {
  flux.dispatch('TABLE_DEFINITION_SET_TABLE_DEFINITION', { tableDefinition: output });
}

export function resetTableDefinition() {
  flux.dispatch('TABLE_DEFINITION_RESET');
}

export function setMaterializedTable(materializedTable: MaterializedTableOutput | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_MATERIALIZED_TABLE', { materializedTable });
}

export function setDoTableReload(doTableReload: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_DO_TABLE_RELOAD', { doTableReload });
}

export function setDisplayPropertyForColumn(uuidColumnToPropertyNamePair: MaterializedTableItemColumnPropertyPair) {
  flux.dispatch('TABLE_DEFINITION_SET_PROPERTY_NAME_FOR_UUID_COLUMN', { uuidColumnToPropertyNamePair });
}

export function addPendingTableId(pendingTableId: string) {
  flux.dispatch('TABLE_DEFINITION_ADD_PENDING_TABLE_ID', { pendingTableId });
}

export function removePendingTableId(pendingTableId: string) {
  flux.dispatch('TABLE_DEFINITION_REMOVE_PENDING_TABLE_ID', { pendingTableId });
}

export function setEvaluationResults(evaluationResults: { [key: string]: RowRulesResultV1[] }) {
  flux.dispatch('TABLE_DEFINITION_SET_EVALUATION_RESULTS', { evaluationResults });
}

export function setShowEvaluationDetailModal(show: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_SHOW_EVALUATION_DETAIL_MODAL', { show });
}

export function setEvaluationResultsToView(evaluationResults: RulesResultV1[]) {
  flux.dispatch('TABLE_DEFINITION_SET_EVALUATION_RESULTS_TO_VIEW', { evaluationResults });
}

export function setEvaluationDetailColumn(column: ScalingTableColumnDefinition | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_EVALUATION_DETAIL_COLUMN', { column });
}

export function setCreatedItemFinderId(id: string | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_CREATED_ITEM_FINDER_ID', { createdItemFinderId: id });
}

export function setAllowHiddenColumnsAsInputs(allowHiddenColumnsAsInputs: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_ALLOW_HIDDEN_COLUMNS_AS_INPUTS', { allowHiddenColumnsAsInputs });
}

export function setAgGridApi(agGridApi: GridApi) {
  flux.dispatch('TABLE_DEFINITION_SET_AG_GRID_API', { agGridApi });
}

export function setSelectedColumns(selectedColumns: string[]) {
  flux.dispatch('TABLE_DEFINITION_SET_SELECTED_COLUMNS', { selectedColumns });
}

export function setRowDataIsLoading(rowDataIsLoading: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_ROW_DATA_IS_LOADING', { rowDataIsLoading });
}

export function setRequestProgress(payload: {
  id: string;
  progress: Record<string, unknown>;
  timingInformation?: Record<string, unknown>;
  meterInformation?: Record<string, unknown>;
}) {
  flux.dispatch('REQUEST_SET_PROGRESS', payload);
}

/**
 * Converts the selected columns on the scaling table to a simple table, creating a backing table if not
 * present, and adding the columns to the trend.  Also focuses the trend tab and set the table worksheet view. Sets
 * indicators for isFormulaAcrossTableProcessing and isFormulaAcrossTable to true.
 */
export const toSimpleTable = async () => {
  const selectedColumns = sqTableDefinitionStore.tableDefinitionOutput.columnDefinitions.filter((col) =>
    sqTableDefinitionStore.selectedColumns.includes(col.id),
  );

  if (selectedColumns.some((col) => col.columnType !== ColumnTypeEnum.UUID)) {
    errorToast({ messageKey: 'SCALING.SIMPLE_TABLE_COLUMN_TYPE_ERROR' });
    return;
  }

  flux.dispatch('TABLE_BUILDER_SET_IS_FORMULA_ACROSS_TABLE_PROCESSING', { isFormulaAcrossTableProcessing: true });
  flux.dispatch('TABLE_BUILDER_SET_IS_FORMULA_ACROSS_TABLE', { isFormulaAcrossTable: true });
  flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column: { key: 'asset' } });
  setView(WORKSHEET_VIEW.TABLE);
  flux.dispatch('LAYOUT_SET_AUTO_FOCUS_TREND_TAB', { autofocus: true });

  await addBackingTable(
    selectedColumns.map((col) => col.id),
    sqTableDefinitionStore.id,
  );

  flux.dispatch('TREND_SET_SCALING_COLUMNS_UPDATED', false);
  selectedColumns.forEach((col) => {
    const columnData = sqTableDefinitionStore.getColumnFromProcessedMaterializedTable(col.columnName);
    const firstItem = columnData.find((item) => !!item.uuid);
    const props = {
      sampleItemId: firstItem?.uuid,
      sampleItemName: firstItem?.fetchedProperties?.[SeeqNames.Properties.Name],
      tableId: sqTableDefinitionStore.id,
      tableName: sqTableDefinitionStore.name,
    };
    const scalingColumn: any = {
      id: col.id,
      name: sqTableDefinitionStore.getColumnDisplayName(col.columnName),
      itemType: ITEM_TYPES.SCALING_COLUMN,
    };
    addTrendItem(scalingColumn, props);
  });
  flux.dispatch('TREND_SET_SCALING_COLUMNS_UPDATED', true);
};
