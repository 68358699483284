import React, { useEffect, useRef, useState } from 'react';
import { ProgressBar } from '@seeqdev/qomponents';
import { sqAxiosStore, sqWorkbookStore } from '@/core/core.stores';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { useLocation } from 'react-router-dom';

const LOADING_BAR_BY_THEME = {
  brand: '#2a5c84',
  analysis: '#00a059',
  topic: '#00acdb',
  vantage: '#a35fec',
};

interface LoadingBarProps {
  top?: boolean;
}
/**
 * This component encapsulates the "loading indicator" that serves as the "border" that displays the color of the
 * product theme. It also includes a spinner that spins while the page is loading.
 */
export const LoadingBar: React.FunctionComponent<LoadingBarProps> = ({ top = false }) => {
  const { pendingRequests } = useFlux(sqAxiosStore);
  const { isReportBinder, isVantage, isAnalysis } = useFlux(sqWorkbookStore);
  const progress = useRef(100);
  const newProgress = isVantage ? 100 : 100 / (1 + pendingRequests);
  const location = useLocation();

  if (progress.current === 100 || progress.current < newProgress) {
    progress.current = newProgress;
  }

  const getColor = () => {
    if (top) {
      return progress.current > 99 ? 'transparent' : LOADING_BAR_BY_THEME.brand;
    } else if (!(location.pathname.includes('/worksheet') || location.pathname.includes('/view'))) {
      // we need to check the current location so we can ensure the bar shows the correct color when a new workbook.
      // is created
      return LOADING_BAR_BY_THEME.brand;
    } else if (isVantage) {
      return LOADING_BAR_BY_THEME.vantage;
    } else if (isAnalysis) {
      return LOADING_BAR_BY_THEME.analysis;
    } else if (isReportBinder) {
      return LOADING_BAR_BY_THEME.topic;
    } else {
      return LOADING_BAR_BY_THEME.brand;
    }
  };

  useEffect(() => {
    setBarColor(getColor());
  }, [isVantage, isAnalysis, isReportBinder, location]);

  const [barColor, setBarColor] = useState(getColor());

  return (
    <>
      <ProgressBar
        containerExtraClasses={top ? 'topLoadingBar' : 'loadingBar'}
        values={[{ value: progress.current, color: barColor }]}
      />
    </>
  );
};
