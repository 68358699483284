import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon, InputGroup } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { FolderExplorerModal } from '@/explorer/FolderExplorerModal.organism';
import { HOME_SCREEN_TABS } from '@/main/app.constants';
import { sqItemsApi } from '@/sdk';

export interface SelectFolderProps extends ValidatingFormComponent<any> {
  component: 'SelectFolderFormComponent';
  helpTextKey?: string;
  customErrorText?: string;
  title: string;
  notSelectedMessage: string;
  onChange: (value: any) => void;
  onDelete?: () => void;
}

export const SelectFolderFormComponent: React.FunctionComponent<SelectFolderProps> = (props) => {
  const {
    name,
    value,
    testId = 'selectFolder',
    validation,
    extendValidation,
    onChange,
    customErrorText,
    title,
    notSelectedMessage,
    required = true,
    onDelete,
  } = props;

  const { t } = useTranslation();

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;

  const [isSelectingItem, setIsSelectingItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value);
  const [formattedItem, setFormattedItem] = useState<any>();

  useEffect(() => {
    onChange(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    const formatValue = async () => {
      const itemOrValue = value && !value?.id ? value : selectedItem?.id;
      if (itemOrValue) {
        const result = await sqItemsApi.getItemAndAllProperties({ id: itemOrValue });
        setFormattedItem(result.data);
      }
    };
    if (value || selectedItem) {
      formatValue();
    }
  }, [value, selectedItem]);

  const defaultValidation = (value: any) => required && !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const clearAndClose = () => {
    setIsSelectingItem(false);
  };

  const folderIcon = () => {
    return (
      <Icon
        icon="fc-folder"
        testId={`${testId}-icon`}
        type="inherit"
        extraClassNames="sq-fairly-dark-gray pl5 pr10"
        large={true}
      />
    );
  };

  const noSelectedItemResult = () => {
    return (
      <div className="sq-fairly-dark-gray text-italic" data-testid={`${testId}-notSelected`}>
        <span>{t(notSelectedMessage)}</span>
      </div>
    );
  };

  const formatItemName = (item: any) => {
    if (item?.id) {
      return item.name;
    }
    return formattedItem?.name;
  };

  const searchResult = (item: any) => (
    <div className="flexColumnContainer flexSpaceBetween flexFill ptb2">
      {(item && (
        <>
          <div className="flexColumnContainer flexCenter">{folderIcon()}</div>
          <div className="flexFill" data-testid={`${testId}-selected`}>
            <div className="searchResultName">
              <span className="simple-word-break">{formatItemName(item)}</span>
            </div>
          </div>
        </>
      )) ||
        noSelectedItemResult()}
    </div>
  );

  return (
    <>
      <FormFieldWrapper
        wrapperClassNames="flexFill"
        testId={testId}
        showError={showError}
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const formFieldProps = getFormFieldProps(formState, input, meta, props);
            return (
              <InputGroup
                field={
                  <div
                    className={classNames(
                      'width-maximum ptb0 flexColumnContainer flexAlignCenter cursorPointer',
                      formFieldProps.extraClassNames,
                    )}
                    onClick={() => setIsSelectingItem(true)}
                    data-testid={`${testId}-value`}>
                    {searchResult(selectedItem)}
                  </div>
                }
                append={[
                  onDelete && selectedItem
                    ? {
                        variant: 'button',
                        buttonProps: {
                          icon: 'fa-trash',
                          iconStyle: 'theme',
                          testId: `${testId}-delete`,
                          onClick: () => {
                            onDelete();
                            setSelectedItem(undefined);
                            setFormattedItem(undefined);
                          },
                        },
                      }
                    : undefined,
                  {
                    variant: 'button',
                    buttonProps: {
                      icon: 'fa-pencil',
                      iconStyle: 'theme',
                      testId: `${testId}-edit`,
                      onClick: () => {
                        setIsSelectingItem(true);
                      },
                    },
                  },
                ]}
              />
            );
          }}
        </Field>
      </FormFieldWrapper>
      {isSelectingItem && (
        <FolderExplorerModal
          onClose={clearAndClose}
          item={formattedItem}
          currentTab={HOME_SCREEN_TABS.MY_FOLDER}
          onSelect={setSelectedItem}
          onSave={clearAndClose}
          title={title}
          submitLabel="SAVE"
          inFormComponent={true}
        />
      )}
    </>
  );
};
