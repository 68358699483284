import { sqWorkbooksApi } from '@/sdk/api/WorkbooksApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { instructions } from '@/impactReports/impactReport.types';
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { t } from 'i18next';
import { generateRequestId } from '@/utilities/http.utilities';
import { useRef } from 'react';

interface AIResult {
  getImpactReportText: (workbookId: string, worksheetId: string, customPrompt?: string) => Promise<string>;
  abortImpactReportTextQuery: () => void;
}
export const useAI = (): AIResult => {
  const controllerRef = useRef<AbortController>();

  /**
   * This function requests an AI generated description of the worksheet specified by the workbook and worksheetId
   * provided. This is used to populate the description for Impact Reports.
   */
  const getImpactReportText = async (workbookId: string, worksheetId: string, customPrompt?: string) => {
    const originURL = `/view/worksheet/${workbookId}/${worksheetId}`;
    const workbookResponse = await sqWorkbooksApi.getWorkbook({ id: workbookId });
    const worksheetResponse = await sqWorkbooksApi.getWorksheet({ workbookId, worksheetId });
    const originLabel = encodeURIComponent(`${workbookResponse.data?.name} - ${worksheetResponse.data?.name}`);

    controllerRef.current = new AbortController();
    const getPromptType = () => {
      // pick the right agent based on the type of the worksheet:
      switch (workbookResponse.data?.type) {
        case SeeqNames.Types.Topic:
          return 'impact_report_organizer';
        case SeeqNames.Types.Vantage:
          return 'impact_report_vantage';
        default:
          return 'impact_report_workbench';
      }
    };

    const { signal } = controllerRef.current;
    const getPrompt = () => {
      if (customPrompt) {
        return customPrompt;
      }
      let prompt;
      switch (workbookResponse.data?.type) {
        case SeeqNames.Types.Topic:
          prompt = t('IMPACT_REPORT.FILL_WITH_AI_PREFIX_PROMPT_ORGANIZER');
          break;
        case SeeqNames.Types.Vantage:
          prompt = '';
          break;
        default:
          prompt = t('IMPACT_REPORT.FILL_WITH_AI_PREFIX_PROMPT_ANALYSIS');
      }
      return prompt + instructions;
    };

    const body = {
      prompt: getPrompt(),
      promptType: getPromptType(),
    };

    const headers = {
      'content-type': APPSERVER_API_CONTENT_TYPE,
      [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
      [SeeqNames.API.Headers.RequestOrigin]: 'workbench',
      [SeeqNames.API.Headers.RequestOriginURL]: originURL,
      [SeeqNames.API.Headers.RequestOriginLabel]: originLabel,
      [SeeqNames.API.Headers.RequestId]: generateRequestId(),
    };
    try {
      const response = await fetch(`/genai/llm/magiq`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
        signal,
      });
      return await response.json();
    } catch (ex) {
      console.log(ex);
    }
  };

  const abortImpactReportTextQuery = () => {
    controllerRef.current?.abort();
  };

  return { getImpactReportText, abortImpactReportTextQuery };
};
