import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { generateRequestId, getOriginLabel, getOriginUrl } from '@/utilities/http.utilities';
import {
  Agent,
  agents,
  AssistantOrigin,
  getDataLabAiAgents,
  GroupedHistoryEntryType,
  HistoryEntryType,
  MarkdownRenderingLocation,
} from './aiAssistant.types';
import { isDataLabAiAssistant } from '@/main/routing.utilities';
import { aiAssistantEnabled, genAIAgents, genAIEnabled } from '@/services/systemConfiguration.utilities';
import { isSystemTest } from '@/core/utilities';
import { isMobileDevice } from '@/utilities/utilities';
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import {
  endOfMonth,
  endOfWeek,
  isThisMonth,
  isThisWeek,
  isToday,
  isWithinInterval,
  isYesterday,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import type { WorkstepsStore } from '@/worksteps/worksteps.store';
import { WORKSTEP_SCHEMA_VERSION } from '@/worksteps/worksteps.constant';
import type { DurationStore } from '@/trendData/duration.store';

export function headers(origin?: MarkdownRenderingLocation | AssistantOrigin) {
  return {
    [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
    [SeeqNames.API.Headers.RequestOrigin]: origin || 'chat',
    [SeeqNames.API.Headers.RequestOriginURL]: getOriginUrl(),
    [SeeqNames.API.Headers.RequestOriginLabel]: getOriginLabel(),
    [SeeqNames.API.Headers.RequestId]: generateRequestId(),
  };
}

/**
 * Gets the Chats for the current user.
 *
 * @returns  A chronologically ordered dictionary of chat conversations
 */
export async function getChats(): Promise<Record<string, HistoryEntryType>> {
  const response = await fetch('/genai/chats', { method: 'GET', headers: headers() });
  return await response.json();
}

export async function deleteChat(chatId: string): Promise<void> {
  await fetch(`/genai/chats?chat_id=${chatId}`, { method: 'DELETE', headers: headers() });
}

export function getFriendlyMarkup(id: string) {
  const element = document.getElementById(id);
  if (!element) return '';

  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  if (!selection) return '';

  selection.removeAllRanges();
  selection.addRange(range);
  const text = selection.toString();
  selection.removeAllRanges();
  return text;
}

export function copyToClipboard(
  dataId: string,
  onCopyFinish = () => {},
  { clearSelection } = { clearSelection: true },
) {
  const element = document.querySelector(`[data-id="${dataId}"]`);
  if (!element) return;

  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  if (!selection) return;

  selection.removeAllRanges();
  selection.addRange(range);
  const success = document.execCommand('copy');

  if (clearSelection) selection.removeAllRanges();
  if (success) onCopyFinish();
}

export function getAgents(): Agent[] {
  if (isDataLabAiAssistant()) {
    const availableAgents = getDataLabAiAgents();
    return agents.filter((agent) => availableAgents?.includes(agent.key));
  }
  const availableAgents = genAIAgents()?.toLowerCase();
  return agents.filter((agent) => agent.key !== 'code' && availableAgents?.includes(agent.key));
}

export function getDefaultDisplayed(): boolean {
  return !isSystemTest() && !isMobileDevice() && aiAssistantEnabled() && genAIEnabled();
}

export const updateChat = async (chatId: string, { favorite, name }: { favorite?: boolean; name?: string }) => {
  return await fetch(`/genai/chats/${chatId}`, {
    method: 'PUT',
    headers: { ...headers(), 'content-type': APPSERVER_API_CONTENT_TYPE },
    body: JSON.stringify({
      favorite: favorite === undefined ? null : favorite,
      name: name === undefined ? null : name,
    }),
  });
};

export const groupChats = (chats: Record<string, HistoryEntryType>): GroupedHistoryEntryType => {
  const today = new Date();
  const groupedChats: GroupedHistoryEntryType = {
    favorites: [],
    shared_with_me: [],
    today: [],
    yesterday: [],
    this_week: [],
    last_week: [],
    this_month: [],
    last_month: [],
    older: [],
  };

  const determineCategory = (
    updatedAt: string | undefined | null,
    favorite: boolean,
    shared: boolean,
  ): keyof GroupedHistoryEntryType => {
    if (favorite) return 'favorites';
    if (shared) return 'shared_with_me';
    if (!updatedAt) return 'older';

    const updatedAtDate = new Date(updatedAt);
    if (isNaN(updatedAtDate.getTime())) return 'older';

    if (isToday(updatedAtDate)) return 'today';
    if (isYesterday(updatedAtDate)) return 'yesterday';
    if (isThisWeek(updatedAtDate, { weekStartsOn: 1 })) return 'this_week';

    const lastWeekStart = startOfWeek(subDays(today, 7), { weekStartsOn: 1 });
    const lastWeekEnd = endOfWeek(subDays(today, 7), { weekStartsOn: 1 });
    if (isWithinInterval(updatedAtDate, { start: lastWeekStart, end: lastWeekEnd })) return 'last_week';

    if (isThisMonth(updatedAtDate)) return 'this_month';

    const lastMonthStart = startOfMonth(subDays(today, 30));
    const lastMonthEnd = endOfMonth(subDays(today, 30));
    if (isWithinInterval(updatedAtDate, { start: lastMonthStart, end: lastMonthEnd })) return 'last_month';

    return 'older';
  };

  Object.entries(chats).forEach(([chatId, chatData]) => {
    const category = determineCategory(chatData.updatedAt, chatData.favorite, !!chatData.sharedBy);

    groupedChats[category].push({
      ...chatData,
      displayText: chatData.name || chatData.messages[0]?.dialog?.substring(0, 100) || 'No message available',
      chatId,
    });
  });

  return groupedChats;
};

export const getContext = (
  sqWorkstepsStore: WorkstepsStore,
  sqDurationStore: DurationStore,
  origin?: AssistantOrigin,
) => {
  if (sqWorkstepsStore.current.id) {
    // We have a workstep, so we can provide context
    const context: Record<string, unknown> = { ...sqWorkstepsStore.current, version: WORKSTEP_SCHEMA_VERSION };
    if (origin === 'vantage') {
      const { start, end } = sqDurationStore.displayRange;
      const [startIso, endIso] = [start.toISOString(true), end.toISOString(true)];
      context['timeRange'] = `${startIso} - ${endIso}`;
    }
    return context;
  }

  return undefined;
};
