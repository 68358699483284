// @ts-strict-ignore
import badWords from 'badwords-list';
import { PermissionsV1 } from '@/sdk/model/PermissionsV1';
import hash from 'object-hash';
import {
  API_TYPES,
  CREATED_BY_SEEQ_WORKBENCH,
  GUID_REGEX_PATTERN,
  HOME_SCREEN_TABS,
  ITEM_ICONS,
  ITEM_METADATA,
  NUMBER_CONVERSIONS,
  STRING_UOM,
  TRENDABLE_TYPES,
} from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import { API_TYPES_TO_ITEM_TYPES, BAR_CHART_LINE_WIDTHS, ITEM_TYPES, ItemDiff } from '@/trendData/trendData.constants';
import { logError } from '@/utilities/logger';
import i18next from 'i18next';
import { sqDurationStore, sqPluginStore, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { DEPRECATED_TOOL_NAMES, TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { headlessCaptureMetadata } from '@/utilities/screenshot.utilities';
import { getPendingRequestCount } from '@/requests/axios.utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { isCanceled } from '@/utilities/http.utilities';
import { formatMessage } from '@/utilities/logger.utilities';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { SVG_PATH } from '@/worksheet/worksheet.constants';
import { isAllContentFinishedLoading } from '@/annotation/contentSelector.utilities';
import { isInWorkbookRoute, isWorksheet } from '@/main/routing.utilities';
import { SyntheticEvent } from 'react';
import { DEBOUNCE } from '@/core/core.constants';
import { HeadlessJobFormat } from '@/services/headlessCapture.constants';
import { Signal } from '@/utilities/items.types';

export const LETTERS = _.map(
  _.range('a'.charCodeAt(0), 'z'.charCodeAt(0) + 1),
  _.ary(String.fromCharCode, 1),
) as string[];

export function exportedBase64guid() {
  const cryptoObj = window.crypto;
  const array = new Uint8Array(16); // 128 bits of randomness
  cryptoObj.getRandomValues(array);
  return (
    btoa(String.fromCharCode.apply(null, array))
      // Remove url unsafe characters and padding
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
  );
}

/**
 * Converts a file to the base64 representation
 * @param file The file to convert
 * @see https://stackoverflow.com/a/52311051
 */
export function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Finds the number in the array that is the closest to the provided target number.
 *
 * http://stackoverflow.com/questions/4811536/find-the-number-in-an-array-that-is-closest-to-a-given-number
 *
 * @param {Number[]} array  - Array of Numbers
 * @param  {Number} target - Number to find the closest array value for.
 * @returns {Number} the value in the array closest to the provided target value.
 */
export function getClosest(array: number[], target: number) {
  const tuples = _.map(array, function (val) {
    return [val, Math.abs(val - target)];
  });

  return _.reduce(
    tuples,
    function (memo, val) {
      return memo[1] < val[1] ? memo : val;
    },
    [-1, 999],
  )[0];
}

/**
 * Determines if a point is contained within a rectangle
 *
 * @param {Number} x - The x-coordinate of the point
 * @param {Number} y - The y-coordinate of the point
 * @param {Number} left - The coordinate of the rectangle's left side
 * @param {Number} top - The coordinate of the rectangle's top
 * @param {Number} right - The coordinate of the rectangle's right side
 * @param {Number} bottom - The coordinate of the rectangle's bottom
 * @return {Boolean} Returns true if the point is in the rectangle; false otherwise.
 */
export function pointInRectangle(x, y, left, top, right, bottom) {
  const x1 = Math.min(left, right);
  const x2 = Math.max(left, right);
  const y1 = Math.min(top, bottom);
  const y2 = Math.max(top, bottom);
  if (x1 <= x && x <= x2 && y1 <= y && y <= y2) {
    return true;
  } else {
    return false;
  }
}

/**
 * Determines if an item is an asset
 *
 * @param {Object} item - the item to check
 * @returns {Boolean} true if the item is an asset
 */
export function isAsset(item) {
  return _.get(item, 'type') === API_TYPES.ASSET;
}

export function isTableDefinition(item) {
  return _.get(item, 'type') === API_TYPES.TABLE_DEFINITION;
}

/**
 * Determines if an item is an asset group
 *
 * @param {Object} item - the item to check
 * @returns {Boolean} true if the item is an asset group
 */
export function isAssetGroup(item) {
  return (
    (_.find(item?.properties, { name: SeeqNames.Properties.TreeType }) as any)?.value === CREATED_BY_SEEQ_WORKBENCH
  );
}

/**
 * Determines if an item is a datafile
 *
 * @param {Object} item - the item to check
 * @returns {Boolean} true if the item is a datafile
 */
export function isDatafile(item) {
  return item?.type === API_TYPES.DATAFILE;
}

/**
 * Determines if an item is a display
 *
 * @param {Object} item - the item to check
 * @returns {Boolean} true if the item is a display
 */
export function isDisplay(item) {
  return item?.type === API_TYPES.DISPLAY;
}

/**
 * Determines if the supplied item is trendable (e.g. displayable in the Trend) in the user interface
 *
 * @param {object} item - The item to check
 * @return {Boolean} Returns true if the item is trendable; false otherwise;
 */
export function isTrendable(item) {
  return _.includes(TRENDABLE_TYPES, _.get(item, 'type'));
}

/**
 * Calculates a duration given a start and end time. If either time is invalid, returns undefined.
 *
 * @param {number} [capsuleStart] - Start time to evaluate, in milliseconds
 * @param {number} [capsuleEnd] - End time to evaluate, in milliseconds
 * @returns {number|undefined} calculated duration, in milliseconds
 */
export function getCapsuleDuration(capsuleStart?, capsuleEnd?) {
  return _.isFinite(capsuleStart) && _.isFinite(capsuleEnd) ? capsuleEnd - capsuleStart : undefined;
}

/**
 * Determines if the supplied item type is one that can be created by a user with one of the tools as opposed to
 * those, like stored signals, that can only be created via the API.

 * @param {String} type - The type of item as reported via the REST API
 * @returns {boolean} true if the item is type that can be created by a user, false otherwise.
 */
export function isUserCreatedType(type) {
  return _.includes(
    [
      API_TYPES.CALCULATED_CONDITION,
      API_TYPES.CALCULATED_SIGNAL,
      API_TYPES.CALCULATED_SCALAR,
      API_TYPES.TABLE,
      API_TYPES.THRESHOLD_METRIC,
      API_TYPES.DATAFILE,
    ],
    type,
  );
}

/**
 * Determines is a signal is a string series based on the provided unit of measure. Handles both items that are
 * from a store and ones that are fetched and decorated with metadata.
 *
 * @param signal - And object representing a signal item
 * @returns true if the signal is a string series, false otherwise.
 */
export function isStringSeries(signal?: {
  signalMetadata?: { valueUnitOfMeasure?: string | undefined };
  valueUnitOfMeasure?: string | undefined;
  sourceValueUnitOfMeasure?: string | undefined;
}): signal is Signal {
  return (
    (signal as Signal)?.signalMetadata?.valueUnitOfMeasure === STRING_UOM ||
    (signal as Signal)?.valueUnitOfMeasure === STRING_UOM ||
    (signal as Signal)?.sourceValueUnitOfMeasure === STRING_UOM
  );
}

/**
 * Determines is a signal is a step signal based on the interpolation method property.
 *
 * @param {string} id - the id of the signal
 * @returns true if the signal is a step series, false otherwise.
 */
export function isStepSignal(id) {
  return sqItemsApi.getItemAndAllProperties({ id }).then(
    ({ data }) =>
      _.find(data.properties, {
        name: SeeqNames.Properties.InterpolationMethod,
      })?.value === 'Step',
  );
}

/**
 * Determines the icon used for an item.
 *
 * @param {object} item - The item being displayed.
 * @return {String} The fontawesome classes for the icon
 */
export function itemIconClass(item) {
  if (_.get(item, 'iconClass')) {
    return item.iconClass;
  }

  switch (_.get(item, 'type')) {
    case API_TYPES.STORED_SIGNAL:
    case API_TYPES.CALCULATED_SIGNAL:
      return isStringSeries(item) ? ITEM_ICONS.STRING_SERIES : ITEM_ICONS.SERIES;
    case API_TYPES.CAPSULE:
      return ITEM_ICONS.CAPSULE;
    case API_TYPES.CALCULATED_CONDITION:
    case API_TYPES.STORED_CONDITION:
      return ITEM_ICONS.CONDITION;
    case API_TYPES.CALCULATED_SCALAR:
      return ITEM_ICONS.SCALAR;
    case API_TYPES.LITERAL_SCALAR:
      return ITEM_ICONS.SCALAR;
    case API_TYPES.TABLE:
      return ITEM_ICONS.TABLE;
    case API_TYPES.TABLE_DEFINITION:
      return ITEM_ICONS.TABLE_DEFINITION;
    case API_TYPES.THRESHOLD_METRIC:
      return ITEM_ICONS.METRIC;
    case API_TYPES.DATAFILE:
      return ITEM_ICONS.DATAFILE;
    case API_TYPES.DISPLAY:
      return ITEM_ICONS.DISPLAY;
    default:
      return ITEM_ICONS.ASSET;
  }
}

/**
 * Returns the translation key for an Item's type
 *
 * @param {String} type - The item type that is returned by the API
 * @return {String} The translation key
 */
export function getTypeTranslation(type) {
  return `ITEM_TYPES.${_.snakeCase(type).toUpperCase()}`;
}

/**
 * Adds itemType property from corresponding type property if not present.
 *
 * @param {Object} item - An item from one of the stores or from an API request
 * @return {Object} The item with the itemType property set
 */
export function addItemType(item) {
  return _.has(item, 'itemType') ? item : { ...item, itemType: API_TYPES_TO_ITEM_TYPES[item?.type] || item?.type };
}

/**
 * Returns a "point" as an Array. Series-data is an array of either Arrays of [xValue, yValue] or an Object with x
 * y, and marker properties
 * This function returns both of those points as an Array.
 *
 * ****************************************************************************************************************
 * Note: this means that the marker portion of the Object will get lost! So only call this function when you don't
 * need the marker portion!!!
 ******************************************************************************************************************
 *
 * @param {Object|[]} point - if it's an object it's expected to have x and y properties, the Array is expected to
 * be [x, y]
 * @returns {[any , any]} - the x and y values of the point as an Array
 */
export function getPointAsArray(point) {
  return _.isArray(point) ? point : [_.get(point, 'x'), _.get(point, 'y')];
}

export function interpolatedResult(
  pointA,
  pointB,
  targetX: number,
  getX: (point: any) => number = () => undefined,
  getY: (point: any) => number = () => undefined,
) {
  const pointAx = getX(pointA);
  const pointAy = getY(pointA);
  const pointBx = getX(pointB);
  const pointBy = getY(pointB);

  let yValue = null;
  if (_.isFinite(pointAx) && _.isFinite(pointAy) && _.isFinite(pointBx) && _.isFinite(pointBy)) {
    yValue = pointAy + (pointBy - pointAy) * ((targetX - pointAx) / (pointBx - pointAx));
  }

  const closestPoint = targetX - pointAx < pointBx - targetX ? [pointAx, pointAy] : [pointBx, pointBy];

  return { yValue, closestPoint };
}

export function exactResult(
  point,
  getX: (point: any) => number = () => undefined,
  getY: (point: any) => number = () => undefined,
) {
  return {
    yValue: getY(point),
    closestPoint: [getX(point), getY(point)],
  };
}

/**
 * Determine a Y-value to use for a given X-value using the specified data array.
 * If the data contains a Y-value at the exact X-value, use it; otherwise, linearly interpolate a value.
 * Time Series Data is sorted so we can perform a binary search.
 *
 * @param {Object[]} seriesData - An Array containing the series data
 * @param {Number} targetX - The x-value for which to calculate a y-value
 * @param {Function} [getX] - function that determines how to get the x value from a sample in the seriesData
 * @param {Function} [getY] - function that determines how to get the y value from a sample in the seriesData
 * @return {Object} Object containing the results. Object contains three relevant properties:
 *   {Number} Object.yValue - the Y-value for the specified X-value or null if no values to interpolate are found
 *   {Number[]} Object.closestPoint - the closest point found in seriesData, or null if no valid point was found.
 *      Since this function can return an interpolated value, it can be useful to know which data point in
 *      seriesData is closest. For example, it is used to draw the point indicators on the trend when moving the
 *      mouse over the trend.
 *   {Number} Object.closestPoint[0] - X-value of the closest point in seriesData
 *   {Number} Object.closestPoint[1] - Y-value of the closest point in seriesData
 */
export function getYValue(
  seriesData: any[],
  targetX: number,
  getX?: (point: any) => number,
  getY?: (point: any) => number | null,
  interpolationMethod?: string,
) {
  getX = getX || ((point) => getPointAsArray(point)[0]);
  getY = getY || ((point) => getPointAsArray(point)[1]);

  if (seriesData.length === 1) {
    return exactResult(seriesData[0], getX, getY);
  }

  // Ensure that the given x-value is not smaller or bigger than values we have available:
  if (_.isEmpty(seriesData) || targetX < getX(seriesData[0]) || targetX > getX(seriesData[seriesData.length - 1])) {
    return {
      yValue: null,
      closestPoint: [null, null],
    };
  }

  if (seriesData.length === 2 && interpolationMethod !== 'Step') {
    // Check to see if a y-value for the given x exists
    const point = _.find(seriesData, (point) => getX(point) === targetX);

    if (point) {
      return exactResult(point, getX, getY);
    } else {
      return interpolatedResult(seriesData[0], seriesData[1], targetX, getX, getY);
    }
  }

  // Common case - lots of data use binary search
  let hi = seriesData.length;
  let lo = -1;
  while (hi - lo > 1) {
    const mid = Math.floor((lo + hi) / 2);
    const midX = getX(seriesData[mid]);
    if (midX === targetX) {
      return exactResult(seriesData[mid], getX, getY);
    } else if (midX < targetX) {
      lo = mid;
    } else {
      hi = mid;
    }
  }

  if (interpolationMethod === 'Step') {
    const yValue = getY(seriesData[lo]);
    const closestPoint = [getX(seriesData[lo]), getY(seriesData[lo])];
    return { yValue, closestPoint };
  }

  return interpolatedResult(seriesData[lo], seriesData[hi], targetX, getX, getY);
}

/**
 * Validates a GUID string.
 *
 * @param guid - The GUID to check.
 * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
 * @return true if a valid GUID, false otherwise
 */
export function validateGuid(guid: string | any): boolean {
  const re = new RegExp(`^${GUID_REGEX_PATTERN}$`);
  return _.isString(guid) && re.test(guid);
}

/**
 * Extracts all GUIDs from a string.
 *
 * @param {String} s - The string from which to extract.
 * @return {Object[]} Array of GUIDs
 */
export function extractGuids(s: string): RegExpMatchArray | string[] {
  const re = new RegExp(GUID_REGEX_PATTERN, 'g');
  return `${s}`.match(re) || [];
}

/**
 * Generates a GUID string using base64 encoding
 *
 * Note: By design, this function produces a guid in a different format from the GUIDs generated by
 * the backend. This is to avoid confusing a frontend-generated guid with a backend-generated one, and so that
 * regexes designed to find backend-generated guids will not unintentionally match frontend-generated guids.
 *
 * @example dcWyqxUedy-c5owG_HIlsA
 * @returns {string} The generated GUID (22 characters long)
 */
export function base64guid() {
  return exportedBase64guid();
}

/**
 * Generates base64 encoded hash for the current tab.
 *
 * @param {string} tab - the tab to be hashed
 * @returns {string} hash for the provided tab
 */
export function generateTabHash(tab) {
  return btoa(tab);
}

/**
 * Decodes the provided base64 encoded hash. Also validates the hash to ensure one of HOME_SCREEN_TABS is provided
 *
 * @param {string} hash - base64 encoded string
 * @returns {string} decoded hash.
 */
export function getCurrentTabFromHash(hash) {
  const decoded = atob(hash);
  return _.indexOf(_.values(HOME_SCREEN_TABS), decoded) > -1 ? decoded : HOME_SCREEN_TABS.HOME;
}

/**
 * Generates base64 encoded hash for the identifier.
 *
 * @param {string} identifier - the identifier to be hashed
 * @returns {string} hash for the provided identifier
 */
export function generateHomeScreenAddOnHash(identifier) {
  return btoa(identifier);
}

/**
 * Decodes the provided base64 encoded add-on identifier hash and returns the identifier if the add-on is present in
 * the store.
 *
 * @param {string} hash - base64 encoded string
 * @returns {string} the add-on identifier or undefined if the add-on is not in the store.
 */
export function getHomeScreenAddOnIdentifierFromHash(hash) {
  const decoded = atob(hash);
  return sqPluginStore.getPlugin(decoded)?.identifier;
}

/**
 * Computes a short variable identifier given an index number.
 *
 * @param {Number} num - The sequential number of the variable, used to avoid duplicating identifiers.
 * @param {String[]} existingParameters - An array of existing parameter names to avoid conflicts.
 * @returns {String} The variable name
 */
export function getShortIdentifier(num: number, existingParameters?: string[]): string {
  const index = num % LETTERS.length;
  const repeat = Math.floor(num / LETTERS.length) + 1;
  const result = _.repeat(LETTERS[index], repeat);
  if (existingParameters && existingParameters.includes(result)) {
    return _.repeat(LETTERS[index + 1], repeat);
  }
  return result;
}

/**
 * Test whether a word is profane, by checking a list of bad words.
 */
export function isProfane(wordToTest: string): boolean {
  wordToTest = wordToTest.toLowerCase();
  return _.some(badWords.array, (badWord) => wordToTest.includes(badWord));
}

/**
 * Computes a short variable identifier for an item.
 *
 * In contrast to getShortIdentifier, this method will generally produce a slightly longer identifier, but if
 * possible it will be related to the actual name of the item.
 *
 * @param {String} name - A name to use as "inspiration" for the identifier. For example, a name of
 *                        "Temperature" may result in an identifier of "t" or "t2".
 * @param {String[]} namesToAvoid - names that already exist in the context
 * @returns {String} The variable name, or an empty string if one could not be generated
 */
export function getMediumIdentifier(name: string, namesToAvoid: string[]): string {
  // Set a soft limit on identifier length, it might be longer due to adding characters to make it unique
  const maxLength = 4;
  let candidateName = '';

  name = name.trim().toLowerCase();
  if (name.length <= maxLength && /^[a-z\d]*$/.test(name)) {
    // Case 1: the name already works as an identifier, so use it!
    candidateName = name;
  } else {
    // Case 2: try to make an acronym.
    // Find sequences of letters or digits.
    const words = name.match(/([a-z]+)|(\d+)/g) || [];
    _.forEach(words, (word) => {
      if (candidateName.length >= maxLength) {
        // hit the length limit? stop adding chars
        return false;
      } else if (/^[a-z]+$/.test(word)) {
        // sequence of letters? take the first letter (build an acronym from each word)
        candidateName += word.charAt(0);
      } else if (/^\d+$/.test(word)) {
        // sequence of digits? use them as-is but truncate the result to max length
        candidateName = _.truncate(candidateName + word, { length: maxLength, omission: '' });
      } else {
        // else stop here
        return false;
      }
    });
  }

  if (!_.isEmpty(candidateName)) {
    // We found a useful name prefix from the item name. We'll use it and add a number if necessary to
    // distinguish from other similarly-named parameters.

    // paramName must start with underscore or letter (not a number)
    let usableParamName = /^\d+$/.test(candidateName[0]) ? `_${candidateName}` : candidateName;

    // paramName must be unique so compare it against other paramNames in use
    let numericSuffix = 2;
    let uniqueParamName = usableParamName;
    while (_.includes(namesToAvoid, uniqueParamName)) {
      uniqueParamName = usableParamName + numericSuffix++;
    }

    // Avoid profane abbreviations
    if (!isProfane(uniqueParamName)) {
      return uniqueParamName;
    }
  }

  // Case 3: We couldn't come up with a simple identifier from the item name.
  return '';
}

/**
 * Encodes formula parameters for consumption by the API
 *
 * @param {Object} parameters - Where key is variable identifier and value is GUID that it references.
 * @returns {Object[]} An array of `identifier=GUID` parameters
 */
export function encodeParameters(parameters) {
  return _.map(parameters, (v, k) => `${k}=${v}`);
}

/**
 * Compare the new and old items to determine which were added, removed, or changed.
 *
 * Usually this is used to figure out the differences between new and old values that are passed to a
 * $scope.$watch() function.
 *
 * Because the new and old items are immutable we can do simple identity checks to
 * figure out which items are different between the two arrays.
 *
 * If an item has been changed it will show up in the changeset of both new and old items. Which ones are
 * actually changed can be detected by finding those items whose id is in both the "added" and "removed"
 * changesets. Those changed items can then be subtracted from the "added" and "removed" changesets to
 * give the actual added and removed items.
 *
 * @param {Object[]} newItems - An array of immutable objects with the new changes.
 * @param {Object[]} oldItems - An array of immutable objects with previous values.
 *                         The objects in each array must all contain an 'id' field that uniquely identifies it.
 * @param {String[]} propsToCheck - An array of properties to aggregate changes by. Defaults to empty array.
 * @param {String} idProp - The property that uniquely identifies an item in both newItems and oldItems.
 *                        Defaults to 'id'.
 *
 * @return {Object} An object with addedItems, removedItems, changedItems, changes
 */
export function diffItemArrays(
  newItems: readonly any[],
  oldItems: readonly any[],
  propsToCheck: string[] = [],
  idProp = 'id',
): ItemDiff {
  const removedOrChangedItems = _.difference(oldItems, newItems);
  const addedOrChangedItems = _.difference(newItems, oldItems);
  const changedIds = _.intersection(_.map(addedOrChangedItems, idProp), _.map(removedOrChangedItems, idProp));
  const isChangedItem = _.flow(_.property(idProp), _.partial(_.includes, changedIds));
  const changedItems = _.filter(addedOrChangedItems, isChangedItem);
  const removedItems = _.reject(removedOrChangedItems, isChangedItem);
  let addedItems = _.reject(addedOrChangedItems, isChangedItem);
  const changesByProperty = _.chain(propsToCheck)
    .keyBy(_.identity)
    .mapValues((prop) =>
      _.filter(changedItems, function (item) {
        // Somewhere in the process we seem to be cloning the store items and that causes all objects to get new ids -
        // which causes the comparison with just !== to not be sufficient. So, we treat some of those properties
        // special and compare the actual properties of the object instead. We don't want to do this deep comparison
        // for every property as this could add another level of slowness. Ideally we'd find where we clone and fix
        // the problem before it comes to this.
        const objectComparisonProps = ['yAxisConfig', 'zones'];
        if (_.indexOf(objectComparisonProps, prop) > -1) {
          return !_.isEqual(item[prop], _.result(_.find(oldItems, [idProp, item[idProp]]), prop));
        } else {
          return item[prop] !== _.result(_.find(oldItems, [idProp, item[idProp]]), prop);
        }
      }),
    )
    .value();

  if (
    process.env.NODE_ENV !== 'production' &&
    (_.some(newItems, _.negate(Object.isFrozen)) || _.some(oldItems, _.negate(Object.isFrozen)))
  ) {
    throw new TypeError('Arrays must be immutable');
  }

  // A directive may not yet be instantiated when $watch is first fired which will result in
  // missing the initial setting of items to []. In that case all items are new.
  if (newItems === oldItems && !addedItems.length) {
    addedItems = newItems ? [...newItems] : [];
  }

  const changedProperties = _.chain(changesByProperty).pickBy(_.negate(_.isEmpty)).keys().value();
  const itemsAdded = !_.isEmpty(addedItems);
  const itemsRemoved = !_.isEmpty(removedItems);

  return {
    addedItems,
    removedItems,
    changedItems,
    changes: changesByProperty,
    changedProperties,
    hasAnyPropertyChanged: _.flow(_.partial(_.intersection, changedProperties), _.negate(_.isEmpty)),
    itemsAdded,
    itemsRemoved,
    itemsAddedOrRemoved: itemsAdded || itemsRemoved,
  };
}

/**
 * Determines if the platform is from Apple
 *
 * @returns {boolean} True if the browser is running on a Mac, iPod, iPhone, or iPad; false otherwise.
 */
export function isApplePlatform() {
  return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
}

/**
 * Replaces all occurrences of a given string within a string with the specified replacement.
 *
 * @param {String} input - The string that the occurrences of 'find' should be replaced in
 * @param {String} find - The string to find and replace.
 * @param {String} replace - The value that should replace param.find
 * @returns a string with all the occurrences of param.find replaced with param.replace
 */
export function replaceAll(input, find, replace) {
  return input.replace(new RegExp(_.escapeRegExp(find), 'g'), replace);
}

/**
 * Returns true if any items were added.
 *
 * @param {Object} e - Event object from Baobab
 * @param {String} collectionName - Name of the collection, e.g. 'items'
 * @return {Boolean} True if items were added, false otherwise
 */
export function collectionItemAdded(e, collectionName) {
  return (
    e &&
    e.data &&
    e.data.currentData[collectionName] &&
    (e.data.currentData[collectionName].length > e.data.previousData[collectionName].length ||
      _.some(e.data.paths, function (path: any) {
        return path.length === 1 && path[0] === collectionName;
      }))
  );
}

/**
 * Returns true if any items were removed.
 *
 * @param {Object} e - Event object from Baobab
 * @param {String} collectionName - Name of the collection, e.g. 'items'
 * @return {Boolean} True if items were removed, false otherwise
 */
export function collectionItemRemoved(e, collectionName) {
  return (
    e &&
    e.data &&
    e.data.currentData[collectionName] &&
    (e.data.previousData[collectionName].length > e.data.currentData[collectionName].length ||
      _.some(e.data.paths, function (path: any) {
        return path.length === 1 && path[0] === collectionName;
      }))
  );
}

/**
 * Returns true if any of the specified properties or their children changed by comparing previous and current
 * data and paths. It checks paths to allow for sub-properties to be checked and checks data to catch times when a
 * merge() was the cause of the data changing.
 *
 * @param {Object} e - Event object from Baobab
 * @param {String[][]} e.data.paths - Array of strings representing the elements which changed. For
 *   example, ['view'] would indicate that the view property changed.
 * @param {String[]|String} propertyNames - Names of the properties to test
 * @return {Boolean} True if property changed, otherwise false
 */
export function propertyChanged(e, propertyNames) {
  propertyNames = _.castArray(propertyNames);
  return (
    e &&
    e.data &&
    ((_.chain(e).get('data.paths', []) as any).flatten().intersection(propertyNames).value().length > 0 ||
      _.some(propertyNames, function (property: string) {
        return e.data.previousData[property] !== e.data.currentData[property];
      }))
  );
}

/**
 * Returns true if any of the specified properties of a collection changed as determined by the Baobab event object.
 * If an item was completely added or removed from the collection it treats that action as having changed the
 * property and returns true. True is also returned if there was a merge transaction on one of the items since the
 * exact keys that changed are not known in that case.
 *
 * @param {Object} e - Event object from Baobab
 * @param {String[][]} e.data.paths - Array of strings representing the elements which changed. For
 *   example, ['items', '0'] would indicate that an entire item changed, ['items', '0', 'selected'] would
 *   indicate a specific property of an item changed.
 * @param {String} collectionName - Changes must be only those that occurred in this collection.
 * @param {String[]|String} propertyNames - Names of the properties to test
 * @return {Boolean} True if property changed, otherwise false
 */
export function collectionPropertyChanged(e, collectionName: string, propertyNames) {
  propertyNames = _.isArray(propertyNames) ? propertyNames : [propertyNames];
  return (
    collectionItemAdded(e, collectionName) ||
    collectionItemRemoved(e, collectionName) ||
    _.some(_.get(e, 'data.transaction', []), function (transaction) {
      return (
        transaction.path[0] === collectionName &&
        (transaction.type === 'merge' || _.intersection(transaction.path, propertyNames).length > 0)
      );
    })
  );
}

/**
 * Call _.cloneDeep with a customizer that will not copy any properties whose key equals property.
 *
 * NOTE: The property will be omitted on all levels of the Object
 *
 * @param  {Object} obj - The array, object, etc that will be passed to _.cloneDeepWith
 * @param  {String[]} properties - The properties that should not be copied
 * @return {Object} - return copied object that may share the properties whose key equals property
 */
export function cloneDeepOmit(obj, properties) {
  return _.cloneDeepWith(obj, function (value, key) {
    // When the customizer does not return the default _.cloneDeep behavior is used
    if (_.includes(properties, key)) {
      return value;
    }
  });
}

/**
 * Returns the default bar chart bar width. By looking at the number of samples as well as the available chart
 * width a sensible default value is chosen.
 *
 * @param data {Number[]} - bar chart data
 * @param chartWidth {Number} - width of the chart in pixels
 * @returns {Number} calculated bar width in pixels
 */
export function getDefaultBarWidth(data, chartWidth) {
  if (_.isNil(chartWidth) || _.isEmpty(data)) {
    return 1;
  }

  const calculatedBarWidth = chartWidth / (data.length * 4);
  return getClosest(BAR_CHART_LINE_WIDTHS, calculatedBarWidth);
}

/**
 * Helper to validate a Number input.
 * This is needed as _.isFinite converts undefined to 0, which is a valid number,
 * but if a text field is empty, it doesn't really contain 0 ...
 *
 * @param {String} input - the candiate to validate
 * @returns {Boolean} true if the input was actually a Number, false otherwise.
 */
export function validateNumber(input) {
  return _.trim(input) !== '' && _.isFinite(_.toNumber(input));
}

/**
 * Helper to fix mouse event swallowing when dragging over an iframe
 * Works in pair with restoreIframeMouseEventBehavior
 */
export function preventIframeMouseEventSwallow() {
  document.querySelectorAll('iframe').forEach((element: HTMLElement) => {
    element.style.pointerEvents = 'none';
  });
}

/**
 * Helper to restore mouse event behavior when finishing a drag operation.
 * Works in pair with preventIframeMouseEventSwallow
 */
export function restoreIframeMouseEventBehavior() {
  document.querySelectorAll('iframe').forEach((element: HTMLElement) => {
    element.style.pointerEvents = 'auto';
  });
}

/**
 * Helper to prepare a resize via mouse dragging. It also prevents mouse event swallowing when dragging goes over an
 * iframe.
 * @param e The drag event
 * @param resizeAction The callback for mousemove
 * @param onDragStart Additional config for drag start
 * @param onDragEnd Additional config for drag end
 */
export function prepareDragResize(
  e: SyntheticEvent,
  resizeAction: (eventObject: any, ...args: any[]) => any,
  onDragStart: (...args: any[]) => any = null,
  onDragEnd: (...args: any[]) => any = null,
) {
  /**
   * Removes the event handlers that are part of the drag operation.
   */
  function endResizePanel() {
    document.removeEventListener('mousemove', resizeAction);
    document.removeEventListener('mouseup', endResizePanel);

    document.removeEventListener('touchmove', resizeAction);
    document.removeEventListener('touchend', endResizePanel);

    restoreIframeMouseEventBehavior();

    if (onDragEnd) {
      onDragEnd();
    }
  }

  if (e.nativeEvent instanceof Event) {
    // To prevent text from being selected
    e.preventDefault();
  }

  document.addEventListener('mousemove', resizeAction);
  document.addEventListener('mouseup', endResizePanel);

  document.addEventListener('touchmove', resizeAction);
  document.addEventListener('touchend', endResizePanel);
  preventIframeMouseEventSwallow();

  if (onDragStart) {
    onDragStart();
  }
}

/**
 * Parses a URL or query string and returns an object containing the result.
 * For example, 'https://test.com:443?a=foo&b=bar' results in { a: 'foo', b: 'bar' }
 *
 * @param queryString - the URL string
 * @returns an object containing the query parameters
 */
export function parseQueryString(queryString: string): Record<string, string> {
  if (_.isEmpty(queryString)) {
    return {};
  }
  // Clean off the protocol, host, and port if they're there
  let searchString = queryString;
  if (queryString.indexOf('?') > 0) {
    searchString = queryString.substring(queryString.indexOf('?'));
  }

  const params = searchString.replace('&amp;', '&').split('&');
  return _.transform(
    params,
    function (result, param: string) {
      const tokens = param.split('=');
      if (tokens.length === 2) {
        result[_.trimStart(tokens[0], '?')] = tokens[1];
      }
    },
    {},
  );
}

/**
 * A simple function for retrieving values from url arguments
 *
 * @param {string} url - the URL string
 * @param {string} urlArgument - an argument from url
 * @param {string} defaultValue - if passed default value use it instead of empty string
 * @return {string} return it, else return defaultValue
 */
export function getURIArgument(url: string, urlArgument: string, defaultValue = ''): string {
  const urlArguments = parseQueryString(url);
  return _.has(urlArguments, urlArgument) ? decodeURIComponent(urlArguments[urlArgument] as string) : defaultValue;
}

/**
 * Helper function that returns the corresponding "certain" id for a given uncertain or zero-length capsule id.
 * If a certain id is provided the return of this function will not change it.
 *
 * @param id - the uncertain or zero-length id
 * @returns the corresponding certain id.
 */
export function getCertainId(id: string): string {
  return id && id.replace(/_uncertain|_zero/gi, '');
}

/**
 * Helper function that returns the corresponding "uncertain" id for a given certain id.
 * If an uncertain id is provided the return of this function will not change it.
 *
 * @param {String} id - the certain id
 * @returns {String} the corresponding uncertain id.
 */
export function getUncertainId(id) {
  if (_.includes(id, '_uncertain_')) {
    return id;
  }

  return _.replace(id, '_', '_uncertain_');
}

/**
 * Returns a new ID that can be identified as a "temporary" ID that didn't come from the backend
 *
 * @returns {String} Generated ID that can be identified as "temporary"
 */
export function generateTemporaryId(): string {
  // @author Slavik Meltser (slavik@meltser.info).
  // @link http://slavik.meltser.info/?p=142
  function _p8(s?: boolean) {
    const p = `${Math.random().toString(16)}000000000`.substr(2, 8);
    return s ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
  }

  return `${_p8() + _p8(true) + _p8(true) + _p8()}_temp`;
}

/**
 * Parses the data property string into an object and returns it. If data property is not JSON-formatted, an empty
 * object is returned.
 *
 * @param {String} payload - the data property string
 * @returns {Object} an object containing the data properties
 */
export function parseDataProperty(payload) {
  if (_.get(payload, 'data')) {
    const jsonMessage = _.attempt(JSON.parse, payload.data);
    if (_.isError(jsonMessage)) {
      logError(`Failed to parse .data property as JSON. Ignoring value: ${payload.data}`);
      return;
    }
    return jsonMessage;
  }
}

/**
 * Calculate a name to use given a set of existing names and a prefix. Finds the largest number at the end of the
 * existing names, adds 1, and prepends the prefix.
 *
 * @param {Array} existingNames - Existing names from which to extract the numeric suffixes
 * @param {String} [prefix] - Prefix to use for the resulting name
 * @param {Boolean} [prefixMatch=false] - When true, numbers in existing names are only extracted if the name
 *   matches the specified prefix
 * @returns {String} name to use
 */
export function getNextName(existingNames, prefix?, prefixMatch?) {
  return (
    _.chain(existingNames)
      .map(function (name) {
        if (prefix && prefixMatch) {
          return name.replace(new RegExp(`^${prefix}.*?(\\d+)`, 'i'), '$1');
        } else {
          const matches = name.match(/\d+$/);
          return matches ? matches[0] : 0;
        }
      })
      .map(_.toNumber)
      .filter(_.isFinite) as any
  )
    .max()
    .add(1)
    .toString()
    .thru(function (number) {
      return prefix ? `${prefix} ${number}` : number;
    })
    .value();
}

/**
 * Formats name as 'text...text'
 *
 * @param {String} name - the name to format
 * @param {Number} maxLength - the maximum length string to display
 * @param {Number} characterCount - the number of characters to display on each side of the ellipses
 * @returns {String} formatted name (first 9 characters, ellipses, last 9 characters);
 */
export function truncate(name, maxLength, characterCount) {
  if (_.size(name) > maxLength) {
    return _.truncate(name, { length: characterCount + 3 }) + name.substring(name.length - characterCount);
  }
  return name;
}

/**
 * Formats an identity for display. For Users, formatted result includes .name and .username; for UserGroups,
 * result only includes .name
 *
 * @param {Object} identity - Identity to be formatted, either User or UserGroup.
 * @returns {string} formatted identity, either `name (username)` for Users, or `name` for UserGroups or empty
 * string if no identity is provided.
 */
export function prettyFormatIdentity(identity, options = {}): string {
  if (!identity) {
    return '';
  }
  const resolvedOptions = _.defaults(options, { multiLine: false });
  const args = resolvedOptions.multiLine ? ['<div>', '</div>'] : [' (', ')'];
  args.unshift(identity);
  return identity.type === 'User'
    ? `${identity.name}${identitySpecifier(...(args as [any, string, string]))}`
    : identity.name;
}

/**
 * Formats an identity for display on two lines. For Users, formatted result includes .name and .username; for
 * UserGroups, result only includes .name
 *
 * @param {Object} identity - Identity to be formatted, either User or UserGroup.
 * @returns {string} formatted identity, either `name (username)` for Users, or `name` for UserGroups
 */
export function multiLineFormatIdentity(identity): string {
  if (!identity) {
    throw new TypeError('identity must be an object representing a User or UserGroup');
  }
  return prettyFormatIdentity(identity, { multiLine: true });
}

/**
 * Returns an additional piece of information for the identity that will help a user
 * differentiate between two accounts with the same full name.
 *
 * @param {Object} user - the user for which to return a specifier
 * @param {string} specifierBefore - optionally prepend a string to the specifier
 * @param {string} specifierAfter - optionally append a string to the specifier
 * @returns the specifier
 */
export function identitySpecifier(user, specifierBefore = '', specifierAfter = ''): string {
  const specifier = readableIdentifier(user) || (user.datasource && user.datasource.name);
  if (specifier) {
    return `${specifierBefore}${specifier}${specifierAfter}`;
  }
  return '';
}

/**
 * Returns a human readable identifier for a user.
 *
 * @param {Object} user - the user
 * @returns a readable identifier for the user
 */
export function readableIdentifier(user): string {
  if (!user) {
    throw new TypeError('user must be an object representing a User');
  }
  return user.isUsernameReadable ? user.username : user.email;
}

/**
 * This function decorates the provided item with signalMetadata and conditionMetadata based on the item properties.
 *
 * @param {object} item - object defining an item as returned by the API
 * @returns {object} an item that has been decorated with signalMetadata and conditionMetadata based on the
 * provided properties.
 */
export function decorateItemWithProperties(item) {
  const decoratedItem = addItemType(item);

  const addComplexProperty = (metadata, property, propName) => {
    metadata[`${propName}`] = {
      uom: property.unitOfMeasure,
      value: _.toNumber(property.value),
    };
    return metadata;
  };

  const addSimpleProperty = (metadata, property, propName) => {
    metadata[`${propName}`] = property.value;
    return metadata;
  };

  if (decoratedItem.itemType !== ITEM_TYPES.CONDITION) {
    const signalMetadata = {};
    const signalMetadataProps = [
      {
        accessor: ITEM_METADATA.interpolationMethod,
        key: 'interpolationMethod',
      },
      { accessor: ITEM_METADATA.keyUnitOfMeasure, key: 'keyUnitOfMeasure' },
      { accessor: ITEM_METADATA.valueUnitOfMeasure, key: 'valueUnitOfMeasure' },
    ];

    const complexSignalMetadataProps = [{ accessor: 'Maximum Interpolation', key: 'maxInterpolation' }];

    _.forEach(item.properties, (property) => {
      const importantSignalProp = _.find(signalMetadataProps, {
        accessor: property.name,
      });
      if (importantSignalProp) {
        addSimpleProperty(signalMetadata, property, importantSignalProp.key);
      }
      const importantComplexSignalProp = _.find(complexSignalMetadataProps, {
        accessor: property.name,
      });
      if (importantComplexSignalProp) {
        addComplexProperty(signalMetadata, property, importantComplexSignalProp.key);
      }
    });

    if (_.isEmpty(signalMetadata)) {
      return _.omit(decoratedItem, 'properties');
    }

    return _.chain(decoratedItem).assign({ signalMetadata }).omit('properties').value();
  } else {
    const conditionMetadata = {};
    const conditionMetadataProps = [
      {
        accessor: SeeqNames.Properties.MaximumDuration,
        key: 'maximumDuration',
      },
      { accessor: ITEM_METADATA.maxInterpolation, key: 'maxInterpolation' },
    ];

    _.forEach(item.properties, (property) => {
      const importantConditionMetadataProp = _.find(conditionMetadataProps, {
        accessor: property.name,
      });
      if (importantConditionMetadataProp) {
        addComplexProperty(conditionMetadata, property, importantConditionMetadataProp.key);
      }
    });

    if (_.isEmpty(conditionMetadata)) {
      return _.omit(decoratedItem, 'properties');
    }

    return _.chain(decoratedItem).assign({ conditionMetadata }).omit('properties').value();
  }
}

/**
 * Returns the calculated lane width or the minimum when the calculated one is not valid in milliseconds.
 *
 * @param {Number} duration - The duration of the display range in milliseconds
 * @param {Number} numPixels - The number of pixels available to the chart
 * @returns {Number} laneWidth
 */
export function getMSPerPixelWidth(duration, numPixels) {
  const minLaneWidth = 1 / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;
  const proposedLaneWidth = duration / numPixels;
  return proposedLaneWidth > minLaneWidth ? proposedLaneWidth : minLaneWidth;
}

/**
 * Converts a permissions object to a human readable string
 *
 * @param permissions - permissions object
 * @param [defaultMessage] - default message if no permissions are set
 */
export function prettyPermissions(permissions: PermissionsV1, defaultMessage?: string) {
  return (
    _.chain(permissions)
      .map((value, key) => (value ? i18next.t(`ACCESS_CONTROL.ACCESS_LEVELS.${_.toUpper(key)}`) : undefined))
      .compact()
      .join(', ')
      .value() ||
    defaultMessage ||
    ''
  );
}

export function equalsIgnoreCase(string1: string, string2: string) {
  return _.toLower(string1) === _.toLower(string2);
}

/**
 * Finds the duplicate values in a string array (case sensitive).
 *
 * @param stringArray - array in which to look for duplicates
 * @returns array of duplicates or an empty array if no duplicate values are found
 */
export function getDuplicateStringsInArray(stringArray: string[]): string[] {
  const counts = stringArray.reduce((a, b) => {
    a[b] = (a[b] || 0) + 1;
    return a;
  }, {});
  return Object.keys(counts).filter((a) => counts[a] > 1);
}

/**
 * Checks the last element in an item name for an index value and removes the index value to get the name prefix
 *
 * @param {string} fullName - the name of the item to check the index value
 * @returns {string} the prefix of the name of the item
 */
export function getNamePrefix(fullName) {
  const match = /^(.*) \d+$/.exec(_.trim(fullName));
  return match ? match[1] : fullName;
}

export function randomInt(): number {
  return _.random(Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
}

/**
 * Helper to build an email link
 *
 * @param mailTo - the email address to mail to
 * @param contact - the contact name
 * @param subjectKey - optional email subject key
 * @param bodyKey - optional email body key
 */
export function buildEmailLink(mailTo: string, contact: string, subjectKey?: string, bodyKey?: string) {
  const email = '<a href="mailto:';
  return subjectKey && bodyKey
    ? email.concat(mailTo, '?subject=', i18next.t(subjectKey), '&body=', i18next.t(bodyKey), '">', contact, '</a>')
    : email.concat(mailTo, '">', contact, '</a>');
}

export function areDateRangesSimilar(dateRange1, dateRange2): boolean {
  return (
    !dateRange2.isArchived &&
    dateRange1.range.end - dateRange1.range.start === dateRange2.range.end - dateRange2.range.start &&
    _.isEqual(dateRange1.condition, dateRange2.condition) &&
    dateRange1.auto.enabled === dateRange2.auto.enabled
  );
}

export function areAssetSelectionsSimilar(assetSelection1, assetSelection2): boolean {
  return !assetSelection2.isArchived && assetSelection1.asset.id === assetSelection2.asset.id;
}

export function getHref(item) {
  switch (item.type) {
    case SeeqNames.Types.CalculatedSignal:
      return `/signals/${item.id}`;
    case SeeqNames.Types.CalculatedCondition:
      return `/conditions/${item.id}`;
    case SeeqNames.Types.CalculatedScalar:
      return `/scalars/${item.id}`;
    case SeeqNames.Types.ThresholdMetric:
      return `/metrics/${item.id}`;
    case SeeqNames.Types.Chart:
      return `/formulas/functions/${item.id}`;
    default:
      return `/items/${item.id}`;
  }
}

/**
 * Returns the "next" default name.
 * Default names are expected to follow the <defaultNameTranslationKey> <nextNumber> pattern.
 */
export function getNextDefaultName(existingNames: string[], defaultNameTranslationKey: string) {
  const baseName = i18next.t(defaultNameTranslationKey);
  const extractIncrementingNumber = (nameAndNumber: string) =>
    _.toNumber(nameAndNumber.substring(_.lastIndexOf(nameAndNumber, ' ') + 1)) || 0;

  const nextSuggestedNumber = _.chain(existingNames)
    .filter((name) => _.startsWith(name, baseName))
    .map(extractIncrementingNumber)
    .sortBy()
    .last()
    .value();

  const nextNumber = _.isFinite(nextSuggestedNumber) ? nextSuggestedNumber + 1 : 1;

  return `${baseName} ${nextNumber}`;
}

export function getUserTrackingData({ anonymizeTelemetry, adminEmail, username, userEmail }) {
  const guardedHash = (object) => (_.isUndefined(object) || object === '' ? '' : hash(object));
  const variableTransformer = anonymizeTelemetry ? guardedHash : _.identity;
  const [user = '', domain = ''] = _.split(adminEmail, '@');
  const trackableServerEmail = !_.isEmpty(user) || !_.isEmpty(domain) ? `${variableTransformer(user)}@${domain}` : '';
  const trackableUsername = variableTransformer(username);
  const trackableEmail = variableTransformer(userEmail);
  return {
    serverEmail: trackableServerEmail,
    userName: trackableUsername,
    userEmail: trackableEmail,
  };
}

/**
 * @deprecated
 */
export class Deferred<T> implements Promise<T> {
  private _resolveSelf;
  private _rejectSelf;
  private promise: Promise<T>;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this._resolveSelf = resolve;
      this._rejectSelf = reject;
    });
  }

  public then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): Promise<TResult1 | TResult2> {
    return this.promise.then(onfulfilled, onrejected);
  }

  public catch<TResult = never>(
    onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null,
  ): Promise<T | TResult> {
    return this.promise.catch(onrejected);
  }

  public finally<TResult = never>(): Promise<T | TResult> {
    return this.promise.finally();
  }

  public resolve(val: T) {
    this._resolveSelf(val);
  }

  public reject(reason: any) {
    this._rejectSelf(reason);
  }

  [Symbol.toStringTag]: 'Promise';
}

// TODO: CRAB-29037 randomUUID will be added in Typescript 4.6 and this should be removed once we update our dependency
declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

export function randomUUID() {
  // Safety check because of the warning about only being available in HTTPS context on some browsers according to MDN:
  // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID#browser_compatibility
  if (crypto.randomUUID) {
    return crypto.randomUUID();
  }

  // Fallback using a Vanilla JS implementation that's good enough for our use here
  let dt = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

/**
 * Evaluates whether specified start and end are valid timestamps (in milliseconds) and if they fall within the
 * current display range
 *
 * @param capsuleStart - Start time to evaluate, in milliseconds
 * @param capsuleEnd - End time to evaluate, in milliseconds
 *
 * @returns - true if the start and end is defined and the start and end falls within the current display range
 */
export function isCapsuleFullyVisible(capsuleStart?: number | null, capsuleEnd?: number | null): boolean {
  return isTimestampVisible(capsuleStart) && isTimestampVisible(capsuleEnd);
}

/**
 * Evaluates whether a specified unix timestamp is a valid timestamps (in milliseconds) and is within the current
 * display range
 *
 * @param unixTimestamp - time to evaluate, in milliseconds
 * @param displayStart - start of the display range, in milliseconds
 * @param displayEnd - end of the display range, in milliseconds
 * @returns - true if unixTimestamp is defined and within the current display range
 */
export function isTimestampVisible(
  unixTimestamp?: number | null,
  displayStart = sqDurationStore.displayRange.start.valueOf(),
  displayEnd = sqDurationStore.displayRange.end.valueOf(),
): boolean {
  return _.isFinite(unixTimestamp) && unixTimestamp >= displayStart && unixTimestamp <= displayEnd;
}

/**
 * Returns the tool type of a user-created item. Defaults to FORMULA tool if it is a user-created item that
 * does not have a valid tool type encoded (e.g. items that are created via the REST API or the Tree File datasource
 * will not initially have a UIConfig).
 *
 * @param {Object} item - An item from the REST API
 * @returns {TREND_TOOLS|undefined} One of TREND_TOOLS for user-created items; otherwise undefined
 */
export function getToolType(item: any) {
  if (isUserCreatedType(item.type)) {
    const uiConfigProp = _.find(item.properties, ['name', SeeqNames.Properties.UIConfig]) as any;
    if (item.type === API_TYPES.THRESHOLD_METRIC) {
      return TREND_TOOLS.THRESHOLD_METRIC;
    } else if (uiConfigProp) {
      const config = JSON.parse(uiConfigProp.value);

      // If the tool name is deprecated, return it so it can be converted by the configUpgrader service
      if (_.includes(DEPRECATED_TOOL_NAMES, config.type)) {
        return config.type;
      }

      return _.includes(_.values(TREND_TOOLS), config.type) ? config.type : TREND_TOOLS.FORMULA;
    } else {
      return TREND_TOOLS.FORMULA;
    }
  } else if (
    _.get(_.find(item.properties, ['name', SeeqNames.Properties.DatasourceClass]), 'value') ===
    SeeqNames.LocalDatasources.Datafile
  ) {
    return TREND_TOOLS.IMPORTDATAFILE;
  }
}

/**
 * Indicates what category of job this headless browser page is mapped to.
 *
 * @returns the category of the job for which this page is rendering. If this page is not rendering in a headless
 *   browser, then this function returns undefined
 */
export function headlessRenderCategory() {
  return headlessCaptureMetadata().category;
}

/**
 * Determine if the current route points to a workbook and the current workbook is loaded
 *
 * @return {Boolean} - true if the workbook is in presentation mode
 */
export function isInWorkbookRouteAndWorkbookLoaded(): boolean {
  return sqWorkbookStore.isWorkbookLoaded && isInWorkbookRoute();
}

/**
 * Waits for Seeq to settle, i.e. no outstanding http requests or browser timeouts, and all Topic Document content has
 * finished loading (or error).
 *
 * @return {Promise} that resolves when the app has stabilized
 */
export function waitForAppQuiescence() {
  return new Promise((resolve) => waitUntilSeeqSettled(resolve));

  function waitUntilSeeqSettled(resolve) {
    if (getPendingRequestCount() === 0 && isAllContentFinishedLoading() && isAgGridFinishedLoading) {
      setTimeout(
        () => {
          updateAgGridSizing();
          resolve();
        },
        !!getAgGridElement() && headlessCaptureMetadata().jobFormat === HeadlessJobFormat.PNG ? 100 : 1,
      );
      return;
    }
    setTimeout(() => waitUntilSeeqSettled(resolve), 200);
  }
}

/**
 * Ag-Grid can take extra time to finish setting up, which can lead to a mis-sized table in the screenshot. This should
 * only ever be set in Analysis when ag-grid has finished loading
 */
let isAgGridFinishedLoading = true;

export function startAgGridLoading() {
  isAgGridFinishedLoading = false;
}

// Debounced because it takes a bit for auto-height rows to finish resizing, even with row-animation disabled
export const finishAgGridLoading = _.debounce(() => {
  isAgGridFinishedLoading = true;
}, DEBOUNCE.MEDIUM_WITH_HEADLESS_RENDER_SUPPORT);

/**
 * Ag-Grid has a variety of resizing options which can be difficult to manage. Due to us using our own custom
 * styling on top of the table, when grouping and using the domLayout 'print' mode, the groups sometimes will get
 * smooshed together. To work around this, we use the `autoHeight` mode and suppress row virtualization, forcing all
 * of the rows to be rendered. This causes another problem wherein Ag-Grid takes up the full width of the screen
 * rather than just the table width, due to differences in styling between 'print' and 'autoHeight'. Working around
 * that inside the table builder component requires us to set and unset the width as it resizes multiple times on
 * load, which over complicates the component. Instead, we can wait until everything has settled and then update the
 * width once, right here.
 */
function updateAgGridSizing() {
  if (!sqWorkbookStore.isReportBinder) {
    const maybeAgGrid = getAgGridElement();
    if (maybeAgGrid) {
      // Headers might be disabled but there might be no rows at all so just take the minimum of all possible values
      const viewportWidth =
        (maybeAgGrid.querySelector('.ag-header-viewport') as HTMLElement)?.offsetWidth ?? Number.MAX_SAFE_INTEGER;
      const containerWidth =
        (maybeAgGrid.querySelector('.ag-header-container') as HTMLElement)?.offsetWidth ?? Number.MAX_SAFE_INTEGER;
      const rowWidth =
        (maybeAgGrid.querySelector('.ag-center-cols-container') as HTMLElement)?.offsetWidth ?? Number.MAX_SAFE_INTEGER;

      const newWidth = Math.min(viewportWidth, containerWidth, rowWidth);
      maybeAgGrid.style.width = `${newWidth}px`;
    }
  }
}

function getAgGridElement(): HTMLElement | null {
  return document.querySelector('.ag-root-wrapper');
}

/**
 * Waits for any visible display pan plugins to complete their rendering
 *
 * @return {Promise} that resolves when all visible display pane plugins have completed their rendering
 */
export function waitForPluginQuiescence() {
  return new Promise((resolve) => waitUntilPluginRenderComplete(resolve));

  function waitUntilPluginRenderComplete(resolve) {
    const keepWaiting = () => setTimeout(_.partial(waitUntilPluginRenderComplete, resolve), 200);

    if (sqPluginStore.displayPaneRenderComplete) {
      setTimeout(resolve, 1);
    } else {
      keepWaiting();
    }
  }
}

/**
 * @returns {String} the protocol, address and port of the current Workbench URL
 */
export function getWorkbenchAddress() {
  return `${window.location.protocol}//${window.location.host}`;
}

/**
 * Determines if the item is in the current scope, meaning it is either in the global scope (scopedTo is empty)
 * or it is scoped to the current workbook.
 *
 * @param {Object} item - The item to filter
 * @returns {Boolean} True if it is in the current or global scope, false otherwise
 */
export function isInScope(item) {
  return _.isEmpty(item.scopedTo) || item.scopedTo === sqWorkbenchStore.stateParams.workbookId;
}

/**
 * A wrapper around $window.location.reload() for easier testing
 */
export function reload() {
  console.log(
    '**********************************___________________________________*********************************************************',
  );
  window.location.reload();
}

/**
 * Ignore errors due to backdrop clicks or escape key presses as these are valid ways to close the modal
 *
 * @param e The event object
 */
export function handleModalOpenErrors(e) {
  if (e !== 'backdrop click' && e !== 'escape key press') {
    errorToast({ httpResponseOrError: e });
  }
}

/**
 * Format a http response object or error for display to the user. Formatted errors will not include call stacks.
 * Will return an empty string to indicate that nothing should be shown to the user.
 *
 * @param {Object} httpResponse - Response object from the HTTP call.
 * @returns {String} The formatted response
 */
export function formatApiError(httpResponse: Error): string {
  if (!_.isNil(_.get(httpResponse, 'response.data', httpResponse)) && !isCanceled(httpResponse)) {
    return formatMessage`${_.isError(httpResponse) ? httpResponse.toString() : httpResponse}`;
  }

  return '';
}

/**
 * Ensures that an async function which is invoked multiple times while the initial invocation is outstanding is
 * only run a total of two times: the initial invocation and once more when it finishes. If invoked multiple times
 * with different arguments the callback will be invoked with the most recent arguments after the first promise
 * resolves.
 *
 * @param {Function} callback - A callback that returns a promise
 * @return {Function} The callback wrapped in code that guards against multiple invocations while the promise is
 * resolving.
 */
export function debounceAsync(callback: (...args) => Promise<any>): (...args) => void {
  let isRunning = false;
  let queuedRequest = () => {};
  const debouncedCallback = (...args) => {
    if (isRunning) {
      queuedRequest = () => debouncedCallback(...args);
    } else {
      isRunning = true;
      Promise.resolve()
        .then(() => callback(...args))
        .catch((exception) => {
          logError(formatMessage`Unhandled debounceAsync error ${exception}`);
        })
        .finally(() => {
          isRunning = false;
          queuedRequest();
          queuedRequest = () => {};
        });
    }
  };

  return debouncedCallback;
}

export function isViewOnlyWorkbookMode() {
  return sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.VIEW;
}

export function isPresentationWorkbookMode() {
  return sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.PRESENT;
}

export function isEditWorkbookMode() {
  return sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT;
}

export const isAnalysisLocked = () => sqWorkbookStore.isLocked;

/**
 * Determines if an icon is SVG
 *
 * @param icon - an icon string that will either be an icon class or an SVG path definition (e.g. "fa fa-wrench" or
 * "svgpath:M 17.0181 0 ...")
 */
export function isSvgIcon(icon: string): boolean {
  return _.startsWith(icon, SVG_PATH);
}

/**
 * Retrieves the SVG path from an SVG icon
 *
 * @param icon - an SVG icon string including path definition (e.g. "svgpath:M 17.0181 0 ...")
 * @returns the SVG icon path or an empty string if the supplied icon is not SVG
 */
export function getSvgIconPath(icon: string): string {
  return isSvgIcon(icon) ? icon.substring(SVG_PATH.length) : '';
}

// used to set the Tab Title
export function getDocumentTitle(currentPath?: string, worksheetId?: string): string {
  const documentTitle = [];
  if (isWorksheet()) {
    documentTitle.push(sqWorkbookStore.getWorksheetName(worksheetId));
    documentTitle.push(sqWorkbookStore.name);
  }

  if (_.includes(currentPath, '/administration')) {
    documentTitle.push('Administration');
  }

  if (_.includes(currentPath, '/logs')) {
    documentTitle.push('View Logs');
  }

  if (_.includes(currentPath, '/license')) {
    documentTitle.push('Manage License');
  }

  if (_.includes(currentPath, '/auditTrail')) {
    documentTitle.push('Audit Trail');
  }

  if (_.includes(currentPath, '/notifications-management')) {
    documentTitle.push('Notifications Management');
  }

  if (currentPath?.includes('/vantage-management')) {
    documentTitle.push('Vantage Management');
  }

  if (_.includes(currentPath, '/notifications-history')) {
    documentTitle.push('Notifications History');
  }

  if (_.includes(currentPath, '/usage-overview')) {
    documentTitle.push('Usage');
  }

  documentTitle.push('Seeq');

  return documentTitle.join(' - ');
}
export type themeType = 'analysis' | 'report' | 'vantage' | 'topic' | 'default' | 'brand';
export function switchModeAndTheme(dark: boolean, theme: themeType): void {
  document.body.className = '';
  if (theme === 'brand') {
    document.body.classList.add(`color_topic${dark ? '_dark' : ''}`); // so we get the default blues for buttons and
    // such
  }
  document.body.classList.add(`color_${theme}`);
  dark && document.body.classList.add(`color_${theme}_dark`);
  document.body.classList.add(dark ? 'tw-dark' : 'light');
}

export function md5Hash(value: any): string {
  return hash(value, { algorithm: 'md5' });
}

export function isMobileDevice(): boolean {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  } catch (e) {
    return false;
  }
}

/**
 * Finds and returns the duplicate values in an array.
 *
 * @param arr - The array to search for duplicates.
 * @returns An array containing the duplicate values found in the input array.
 */
export function duplicates<T extends string | number | boolean | null | undefined>(arr: T[]): T[] {
  return _.xor(arr, _.xor(...arr.map((v) => [v])));
}

// TODO: CRAB-44386 replace with Array.with
/**
 * Replaces the value at the specified index in an array.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array to modify.
 * @param {number} index - The index of the value to replace.
 * @param {T} value - The new value to replace at the specified index.
 * @returns {T[]} - A new array with the value replaced at the specified index.
 */
export const replaceValueAtIndex = <T>(array: T[], index: number, value: T): T[] =>
  array.map((v, i) => (i === index ? value : v));
