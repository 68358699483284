import { PersistenceLevel, Store } from '@/core/flux.service';
import { AnyProperty } from '@/utilities.types';
import _ from 'lodash';

/**
 * This store manages the pieces of the layout data that apply WORKSHEET-level wide.
 */
export class LayoutStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqLayoutStore';

  initialize() {
    this.state = this.immutable({
      assetGroupEditorVisible: false,
      tableDefinitionEditorVisible: false,
      displayPaneAddOnCapsulesPanelEnabled: false,
      displayPaneAddOnDetailsPanelEnabled: false,
      trendVisible: true,
      autoFocusToolsTab: false,
      autoFocusDataTab: false,
      reset: false,
      resetJournalToolbar: false,
      journalTabVisible: false,
      sidePanelCollapsed: false,
      formulaInTab: false,
      formulaTabAnchorPosition: { top: 0, left: 0, height: 0, width: 0 },
      formulaToolSize: { width: 0, height: 0 },
      calculationHierarchyInTab: false,
      layout: null,
      toolsTabVisible: false,
      autoFocusTrendTab: false,
      autoFocusTableDefinitionTab: false,
    });
  }
  get toolsTabVisible(): boolean {
    return this.state.get('toolsTabVisible');
  }

  get journalTabVisible(): boolean {
    return this.state.get('journalTabVisible');
  }

  get autoFocusToolsTab(): boolean {
    return this.state.get('autoFocusToolsTab');
  }

  get autoFocusDataTab(): boolean {
    return this.state.get('autoFocusDataTab');
  }

  get reset(): boolean {
    return this.state.get('reset');
  }

  get resetJournalToolbar(): boolean {
    return this.state.get('resetJournalToolbar');
  }

  get assetGroupEditorVisible(): boolean {
    return this.state.get('assetGroupEditorVisible');
  }

  get tableDefinitionEditorVisible(): boolean {
    return this.state.get('tableDefinitionEditorVisible');
  }

  get displayPaneAddOnCapsulesPanelEnabled(): boolean {
    return this.state.get('displayPaneAddOnCapsulesPanelEnabled');
  }

  get displayPaneAddOnDetailsPanelEnabled(): boolean {
    return this.state.get('displayPaneAddOnDetailsPanelEnabled');
  }

  get trendVisible(): boolean {
    return this.state.get('trendVisible');
  }

  get calculationHierarchyInTab(): boolean {
    return this.state.get('calculationHierarchyInTab');
  }

  get sidePanelCollapsed(): boolean {
    return this.state.get('sidePanelCollapsed');
  }

  get formulaInTab(): boolean {
    return this.state.get('formulaInTab');
  }
  get formulaTabAnchorPosition(): { top: number; left: number; height: number; width: number } {
    return this.state.get('formulaTabAnchorPosition');
  }
  get formulaToolSize(): { height: number; width: number } {
    return this.state.get('formulaToolSize');
  }
  get autoFocusTrendTab(): boolean {
    return this.state.get('autoFocusTrendTab');
  }

  get autoFocusTableDefinitionTab(): boolean {
    return this.state.get('autoFocusTableDefinitionTab');
  }

  dehydrate() {
    return _.omit(this.state.serialize(), [
      'reset',
      'resetJournalToolbar',
      'journalTabVisible',
      'autoFocusToolsTab',
      'autoFocusDataTab',
    ]);
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    LAYOUT_SET_ASSET_GROUP_EDITOR_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('assetGroupEditorVisible', visible);
    },

    LAYOUT_SET_TABLE_DEFINITION_EDITOR_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('tableDefinitionEditorVisible', visible);
    },

    LAYOUT_SET_DISPLAY_PANE_ADD_ON_CAPSULES_PANEL_ENABLED: ({ enabled }: { enabled: boolean }) => {
      this.state.set('displayPaneAddOnCapsulesPanelEnabled', enabled);
    },

    LAYOUT_SET_DISPLAY_PANE_ADD_ON_DETAILS_PANEL_ENABLED: ({ enabled }: { enabled: boolean }) => {
      this.state.set('displayPaneAddOnDetailsPanelEnabled', enabled);
    },
    LAYOUT_SET_TREND_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('trendVisible', visible);
    },

    LAYOUT_SET_TOOLS_TAB_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('toolsTabVisible', visible);
    },

    LAYOUT_SET_AUTO_FOCUS_TOOLS_TAB: ({ autofocus }: { autofocus: boolean }) => {
      this.state.set('autoFocusToolsTab', autofocus);
    },
    LAYOUT_SET_AUTO_FOCUS_DATA_TAB: ({ autofocus }: { autofocus: boolean }) => {
      this.state.set('autoFocusDataTab', autofocus);
    },
    LAYOUT_RESET: ({ reset }: { reset: boolean }) => {
      this.state.set('reset', reset);
    },
    LAYOUT_RESET_JOURNAL_TOOLBAR: ({ reset }: { reset: boolean }) => {
      this.state.set('resetJournalToolbar', reset);
    },
    LAYOUT_SET_JOURNAL_TAB_VISIBLE: ({ visible }: { visible: boolean }) => {
      this.state.set('journalTabVisible', visible);
    },
    LAYOUT_SET_SIDE_PANEL_COLLAPSED: ({ collapsed }: { collapsed: boolean }) => {
      this.state.set('sidePanelCollapsed', collapsed);
    },
    LAYOUT_SET_CALCULATION_HIERARCHY_IN_TAB: ({ inTab }: { inTab: boolean }) => {
      this.state.set('calculationHierarchyInTab', inTab);
    },
    LAYOUT_SET_FORMULA_IN_TAB: ({ inTab }: { inTab: boolean }) => {
      this.state.set('formulaInTab', inTab);
    },
    LAYOUT_SET_AUTO_FOCUS_TREND_TAB: ({ autofocus }: { autofocus: boolean }) => {
      this.state.set('autoFocusTrendTab', autofocus);
    },
    LAYOUT_SET_AUTO_FOCUS_TABLE_DEFINITION_TAB: ({ autofocus }: { autofocus: boolean }) => {
      this.state.set('autoFocusTableDefinitionTab', autofocus);
    },

    LAYOUT_SET_FORMULA_TAB_ANCHOR_POSITION: ({
      top,
      left,
      height,
      width,
    }: {
      top: number;
      left: number;
      height: number;
      width: number;
    }) => {
      this.state.set('formulaTabAnchorPosition', { top, left, height, width });
    },
    LAYOUT_SET_FORMULA_TOOL_SIZE: ({ height, width }: { height: number; width: number }) => {
      this.state.set('formulaToolSize', { height, width });
    },
  };
}
