// @ts-strict-ignore
import { cancelRunningRequest } from '@/utilities/http.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import { Deferred } from '@/utilities/utilities';
import { sqRequestsApi } from '@/sdk/api/RequestsApi';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { addCsrfHeader } from '@/utilities/auth.utilities';
import { sqWorkbenchStore } from '@/core/core.stores';
import { SeeqAxiosRequestConfig } from './axios.utilities';

export const CANCELLATION_GROUP_GUID_SEPARATOR = ':';

type Canceller = {
  groups?: string[];
  deferred?: any; // it seems this should be of type Deferred<void>, but that's incompatible with how it's being used to assign to the timeout property on requestCancellation.interceptor.ts(35,7)
  controller: AbortController;
  config: SeeqAxiosRequestConfig;
};

let cancelers: Record<string, Canceller> = {};
let unsubscribe: () => void = _.noop;

/**
 * Creates a new deferred instance and adds it to the cancelers map.
 *
 * @param id - Unique request identifier for the http request
 * @param config - The request configuration object
 * @param groups - One or more groups to which the canceler belongs. For use with cancelGroup()
 * @return {Promise} Unresolved promise that can be used as the `timeout` parameter for an http request.
 */
export function addPendingRequest(id: string, config: SeeqAxiosRequestConfig, groups?: string | string[]) {
  const groupArray = typeof groups === 'string' ? [groups] : groups;
  _.defaults(config, { cancelOnServer: !!groupArray });
  return addCanceler(id, config, groupArray);
}

function addCanceler(id: string, config: SeeqAxiosRequestConfig, groups: string[]) {
  // Create an AbortController for this request and set it in the config
  const controller = new AbortController();
  config.signal = controller.signal;

  cancelers[id] = { groups, deferred: new Deferred(), controller, config };
  return cancelers[id].deferred;
}

function getCancelers() {
  return cancelers;
}

export function forEachPending(fn: (id: string, canceler: Canceller) => void) {
  _.forEach(cancelers, (canceler, id) => {
    fn(id, canceler);
  });
}

/**
 * Returns a canceler object for the specified request
 *
 * @param   id - Unique request identifier
 * @return  The deferred and groups for the request
 */
export function getCanceler(id: string): Canceller {
  return cancelers[id];
}

export function removeCanceler(id: string) {
  delete cancelers[id];
}

/**
 * Returns an array of all IDs for all outstanding requests
 *
 * @returns {String[]} of IDs for all outstanding requests
 */
export function getAllRequestIds() {
  return _.keys(cancelers);
}

/**
 * Removes the canceler identified by the specified id. Should be called when a request returns.
 * Updates the progress only to ensure we do not over-write the timing and meter Information that is still
 * required to display request details.
 *
 * @param  {String} id - Unique request identifier
 */
export function removePendingRequest(id: string) {
  updateProgress(id, { progress: undefined });
  removeCanceler(id);
}

/**
 * Resolves the canceler for the specified request. Suppresses 404 errors, which indicate that the request is no
 * longer running.
 *
 * @param  {String} id - Unique request identifier
 * @param {Boolean} [localOnly=false] - optionally cancel browser requests only
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * to the user when the cancellation call is just prior to another call to fetch the group again (e.g. signals in
 * trend actions). The additional context allows us to guard against erroneously setting the trend item data
 * status to cancelled (see sqTrendActions.catchItemDataFailure() for more details).
 */
export function cancel(id, localOnly = false, refetching = false) {
  const canceler = getCanceler(id);
  if (canceler) {
    canceler.config.refetching = refetching;
    canceler.deferred.resolve();
    removeCanceler(id);

    // Cancel on the server
    if (canceler.config.cancelOnServer && !localOnly) {
      const serverCancellation = cancelRunningRequest(id);
      canceler.controller.abort();
      return serverCancellation;
    }

    // Just local (browser) cancellation
    canceler.controller.abort();
  }
}

/**
 * Cancels all registered requests
 *
 * @param {Boolean} [localOnly=false] - optionally cancel browser requests only
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * @param {Function} [filter] - optional filter function to determine which requests to cancel
 * @return {Promise} Resolves when all the requests have been cancelled
 */
export function cancelAll(
  localOnly = false,
  refetching = false,
  filter: (config: SeeqAxiosRequestConfig) => boolean = undefined,
) {
  return _.chain(getCancelers())
    .pickBy((x) => !filter || filter(x.config))
    .keys()
    .map((requestId) => cancel(requestId, localOnly, refetching))
    .thru((requests) => Promise.all(requests))
    .value();
}

/**
 * Cancels all registered requests for the specified group
 *
 * @param {String} group - The group to which the request belongs
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * to the user when the cancellation call is just prior to another call to fetch the group again (e.g. signals in
 * trend actions). The additional context allows us to guard against erroneously setting the trend item data
 * status to cancelled (see sqTrendActions.catchItemDataFailure() for more details).
 * @return {Promise} Resolves when all the requests have been cancelled
 */
export function cancelGroup(group, refetching = false) {
  return _.chain(getCancelers())
    .pickBy((request) => _.includes(request.groups, group))
    .keys()
    .map((requestId) => cancel(requestId, false, refetching))
    .thru((requests) => Promise.all(requests))
    .value();
}

/**
 * Cancels all server requests from the current user
 *
 * @return {Promise} a promise that gets fulfilled when request completes
 */
export function cancelCurrentUserServerRequests() {
  cancelAll(true);
  return sqRequestsApi.cancelMyRequests();
}

/**
 * Cancels all server requests
 * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
 * @return {Promise} a promise that gets fulfilled when request completes
 */
export function cancelAllServerRequests(refetching = false) {
  cancelAll(true, refetching);
  return sqRequestsApi.cancelRequests({});
}

/**
 * Updates the progress and if requested the timing and meter information of the request in the items stores. Also
 * resets the canceler expiresAt property to be the current time plus a grace period.
 *
 * @param  {String} id - Unique request identifier
 * @param  {Object} info - Object containing properties to update
 * @param  {Number|undefined} [info.progress] - the percent progress of the request
 * @param  {String} [info.timingInformation] - String providing information displayed in the "Time" section of the
 *   Request Details Panel.
 * @param  {String} [info.meterInformation] - String providing information displayed in the "Samples Read" section
 *   of the Request Details Panel.
 */
export function updateProgress(id, info) {
  const canceler = getCanceler(id);
  if (canceler) {
    _.chain(canceler.groups)
      .flatMap((group) => _.split(group, CANCELLATION_GROUP_GUID_SEPARATOR))
      .forEach((group) => {
        flux.dispatch('REQUEST_SET_PROGRESS', _.assign({}, { id: group }, info), PUSH_IGNORE);
      })
      .value();
  }
}

export function subscribeToPendingRequests(sessionId, subscribeFn) {
  subscribe(sessionId, subscribeFn);
}

/**
 * Subscribes to request messages for this session. Also unsubscribes from the subscription to the previous
 * interactive session, if present.
 *
 * @param {String} sessionId - Interactive session ID that identifies this client connection.
 */
export function subscribe(sessionId, subscribeFn) {
  unsubscribe();
  unsubscribe = subscribeFn({
    channelId: [SeeqNames.Channels.RequestsProgress, sessionId],
    onMessage: onProgressMessage,
    useSubscriptionsApi: false, // Channel is auto-subscribed by the backend
  });
}

/**
 * Internal handler for request messages. Updates the progress of associated requests or evaluates if an
 * outstanding pending async request is missing.
 *
 * @param {Object} message - Data received from websocket
 */
export function onProgressMessage(message) {
  const outstandingRequests = getAllRequestIds();
  _.forEach(outstandingRequests, (requestId) => {
    const requestUpdate = _.find(message.requests, { requestId }) as any;
    if (requestUpdate) {
      const payload = _.pick(requestUpdate, ['timingInformation', 'meterInformation']) as any;
      if (requestUpdate.totalCount) {
        payload.progress = ((requestUpdate.completedCount / requestUpdate.totalCount) * 100).toFixed(0);
      }
      updateProgress(requestId, payload);
    }
  });
}

/**
 * Gets the total number of active cancellable requests.
 *
 * @param {String} [group] - if provided only count requests within that group
 * @returns {Number} - the total number of active cancellable requests
 */
export function count(group?) {
  return _.isNil(group)
    ? _.keys(getCancelers()).length
    : _.chain(getCancelers())
        .pickBy((request) => _.includes(request.groups, group))
        .keys()
        .thru((requestIds) => requestIds.length)
        .value();
}

export function clearCancelers() {
  cancelers = {};
}

/**
 * Helper function that cancels all the requests for a user's specific session
 *
 * @param {Object} sqWorkbenchStore - the workbench store
 */
export function cancelRequestsOnUnload() {
  const headers = new Headers(
    addCsrfHeader({
      Accept: 'application/vnd.seeq.v1+json',
    }),
  );

  const sessionId = sqWorkbenchStore.interactiveSessionId;
  // Fetch is being used here, with the keepalive flag set to true, since several browsers no longer support XHR
  // during the onbeforeunload event
  return fetch(`${APPSERVER_API_PREFIX}/requests/me/${sessionId}`, {
    method: 'DELETE',
    keepalive: true,
    headers,
  });
}
