import { CapsuleProperty } from '@/utilities/datetime.constants';

export const CAPSULE_SOURCE_ID_PROPERTY_NAME = 'Source Unique Id';
export const CAPSULE_UNIQUE_PROPERTY_NAME = 'Unique Tag';

/** A capsule that is stored in the capsule group store  */
export interface CapsuleGroupCapsule {
  /** If this capsule was copied from another condition the id is the unique id of the source capsule*/
  id?: string;
  /** Beginning datetime for the capsule in ms */
  startTime: number;
  /** End datetime for the capsule in ms */
  endTime: number;
  /** An array of properties, only present if the properties are already known */
  properties?: CapsuleProperty[];

  /**
   * Information about the source capsule which is used to request the properties of the capsule. This field and
   * properties are mutually exclusive
   */
  propertiesSource?: {
    startTime: number;
    endTime: number;
    id: string;
    conditionId: string;
    capsuleId: string;
    isUncertain: boolean;
  };
}
