// @ts-strict-ignore
import _ from 'lodash';
import { decorate } from '@/trend/trendViewer/itemDecorator.utilities';
import { getCapsuleDuration, getCertainId, isCapsuleFullyVisible } from '@/utilities/utilities';
import {
  BREAK_SIZE,
  CAPSULE_PANEL_LOOKUP_COLUMNS,
  CAPSULE_PANEL_TREND_COLUMNS,
  DEFAULT_CAPSULE_LINE_WIDTH,
  ITEM_TYPES,
  PREVIEW_ID,
  SAMPLE_OPTIONS,
  TREND_PANELS,
  TREND_VIEWS,
  TrendColorMode,
  UsedCapsulePropertyValues,
  UserCapsulePropertyColorSettings,
  ZERO_LENGTH_IDENTIFIER,
} from '@/trendData/trendData.constants';
import { warnToast } from '@/utilities/toast.utilities';
import { getColumnValueAndUOM } from '@/utilities/columnHelper.utilities';
import { nanosToMillis } from '@/datetime/dateTime.utilities';
import { getDefaultCapsuleDataLabelSettings } from '@/trend/trendDataHelper.utilities';
import {
  sqAnnotationStore,
  sqDurationStore,
  sqInvestigateStore,
  sqTrendConditionStore,
  sqTrendSeriesStore,
  sqTrendStore,
} from '@/core/core.stores';
import { getUniqueId, isCapsuleAggregated } from '@/trendData/trend.utilities';
import { InitializeMode } from '@/core/flux.service';
import { BaseItemStore } from '@/trendData/baseItem.store';
import { COMMON_PROPS } from '@/trendData/baseItem.constants';
import { computeChartZones, splitUniqueId } from '@/trendData/trendCapsule.utilities';
import { getCapsuleColumnTitle } from '@/trend/trendViewer/trendViewer.utilities';
import { AnyProperty } from '@/utilities.types';
import {
  CalculatedChartCapsule,
  CapsuleCalculatedChartItem,
  CapsuleChartItem,
  CapsuleItem,
  CapsuleToAdd,
} from '@/trendData/trendData.types';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { isStatColumn } from '@/utilities/formula.utilities';

const TEXT_HEIGHT_IN_PIXELS = 12;

/**
 * A store for containing capsules which can be displayed on the chart. A capsule is composed of a start and end
 * time
 *
 * This store is augmented with additional functionality from sqBaseItemStore.
 */
export class TrendCapsuleStore extends BaseItemStore<CapsuleItem> {
  static readonly storeName = 'sqTrendCapsuleStore';

  private totalYValue = 1;

  /**
   * Color is dependent on Condition store.
   */
  rehydrateWaitFor = ['sqTrendConditionStore'];

  initialize(initializeMode: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable(
      _.defaults(
        {
          // Avoid clearing state that is not dehydrated when the store is re-initialized. Async calls will repopulate
          // these properties as needed
          items: saveState ? this.state.get('items') : [],
          chartItems: saveState ? this.state.get('chartItems') : [],
          selectedCapsules: [],
          stitchBreaks: [],
          stitchTimes: [],
          capsulesTimings: [],
          calculatedChartItems: saveState ? this.state.get('calculatedChartItems') : [],
          previewChartItem: saveState ? this.state.get('previewChartItem') : {},
          stitchDetailsSet: false,
          trendCapsulePropertyColors: {},
          usedCapsulePropertyValues: [],
        },
        COMMON_PROPS,
      ),
    );
  }

  /**
   * Returns the capsules for use on the chart.
   */
  get chartItems(): CapsuleChartItem[] {
    return this.state.get('chartItems');
  }

  /**
   * Returns the start, end, and duration of the time periods to be displayed
   */
  get stitchTimes() {
    return this.state.get('stitchTimes');
  }

  /**
   * Returns the start, end and conditionId of capsules that should be displayed
   */
  get capsulesTimings() {
    return this.state.get('capsulesTimings');
  }

  /**
   * Returns the time periods to be excluded in the format required by Highcharts
   */
  get stitchBreaks(): { breakSize: number; from: number; to: number }[] {
    return this.state.get('stitchBreaks');
  }

  /**
   * Returns an array containing the capsule items that are selected
   */
  get selectedCapsules() {
    return this.state.get('selectedCapsules');
  }

  /**
   * Returns the Id of the condition that is being edited.
   */
  get editingId() {
    return this.state.get('editingId');
  }

  /**
   * Returns an Object describing a condition (so that it can be updated upon scrolling)
   */
  get previewCapsuleDefinition() {
    return this.state.get('previewCapsulesDefinition');
  }

  /**
   * Returns an Object describing the preview chart item.
   */
  get previewChartItem() {
    return this.state.get('previewChartItem');
  }

  /**
   * Returns a boolean stating if the stitch details have been set or not
   */
  get stitchDetailsSet() {
    return this.state.get('stitchDetailsSet');
  }

  get anySelectedTableCapsuleInDisplay(): boolean {
    return _.some(this.items, (item) => item.selected);
  }

  get hasAggregatedCapsules(): boolean {
    return _.filter(this.chartItems, (chartItem) => !!chartItem.isAggregated).length > 0;
  }

  get usedCapsulePropertyValues(): UsedCapsulePropertyValues {
    return this.state.get('usedCapsulePropertyValues');
  }

  /**
   * Determines if there are non-zero length capsules to be displayed in the display range
   *
   * @returns True if non-zero length capsules are in displayed in the display range
   */
  hasDisplayedCapsules(): boolean {
    return _.some(
      this.state.get('items'),
      (capsule) =>
        capsule.startTime < sqDurationStore.displayRange.end.valueOf() &&
        (capsule.endTime - capsule.startTime > 0 || _.isNil(capsule.endTime)),
    );
  }

  /**
   * Finds one of the chart capsules
   *
   * @param id The id of the chart item
   */
  findChartItem(id: string) {
    return _.find(this.state.get('chartItems'), { id: getCertainId(id) });
  }

  /**
   * The colors the user has to use for coloring conditions in non-capsule time trend views.
   */
  get trendCapsulePropertyColors(): UserCapsulePropertyColorSettings {
    return this.state.get('trendCapsulePropertyColors');
  }

  /**
   * Finds the capsule that encompasses the time passed in.
   *
   * @param {string} id - The id of the chartItem
   * @param {number} time - The time value that the capsule must encompass
   *
   * @returns {Object} An item suitable for selecting or unselecting. Undefined if not found.
   */
  findChartCapsule = (id: string, time: number) => {
    const ZERO_LENGTH_TOLERANCE = 5;
    const zeroLengthLookupTime = nanosToMillis(time);

    const capsule = _.find(this.state.get('chartItems', { id: getCertainId(id) }, 'capsules'), (capsule: any) => {
      if (capsule.startTime === capsule.endTime) {
        return (
          zeroLengthLookupTime >= nanosToMillis(capsule.startTime) - ZERO_LENGTH_TOLERANCE &&
          zeroLengthLookupTime <= nanosToMillis(capsule.startTime) + ZERO_LENGTH_TOLERANCE
        );
      }
      return time >= capsule.startTime && time <= capsule.endTime;
    });

    if (capsule) {
      const selected =
        this.isSelected(capsule.id) ||
        (capsule.isUncertain &&
          _.find(
            this.state.get('selectedCapsules'),
            (selectedCapsule: any) =>
              splitUniqueId(capsule.id).conditionId === splitUniqueId(selectedCapsule.id).conditionId &&
              (capsule.startTime === selectedCapsule.startTime || capsule.endTime === selectedCapsule.endTime),
          ));

      return _.assign({}, capsule, {
        itemType: ITEM_TYPES.CAPSULE,
        selected,
      });
    }
  };

  /**
   * Determines if a capsule is selected.
   *
   * @param {String} id - The id of the capsule
   *
   * @returns {Boolean} True if it is selected, false otherwise
   */
  isSelected(id: string): boolean {
    return _.some(this.state.get('selectedCapsules'), ['id', id]);
  }

  protected readonly handlers = {
    ...super.baseHandlers,
    TREND_ADD_CAPSULES: this.addCapsules,
    TREND_SET_CHART_CAPSULES: this.setChartItems,
    TREND_ADD_CHART_CAPSULES: this.addChartItems,
    TREND_RECOMPUTE_CHART_CAPSULES: this.updateChartItems,
    TREND_SET_EDITING_CAPSULE_SET_ID: this.setEditingId,
    TREND_SET_CHART_CAPSULES_PREVIEW: this.setPreviewChartItem,
    TREND_REMOVE_CHART_CAPSULES_PREVIEW: this.removePreviewChartItem,
    TREND_REMOVE_CAPSULE_SET_FROM_PREVIEW_LANE: this.removeConditionFromPreviewLane,
    TREND_DISPLAY_EMPTY_CAPSULE_PREVIEW: this.displayEmptyCapsulePreview,
    TREND_REMOVE_ITEMS: this.removeCapsules,
    TREND_SET_SELECTED_CAPSULES: this.setSelectedCapsules,
    TREND_SET_SELECTED: this.setCapsuleSelected,
    TREND_UNSELECT_ALL_CAPSULES: this.unselectAllCapsules,
    TREND_REPLACE_CAPSULE_SELECTION: this.replaceCapsuleSelection,
    TREND_SET_STITCH_BREAKS: this.setStitchBreaks,
    TREND_SET_STITCH_TIMES: this.setStitchTimes,
    TREND_SET_STITCH_DETAILS: this.setStitchDetails,
    TREND_SET_STITCH_DETAILS_SET: this.setStitchDetailsSet,
    TREND_CUSTOMIZE_CHART_CAPSULES: this.customizeChartCapsules,
    SET_TREND_CAPSULE_PROPERTY_COLORS: ({
      trendCapsulePropertyColors,
    }: {
      trendCapsulePropertyColors: UserCapsulePropertyColorSettings;
    }) => {
      this.state.set('trendCapsulePropertyColors', trendCapsulePropertyColors);
    },
    SET_USED_CAPSULE_PROPERTY_VALUES: ({ usedCapsulePropertyValues }: { usedCapsulePropertyValues: string[] }) => {
      this.state.set('usedCapsulePropertyValues', usedCapsulePropertyValues);
    },
  };

  /**
   * Adds capsules that are shown in the data table.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} capsules - Array of capsules to add
   * @param {String} capsules[].id - The id used by the backend to identify this capsule. Not guaranteed
   *   to be unique.
   * @param {String} capsules[].isChildOf - Id of the condition to which this capsule belongs
   * @param {String} capsules[].conditionName - name of the condition the capsule belongs to
   * @param {Boolean} capsules[].isReferenceCapsule - True if this is the reference capsule for a visual
   *   search
   * @param {Number} capsules[].startTime - Timestamp (ms) of the start of the capsule
   * @param {Number} capsules[].endTime - Timestamp (ms) of the end of the capsule
   * @param {Object} capsules[].statistics - key/value of user-defined statistics
   * @param numPixels - number of pixels needed to calculate stitch data
   */
  private addCapsules({ capsules, numPixels }: { capsules: CapsuleToAdd[]; numPixels: number }) {
    const uncertainCapsuleUpdates = _.chain<CapsuleItem[]>(this.state.get('items'))
      .filter('isUncertain')
      .transform((map, uncertainCapsule) => {
        map[uncertainCapsule.id] = _.chain(capsules)
          .filter(['isChildOf', uncertainCapsule.isChildOf])
          .reject(['id', splitUniqueId(uncertainCapsule.id).capsuleId])
          .find(
            (capsule) =>
              uncertainCapsule.startTime === capsule.startTime || uncertainCapsule.endTime === capsule.endTime,
          )
          .thru((capsule) => (capsule ? { ...capsule, id: getUniqueId(capsule.isChildOf, capsule.id) } : capsule))
          .value();
      }, {} as Record<string, CapsuleToAdd>)
      .omitBy(_.isUndefined)
      .value();

    const selectedCapsules = _.filter(
      this.state.get('selectedCapsules'),
      (selectedCapsule: any) => !_.isUndefined(uncertainCapsuleUpdates[selectedCapsule.id]),
    );

    const replacementCapsules = _.map(selectedCapsules, (selectedCapsule) =>
      _.pick(uncertainCapsuleUpdates[selectedCapsule.id], ['id', 'startTime', 'endTime', 'isUncertain']),
    );

    this.state.set(
      'selectedCapsules',
      _.difference(this.state.get('selectedCapsules'), selectedCapsules).concat(replacementCapsules),
    );

    sqTrendSeriesStore.syncUncertainCapsuleSeries(uncertainCapsuleUpdates);

    const items = _.map(capsules, (capsule) => {
      const id = getUniqueId(capsule.isChildOf, capsule.id);

      return this.createItem(
        id,
        undefined,
        ITEM_TYPES.CAPSULE,
        _.assign(_.omit(capsule, ['id']), {
          isUncertain: capsule.isUncertain,
          selected: this.isSelected(id),
          cursorKey: capsule.cursorKey,
          duration: getCapsuleDuration(capsule.startTime, capsule.endTime),
        }),
      );
    });
    this.state.set('items', items);

    // Chart capsule labels rely on data from the items in the table
    if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
      this.setStitchDetails({ numPixels });
    }
    if (!_.isEmpty(sqTrendStore.enabledColumns(TREND_PANELS.CHART_CAPSULES))) {
      this.updateChartItems();
    }
  }

  /**
   * THIS IS TO EXPOSE THIS STATE FOR TESTING.  IT SHOULD NOT BE USED OUTSIDE OF TESTING PURPOSES
   * @param {Boolean} isSet - the value that states if the stitch details have been set
   */
  private setStitchDetailsSet(isSet: boolean) {
    this.state.set('stitchDetailsSet', isSet);
  }

  /**
   * Sets the stitch details for a given condition
   *
   * @param {Number} numPixels - contains the number of pixels on the chart
   */
  private setStitchDetails({ numPixels }: { numPixels: number }) {
    // payload may be undefined when loading since we don't have the current pixels
    if (!numPixels) {
      this.state.set('stitchDetailsSet', false);
      return;
    }

    // do not create stitch details while capsules are loading as we'd be using old capsules.
    if (sqTrendStore.capsulePanelIsLoading) {
      this.state.set('stitchDetailsSet', false);

      return;
    }

    const selectedCapsules = _.filter(this.state.get('items'), 'selected');
    const times = [];
    const capsulesTimings = _.chain(selectedCapsules.length ? selectedCapsules : this.state.get('items'))
      .reject((capsule) => capsule.startTime === capsule.endTime && capsule.startTime !== null)
      .map((capsule: any) => {
        // bound any partially visible capsules to the display range
        let startTime = capsule.startTime;
        if (!startTime || startTime < sqDurationStore.displayRange.start.valueOf()) {
          startTime = sqDurationStore.displayRange.start.valueOf();
        }
        let endTime = capsule.endTime;
        if (!endTime || endTime > sqDurationStore.displayRange.end.valueOf()) {
          endTime = sqDurationStore.displayRange.end.valueOf();
        }

        return {
          start: startTime,
          end: endTime,
          isChildOf: capsule.isChildOf,
        };
      })
      .sortBy(['start'])
      .forEach((capsule: any) => {
        // If the start time is greater than the last entry, add the start time and the end time to the array
        if (times.length === 0 || capsule.start > times[times.length - 1]) {
          times.push(capsule.start);
          times.push(capsule.end);
        } else if (capsule.end > _.last(times)) {
          times[times.length - 1] = capsule.end;
        }
      })
      .value();

    const stitchTimes = _.transform(_.chunk(times, 2), (result, [start, end]) => {
      result.push({ start, end, duration: end - start });
    });

    if (stitchTimes.length === 0) {
      this.state.merge({
        stitchTimes: [],
        stitchBreaks: [],
        capsulesTimings: [],
        stitchDetailsSet: false,
      });

      return;
    }

    if (!_.isEqual(stitchTimes, this.state.get('stitchTimes'))) {
      this.state.set('stitchTimes', stitchTimes);
    }

    if (!_.isEqual(capsulesTimings, this.state.get('capsulesTimings'))) {
      this.state.set('capsulesTimings', capsulesTimings);
    }

    // If the times array doesn't start or end with the display range, make sure that there is a break
    if (_.head(times) !== sqDurationStore.displayRange.start.valueOf()) {
      times.unshift(sqDurationStore.displayRange.start.valueOf());
    } else {
      times.shift();
    }

    if (_.last(times) !== sqDurationStore.displayRange.end.valueOf()) {
      times.push(sqDurationStore.displayRange.end.valueOf());
    } else {
      times.pop();
    }

    const breaks = _.transform(_.chunk(times, 2), function (result, chunk) {
      result.push(_.zipObject(['from', 'to'], chunk));
    });

    if (breaks.length >= numPixels) {
      warnToast({ messageKey: 'TOO_MANY_BREAKS_CHAIN_VIEW' });
      this.state.set('stitchDetailsSet', false);

      return;
    }

    _.forEach(breaks, (thisBreak: any) => {
      thisBreak.breakSize = parseFloat(BREAK_SIZE);
    });

    if (!_.isEqual(breaks, this.state.get('stitchBreaks'))) {
      this.state.set('stitchBreaks', breaks);
    }
    const wasStitchDetailsSet = this.state.get('stitchDetailsSet');
    this.state.set('stitchDetailsSet', true);
    if (!wasStitchDetailsSet) {
      this.updateChartItems();
    }
  }

  /**
   * Updates the lane/thickness property for chartItems and calculatedChartItems.
   * Called whenever the lane/lineWidth property is set on a condition.
   */
  private customizeChartCapsules(payload: { id: string; lineWidth?: number; lane?: number; overlay?: number }) {
    const { id, lane, lineWidth, overlay } = payload;

    const hasLane = _.has(payload, 'lane');
    const hasLineWidth = _.has(payload, 'lineWidth');
    const hasOverlay = _.has(payload, 'overlay');
    _.forEach({ chartItems: 'conditionId', calculatedChartItems: 'id' }, (key, path) => {
      _.forEach(this.state.get(path), (chartItem, i) => {
        if (chartItem[key] === id || getCertainId(chartItem[key]) === id) {
          hasLane && this.state.set([path, i, 'lane'], lane);
          hasLineWidth && this.state.set([path, i, 'thickness'], lineWidth);
          hasOverlay && this.state.set([path, i, 'overlay'], overlay);
        }
      });
    });

    (hasLineWidth || hasOverlay) && this.updateChartItems();
  }

  /**
   * Updates the zones and anyCapsulesSelected properties for each chartItem, both of which are based on
   * selectedCapsules. Each set of non-overlapping capsules is its own line series in the
   * chart. Groups capsules together by condition, adding small vertical offsets if capsules from the same
   * condition overlap, and allowing larger spaces between different conditions.  Capsules are only rendered as
   * selected if the are entirely visible in the display range.
   * This function also manages the correct display of preview capsules. If a new Search is
   * performed then the resulting preview capsules are simply appended. If an existing search is edited then the
   * original capsules are omitted and replaced with the preview capsules.
   */
  private updateChartItems() {
    let allCapsules: CapsuleCalculatedChartItem[];
    let index = -1;
    let calculatedItems = this.state.get('calculatedChartItems');
    const editingId = this.state.get('editingId');
    const previewCapsuleDef = this.state.get('previewCapsulesDefinition');
    const displayPreview = !_.isEmpty(previewCapsuleDef);
    this.totalYValue = 1;

    if (!_.isNil(editingId) && editingId !== PREVIEW_ID && displayPreview) {
      calculatedItems = _.cloneDeep(this.state.get('calculatedChartItems'));
      index = _.findIndex(calculatedItems, { id: editingId });
      calculatedItems.splice(index, 1, this.state.get('previewChartItem'));
      allCapsules = calculatedItems;
    } else if (displayPreview) {
      allCapsules = calculatedItems.concat(this.state.get('previewChartItem'));
    } else {
      allCapsules = calculatedItems;
    }

    const shouldUseTableCapsules =
      sqTrendStore.view === TREND_VIEWS.CHAIN ||
      (sqTrendStore.view === TREND_VIEWS.CALENDAR && sqTrendStore.trendColorSettings.colorMode !== TrendColorMode.Item);
    const shouldUseSelectedTableCapsules = shouldUseTableCapsules && this.anySelectedTableCapsuleInDisplay;
    const shouldDisplayAllCapsules = sqTrendStore.view === TREND_VIEWS.CALENDAR;
    const stitchBreaks = this.stitchBreaks;
    const items = this.items;
    const chartCapsules = _.chain(allCapsules)
      .sortBy((condition) => sqTrendConditionStore.findItemIndex(condition.id))
      .sortBy((condition) => (condition.id === PREVIEW_ID ? 1 : 0))
      .flatMap((condition) =>
        this.prepareConditionForDisplay(
          condition,
          items,
          stitchBreaks,
          editingId,
          shouldUseTableCapsules,
          shouldUseSelectedTableCapsules,
          shouldDisplayAllCapsules,
        ),
      )
      .compact()
      .value();

    this.state.set('chartItems', chartCapsules);

    if (_.isEmpty(chartCapsules)) {
      return;
    }

    const fullyVisibleCapsules = _.chain(chartCapsules)
      .flatMap('capsules')
      .filter((capsule) => {
        return !capsule.notFullyVisible ? capsule : undefined;
      })
      .value();

    const selectedCapsules = this.state.get('selectedCapsules');
    const selectedIds = _.chain(selectedCapsules)
      .map((selectedCapsule: any) => {
        const { conditionId, capsuleId } = splitUniqueId(selectedCapsule.id);

        const isFullyVisibleCapsule =
          _.some(fullyVisibleCapsules, { id: selectedCapsule.id }) ||
          isCapsuleFullyVisible(selectedCapsule.startTime, selectedCapsule.endTime); // for in-progress capsules
        if (!isFullyVisibleCapsule) {
          return;
        }

        const possibleCapsuleMatches = _.chain(calculatedItems).filter(['id', conditionId]).flatMap('capsules').value();

        const capsule = _.find(possibleCapsuleMatches, ['id', capsuleId]);
        if (capsule) {
          return getUniqueId(conditionId, capsule.id);
        }

        const maybeCapsule = _.find(
          possibleCapsuleMatches,
          (capsule: any) =>
            selectedCapsule.startTime === nanosToMillis(capsule.start) ||
            selectedCapsule.endTime === nanosToMillis(capsule.end),
        );

        return maybeCapsule ? getUniqueId(conditionId, maybeCapsule.id) : selectedCapsule.id;
      })
      .compact()
      .value();

    // map() below is applied over a Baobab cursor object not an array, so it does not need to return
    // eslint-disable-next-line array-callback-return
    this.state.select('chartItems').map((cursor) => {
      cursor.set(
        'anyCapsulesSelected',
        _.some(selectedIds, (id) => splitUniqueId(id).conditionId === getCertainId(cursor.get('conditionId'))),
      );

      cursor.set('zones', computeChartZones(cursor.get(), selectedIds));
    });
  }

  /**
   * Prepares a condition for display on the trend.
   * This function generates a series that is used to visualize the capsule results.
   * Capsules that would otherwise overlap are assigned to new 'rows' to ensure the start and end times of each
   * capsule are properly displayed.
   *
   * To ensure the preview displays properly if no capsules are found an empty row is added to ensure things look
   * right.
   *
   * This function also manages the proper display of uncertain capsules by adding an additional "uncertain" capsule
   * set that overlaps the original condition and helps create the start/end boundaries of the outline (Note: the
   * top and bottom outline are achieved by setting the lineHeight for uncertain capsules to a smaller width than the
   * the one of the capsules.)
   *
   * In chain-view, table capsules (`items`) are used to generate the series instead of condition capsules.
   * This is done because chain-view only displays those capsules that are in the capsule table and may show
   * incorrect selections if the condition capsules are used since they can be bucketized.
   *
   * @param condition - the condition to prepare for display.
   * @param tableCapsules - the capsules that are displayed in the table
   * @param stitchBreaks - the stitch breaks used in chain view
   * @param editingId - the id of the condition that is currently being edited.
   * @param shouldUseTableCapsules - true if table capsules should be used instead of condition capsules to create
   *   chart-items
   * @param shouldUseSelectedTableCapsules - true if chart-items should be limited to selected capsules in chain view
   *
   * @returns the condition prepared for display - as a series with 'zones' to support selection.
   */
  private prepareConditionForDisplay(
    condition: CapsuleCalculatedChartItem,
    tableCapsules: CapsuleItem[],
    stitchBreaks: AnyProperty[],
    editingId: string,
    shouldUseTableCapsules: boolean,
    shouldUseSelectedTableCapsules: boolean,
    shouldDisplayAllCapsules: boolean,
  ): CapsuleChartItem[] | undefined {
    if (_.isEmpty(condition)) {
      return;
    }

    const isEditing = _.startsWith(condition.id, PREVIEW_ID) || editingId === condition.id;
    const prevEnds: Record<number, number> = {};
    const displayRangeStart = sqDurationStore.displayRange.start.valueOf();
    const displayRangeEnd = sqDurationStore.displayRange.end.valueOf();

    this.totalYValue = 1;

    const columns = _.concat(CAPSULE_PANEL_TREND_COLUMNS, _.find(CAPSULE_PANEL_LOOKUP_COLUMNS, { key: 'asset' }));
    const capsuleColumns = _.chain(columns)
      // CAPSULE panel version must be used because they have the property or statistic path
      .concat(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES))
      .concat(sqTrendStore.customColumns(TREND_PANELS.CAPSULES))
      .filter((column) => sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key))
      .value();

    const capsuleDataLabels: AnyProperty<{ label: string; titles: string[] }> = {};
    let lineWidth = Math.max(
      (condition.thickness ?? condition.lineWidth ?? 1) * DEFAULT_CAPSULE_LINE_WIDTH,
      sqAnnotationStore.annotatedItemIds?.[condition.id] ? 25 : 0,
    );

    const capsules = shouldUseTableCapsules
      ? _.chain(tableCapsules)
          .filter((capsule) => getCertainId(capsule.conditionId) === condition.id)
          .reject((capsule) => shouldUseSelectedTableCapsules && !capsule.selected && !shouldDisplayAllCapsules)
          .sortBy('endTime')
          .value()
      : condition.capsules;
    const isAggregated = _.chain(condition.capsules)
      .flatMap((capsule) => capsule.properties)
      .thru(isCapsuleAggregated)
      .value();
    const showLabels = (shouldUseSelectedTableCapsules || !isAggregated) && capsuleColumns.length > 0;

    // If data labels are enabled, compute the maximum line height so that all capsules use the same one
    if (showLabels) {
      _.chain(capsules)
        // Filter out those capsules that fall inside a break in chain view so that their labels don't leak out
        .reject<CalculatedChartCapsule | CapsuleItem>((capsule) => {
          if (shouldUseTableCapsules) {
            const capsuleStartTime = this.getCapsuleTimestampMilliseconds(capsule, 'start', shouldUseTableCapsules);

            return _.some(stitchBreaks, ({ from, to }) =>
              _.inRange(_.isNil(capsuleStartTime) ? displayRangeStart : capsuleStartTime, from, to),
            );
          }

          return false;
        })
        .forEach((capsule) => {
          const tableCapsule = shouldUseTableCapsules
            ? capsule
            : _.find(tableCapsules, ['id', getUniqueId(condition.id, capsule.id)]);
          const trendCapsuleItem = _.find(sqTrendConditionStore.items, ['id', condition.id]);
          const dataLabels = _.chain(capsuleColumns)
            // Can only get statistic values from table capsules, but that comes with the caveat that unbounded
            // or uncertain won't be found because their IDs won't match (which is also why those capsules can't be
            // selected in the capsules pane)
            .reject((column) => isStatColumn(column) && !tableCapsule)
            .map((column) => {
              const startTime = this.getCapsuleTimestampMilliseconds(capsule, 'start', shouldUseTableCapsules);
              const endTime = this.getCapsuleTimestampMilliseconds(capsule, 'end', shouldUseTableCapsules);
              const capsuleWithData = isStatColumn(column)
                ? {
                    ...tableCapsule,
                    formatOptions: column.referenceSeries
                      ? sqTrendSeriesStore.findItem(column.referenceSeries).formatOptions
                      : null,
                  }
                : {
                    itemType: ITEM_TYPES.CAPSULE,
                    startTime,
                    endTime,
                    duration: startTime && endTime ? endTime - startTime : undefined,
                    properties: this.getCapsuleProperties(capsule, shouldUseTableCapsules),
                    propertiesUOM: this.getCapsulePropertiesUOM(capsule, shouldUseTableCapsules),
                  };

              return {
                title: getCapsuleColumnTitle(column),
                value:
                  column.key === 'asset'
                    ? trendCapsuleItem?.assets[0]?.name
                    : getColumnValueAndUOM(column, decorate({ items: capsuleWithData })),
              };
            })
            .reject(({ value }) => !_.isNumber(value) && _.isEmpty(value))
            .value();

          if (dataLabels.length) {
            lineWidth = Math.max(TEXT_HEIGHT_IN_PIXELS * dataLabels.length, lineWidth);
            capsuleDataLabels[capsule.id] = {
              label: _.map(dataLabels, 'value').join('<br>'),
              titles: _.map(dataLabels, 'title'),
            };
          }
        })
        .value();
    }

    // De-conflict the capsules so as to figure out which row they should go on
    const chartItems = _.chain(capsules)
      .transform<CalculatedChartCapsule | CapsuleItem, CapsuleChartItem>((rows, capsule) => {
        let offset = 0;
        let nextPointStart = null;
        const startMilliseconds = this.getCapsuleTimestampMilliseconds(capsule, 'start', shouldUseTableCapsules);
        const endMilliseconds = this.getCapsuleTimestampMilliseconds(capsule, 'end', shouldUseTableCapsules);
        const cursorKeyMilliseconds = capsule.cursorKey ? nanosToMillis(capsule.cursorKey) : undefined;
        const notFullyVisible = !isCapsuleFullyVisible(startMilliseconds, endMilliseconds);
        const xStart = startMilliseconds ?? displayRangeStart;
        const xEnd = endMilliseconds ?? displayRangeEnd;
        const zeroLength = xStart === xEnd;

        // zeroLength capsules are placed on a separate series to ensure the marker property doesn't cause unwanted
        // artifacts (https://seeq.atlassian.net/browse/CRAB-37825); to properly determine the
        // available label width we need to store the actual nextPoint in a property that we can access during
        // the label rendering (calculating it on the fly is too expensive and causes the browser to freeze when a
        // large number of capsules is displayed).
        if (zeroLength) {
          let nextPointIndex = _.findIndex(condition.capsules, capsule);
          // since the data-precision for capsules is nanoseconds but the display precision is milliseconds it is
          // possible to have 2 capsules that show as identical. This while loop makes sure we get the visually
          // "next" point
          while (nextPointStart === null && nextPointIndex < _.size(condition.capsules)) {
            const nextUpStartInMillis = nanosToMillis(condition.capsules[nextPointIndex]?.start);
            if (nextUpStartInMillis === startMilliseconds) {
              nextPointIndex++;
            } else {
              nextPointStart = nextUpStartInMillis;
            }
          }
        }

        // find the first non-overlapping row
        do {
          const prevEnd = _.get(prevEnds, offset, -Number.MAX_VALUE);

          if (xStart < prevEnd) {
            offset += lineWidth - 1;
          } else {
            prevEnds[offset] = xEnd;
            break;
          }
          // eslint-disable-next-line no-constant-condition
        } while (true);

        const yValue = this.totalYValue + offset;

        // We need each colored set of the same capsule to be on the same row, but highcharts doesn't let us color
        // the connections between datums differently in a single series, so we have to split into multiple series
        // (ie, rows)
        const capsuleHasColor = capsule['color'] !== undefined;
        const colorToUse: string = capsule['color'] ?? condition.color;
        // to prevent https://seeq.atlassian.net/browse/CRAB-37825 we need to make sure that zero-length rows are
        // separate from "regular" capsules; they need to however share the same y-Values to be properly de-conflicted
        let row = _.find(
          rows,
          (row) => row.data[0].y === yValue && row.isZeroLength === zeroLength && row.color === colorToUse,
        );

        if (!row) {
          const rowId = `${yValue}-${condition.id}${zeroLength ? ZERO_LENGTH_IDENTIFIER : ''}${
            capsuleHasColor ? `-${colorToUse}` : ''
          }`;
          row = {
            id: rowId,
            uniqueId: rowId,
            conditionId: condition.id,
            conditionName: condition.name,
            color: colorToUse,
            selected: condition.selected,
            lane: condition.lane,
            isPreviewItem: condition.isPreviewItem,
            itemType: ITEM_TYPES.CAPSULE,
            isAggregated,
            anyCapsulesSelected: false,
            data: [],
            capsules: [],
            zones: [],
            lineWidth: zeroLength ? DEFAULT_CAPSULE_LINE_WIDTH : lineWidth,
            dataLabels: getDefaultCapsuleDataLabelSettings(),
            yValue,
            sampleDisplayOption: zeroLength ? SAMPLE_OPTIONS.SAMPLES : SAMPLE_OPTIONS.LINE,
            isZeroLength: zeroLength,
            overlay: condition.overlay,
          };
          rows.push(row);
        }

        const { label: dataLabelString, titles: dataLabelTitles } = capsuleDataLabels[capsule.id] ?? {};
        row.lineWidth = Math.max(lineWidth, row.lineWidth);
        row.dataLabels.enabled = row.dataLabels.enabled || !_.isEmpty(dataLabelString);
        row.dataLabels.labelNames = _.uniq(row.dataLabels.labelNames.concat(dataLabelTitles || []));

        // Create the line segment for this capsule
        row.data.push({
          // Move the x to the edge of the chart if it is off-screen so data labels show up
          ...{ x: Math.max(xStart, displayRangeStart), y: yValue },
          ...(nextPointStart && { nextPointStart }),
          ...(dataLabelString && {
            dataLabelString,
            labelText: dataLabelString,
          }),
        });

        row.data.push({ x: xEnd, y: yValue });

        // Create the space between the capsules.
        row.data.push({ x: xEnd, y: null });

        if (capsule.isUncertain && !capsule.cursorKey && row.firstFullyUncertainCapsuleStart === undefined) {
          row.firstFullyUncertainCapsuleStart = xStart;
        }

        // Keep a reference to the capsule for selection purposes
        row.capsules.push({
          id: shouldUseTableCapsules ? capsule.id : getUniqueId(condition.id, capsule.id),
          isReferenceCapsule: this.isTableCapsule(capsule, shouldUseTableCapsules)
            ? capsule.isReferenceCapsule
            : (_.get(
                _.find(capsule.properties, ['name', SeeqNames.CapsuleProperties.ReferenceCapsule]),
                'value',
                false,
              ) as boolean),
          isUncertain: capsule.isUncertain,
          cursorKey: cursorKeyMilliseconds,
          startTime: xStart,
          endTime: xEnd,
          notFullyVisible,
          yValue,
        });
      }, [])
      .value();

    if (_.isEmpty(capsules)) {
      const emptyRow = {
        id: `${this.totalYValue}-${condition.id}`,
        uniqueId: `${this.totalYValue}-${condition.id}`,
        conditionId: condition.id,
        color: condition.color,
        lane: condition.lane,
        selected: false,
        itemType: ITEM_TYPES.CAPSULE,
        isAggregated: false,
        anyCapsulesSelected: false,
        lineWidth: DEFAULT_CAPSULE_LINE_WIDTH,
        data: [],
        capsules: [],
        zones: [],
        yValue: this.totalYValue,
      } as CapsuleChartItem;
      chartItems.push(emptyRow);
      this.totalYValue += 1;
    } else {
      if (!_.isEmpty(chartItems)) {
        this.totalYValue += (_.chain(prevEnds).keys().map(Number).max().value() ?? 0) + 1;
      }
    }

    if (isEditing) {
      this.totalYValue += 1;
    }

    // This is how we get Highcharts to display a start and end line that helps us generate an "outline" for an
    // uncertain capsule. If a condition contains uncertain capsules we overlay the uncertain capsule with another
    // uncertain capsule that is just a bit shorter than the original capsule to generate the start/end outline
    // boundary. This code clones the existing capsules, filters out the uncertain capsules, adjusts the start and
    // end times for those capsules to generate the start and end lines, and adds new ids for capsules and
    // condition, as well as the itemType UNCERTAIN_BOUNDED_CAPSULE.
    if (_.some(capsules, 'isUncertain')) {
      const uncertainItems = _.chain(chartItems)
        .map((row) => {
          // the width of the start and end boundary needs to be calculated based on the display range duration to
          // ensure it looks consistent for any given display range. This calculation below has proven to result in
          // the desirable width.
          const firstUncertainCapsule = _.find(row.capsules, 'isUncertain');
          // If the uncertain capsule has a cursorKey, set that as the uncertainty start
          const unboundedCapsuleUncertaintyStart = firstUncertainCapsule ? firstUncertainCapsule.cursorKey : undefined;
          // Make sure that we're only displaying uncertainty if the start of the uncertainty is before the end of the
          // capsule, if there is a cursorKey. If there is not a cursor key, then the capsule is bounded, defaulting to
          // true for the below check.
          const displayUncertainty = unboundedCapsuleUncertaintyStart
            ? unboundedCapsuleUncertaintyStart <= firstUncertainCapsule.endTime
            : true;
          // We want to display an uncertain box if the capsule exists, and either doesn't have a cursor (bounded) or
          // has a cursor and the cursor is <= end time (unbounded)>
          if (firstUncertainCapsule && displayUncertainty) {
            const newRow = _.cloneDeep(row);
            const lineWidthInTime = (sqDurationStore.displayRange.duration.asMilliseconds() / 1000) * 1.4;
            const uncertainAfter = firstUncertainCapsule.startTime;
            newRow.isUncertain = true;
            newRow.conditionId = `${newRow.conditionId}_uncertain`;
            newRow.id = `${newRow.yValue}-${newRow.conditionId}`;
            newRow.uniqueId = `${newRow.yValue}-${newRow.conditionId}`;
            newRow.color = '#fff';
            newRow.itemType =
              unboundedCapsuleUncertaintyStart === firstUncertainCapsule.endTime
                ? ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE
                : ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE;
            // Shrink the line width so that it shows inside the original line
            newRow.lineWidth -= newRow.itemType === ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE ? 4 : 5;
            // Data labels should only be shown for the original row
            delete newRow.dataLabels;
            newRow.data = _.chain(newRow.data)
              .chunk(3)
              .map((group) => {
                if (group[0].x >= uncertainAfter) {
                  // Draw a line through each (partially) uncertain capsule to hollow them out.
                  // Note: group[0].x is the x value of the beginning of the capsule
                  //       group[1].x and group[2].x are both the x value of the end of the capsule.

                  const capsuleDuration = group[1].x - group[0].x;
                  if (
                    capsuleDuration !== 0 &&
                    newRow.itemType === ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE &&
                    group[1].x === unboundedCapsuleUncertaintyStart
                  ) {
                    // Display the uncertain-unbounded capsules by creating an open end for the last
                    // 5% of the capsule.
                    // Note: this branch is only hit if the cursor key is exactly at the end of this
                    // unbounded uncertain capsule.
                    group[0].x = Math.max(group[0].x, unboundedCapsuleUncertaintyStart - capsuleDuration * 0.05);
                  } else if (capsuleDuration !== 0) {
                    // Note: this branch is hit if this capsule is uncertain and there is no cursor key
                    // or the cursor key exists and is _not_ exactly at the end of the capsule.
                    // It could be:
                    //  a. A partially certain or fully uncertain unbounded capsule
                    //      In this case, start the hollowing out at the cursor key
                    //      or the beginning of this capsule, whichever is later.
                    //  b. An uncertain bounded capsule
                    //      Hollow out the whole capsule.
                    const adjustment =
                      capsuleDuration < lineWidthInTime * 3 ? (capsuleDuration / 2 / 100) * 15 : lineWidthInTime;

                    // Adjust the end back so we don't draw over the end, but don't go
                    // before the start.
                    const adjustedEnd = Math.max(group[0].x, group[1].x - adjustment);
                    group[1].x = adjustedEnd;
                    group[2].x = adjustedEnd;

                    // Adjust the start, but don't go beyond the adjusted end.
                    const adjustedStart = Math.min(group[0].x + adjustment, adjustedEnd);

                    group[0].x = unboundedCapsuleUncertaintyStart
                      ? // We have a cursor key; if this is a partially certain capsule, start
                        // the hollowing out at the cursor key.
                        // Otherwise this is a fully uncertain, bounded capsule; hollow out the whole thing.
                        Math.min(adjustedEnd, Math.max(adjustedStart, unboundedCapsuleUncertaintyStart))
                      : adjustedStart;
                  }

                  // Cursor labels should only be on the original rows
                  delete group[0].labelText;
                  delete group[1].labelText;

                  return group;
                }

                return undefined;
              })
              .compact()
              .flatten()
              .value();

            return newRow;
          }

          return undefined;
        })
        .compact()
        .value();

      return _.flatten([uncertainItems, chartItems]);
    }

    return chartItems;
  }

  /**
   * Sets capsules to be shown in chart.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} conditions - Array of conditions and their capsules
   * @param {String} conditions[].id - Id of the condition to which this capsules belong
   * @param {String} conditions[].color - Color for this set of capsules
   * @param {Boolean} conditions[].selected - True if the entire series is selected, false otherwise
   * @param {Object[]} conditions[].capsules - Array of capsules to add. Must be ordered by startTime.
   * @param {String} conditions[].capsules[].id - The id used by the backend to identify this capsule.
   *    Not guaranteed to be unique.
   * @param {Number} conditions[].capsules[].start - Timestamp in nanoseconds of the start of the capsule
   * @param {Number} conditions[].capsules[].end - Timestamp in nanoseconds of the end of the capsule
   * @param {Object[]} conditions[].capsules[].properties - Any additional properties for the capsule. If
   *    a capsule has the count property it is assumed to be an aggregate capsule.
   * @param {Object} numPixels - number of pixels needed to calculate stitch data
   */
  private setChartItems({ conditions, numPixels }) {
    this.state.set('calculatedChartItems', _.flatten(conditions));
    if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
      this.setStitchDetails({ numPixels });
    }
    this.updateChartItems();
  }

  /**
   * Adds capsules to those that are shown in chart.
   *
   * @param {Object[]} conditions - Array of conditions and their capsules
   * @param {String} conditions[].id - Id of the condition to which this capsules belong
   * @param {String} conditions[].color - Color for this set of capsules
   * @param {Boolean} conditions[].selected - True if the entire series is selected, false otherwise
   * @param {Object[]} conditions[].capsules - Array of capsules to add. Must be ordered by startTime.
   * @param {String} conditions[].capsules[].id - The id used by the backend to identify this capsule.
   *    Not guaranteed to be unique.
   * @param {Number} conditions[].capsules[].start - Timestamp in nanoseconds of the start of the capsule
   * @param {Number} conditions[].capsules[].end - Timestamp in nanoseconds of the end of the capsule
   * @param {Object[]} conditions[].capsules[].properties - Any additional properties for the capsule. If
   *    a capsule has the count property it is assumed to be an aggregate capsule.
   * @param {Object} numPixels - number of pixels needed to calculate stitch details
   */
  private addChartItems({ conditions, numPixels }) {
    const calculatedItems = _.unionBy(_.flatten(conditions), this.state.get('calculatedChartItems'), 'id');

    this.state.set('calculatedChartItems', calculatedItems);
    if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
      this.setStitchDetails({ numPixels });
    }
    this.updateChartItems();
  }

  /**
   * Sets the id of the Condition being edited. If any capsules were selected, they are removed.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - the id.
   */
  private setEditingId(payload) {
    this.state.set('editingId', _.get(payload, 'id', PREVIEW_ID));
    this.state.set('previewCapsulesDefinition', {});
    const selected = this.state.get('selectedCapsules');
    this.removeCapsulesByConditionId(payload.id);
    const newSelected = this.state.get('selectedCapsules');
    if (_.size(newSelected) !== _.size(selected)) {
      this.updateChartItems();
    }
  }

  /**
   * Utility function to remove all selected capsules that belong to the provided condition
   *
   * @param {String} conditionId - GUID of the condition whose capsules should be remove
   */
  private removeCapsulesByConditionId(conditionId: string) {
    const selectedCapsules = _.clone(this.state.get('selectedCapsules'));
    const removedSelection = _.remove(
      selectedCapsules,
      (selectedCapsule: any) => conditionId === splitUniqueId(selectedCapsule.id).conditionId,
    );

    if (this.state.get('selectedCapsules').length !== selectedCapsules.length) {
      this.state.set('selectedCapsules', selectedCapsules);
      _.forEach(removedSelection, (c) => this.setProperty(c.id, 'selected', false));
    }
  }

  get itemsAndPreview() {
    return _.concat(this.items, this.previewChartItem);
  }

  /**
   * Sets the preview capsules so they can be displayed. Also stores an Object that defines how to generate those
   * preview capsules so they can be updated when the users scrolls.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.formula - formula used to create the capsules
   * @param {Object[]} payload.parameters - Object array describing the parameters used to create the capsules
   * @param {String} payload.color - the color used to visualize the capsules
   * @param {String} [payload.id] - the id of the condition being edited, PREVIEW_ID if it's a new search.
   * @param {object[]} [payload.capsules] - capsules list to be added.
   * @param {object} [payload.existingCondition] - previously existing set of capsules of the same id or from the
   * current preview capsules item.
   */
  private setPreviewChartItem(payload) {
    const color = payload.color;
    const id = payload.id || PREVIEW_ID;

    this.state.set('previewCapsulesDefinition', {
      formula: payload.formula,
      parameters: payload.parameters,
      color: payload.color,
      id,
    });

    let previewCapsulesItems;
    if (_.isUndefined(payload.id) || payload.id === payload.existingCondition?.id) {
      previewCapsulesItems = _.assign(payload.capsules, {
        id: _.get(payload.existingCondition, 'id', PREVIEW_ID),
        color,
        lane: payload.lane,
        itemType: ITEM_TYPES.CONDITION,
        isPreviewItem: true,
      });
    } else {
      previewCapsulesItems = _.concat(
        payload.existingCondition,
        _.assign(payload.capsules, {
          id: payload.id,
          color,
          lane: payload.existingCondition?.lane + 1 || payload.lane,
          itemType: ITEM_TYPES.CONDITION,
          isPreviewItem: true,
        }),
      );
    }
    this.state.set('previewChartItem', previewCapsulesItems);

    // Preview capsules inherently change a lot so we clear any selected capsules that don't seem to exist any more
    const cursor = this.state.select('selectedCapsules');
    const capsules = _.get(payload, ['capsules', 'capsules'], []);
    _.forEachRight(cursor.get() as any[], (selection, index) => {
      const split = splitUniqueId(selection.id);
      if (split.conditionId === id && !_.some(capsules, (c) => split.capsuleId === c.id)) {
        cursor.unset(index);
      }
    });

    this.updateChartItems();
  }

  /**
   * Removes the previewChartItem.
   */
  private removePreviewChartItem() {
    if (this.state.get(['previewCapsulesDefinition', 'id'])) {
      this.removeCapsulesByConditionId(this.state.get(['previewCapsulesDefinition', 'id']));
    }

    this.state.merge({
      editingId: null,
      previewChartItem: {},
      previewCapsulesDefinition: {},
    });

    this.updateChartItems();
  }

  /**
   * Displays an empty Capsule Preview lane.
   */
  private displayEmptyCapsulePreview() {
    this.state.set('previewChartItem', {
      capsules: [],
      id: this.state.get('editingId'),
    });
    this.updateChartItems();
  }

  /**
   * Removes a condition preview lane.
   *
   * @param {Object} payload - Object container for arguments
   * @param {string} payload.conditionId - Id of condition to be removed
   */
  private removeConditionFromPreviewLane(payload) {
    const previewCapsules = _.clone(this.previewChartItem);
    _.remove(previewCapsules, (item: any) => item?.id === payload.conditionId);

    this.state.merge({
      editingId: null,
      previewChartItem: previewCapsules,
    });

    this.updateChartItems();
  }

  /**
   * Augments the sqBaseItemStore functionality of removing items by also handling the removal of selectedCapsules if
   * their parent condition is also removed.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} payload.items - An array of items to remove
   */
  private removeCapsules(payload) {
    this.removeItems(payload);
    // Don't remove child selections in capsule picking mode to avoid the loss of selected capsules
    if (!sqInvestigateStore.isCapsulePickingMode) {
      _.chain(payload.items)
        .filter(['itemType', ITEM_TYPES.CONDITION])
        .forEach((condition) => this.removeCapsulesByConditionId(condition.id))
        .value();
    }
    if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
      this.setStitchDetails({ numPixels: payload.numPixels });
    }
  }

  /**
   * Selects multiples capsules.
   *
   * @param payload - Object container for arguments
   * @param payload.capsules - An array of capsules that will be selected
   * @param payload.numPixels - number of pixels needed to calculate stitch data
   */
  private setSelectedCapsules({ capsules, numPixels }: { capsules: object[]; numPixels?: number }) {
    _.forEach(capsules, (capsule) => {
      this.setCapsuleSelected({ item: capsule, selected: true, numPixels }, false, false);
    });
    this.updateChartItems();
    if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
      this.setStitchDetails({ numPixels });
    }
  }

  /**
   * Augments the sqBaseItemStore functionality of setting the selected property by also tracking selection status
   * in selectedCapsules and keeping calculatedChartItem selection in sync with conditions.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object} payload.item - The item to select
   * @param {Boolean} payload.selected - Selection status
   * @param updateChartItems - Specifies if updateChartItems should be called. Useful to avoid multiple calls when
   * many capsules are selected.
   * @param setStitchDetails - Specifies if stitch details should be updated for chain view
   */
  private setCapsuleSelected(payload, updateChartItems = true, setStitchDetails = true) {
    if (payload.item.itemType === ITEM_TYPES.CAPSULE) {
      // In capsule picking mode, the selection is the source of truth for the capsules, not the items (capsule table)
      // array. Otherwise this logic would prevent capsules from being removed from the picked capsules via the
      // [x] buttons in the capsule group component if there happened to be an item in the capsule pane with a start
      // and end time that matches and is from the same condition originally. (It is easy to get into this situation
      // when you pick capsules from a condition derived from the manual condition you are editing). The downside is
      // that this could cause selecting an in progress capsule on the chart could lead to not being selected in the
      // capsules pane (the edge case the following code would otherwise prevent)
      const itemsSourceOfTruth = !sqInvestigateStore.isCapsulePickingMode;
      if (itemsSourceOfTruth && !_.find(this.state.get('items'), ['id', payload.item.id])) {
        // Since the capsule isn't in items (capsule table), `payload.item` must be from `chartCapsules`. If there is
        // a capsule in the items (capsule table) that is almost the same, we will use it as 'the source of truth'.
        // If such a capsule exists then the capsule table and the chart are likely out of sync (i.e., api calls
        // execute at different times) and the current capsule isUncertain or was isUncertain
        const conditionId = splitUniqueId(payload.item.id).conditionId;
        const possibleCapsules = _.filter(this.state.get('items'), ['isChildOf', conditionId]);
        const maybeCapsule = _.find(
          possibleCapsules,
          (capsule: any) => payload.item.startTime === capsule.startTime || payload.item.endTime === capsule.endTime,
        );
        payload.item = maybeCapsule || payload.item;
      }

      const index = _.findIndex(this.state.get('selectedCapsules'), ['id', payload.item.id]);
      if (payload.selected && index < 0) {
        this.state.push('selectedCapsules', _.pick(payload.item, ['id', 'startTime', 'endTime', 'isUncertain']));
      } else if (!payload.selected && index >= 0) {
        this.state.splice('selectedCapsules', [index, 1]);
      }

      this.setSelected(payload);
      if (sqTrendStore.view === TREND_VIEWS.CHAIN && setStitchDetails) {
        this.setStitchDetails({ numPixels: payload.numPixels });
      }
      if (updateChartItems) {
        this.updateChartItems();
      }
    }

    // Keep the selected property of the calculatedChartItems in sync with the capsule series
    // to which they belong. This branch is hit when selecting a condition in the series panel only.
    if (payload.item.itemType === ITEM_TYPES.CONDITION) {
      _.forEach(this.state.get('calculatedChartItems'), (item, i) => {
        if (item.id === payload.item.id) {
          this.state.set(['calculatedChartItems', i, 'selected'], payload.selected);
        }
      });
      if (sqTrendStore.view === TREND_VIEWS.CHAIN && setStitchDetails) {
        this.setStitchDetails({ numPixels: payload.numPixels });
      }
      if (updateChartItems) {
        this.updateChartItems();
      }
    }
  }

  /**
   * Unselects all items and chartItems.
   */
  private unselectAllCapsules() {
    this.state.set('selectedCapsules', []);
    _.forEach(this.state.get('items'), (item, i) => {
      this.state.set(['items', i, 'selected'], false);
    });
    // Since "this.updateChartItems" makes use of "this.anySelectedTableCapsuleInDisplay", it needs to be called
    // after all items are updated in the above forEach
    this.updateChartItems();
  }

  /**
   * Sets the stitch view breaks for the given condition
   *
   * @param [Object] stitchBreaks - The array of stitch breaks
   */
  private setStitchBreaks({ stitchBreaks }) {
    this.state.set('stitchBreaks', stitchBreaks);
    if (!_.isEmpty(sqTrendStore.enabledColumns(TREND_PANELS.CHART_CAPSULES))) {
      this.updateChartItems(); // Chart capsules filter their labels based on stitch breaks
    }
  }

  /**
   * Sets the stitch view times for the given condition
   *
   * @param [Object] stitchTimes - The array of stitch times
   */
  private setStitchTimes({ stitchTimes }) {
    this.state.set('stitchTimes', stitchTimes);
  }

  /**
   * Overwrite the `selectedCapsules` with the provided capsules and update the `items` and `chartItems` to match.
   *TREND_ADD_CAPSULES
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} payload.capsules - Selected capsules
   * @param {String} payload.capsules[].id - capsule's unique id created from `getUniqueId`
   * @param {Number} payload.capsules[].startTime - end of the capsule in ms
   * @param {Number} payload.capsules[].endTime - start of capsule in ms
   * @param {Boolean} payload.capsules[].isUncertain - indicates that the capsule is uncertain
   * @param {Number} payload.capsules[].cursorKey - start of uncertainty
   */
  private replaceCapsuleSelection({ capsules }) {
    this.unselectAllCapsules();
    this.state.set(
      'selectedCapsules',
      _.map(capsules, (c) => _.pick(c, ['id', 'startTime', 'endTime', 'isUncertain', 'cursorKey'])),
    );
    _.forEach(capsules, (c) => this.setProperty(c.id, 'selected', true));
  }

  protected getChartChildrenToColor() {
    return this.chartItems;
  }

  /**
   * Overrides the behavior of TREND_SET_COLOR to take preview and chart items into account.
   *
   * Called when the color for an item changes, should set the color on the item as necessary
   *
   * @param {Object} item - serialized item object
   * @param {Object} color - color that should be set
   * @param {string} [parentId] - what was the id of the parent that triggered the change
   */
  protected onSetColor(item: any | undefined, color, parentId?: string) {
    if (item) {
      this.getItemCursor(item.id).set('color', color);
    }
    if (parentId) {
      _.forEach({ chartItems: 'conditionId', calculatedChartItems: 'id' }, (key, path) => {
        _.forEach(this.state.get(path), (chartItem, i) => {
          if (chartItem[key] === parentId) {
            this.state.set([path, i, 'color'], color);
          }
        });
      });
    }

    if (item?.id === PREVIEW_ID) {
      this.state.merge('previewChartItem', { color });
      this.updateChartItems();
    }
  }

  private isTableCapsule(
    capsule: CapsuleItem | CalculatedChartCapsule,
    shouldUseTableCapsules: boolean,
  ): capsule is CapsuleItem {
    return shouldUseTableCapsules;
  }

  private getCapsuleTimestampMilliseconds(
    capsule: CapsuleItem | CalculatedChartCapsule,
    field: 'start' | 'end',
    shouldUseTableCapsules: boolean,
  ): number | undefined | null {
    if (this.isTableCapsule(capsule, shouldUseTableCapsules)) {
      return capsule[`${field}Time`];
    }

    const value = capsule[field];

    return _.isNil(value) ? value : nanosToMillis(value);
  }

  private getCapsuleProperties(capsule: CapsuleItem | CalculatedChartCapsule, isChainView: boolean) {
    return this.isTableCapsule(capsule, isChainView)
      ? capsule.properties
      : _.transform(
          capsule.properties,
          (memo, property) => {
            memo[property.name] = property.value;
          },
          {},
        );
  }

  private getCapsulePropertiesUOM(capsule: CapsuleItem | CalculatedChartCapsule, isChainView: boolean) {
    return this.isTableCapsule(capsule, isChainView)
      ? capsule.propertiesUOM
      : _.transform(
          capsule.properties,
          (memo, property) => {
            memo[property.name] = property.unitOfMeasure;
          },
          {},
        );
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated items.
   */
  dehydrate() {
    return _.assign(
      _.omit(this.state.serialize(), [
        'items',
        'chartItems',
        'stitchBreaks',
        'stitchTimes',
        'capsulesTimings',
        'calculatedChartItems',
        'previewChartItem',
        'stitchDetailsSet',
      ]),
      {
        // Need to keep a history of uncertain capsules so we can match them up with 'new' uncertain capsules as they
        // grow or turn certain.
        items: _.chain(this.state.get('items'))
          .filter('isUncertain')
          .map((item) =>
            _.pick(item, [
              'id',
              'startTime',
              'endTime',
              'isChildOf',
              'childType',
              'isUncertain',
              'itemType',
              'cursorKey',
            ]),
          )
          .value(),
      },
    );
  }

  /**
   * Re-creates the dehydrated capsules.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Gets all of the values for the given capsule property, as strings
   */
  getValuesForProperty(capsulePropertyName: string): string[] {
    return _.chain(this.state.get('items'))
      .map((capsule) => capsule.properties?.[capsulePropertyName] ?? undefined)
      .compact()
      .uniq()
      .sortBy()
      .value();
  }
}
