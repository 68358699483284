import React from 'react';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { SelectWorkbooks } from '@/core/SelectWorkbooks.molecule';
import { SelectedSearchItem } from '@/search/search.types';

export interface SelectWorkbenchProps extends ValidatingFormComponent<SelectedSearchItem[]> {
  component: 'SelectWorkbookFormComponent';
  helpTextKey?: string;
  customErrorText?: string;
  notSelectedMessage: string;
  title: string;
  showModal?: boolean;
  setShowModal?: (value: boolean) => void;
  setShowLocalAssetModal?: (value: boolean) => void;
  isMultiple?: boolean;
}

export const SelectWorkbookFormComponent: React.FunctionComponent<SelectWorkbenchProps> = (props) => {
  const {
    name,
    value,
    testId = 'selectWorkbench',
    validation,
    extendValidation,
    customErrorText,
    notSelectedMessage,
    required = true,
    title,
    showModal = false,
    setShowModal = _.noop,
    setShowLocalAssetModal = _.noop,
    isMultiple,
  } = props;

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;

  const defaultValidation = (value: any) => required && !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <>
      <FormFieldWrapper
        wrapperClassNames="flexFill"
        testId={testId}
        showError={showError}
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const { onChange, value, extraClassNames } = getFormFieldProps(formState, input, meta, props);

            return (
              <SelectWorkbooks
                value={value}
                onChange={onChange}
                title={title}
                setShowLocalAssetModal={setShowLocalAssetModal}
                extraClassNames={extraClassNames}
                notSelectedMessage={notSelectedMessage}
                isMultiple={isMultiple}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            );
          }}
        </Field>
      </FormFieldWrapper>
    </>
  );
};
