import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImpactReportSlider } from '@/impactReports/ImpactReportSlider.atom';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { ReportContentWorksheetAndUrl } from '@/reportEditor/components/reportContentModal/shared/ReportContentWorksheetAndUrl.molecule';
import { SelectWorksheet } from '@/worksheets/SelectWorksheet.molecule';
import { Button, Icon } from '@seeqdev/qomponents';
import _ from 'lodash';

import {
  availableStages,
  IMPACT_OWNER_FIRSTNAME,
  IMPACT_OWNER_LASTNAME,
  IMPACT_REPORT_LABEL_ID,
  ImpactReportCategory,
  ImpactType,
  instructions,
  quantifyUnits,
  stageIcons,
  Stages,
  TRACK_IMPACT,
} from '@/impactReports/impactReport.types';
import { sqContextApi, sqWorkbooksApi } from '@/sdk';

import { sqImpactReportStore, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { SaveAndContinueFormBuilder } from '@/formbuilder/SaveAndContinueFormBuilder.page';
import { IdentityPreviewV1 } from '@/sdk/model/IdentityPreviewV1';
import { useAsyncEffect } from 'rooks';
import { randomUUID } from '@/utilities/utilities';
import {
  addOrUpdateImpactReport,
  getCustomCategoryTitle,
  getImpactReportTableId,
  stageDisplayStringByIndex,
} from '@/impactReports/impactReport.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { ScalarPropertyV1 } from '@/sdk/model/ScalarPropertyV1';
import { SelectedSearchItem } from '@/search/search.types';
import { ManageCustomCategoriesModal } from '@/impactReports/ManageCustomCategoriesModal.molecule';
import { isAdmin } from '@/services/authorization.service';
import { SelectedWorksheetDisplayAndLink } from '@/impactReports/SelectedWorksheetDisplayAndLink.atom';
import { doTrack } from '@/track/track.service';
import { ContextLabelWithIdInputV1 } from 'sdk/model/ContextLabelWithIdInputV1';
import { ImpactReportAIOverlay } from '@/impactReports/ValueCaptureAIOverlay.atom';
import { genAIEnabled } from '@/services/systemConfiguration.utilities';
import { useFluxPaths } from '@/core/hooks/useFluxPaths.hook';
import { setUpdateNominations } from '@/impactReports/impactReport.actions';

interface ImpactReportModalProps {
  existingReport?: string;
  stageIndex?: number;
  workbookId?: string;
  worksheetId?: string;
  setWorkbookId: (id: string) => void;
  setWorksheetId: (id: string) => void;
  closeFn: (reloadTable?: boolean) => void;
  setStageIndex: (idx: number) => void;
  report?: Record<string, any>;
  categories?: ImpactReportCategory[];
}

interface ImpactReportHeaderProps {
  workbookId: string;
  worksheetId: string;
  stageIndex: number;
  setStageIndex: (idx: number) => void;
  setWorkbookId: (id: string) => void;
  setWorksheetId: (id: string) => void;
}

export const ImpactReportHeader: React.FunctionComponent<ImpactReportHeaderProps> = ({
  workbookId,
  worksheetId,
  stageIndex = 0,
  setStageIndex,
  setWorksheetId,
  setWorkbookId,
}) => {
  const { t } = useTranslation();
  const [currentStage, setCurrentStage] = useState<Stages | null>(null);
  const [currentStageDisplay, setCurrentStageDisplay] = useState('');

  useEffect(() => {
    setCurrentStage(availableStages[stageIndex]);
    setCurrentStageDisplay(t(`IMPACT_REPORT.${availableStages[stageIndex]?.toUpperCase()}`));
  }, [stageIndex]);

  return (
    <div className="flexRowContainer valueCapture width-maximum">
      <div className="mb5">
        <h4>{t('IMPACT_REPORT.CAPTURE_IMPACT')}</h4>
      </div>
      <div className="flexColumnContainer flexWrap">
        {workbookId && worksheetId ? (
          <div className="flexRowContainer mt0 flexAlignStart flexFill">
            <div>{t('IMPACT_REPORT.SELECTED_WORKSHEET')}</div>
            <div>
              <SelectedWorksheetDisplayAndLink workbookId={workbookId} worksheetId={worksheetId} />
              <Icon
                icon="fa-close"
                onClick={() => {
                  setWorkbookId('');
                  setWorksheetId('');
                  setStageIndex(0);
                }}
                extraClassNames="ml10 mt3 cursorPointer"
              />
            </div>
          </div>
        ) : (
          <div className="flexFill" />
        )}
        {currentStage && (
          <div className="flexRowContainer flexAlignEnd">
            <h6 className="mtn8">
              {t('IMPACT_REPORT.CURRENT_STAGE')} {currentStageDisplay}
            </h6>
            <ImpactReportSlider setStageIdx={setStageIndex} stageIndex={stageIndex} />
          </div>
        )}
      </div>
    </div>
  );
};

export const ImpactReportForm: React.FunctionComponent<ImpactReportModalProps> = ({
  stageIndex = 0,
  workbookId,
  worksheetId,
  setWorkbookId,
  setWorksheetId,
  setStageIndex,
  closeFn,
  report,
  categories,
}) => {
  const isReport = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const { showSite, showAssetPath } = useFluxPaths(sqImpactReportStore, ['showSite', 'showAssetPath']);
  const { t } = useTranslation();
  const [impactType, setImpactType] = useState<ImpactType>('oneTime');
  const [rowDatumId, setRowDatumId] = useState<string | undefined>(
    report && report[SeeqNames.MaterializedTables.DatumIdColumn],
  );
  // we don't need a store, but we need to store the values in local state to ensure we can persist them across stages
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(genAIEnabled() ? '' : instructions);
  const [owner, setOwner] = useState({
    id: sqWorkbenchStore.currentUser.id,
    name: sqWorkbenchStore.currentUser.name,
  });
  const [customCategories, setCustomCategories] = useState<{ [key: string]: any }>({});

  const [asset, setAsset] = useState('');
  const [selectUnit, setSelectUnit] = useState('');
  const [lessonsLearned, setLessonsLearned] = useState('');
  const [impactStartDate, setImpactStartDate] = useState<Date | undefined>(undefined);
  const [impactEndDate, setImpactEndDate] = useState<Date | undefined>(undefined);
  const [impactAmount, setImpactAmount] = useState('');
  const [quantifyAmount, setQuantifyAmount] = useState('100');
  const [impactFrequency, setImpactFrequency] = useState('');
  const [calcItem, setCalcItem] = useState<SelectedSearchItem | undefined>(undefined);
  const [isReportBinder, setIsReportBinder] = useState(isReport && window.location.pathname !== '/value');

  const [site, setSite] = useState(sqWorkbenchStore.currentUser.site);
  const [forceUpdateText, setForceUpdateText] = useState(description);
  // this is a temporary solution - for now we do not support generating AI description for Vantage
  const [isVantage, setIsVantage] = useState(false);
  useAsyncEffect(async () => {
    if (!workbookId) return;
    const workbookResponse = await sqWorkbooksApi.getWorkbook({ id: workbookId });
    const vantage = workbookResponse.data?.type === SeeqNames.Types.Vantage;
    setIsVantage(vantage);
    setShowAIOverlay(!vantage && description === '');
    if (vantage && description === '') {
      setForceUpdateText(instructions);
    }
  }, [workbookId]);
  // end temporary code
  const [showAIOverlay, setShowAIOverlay] = useState(description === '');
  const tableId = useFluxPath(sqImpactReportStore, () => sqImpactReportStore.tableId);

  useAsyncEffect(async () => {
    await getImpactReportTableId();
  }, []);

  useEffect(() => {
    if (!report || _.isEmpty(report)) return;
    setAsset(report[SeeqNames.ImpactReport.ImpactAsset]);
    setTitle(report[SeeqNames.ImpactReport.ImpactName]);
    setDescription(report[SeeqNames.ImpactReport.ImpactDescription]);
    setImpactEndDate(
      report[SeeqNames.ImpactReport.ImpactEndDate] && new Date(report[SeeqNames.ImpactReport.ImpactEndDate]),
    );
    setImpactStartDate(
      report[SeeqNames.ImpactReport.ImpactStartDate] && new Date(report[SeeqNames.ImpactReport.ImpactStartDate]),
    );
    setSelectUnit(report[SeeqNames.ImpactReport.ImpactUnit] as string);
    setCalcItem({ id: report[SeeqNames.ImpactReport.ImpactFormulaParam] });
    setImpactFrequency(report[SeeqNames.ImpactReport.ImpactFrequency]);
    setImpactAmount(report[SeeqNames.ImpactReport.Impact]);
    setQuantifyAmount(report[SeeqNames.ImpactReport.ImpactQuantifyAmount]);
    setWorksheetId(report[SeeqNames.ImpactReport.AssignedToWorksheet]);
    setWorkbookId(report[SeeqNames.ImpactReport.AssignedToWorkbook]);
    setImpactType(report[SeeqNames.ImpactReport.ImpactSavingsType]);
    setSite(report[SeeqNames.ImpactReport.ImpactSite]);
    setOwner({
      id: report[SeeqNames.ImpactReport.ImpactOwner],
      name: `${report[IMPACT_OWNER_FIRSTNAME]} ${report[IMPACT_OWNER_LASTNAME]}`,
    } as IdentityPreviewV1);
    setLessonsLearned(report[SeeqNames.ImpactReport.ImpactLessonsLearned]);
    const customCats: Record<string, string>[] | undefined =
      categories?.map((category) => {
        const cat = category.category;
        return { [cat.id!]: report[cat.id!] };
      }) || [];
    setCustomCategories(customCats);
    setShowAIOverlay(description === '');
  }, [report]);

  const sectionHeader = (stageIndex: number) => (
    <>
      <div className={`flexColumnContainer flexAlignCenter mbn16 formSectionHeader ${stageIndex !== 0 ? 'mt20' : ''}`}>
        <Icon icon={stageIcons[stageIndex]} extraClassNames="mr10 ml5" />
        <h4 className="flexFill">{stageDisplayStringByIndex(stageIndex)}</h4>
        <div className="mr5">
          {stageIndex === 1 && isAdmin() && <ManageCustomCategoriesModal categories={categories} />}
        </div>
      </div>
      <hr />
    </>
  );

  if (!tableId) return null;

  const categoryFields = categories
    ? categories.map((category) => {
        const cat = category.category;
        const fieldName = getCustomCategoryTitle(category);
        const options = category.options;
        const categoryId = cat.id!;
        return {
          label: fieldName,
          component: 'SelectFormComponent',
          name: categoryId,
          value: customCategories[categoryId] ? customCategories[categoryId] : report && report[categoryId],
          required: false,
          options: options as any,
          onChange: (value) => {
            setCustomCategories({ ...customCategories, [categoryId]: value });
          },
        } as FormElement;
      })
    : [];

  const updateCKContent = (content: string) => {
    setDescription(description + content);
    setForceUpdateText(description + content);
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'identify',
      includeIf: stageIndex >= 0,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'identifyHeader',
          children: sectionHeader(0),
        },
        {
          component: 'TextFieldFormComponent',
          name: 'title',
          label: 'Title',
          value: title,
          required: true,
          onChange: (title) => {
            setTitle(title as string);
          },
          size: 'sm',
          testId: 'title',
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'CKEditorLabel',
          children: [
            <div className="flexColumnContainer flexAlignCenter mb5" key="CKLabel">
              {t('IMPACT_REPORT.DESCRIPTION')}
              {genAIEnabled() && !showAIOverlay && worksheetId && workbookId && !isVantage && (
                <Icon
                  onClick={() => setShowAIOverlay(true)}
                  icon="fa-sparkles"
                  iconPrefix="fa-solid"
                  extraClassNames="ml5 cursorPointer"
                />
              )}
            </div>,
          ],
        },
        {
          component: 'CKEditorFormComponent',
          name: 'description',
          label: '',
          extraClassNames: 'width-maximum',
          value: description,
          required: true,
          onChange: (description) => {
            setDescription(description as string);
          },
          testId: 'description',
          forceUpdateText,
          overlay:
            genAIEnabled() && showAIOverlay ? (
              <ImpactReportAIOverlay
                onClose={() => setShowAIOverlay(false)}
                updateCKContent={updateCKContent}
                worksheetId={worksheetId}
                workbookId={workbookId}
              />
            ) : undefined,
          placeholder: '',
        },
        {
          component: 'LabelFormComponent',
          name: 'ownerLabel',
          value: 'Use Case Owner',
          testId: 'ownerLabel',
          extraClassNames: '',
        },
        {
          component: 'SelectIdentityFormComponent',
          name: 'owner',
          idForLabel: 'useCaseOwner',
          label: 'Use Case Owner',
          extraClassNames: 'width-maximum mb25',
          value: owner,
          required: true,
          startEditable: true,
          onChange: (selectedIdentity) => {
            setOwner(_.pick(selectedIdentity, ['id', 'name']) as IdentityPreviewV1);
          },
          testId: 'owner',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'categorize',
      includeIf: stageIndex >= 1,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'categoryHeader',
          children: sectionHeader(1),
        },
        {
          component: 'TextFieldFormComponent',
          name: 'site',
          label: 'Site',
          value: site,
          onChange: (site) => {
            setSite(site);
          },
          size: 'sm',
          testId: 'site',
          includeIf: showSite,
        },
        ...categoryFields,
        {
          component: 'TextFieldFormComponent',
          name: 'asset',
          label: 'Asset Type',
          value: asset,
          onChange: (asset) => {
            setAsset(asset as string);
          },
          size: 'sm',
          testId: 'title',
          includeIf: showAssetPath,
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'quantify',
      includeIf: stageIndex >= 2,

      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'quantifyHeader',
          children: sectionHeader(2),
        },
        {
          component: 'CKEditorFormComponent',
          name: 'lessonsLearned',
          label: t('IMPACT_REPORT.LESSONS_LEARNED'),
          extraClassNames: 'width-maximum',
          value: lessonsLearned,
          placeholder:
            'What other assets or sites would this be applicable for? Are there particular gotchas or things to be aware of if someone tried to replicate this? Did the changes require any coordination with other departments?',
          onChange: (lessons: string) => {
            setLessonsLearned(lessons);
          },
          testId: 'lessonsLearned',
        },
        {
          component: 'LabelFormComponent',
          name: 'quantifyLabel',
          value: 'Order of Magnitude Direct Impact Estimate (1000s of gallons of water per month)',
          testId: 'quantifyLabel',
        },
        {
          component: 'FormRow',
          name: 'quantifyRow',
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              extraClassNames: 'width-200',
              component: 'SelectFormComponent',
              name: 'quantifyAmount',
              value: quantifyAmount,
              required: true,
              options: [
                { value: '1', label: '1' },
                { value: '10', label: '10s' },
                { value: '100', label: '100s' },
                { value: '1000', label: '1000s' },
                { value: '10000', label: '10.000s' },
                { value: '100000', label: '100.000s' },
                { value: '1000000', label: '1.000.000s' },
              ],
              onChange: (selection) => {
                setQuantifyAmount(selection as string);
              },
            },
            {
              component: 'LabelFormComponent',
              name: 'quantifyLabel',
              value: 'of',
              testId: 'quantifyLabel',
              extraClassNames: 'ml5 mr5',
            },
            {
              component: 'SelectUnitFormComponent',
              name: 'quantifyUnit',
              value: selectUnit,
              onChange: (selection) => {
                setSelectUnit(selection as string);
              },
              required: true,
              units: quantifyUnits,
            },
          ],
        },
        {
          component: 'CheckboxFormComponent',
          name: 'oneTime',
          type: 'radio',
          value: impactType === 'oneTime',
          onChange: () => {
            setImpactType('oneTime');
          },
          checkboxLabel: 'one time impact',
          id: 'oneTime',
          testId: 'oneTime',
          skipStore: true,
        },
        {
          component: 'CheckboxFormComponent',
          name: 'continuous',
          type: 'radio',
          value: impactType === 'continuous',
          onChange: () => {
            setImpactType('continuous');
          },
          checkboxLabel: 'continuous impact',
          id: 'continuous',
          testId: 'continuous',
        },
        {
          component: 'CheckboxFormComponent',
          name: 'customCalc',
          type: 'radio',
          value: impactType === 'customCalc',
          onChange: () => {
            setImpactType('customCalc');
          },
          checkboxLabel: 'custom calculation',
          id: 'customCalc',
          testId: 'customCalc',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'continuous',
      includeIf: stageIndex >= 2,
      components: [
        {
          component: 'FormRow',
          name: 'startEndRow',
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'DatePickerFormComponent',
              name: 'impactStartDate',
              label: t('IMPACT_REPORT.START_DATE'),
              value: impactStartDate,
              required: true,
              onChange: (date) => {
                setImpactStartDate(date);
              },
              testId: 'impactStartDate',
              wrapperClassNames: 'mr5',
              clearDate: () => setImpactStartDate(undefined),
            },
            {
              component: 'DatePickerFormComponent',
              name: 'impactEndDate',
              includeIf: impactType === 'continuous' || impactType === 'customCalc',
              label: t('IMPACT_REPORT.END_DATE'),
              value: impactEndDate,
              onChange: (date: Date) => {
                setImpactEndDate(date);
              },
              testId: 'impactEndDate',
              wrapperClassNames: 'ml5',
              clearDate: () => setImpactEndDate(undefined),
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'monetize',
      includeIf: stageIndex >= 3,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'monetizeHeader',
          children: sectionHeader(3),
        },
        {
          component: 'SelectItemSearchFormComponent',
          includeIf: impactType === 'customCalc' && stageIndex >= 3,
          name: 'customCalcItem',
          value: calcItem?.id ? [calcItem] : undefined,
          title: t('IMPACT_REPORT.FIND_CUSTOM_CALC'),
          isMultiple: false,
          searchTypes: ['Signal'],
          notSelectedMessage: t('IMPACT_REPORT.FIND_CUSTOM_CALC'),
          iconPartialTooltipKey: 'customCalc',
          overrideWorkbookId: workbookId,
          onChange: (item) => {
            item?.length === 1 ? setCalcItem(item?.[0]) : setCalcItem(undefined);
          },
        },
        {
          component: 'FormRow',
          name: 'monetizeFormRow',
          includeIf: impactType === 'oneTime' || impactType === 'continuous',
          components: [
            {
              component: 'TextFieldFormComponent',
              label: 'Amount in ($)',
              name: 'impactAmount',
              value: impactAmount,
              size: 'sm',
              onChange: (amount) => {
                setImpactAmount(amount as string);
              },
              testId: 'impactAmount',
              wrapperClassNames: 'mr5',
              required: true,
              type: 'number',
              min: 1,
            },
            {
              component: 'FormGroup',
              name: 'continuousImpact',
              includeIf: impactType === 'continuous',
              extraClassNames: 'mb20',
              components: [
                {
                  label: t('IMPACT_REPORT.FREQUENCY'),
                  component: 'SelectFormComponent',
                  name: 'impactFrequency',
                  value: impactFrequency,
                  options: [
                    { value: '1', label: 'daily' },
                    { value: '7', label: 'weekly' },
                    { value: '30.5', label: 'monthly' },
                    { value: '90', label: 'quarterly' },
                    { value: '365', label: 'yearly' },
                  ],
                  onChange: (frequency) => {
                    setImpactFrequency(frequency as string);
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const onFormSubmitCallback = async (values: any) => {
    // we need a dummy formula so we can successfully create the condition
    let formula =
      "condition(10y, capsule('1971-01-01T00:00:00.00Z'," +
      " '1971-01-01T00:00:00.00Z').setProperty('Impact Estimate', 0))";

    const startDate = impactStartDate && (impactStartDate as Date)?.toISOString();
    const endDate = impactEndDate && (impactEndDate as Date)?.toISOString();

    if (startDate && impactAmount) {
      if (impactType === 'continuous') {
        if (!impactEndDate) {
          formula = `(condition(10y,capsule('${startDate}', now())).setUncertainty(1h)).intersect(days().setProperty('Impact Estimate',${impactAmount}/${impactFrequency}),keepProperties())`;
        } else {
          formula = `(condition(10y,capsule('${startDate}','${endDate}'))).intersect(past()).intersect(days().setProperty('Impact Estimate',${impactAmount}/${impactFrequency}),keepProperties()).setUncertainty(1h)`;
        }
      } else if (impactType === 'oneTime') {
        formula = `condition(10y,capsule('${startDate}', '${startDate}').setProperty('Impact Estimate', ${impactAmount}))`;
      }
    }
    if (impactType === 'customCalc' && calcItem) {
      formula =
        '($customSavingsSignal *' +
        " ($customSavingsSignal.toCapsules().aggregate(totalDuration(\"s\"),$customSavingsSignal.toCapsules(), startKey()))).reduceUnits().tocapsules().renameProperty('value','Impact Estimate')";
    }

    const properties: Array<ScalarPropertyV1> = [];
    const addIfNotEmpty = (name: string, value: string | boolean | null | undefined) => {
      if (value) {
        properties.push({ name, value });
      }
    };
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactName, title);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactDescription, description);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactOwner, owner?.id);

    addIfNotEmpty(SeeqNames.ImpactReport.ImpactSite, site);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactAsset, asset);

    addIfNotEmpty(SeeqNames.ImpactReport.ImpactLessonsLearned, lessonsLearned);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactQuantifyAmount, quantifyAmount);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactSavingsType, impactType);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactUnit, selectUnit);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactStartDate, impactStartDate && impactStartDate?.toISOString());
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactEndDate, impactEndDate && impactEndDate?.toISOString());

    addIfNotEmpty(SeeqNames.ImpactReport.ImpactFrequency, impactFrequency);

    addIfNotEmpty(SeeqNames.ImpactReport.Impact, impactAmount);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactFormula, formula);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactFormulaParam, calcItem?.id);

    addIfNotEmpty(SeeqNames.ImpactReport.AssignedToWorksheet, worksheetId);
    addIfNotEmpty(SeeqNames.ImpactReport.AssignedToWorkbook, workbookId);
    addIfNotEmpty(SeeqNames.ImpactReport.ImpactStatus, availableStages[stageIndex]);

    // if we are updating an existing report, we need to use the existing datum id
    let useThisDatumId = rowDatumId;
    if (useThisDatumId === undefined) {
      doTrack(TRACK_IMPACT, 'Created new Impact Report', { isReportBinder, impactType });
      useThisDatumId = randomUUID();
    } else {
      doTrack(TRACK_IMPACT, 'Updated existing Impact Report', { isReportBinder, impactType });
    }

    properties.push({ name: SeeqNames.MaterializedTables.ItemIdColumn, value: tableId });
    properties.push({ name: SeeqNames.MaterializedTables.DatumIdColumn, value: useThisDatumId });

    /**
     * TODO: CRAB-46894:
     * As part of CRAB-46894 please remove the next line of code and ensure that the datum_id column rules are
     * removed so that a simple "datum id" property is used as the value of the datum id.
     *
     *
     * How we got here:
     * Due to the lack of a table type for Materialized Tables a migration that changes the datum_id to be expecting
     * a property named "Existing Row Identifier" is applied to the ImpactReport Table. To ensure updates of impact
     * reports work as expected we need to also include a property called "Existing Row Identifier" with our Events.
     * The value is simple the datum id of the row we are updating (as it's still being returned as the datum id).
     */
    properties.push({ name: SeeqNames.MaterializedTables.UniqueRowId, value: useThisDatumId });

    // remove all previously assigned context labels to avoid problems
    if (categories) {
      const contextLabels: ContextLabelWithIdInputV1[] = _.chain(categories)
        .map((category: ImpactReportCategory) => {
          const labelId = values[category.category.id!];
          return labelId
            ? {
                contextLabelId: report && report[`${category.category.id}_${IMPACT_REPORT_LABEL_ID}`],
                datumId: useThisDatumId,
                labelId,
              }
            : null;
        })
        .compact()
        .value();
      try {
        await sqContextApi.batchCreateContextLabels(contextLabels, { itemId: tableId });
      } catch (err) {
        console.log(err);
      }
    }

    await addOrUpdateImpactReport(tableId, properties);
    setRowDatumId(useThisDatumId); // ensure this gets set so the "continue" button works
  };

  return (
    <div className="flexRowContainer flexAlignCenter mb10 flexFill valueCapture">
      {!workbookId && !worksheetId && (
        <div className="flexRowContainer mt15 flexFill width-maximum flexAlignCenter">
          <div className="flexColumnContainer flexAlignCenter mbn16 formSectionHeader width-maximum mb10">
            <h4 className="ml10">{t('IMPACT_REPORT.SELECT_WORKSHEET_AND_WORKBOOK')}</h4>
          </div>
          <hr />
          <ReportContentWorksheetAndUrl
            selectOnlyWorkbooks={true}
            allowedTypes={[
              SeeqNames.Types.Topic,
              SeeqNames.Types.Analysis,
              SeeqNames.Types.Vantage,
              SeeqNames.Types.Folder,
            ]}
            extraClassNames="width-maximum"
            setSelectedItem={(item) => {
              setWorkbookId(item?.id);
            }}
          />
          <Button label={t('IMPACT_REPORT.CONTINUE')} disabled={!workbookId} extraClassNames="mt10" />
        </div>
      )}
      {workbookId && !worksheetId && (
        <div className="flexRowContainer mt15 flexFill flexAlignCenter">
          <div className="flexColumnContainer flexAlignCenter mbn16 formSectionHeader width-maximum mb10">
            <h4 className="ml10">{t('IMPACT_REPORT.SELECT_WORKSHEET')}</h4>
          </div>
          <hr />
          <SelectWorksheet
            workbookId={workbookId}
            worksheetIds={[]}
            setWorksheetIds={(worksheetIds) => {
              if (worksheetIds?.length > 0) setWorksheetId(worksheetIds[0]);
            }}
            workstepIds={[]}
            setWorkstepIds={() => {}}
            setTotalWorksheets={() => {}}
            isLoading={false}
          />
          <Button label={t('IMPACT_REPORT.CONTINUE')} disabled={!worksheetId} extraClassNames="mt10" />
        </div>
      )}

      {workbookId && worksheetId && (
        <>
          <SaveAndContinueFormBuilder
            submitBtnLabel="Save & Close"
            extraClassNames="flexFill overflow-auto height-maximum"
            continueButtonLabel="Save & Continue"
            continueButtonPosition="left"
            continueButtonExtraClassNames="mr10"
            continueButtonEnabled={stageIndex < availableStages.length - 1}
            continueButtonOnSubmit={async (values) => {
              await onFormSubmitCallback(values);
              if (stageIndex < availableStages.length - 1) {
                setStageIndex(stageIndex + 1);
              }
              doTrack(TRACK_IMPACT, 'Save and Continue Button', { stageIndex });
            }}
            continueButtonVariant="theme"
            formDefinition={formDefinition}
            submitFn={async (values) => {
              await onFormSubmitCallback(values);
              setRowDatumId(undefined);
              closeFn(true);
              setUpdateNominations(true);
              doTrack(TRACK_IMPACT, 'Save and Close Button', { stageIndex });
            }}
            closeFn={closeFn}
          />
        </>
      )}
    </div>
  );
};
