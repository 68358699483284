// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon, Select } from '@seeqdev/qomponents';
import { itemIconClass } from '@/utilities/utilities';
import { UnitOfMeasure } from '@/core/UnitOfMeasure.atom';
import { isItemRedacted } from '@/utilities/redaction.utilities';
import { FakeLink } from '@/core/FakeLink';

interface SelectItemWrapperProps {
  items: object[];
  selected: any;
  // for Asset Groups we need to compare the items to the group as well as the item ids will be the same for asset
  // column items
  selectByIdAndGroup?: boolean;
  allowClear: boolean;
  selectPlaceholder?: string;
  isMultipleSelect: boolean;
  disableAutoSelect?: boolean;
  loadingMetadata: boolean;
  noWrap: boolean;
  onRemove: (item: object) => void;
  onChange: (item: any) => void;
  isGrouped?: boolean;
  getCustomIcon?: (item: object) => string;
  stopPropagation?: boolean;
  addAllEnabled?: boolean;
  testId?: string;
  showError?: boolean;
  isDisabled?: boolean;
  extraClassNames?: string;
}

export const SelectItemWrapper: React.FunctionComponent<SelectItemWrapperProps> = (props) => {
  const { t } = useTranslation();

  const {
    items,
    selected,
    allowClear,
    onChange,
    isMultipleSelect,
    onRemove,
    loadingMetadata,
    disableAutoSelect,
    selectPlaceholder,
    noWrap = false,
    isGrouped = true,
    addAllEnabled = false,
    getCustomIcon = itemIconClass,
    stopPropagation = false,
    testId,
    showError = false,
    isDisabled = false,
    extraClassNames = '',
  } = props;

  // We have to close empty selection menu after "Add all".
  // To do this we simulate click event on selector container
  const closeSelectorMenu = () => {
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

    const simulateMouseClick = (element) => {
      mouseClickEvents.forEach((mouseEventType) =>
        element.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          }),
        ),
      );
    };

    const element = document.querySelector('.specOpenIt');
    if (element) simulateMouseClick(element);
  };

  const groupItems = (ungrouped) => {
    const groupItem = (item) => {
      switch (item?.selectGroup) {
        case 'assetGroupColumn':
          return 'ASSET_GROUP_EDITOR.ASSET_GROUP_COLUMNS';
        case 'original':
          return 'ASSET_GROUP_EDITOR.ORIGINAL_ASSIGNMENT';
        case 'pinned':
          return 'SEARCH_DATA.PINNED';
        case 'recentlyAccessed':
          return 'SEARCH_DATA.RECENTLY_ACCESSED';
        default:
          return 'DETAILS';
      }
    };
    return _.chain(ungrouped)
      .groupBy(groupItem)
      .map((obj) => {
        return { label: t(groupItem(obj[0])), options: obj };
      })
      .value();
  };

  const displayEntry = (item, wrapperStyle) => {
    if (!item) {
      return null;
    }

    const unitOfMeasure = item.displayUnitOfMeasure;
    const className = classNames('pr5', 'fa', 'sq-tool-description-color', getCustomIcon(item));

    if (isItemRedacted(item)) {
      return (
        <div className="flexColumnContainer pt8">
          <Icon icon="fa-warning" type="danger" extraClassNames="pr5" />
          <div id="specSelectedSignal" className="flexRowContainer flexFill pb1">
            <div className="flexColumnContainer specChoiceName font-size-smaller">{t('NO_ITEM_ACCESS')}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={wrapperStyle}>
          <Icon icon={className} type="color" color={item?.color || 'sq-text-color'} />
          <div
            id="specSelectedSignal"
            className={classNames('flexRowContainer flexFill pb1 selectItem_itemName', {
              'text-nowrap': noWrap,
              'aggressiveWordBreak': !noWrap,
            })}>
            <div>
              <strong className="mr5 specChoiceName">{item?.name} </strong>
              <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
            </div>

            <div className="xsmall itemAsset">
              {item?.assets?.[0]?.formattedName || item?.assets?.[0]?.datasource?.name}
            </div>
          </div>
        </div>
      );
    }
  };

  const getSelectedItemLabel = (item) => {
    const currentItem: any = _.isObject(item) ? _.find(items, { id: item.id }) : _.find(items, { id: item });
    return displayEntry(currentItem, 'flexColumnContainer flexCenter flexFill');
  };

  const getOptionLabel = (item) => {
    const wrapperClassName = classNames(
      'flexColumnContainer',
      'flexCenter',
      'cursorPointer',
      'pl10',
      'pr10',
      'pt2',
      'pb2',
    );
    return displayEntry(item, wrapperClassName);
  };

  const getOptionValue = (item) => item.id;
  const formatGroupLabel = (data) => {
    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        {isMultipleSelect && addAllEnabled && (
          <FakeLink
            testId="detailsAddAll"
            onClick={() => {
              data.options.forEach((item) => onChange([item]));
              closeSelectorMenu();
            }}>
            {t('DETAILS_SELECTION.ADD_ALL')}
          </FakeLink>
        )}
      </div>
    );
  };

  const prepareItemsForDisplay = (items) => {
    let displayItems: any[] = _.reject(items, (item) => isItemRedacted(item));
    if (isMultipleSelect) {
      displayItems = _.reject(displayItems, (item) => _.includes(selected, item.id));
    }
    return isGrouped ? groupItems(displayItems) : displayItems;
  };

  const matchesFilter = (option, input) => input === '' || _.includes(_.toLower(option.data.name), _.toLower(input));

  const displayItems = prepareItemsForDisplay(items);

  return (
    <div
      data-testid={testId}
      className={`${isGrouped ? 'flexAlignCenter ' : ''} ${
        isDisabled ? 'forceCursorNotAllowed' : ''
      } flexFillOverflow flexColumnContainer`}
      onClick={(e) => stopPropagation && e.stopPropagation()}>
      <Select
        placeholder={selectPlaceholder ? t(selectPlaceholder) : t('SELECT_ITEM')}
        isClearable={allowClear}
        value={selected}
        isMulti={isMultipleSelect}
        options={displayItems}
        formatGroupLabel={formatGroupLabel}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        getSelectedValueLabel={getSelectedItemLabel}
        menuPortalTarget={document.body}
        isLoading={loadingMetadata}
        filterOption={matchesFilter}
        extraClassNames={`flexFill ${props.extraClassNames}`}
        showError={showError}
        onRemove={onRemove}
        isDisabled={isDisabled}
        id={`selectItemWrapper-${testId}`}
      />
    </div>
  );
};

export default SelectItemWrapper;
