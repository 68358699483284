import { SeeqNames } from '@/main/app.constants.seeqnames';

export enum OutputType {
  CONDITION = 'CONDITION',
  SIGNAL = 'SIGNAL',
}

const packageName = SeeqNames.MlService.IsolationForest.Connection.UdfPackage;
const udfName = SeeqNames.MlService.IsolationForest.Connection.UdfName;
export const UDF_NAME = `${packageName}_${udfName}`;
