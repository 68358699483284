import { Icon } from '@seeqdev/qomponents';
import React from 'react';
import { SelectItemSearch } from '@/core/SelectItemSearch.molecule';
import { SelectedSearchItem } from '@/search/search.types';

interface SelectWorkbookProps {
  testId?: string;
  onChange: (value: SelectedSearchItem[]) => void;
  /** Selected workbook IDs */
  value: SelectedSearchItem[] | undefined;
  isMultiple?: boolean;
  title: string;
  showModal?: boolean;
  setShowModal?: (value: boolean) => void;
  setShowLocalAssetModal?: (value: boolean) => void;
  extraClassNames?: string;
  notSelectedMessage: string;
  showAddIcon?: boolean;
}

export const SelectWorkbooks: React.FunctionComponent<SelectWorkbookProps> = ({
  testId = 'selectWorkbooks',
  extraClassNames,
  notSelectedMessage,
  showModal,
  isMultiple,
  value,
  title,
  onChange,
  setShowModal = () => {},
  setShowLocalAssetModal = () => {},
  showAddIcon,
}) => {
  const clearAndClose = () => {
    setShowModal(false);
    setShowLocalAssetModal(true);
  };

  return (
    <SelectItemSearch
      testId={testId}
      value={value}
      onChange={onChange}
      onModalClose={clearAndClose}
      isMultiple={isMultiple}
      itemIcon={
        <Icon icon="fc-analysis" testId={`${testId}Icon`} type="inherit" extraClassNames="pl5 pr10" large={true} />
      }
      modalProps={{ selectionType: 'Analysis', title }}
      notSelectedMessage={notSelectedMessage}
      extraValueClassNames={extraClassNames}
      showModal={showModal}
      showAddIcon={showAddIcon}
    />
  );
};
