// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { PriorityPicker } from '@/workbook/PriorityPicker.molecule';
import { canUseMemo, getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';

export interface PriorityPickerIF extends ValidatingFormComponent<any> {
  component: 'PriorityPickerFormComponent';
  placement: TooltipPosition;
  buttonTooltip?: string;
  buttonTooltipPlacement?: TooltipPosition;
  buttonTestId: string;
  buttonClasses: string;
}

const PriorityPickerFormComponentUnwrapped: React.FunctionComponent<PriorityPickerIF> = (props) => {
  const { name, validation, extendValidation, extraClassNames } = props;

  const defaultValidation = (value) => _.isEmpty(value);
  const formState = useForm().getState();

  return (
    <Field name={name} validate={getValidationFunction(defaultValidation, extendValidation, validation)}>
      {({ input, meta }) => (
        <div className={extraClassNames}>
          <PriorityPicker {...getFormFieldProps(formState, input, meta, props)} />
        </div>
      )}
    </Field>
  );
};

export const PriorityPickerFormComponent = React.memo(PriorityPickerFormComponentUnwrapped, canUseMemo);
