import React from 'react';
import { Modal } from '@seeqdev/qomponents';
import { TrainingPromptContent } from '@/homescreen/TrainingPrompt.molecule';

interface TrainingPromptModalProps {
  onClose: () => void;
}

/**
 * This component renders the Modal that shows the Training "Get Started" prompt for the Home Screen Page.
 * It is accessible via the "Get Started" link from the "Helpful Links" section once the prompt on the Home Screen
 * Page is dismissed.
 */
export const TrainingPromptModal: React.FunctionComponent<TrainingPromptModalProps> = ({ onClose }) => {
  return (
    <Modal open={true} onClose={onClose} testId="trainingPromptModal" title="" hideFooterButtons={true}>
      <div>
        <TrainingPromptContent isModal onClose={onClose} />
      </div>
    </Modal>
  );
};
