import React from 'react';
import { useTranslation } from 'react-i18next';
import { CancelAndExecute } from '@/core/CancelAndExecute.molecule';

export type ForgotPasswordConfirmationProps = {
  closeFn: () => void;
};

export const ForgotPasswordConfirmation: React.FunctionComponent<ForgotPasswordConfirmationProps> = ({ closeFn }) => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t('LOGIN_PANEL.REQUEST_RESET_CONFIRMATION.INTRO')}</p>
      <p>{t('LOGIN_PANEL.REQUEST_RESET_CONFIRMATION.INSTRUCTION')}</p>
      <p className="mt10">{t('LOGIN_PANEL.REQUEST_RESET_CONFIRMATION.FALLBACK')}</p>
      <p className="mt10 mb25">{t('LOGIN_PANEL.REQUEST_RESET_CONFIRMATION.RETRY_CLAUSE')}</p>
      <CancelAndExecute
        hideCancel={true}
        btnDisabled={false}
        cancelAction={() => Promise.resolve()}
        submitBtnLabel="LOGIN_PANEL.REQUEST_RESET_CONFIRMATION.CLOSE"
        submitAction={closeFn}
      />
    </>
  );
};
