// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import tinygradient from 'tinygradient';
import tinycolor from 'tinycolor2';
import Highcharts from 'highcharts';
import { API_TYPES } from '@/main/app.constants';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { nanosToMillis, overlaps } from '@/datetime/dateTime.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getShortIdentifier } from '@/utilities/utilities';
import { PropertyColumn, StatColumn } from '@/utilities/formula.constants';
import { ITEM_DATA_STATUS, ITEM_TYPES, TREND_COLORS } from '@/trendData/trendData.constants';
import { FormulaParameterOutputV1 } from '@/sdk/model/FormulaParameterOutputV1';
import { sqDurationStore, sqTrendCapsuleStore, sqTrendConditionStore, sqTrendSeriesStore } from '@/core/core.stores';
import {
  CapsulePropertyColorsConfig,
  EMPTY_XY_REGION,
  isBoostedData,
  isDataEnoughForBoost,
  MAX_MARKER_SIZE_CALCULATED,
  MIN_MARKER_SIZE_CALCULATED,
  MIN_SAMPLES_FOR_BOOST,
  NumericCapsulePropertyColorsConfig,
  Range,
  SCATTER_PLOT_COLORS,
  SCATTER_PLOT_MODES,
  SCATTER_PLOT_OPACITY,
  SCATTER_PLOT_REDUCED_OPACITY,
  SCATTER_PLOT_VIEWS,
  ScatterPlotColorRange,
  ScatterPlotData,
  ScatterPlotFormula,
  ScatterPlotFXLineUpdate,
  ScatterPlotSignal,
  ScatterPlotSingleTableData,
  SeeqScatterPlotPoint,
  SignalId,
  StringCapsulePropertyColorsConfig,
  XYPlotRegion,
} from '@/scatterPlot/scatterPlot.constants';
import { computeLightestColor } from '@/core/html.utilities';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { getRegressionModelFormula } from '@/utilities/predictionHelper.utilities';
import { buildFilterFragmentForConditions } from '@/utilities/formula.utilities';
import { Extremes } from 'other_components/highcharts';

export function axesToYs(axes: Highcharts.Axis[] | Highcharts.Axis): Record<SignalId, Extremes> {
  const normalizedAxes = _.flatMap([axes]);
  const ys = {};
  _.forEach(normalizedAxes, (axis) => {
    ys[axis.userOptions.signalId] = {
      min: axis.min,
      max: axis.max,
    };
  });
  return ys;
}

export class ScatterPlotStore extends Store {
  static readonly storeName = 'sqScatterPlotStore';
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  /**
   * rawData and scatterData are store-level variables and not kept in this.state because,
   * when they contain lots of data, serializing and deserializing these is computationally
   * intensive and slow. This does mean that these variables aren't frozen/immutable.
   */
  rawPlotData = null;
  scatterPlotData: ScatterPlotData = [];

  /**
   * Initializes the store by setting default values.
   */
  initialize() {
    this.state = this.immutable({
      xSignal: null,
      ySignals: [],
      // Tracks (and emits events) when scatterData changes, since scatterData is not in the baobab store
      scatterDataChangeCount: 0,
      selector: {
        low: 0.5,
        high: 0.75,
      },
      selectedRegion: EMPTY_XY_REGION,
      viewRegion: EMPTY_XY_REGION,
      gradientConfig: undefined,
      fxLines: [],
      colorConditionIds: [],
      colorCapsuleProperty: undefined,
      capsulePropertyColorsConfig: undefined,
      colorSignalId: undefined,
      colorByItemColor: false,
      colorRanges: [],
      plotMode: SCATTER_PLOT_MODES.DISPLAY_RANGE,
      plotView: SCATTER_PLOT_VIEWS.SCATTER_PLOT,
      connect: false,
      showTooltips: true,
      markerSize: undefined,
      isMarkerSizeCustom: false,
      minimapSignals: [],
      densityPlotData: [],
      // Most of our users have screen resolutions in the 1700-2500 px (x) by 1000-1300 px (y) range.
      // After subtracting off the worksheet, tool, details, and investigate panels, we estimate that a
      // typical chart size would be ~1600 px by ~800 px. To get approximately square default bins, then,
      // we set the default # x bins to 40 and # y bins to 20.
      numXBins: 40,
      numYBins: 20,
      xBinSize: 0,
      yBinSize: 0,
      showColorModal: false,
      scatterPlotExtremes: EMPTY_XY_REGION,
      groupCount: 0,
      seriesCount: 0,
    });
    this.rawPlotData = null;
    this.scatterPlotData = [];
  }

  get storeState() {
    return this.state.get();
  }

  get xSignal(): ScatterPlotSignal | null {
    return this.state.get('xSignal');
  }

  get ySignals(): ScatterPlotSignal[] {
    return this.state.get('ySignals');
  }

  get selectedRegion(): XYPlotRegion {
    return this.state.get('selectedRegion');
  }

  get viewRegion(): XYPlotRegion {
    return this.state.get('viewRegion');
  }

  get scatterPlotExtremes() {
    return this.state.get('scatterPlotExtremes');
  }

  isRegionSelected = () => {
    return this.isPlotRegionSelected();
  };

  isViewRegionSet = () => {
    return this.isPlotViewRegionSet();
  };

  isScatterDataEmpty = () => {
    return this.isScatterPlotDataEmpty();
  };

  calculateMarkerSize = () => {
    return this.calculatePlotMarkerSize();
  };

  get scatterData(): ScatterPlotData {
    // scatterData is not frozen in the store, so we have to be careful not to change it ourselves.
    // If you need to change scatterData, be sure to reassign it instead of mutating the existing array.
    return this.scatterPlotData;
  }

  get isDensityDataEmpty() {
    return this.isDensityPlotDataEmpty();
  }

  get densityPlotData() {
    return this.state.get('densityPlotData');
  }

  get rawData() {
    // rawPlotData is not frozen in the store, so we have to be careful not to change it ourselves.
    // If you need to change rawPlotData, be sure to reassign it instead of mutating th existing object.
    return this.rawPlotData;
  }

  get fxLines() {
    return this.state.get('fxLines');
  }

  /**
   * Determines is a signal is a fx line.
   *
   * @param {string} id - the id of the signal
   * @returns true if the signal is a fx line, false otherwise.
   */
  isFxLine = (id: string): boolean => {
    return this.state.get('fxLines').some((fxLine: { id: string }) => fxLine.id === id);
  };

  get colorConditionIds() {
    return this.state.get('colorConditionIds');
  }

  get colorCapsuleProperty() {
    return this.state.get('colorCapsuleProperty');
  }

  get colorSignalId() {
    return this.state.get('colorSignalId');
  }

  get colorRanges() {
    return this.state.get('colorRanges');
  }

  get gradientConfig() {
    return this.state.get('gradientConfig');
  }

  get capsulePropertyColorsConfig() {
    return this.state.get('capsulePropertyColorsConfig');
  }

  get selector() {
    return this.state.get('selector');
  }

  get minimapSignals(): ScatterPlotSignal[] {
    return this.state.get('minimapSignals');
  }

  get plotMode() {
    return this.state.get('plotMode');
  }

  get plotView() {
    return this.state.get('plotView');
  }

  get connect() {
    return this.state.get('connect');
  }

  get showTooltips() {
    return this.state.get('showTooltips');
  }

  get markerSize() {
    return this.state.get('markerSize');
  }

  get scatterDataChangeCount() {
    return this.state.get('scatterDataChangeCount');
  }

  get showColorModal() {
    return this.state.get('showColorModal');
  }

  get colorByItemColor() {
    return this.state.get('colorByItemColor');
  }

  getColorPropertyValueFromCapsule = (capsule, timezone?) => {
    return this.getColorPropertyValueFromCapsuleForPlot(capsule, timezone);
  };

  getCapsulePropertyColor = (propertyValue, colorsConfig) => {
    return this.getCapsulePropertyColorForPlot(propertyValue, colorsConfig, false);
  };

  /**
   * Returns the formula for the xyTable operator for the scatter plot visualization with support for capsule
   * mode and being zoomed into a view region. Formulas is constructed to ensure that the data is only passed
   * over once for each signal. Also returns the formula needed to compute the maxSamples if in capsule mode or if
   * the user is zoomed in on a view range. Besides xy data, the table might contain additional columns used
   * later for coloring (color conditions and color signal).
   *
   * @param {string} xId - The ID of the X signal
   * @param {string} yId - The ID of the Y signal
   * @param {string} queryRangeCapsule - The capsule formula for the query range
   * @param {string[]} colorConditionIds - An array containing the id of conditions for coloring. For each
   * condition, a new column will be added in the result table. Cell values will be 0 or 1 (see addCondition
   * operator for details)
   * @param {string} colorSignalId - The id of the signal for coloring. A new column containing the values of this
   * signal will be added in the table (see addSignal operator for details).
   * @param colorCapsuleProperty - capsule property to color data points on.
   * @param propertyColumns - property columns used for filtering data when coloring or when filtering by condition
   * @param statisticsColumns - statistics columns used for filtering
   * @return {ScatterPlotFormula} The map of parameters for the xyTableFormula and, if applicable, the
   *   sampleLimitFormula. Note that for the xyTableFormula the sampleLimit parameter is denoted by the
   *   {sampleLimit} token which must be replaced with the actual number.
   */
  getXyFormulaAndParameters = (
    xId: string,
    yId: string,
    queryRangeCapsule: string,
    propertyColumns: PropertyColumn[],
    statisticsColumns: StatColumn[],
    colorConditionIds?: any[],
    colorSignalId?: any,
    colorCapsuleProperty?: string | undefined,
  ): ScatterPlotFormula => {
    const parameters = { xSignal: xId, ySignal: yId };
    const xSignalFormula = '$xSignal';
    const ySignalFormula = '$ySignal';
    let conditionsFormula = '';
    let sampleLimitFormula;
    const xyTableArgs = [queryRangeCapsule, '{xSignalFormula}', '{ySignalFormula}', '{sampleLimit}', 'true', 'false'];

    const getFormulaForCondition = (identifier: string, id: string) => {
      return buildFilterFragmentForConditions(
        identifier,
        id,
        queryRangeCapsule,
        propertyColumns,
        statisticsColumns,
        parameters,
      );
    };
    let itemIndex = 0;
    if (this.state.get('plotMode') === SCATTER_PLOT_MODES.CAPSULES) {
      const joinedConditions = _.map(
        getAllItems({
          workingSelection: true,
          excludeDataStatus: [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE],
          itemTypes: [ITEM_TYPES.CONDITION],
        }),
        (item: any) => {
          const identifier = getShortIdentifier(itemIndex++);
          parameters[identifier] = item.id;
          return getFormulaForCondition(identifier, item.id);
        },
      ).join(', ');

      if (joinedConditions) {
        conditionsFormula = sampleLimitFormula = `combineWith(${joinedConditions})`;
      }
    }

    if (conditionsFormula) {
      xyTableArgs.push(conditionsFormula);
    }

    if (this.isPlotViewRegionSet()) {
      const region: XYPlotRegion = this.state.get('viewRegion');
      const y = region.ys[yId];
      const xSearch = `$xSignal.valueSearch(isBetween(${region.x.min}, ${region.x.max}))`;
      const ySearch = `$ySignal.valueSearch(isBetween(${y.min}, ${y.max}))`;
      sampleLimitFormula = `intersect(${xSearch}, ${ySearch})`;
      if (conditionsFormula) {
        sampleLimitFormula += `.intersect(${conditionsFormula})`;
      }

      xyTableArgs.push(region.x.min.toString());
      xyTableArgs.push(region.x.max.toString());
      xyTableArgs.push(y.min.toString());
      xyTableArgs.push(y.max.toString());
    }

    if (sampleLimitFormula) {
      sampleLimitFormula += `.percentDuration(${queryRangeCapsule})`;
    }

    let xyTableFormula = `xyTable(${xyTableArgs
      .join(', ')
      .replace('{xSignalFormula}', xSignalFormula)
      .replace('{ySignalFormula}', ySignalFormula)})`;

    if (colorConditionIds) {
      _.forEach(colorConditionIds, (conditionId) => {
        const identifier = getShortIdentifier(itemIndex++);
        parameters[identifier] = conditionId;
        const conditionFormula = getFormulaForCondition(identifier, conditionId);
        xyTableFormula = `${xyTableFormula}.addCondition('Condition(${conditionId})',${conditionFormula})`;
      });
    }

    if (colorCapsuleProperty) {
      const colorConditionFormulas = [];
      const conditions = _.map(
        getAllItems({
          workingSelection: true,
          excludeDataStatus: [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE],
          itemTypes: [ITEM_TYPES.CONDITION],
        }),
        'id',
      );
      _.forEach(conditions, (conditionId) => {
        let identifier;
        if (_.includes(_.values(parameters), conditionId)) {
          identifier = _.findKey(parameters, (param) => param === conditionId);
        } else {
          identifier = getShortIdentifier(itemIndex++);
          parameters[identifier] = conditionId;
        }
        colorConditionFormulas.push(getFormulaForCondition(identifier, conditionId));
      });
      const colorCapsulePropertyFormula =
        colorConditionFormulas.length > 0
          ? `.addCondition('Property(${colorCapsuleProperty})', ` +
            `combineWith(${colorConditionFormulas.join(',')}), ` +
            `'${colorCapsuleProperty}')`
          : '';
      xyTableFormula = `${xyTableFormula}${colorCapsulePropertyFormula}`;
    }

    if (colorSignalId) {
      const identifier = getShortIdentifier(itemIndex++);
      parameters[identifier] = colorSignalId;
      xyTableFormula = `${xyTableFormula}.addSignal('Signal(${colorSignalId})',$${identifier})`;
    }

    const cancellationGroup = `scatterPlotData_${yId}`;

    return {
      parameters,
      xyTableFormula,
      sampleLimitFormula,
      cancellationGroup,
    };
  };

  /**
   * Determines if the signal can be plotted in scatter plot. It must either be a formula with a single input
   * signal (e.g. f(x) formula) or a regression model made using the frontend Prediction tool with only one input
   * signal. Note that if the signal is a formula, then it may contain scalars.
   *
   * @param {Object[]} parameters - The array of parameters of the calculated signal
   * @param {string} formula - The formula of the calculated signal
   * @return {boolean} True if it can be plotted as a function of x line, false otherwise
   */
  isValidFxSignal(parameters, formula) {
    const nonScalarParameters = _.reject(parameters, (parameter) =>
      _.includes([API_TYPES.CALCULATED_SCALAR, API_TYPES.LITERAL_SCALAR], parameter.item.type),
    );

    const validsignal =
      (_.size(nonScalarParameters) === 1 &&
        _.includes([API_TYPES.STORED_SIGNAL, API_TYPES.CALCULATED_SIGNAL], _.first(nonScalarParameters).item.type)) ||
      (_.chain(parameters)
        .reject((parameter) =>
          _.includes([API_TYPES.STORED_CONDITION, API_TYPES.CALCULATED_CONDITION], parameter.item.type),
        )
        .size()
        .value() === 2 &&
        !!getRegressionModelFormula(formula));

    return validsignal;
  }

  /**
   * Gets the formula to use for plotting a function of x line from a signal with a single input signal variable in
   * its formula. Replaces the variable with timeSince() which will provide evenly spaced X-axis values as the
   * keys and the values will be the Y-axis values.
   *
   * @param functionFormula - The formula of the signal
   * @param parameter - The parameter in the formula that will be replaced
   * @param step - The increment step along the X-axis.
   * @param unit - The unit of measure to use for the formula. Should be determined using #getScalingFactorAndUnit()
   * @return {string} The formula to use to get X and Y values for plotting on scatter plot
   */
  getFxLineFormulaFromFunction(
    functionFormula: string,
    parameter: FormulaParameterOutputV1,
    step: number,
    unit: string,
  ): string {
    let timeSince = `timeSince(0${unit}, ${step}${unit})`;
    if (parameter.item?.valueUnitOfMeasure) {
      timeSince += `.setUnits('${parameter.item.valueUnitOfMeasure}')`;
    } else {
      timeSince += ".setUnits('')";
    }

    return functionFormula.replace(new RegExp(`\\$\\b${_.escapeRegExp(parameter.name)}+\\b`, 'g'), timeSince);
  }

  /**
   * Gets the formula to use to show a function of x line based off of a Prediction signal. Figures out the
   * correct mx+b formula based on coefficient type (e.g. polynomial, logarithmic, etc.) and intercept.
   *
   * @param model - The regression model computed by the backend
   * @param formula - The formula of the Prediction signal
   * @param step - The increment step along the X-axis.
   * @param unit - The unit of measure to use for the formula. Should be determined using #getScalingFactorAndUnit()
   * @return {string} The formula to use to get X and Y values for plotting on scatter plot
   */
  getFxLineFormulaFromModel(model: any, formula: string, step: number, unit: string): string {
    const isLogPrediction = _.includes(formula, 'ln($a)');
    return _.chain(model.table.data)
      .map(([coefficient], index: number) => {
        if (index === 0) {
          return `${coefficient} * timeSince(0${unit}, ${step}${unit}).setUnits('')`;
        } else if (isLogPrediction) {
          return `${coefficient} * ln(timeSince(0${unit}, ${step}${unit}).setUnits(''))`;
        } else {
          return `${coefficient} * (timeSince(0${unit}, ${step}${unit}).setUnits(''))^${index + 1}`;
        }
      })
      .push(model.regressionOutput.intercept)
      .join(' + ')
      .value();
  }

  /**
   * These functions are used to time-encode the X-axis range of scatterplot. We make use of the inherent scaling
   * of Calc Engine units to achieve reasonable fidelity, even though query parameters are in long nanoseconds.
   * This was necessary after the change to the Calc Engine to prohibit non-time keys.
   * See for more details: https://seeq.atlassian.net/wiki/spaces/SQ/pages/2308210853/
   *
   *  @param range - The x-axis range that will be used for computing the scatterplot data
   */
  getScalingFactorAndUnit(range: Range): [number, string] {
    const SCALING_FACTORS: [number, string][] = [
      [1, 'ms'],
      [1000, 's'],
      [1000 * 60, 'min'],
      [1000 * 60 * 60, 'hour'],
      [1000 * 60 * 60 * 24, 'day'],
      [1000 * 60 * 60 * 24 * 7, 'week'],
    ];
    const UPPER_BOUND = Number.MAX_SAFE_INTEGER / (1000 * 60 * 60 * 24 * 7);
    // Minimum duration required to get accurate data. The number was determined by experimentation and the fact
    // that Javascript ISO-8601 have milliseconds as their smallest unit and so the duration must be large enough
    // to get data when converted to milliseconds.
    const MINIMUM_DURATION = 100;
    const duration = range.end - range.start;
    if (range.end <= range.start) {
      throw new Error(`Range end ${range.end} must be greater than range start ${range.start}`);
    }

    if (range.start > UPPER_BOUND || range.end > UPPER_BOUND || duration > UPPER_BOUND) {
      throw new Error('SCATTER.FX_LINE_X_AXIS_TOO_LARGE');
    }

    const factorAndUnit = _.find(SCALING_FACTORS, ([scalingFactor]) => {
      const scaledDuration = duration * scalingFactor;
      return scaledDuration >= MINIMUM_DURATION && scaledDuration <= UPPER_BOUND;
    });

    if (!factorAndUnit) {
      throw new Error('SCATTER.FX_LINE_X_AXIS_TOO_SMALL');
    }

    return factorAndUnit;
  }

  /**
   * Determines if a prediction signal's training window is outside the display range.
   *
   * @param {string} formula - The Prediction signal formula
   * @param {Object} displayRange - The current display range
   * @return {boolean} True if the training window is outside the display range
   */
  isRegressionFormulaOutsideRange = (formula, displayRange) => {
    const match = formula.match(/capsule\("(.*?)", "(.*?)"\)/);
    if (match) {
      const trainingWindowStart = moment.utc(match[1]);
      const trainingWindowEnd = moment.utc(match[2]);
      if (trainingWindowStart > displayRange.end || trainingWindowEnd < displayRange.start) {
        return true;
      }
    }

    return false;
  };

  /**
   * Determines whether to auto-color points on the scatter plot by a newly calculated condition (created in the
   * Condition from Scatter Plot Selection tool) if it is a new condition and its parameter signals are
   * providing the scatter plot data.
   * @param parameters {Object} - the parameters of the condition formula
   */
  isRelevantCondition = (parameters) => {
    const xSignalParam = parameters.find((x) => x.name === 'xSignal');
    const ySignalParam = parameters.find((x) => x.name === 'ySignal');
    return (
      (this.state.get('xSignal')?.id === xSignalParam.item.id &&
        _.includes(_.map(this.state.get('ySignals'), 'id'), ySignalParam.item.id)) ||
      (this.state.get('xSignal')?.id === ySignalParam.item.id &&
        _.includes(_.map(this.state.get('ySignals'), 'id'), xSignalParam.item.id))
    );
  };

  getDensityPlotFormula = (xId: string, yId: string, queryRangeCapsule: string) => {
    const parameters = {
      xSignal: xId,
      ySignal: yId,
    };
    const xSignalFormula = '$xSignal';
    const ySignalFormula = '$ySignal';
    const numXBins = this.state.get('numXBins');
    const numYBins = this.state.get('numYBins');

    let formula = `heatmapTable(${queryRangeCapsule}, ${xSignalFormula}, ${ySignalFormula}, ${numXBins}, ${numYBins}`;

    if (this.isPlotViewRegionSet()) {
      const view: XYPlotRegion = this.state.get('viewRegion');
      const y = view.ys[yId];
      formula = `${formula}, ${view.x.min}, ${view.x.max}, ${y.min}, ${y.max})`;
    } else {
      formula = `${formula})`;
    }

    const cancellationGroup = 'densityPlotData';

    return { formula, parameters, cancellationGroup };
  };

  /**
   * Determines the range of values on X axis based on view region and heat map data
   *
   * @return {Object} The value range on X axis
   */
  getXAxisRange = (): Range => {
    return this.getPlotXAxisRange();
  };

  getYAxisRange = (id: string): Range => {
    return this.getPlotYAxisRange(id);
  };

  getColorAxisRange = () => {
    return this.getPlotColorAxisRange();
  };

  get numXBins() {
    return this.state.get('numXBins');
  }

  get numYBins() {
    return this.state.get('numYBins');
  }

  get xBinSize() {
    return this.state.get('xBinSize');
  }

  get yBinSize() {
    return this.state.get('yBinSize');
  }

  /**
   * Dehydrates the item by retrieving the current set parameters in view
   *
   * @returns {Object} An object with the state properties as JSON
   */
  dehydrate(): Record<string, any> {
    const state = _.omit(
      this.state.serialize(),
      'minimapSignals',
      'gradientConfig',
      'scatterDataChangeCount',
      'showColorModal',
      'scatterPlotExtremes',
    ) as any;
    state.fxLines = _.map(state.fxLines, (line) => _.pick(line, ['id', 'color', 'ySignalId', 'lineWidth']));
    return state;
  }

  /**
   * Rehydrates item from dehydrated state
   * Resets view to scatter plot if the heat map has been disabled
   *
   * @param {Object} dehydratedState State object that should be restored
   */
  rehydrate(dehydratedState: Record<string, any>) {
    this.state.merge(dehydratedState);
  }

  /**
   * Sets the scatter plot mode
   *
   * @param {Object} payload - An object container for the parameters
   * @param {String} payload.plotMode - The desired plot mode (either SCATTER_PLOT_MODES.DISPLAY_RANGE
   * or SCATTER_PLOT_MODES.CAPSULES)
   */
  private setPlotMode = (payload) => {
    this.state.set('plotMode', payload.plotMode);
  };

  private setPlotView = (payload) => {
    this.state.set('plotView', payload.plotView);
  };

  /**
   * Sets whether the samples should be connected by a line on the display
   *
   * @param {Object} payload - An object container for the parameters
   * @param {String} payload.connect- True to connect samples with a line, false to not connect them.
   */
  private setConnect = (payload) => {
    this.state.set('connect', payload.connect);
  };

  /**
   * Sets a region of the chart as selected.
   *
   * @param payload - region to select
   */
  private setSelectedRegion = (payload: XYPlotRegion) => {
    this.state.set('selectedRegion', payload);
  };

  /**
   * Sets the view region of the chart.
   *
   * @param payload - region to view
   */
  private setViewRegion = (payload: XYPlotRegion) => {
    this.state.set('viewRegion', payload);
  };

  /**
   * Sets whether or not to show tooltips/labels on scatter plot points when a user hovers over them
   *
   * @param {Object} payload - An object container for the parameters
   * @param {boolean} payload.showTooltips - whether or not ot show tooltips
   */
  private setShowTooltips = (payload) => {
    this.state.set('showTooltips', payload.showTooltips);
  };

  /**
   * Sets the value of the left and right sides of the scatter plot region selectors
   *
   * @param {Object} payload - An object container for the parameters
   * @param {number} payload.low - The value
   * @param {number} payload.high - The value
   */
  private setSelectors = (payload) => {
    this.state.set('selector', { low: payload.low, high: payload.high });
    this.refreshView();
  };

  /**
   * Update the Scatterplot based on a table of values.
   * Table format:
   * time   x    y   Condition(conditionID)    Condition(conditionID)    Signal(signalId)}.
   *    2   1.5  2.5        0                             1                     0.3
   *    2   1.7  2.1        1                             1                     0.5
   * The conditions are used (if selected by user) to determine the color of a point.
   * The values of the signal are used (if selected by user) to determine the gradient color of a point.
   *
   * @param payload - An object container for the parameters
   * @param payload.results - contains the list of table results
   * @param payload.results[].headers - contains table header information
   * @param payload.results[].data - contains an array with data
   */
  private setScatterData = (payload: { results: { headers: any; data: any[] }[] }) => {
    this.rawPlotData = payload;

    const selectedCapsules = _.filter(
      sqTrendCapsuleStore.items,
      (capsule) => capsule.selected && _.isFinite(capsule.startTime) && _.isFinite(capsule.endTime),
    );
    const capsuleProperty = this.state.get('colorCapsuleProperty');
    const reduceNonSelectedOpacity = !_.isEmpty(selectedCapsules);
    const colorRanges = this.state.get('colorRanges');
    const itemColors = this.state.get('colorByItemColor')
      ? _.map(this.state.get('ySignals'), (signal) => sqTrendSeriesStore.findItem(signal.id).color)
      : [];

    const datumReducer = (memo, datum) => ({
      xMin: !memo.xMin || memo.xMin > datum.x ? datum.x : memo.xMin,
      xMax: !memo.xMax || memo.xMax < datum.x ? datum.x : memo.xMax,
      yMin: !memo.yMin || memo.yMin > datum.y ? datum.y : memo.yMin,
      yMax: !memo.yMax || memo.yMax < datum.y ? datum.y : memo.yMax,
    });

    const SCATTERPLOT_EXTREMES_DEFAULT_VALUE = {
      xMin: undefined,
      xMax: undefined,
      yMin: undefined,
      yMax: undefined,
    };

    const data = _.chain(payload.results)
      .map((result, dataIndex) => {
        const tableHeader = result.headers;
        const tableData = result.data;

        const conditionColorsConfig = this.computeConditionColorsConfig(tableHeader);
        const capsulePropertyColorsConfig = this.computeCapsulePropertyColorsConfig(tableHeader, tableData);
        const gradientConfig = this.computeGradientConfig(tableHeader, tableData);
        const timeRangeConfig = this.computeTimeRangeConfig();

        this.state.set('gradientConfig', gradientConfig);
        this.state.set('capsulePropertyColorsConfig', capsulePropertyColorsConfig);

        let runningExtremes = SCATTERPLOT_EXTREMES_DEFAULT_VALUE;
        let gradientColor: string | undefined;
        const singleTableData: ScatterPlotSingleTableData = _.chain(tableData)
          .reject((row) => _.isNil(row[1]) || _.isNil(row[2])) // Invalid values are not used by the Scatterplot
          .reduce(
            (groupedData, row) => {
              // Color is taken by first criteria that match:
              const [color, isGradient] = _.reduce(
                [
                  (row) => [
                    this.getColorUsingSelectedCapsules(
                      row[0],
                      selectedCapsules,
                      capsuleProperty,
                      capsulePropertyColorsConfig,
                    ),
                    false,
                  ],
                  (row) => [
                    this.getColorUsingCapsuleProperty(row, capsulePropertyColorsConfig, reduceNonSelectedOpacity),
                    false,
                  ],
                  (row) => [this.getColorUsingCondition(row, conditionColorsConfig, reduceNonSelectedOpacity), false],
                  (row) => [this.getColorUsingColorRanges(row[0], colorRanges, reduceNonSelectedOpacity), false],
                  (row) => [this.getColorUsingSignalGradient(row, gradientConfig, reduceNonSelectedOpacity), true],
                  (row) => [this.getColorUsingItemColor(itemColors, dataIndex), false],
                  (row) => [this.getColorUsingDisplayRange(row[0], timeRangeConfig, reduceNonSelectedOpacity), false],
                ],
                ([color, isGradient], getColor) => {
                  if (color) {
                    return [color, isGradient];
                  }

                  // to next iteration: pass current color, pass gradient yes/no status
                  return getColor(row);
                },
                [undefined, false],
              );

              let pointColor = (
                _.startsWith(color, '#') ? tinycolor(color).setAlpha(SCATTER_PLOT_OPACITY).toString() : color
              ) as Highcharts.ColorString;

              gradientColor = isGradient && !gradientColor ? pointColor : gradientColor;

              const preparedPoint: Partial<SeeqScatterPlotPoint> = {
                color: pointColor,
                time: row[0],
                x: row[1],
                y: row[2],
                lineColor: isGradient ? gradientColor : pointColor,
              };

              runningExtremes = datumReducer(runningExtremes, preparedPoint);

              // Group by color - if this point's color is different, create new group
              let group = _.last(groupedData)!;
              const previousColor = _.last(group)?.lineColor ?? preparedPoint.lineColor;
              if (previousColor !== preparedPoint.lineColor) {
                group = [];
                groupedData.push(group);
              }
              group.push(preparedPoint);

              return groupedData;
            },
            [[]],
          )
          .value();

        return {
          singleTableData,
          runningExtremes,
        };
      })
      .thru((data) => {
        if (
          data.some((datum) => {
            const pointCount = _.sumBy(datum.singleTableData, (group) => group.length);
            return isDataEnoughForBoost(pointCount, datum.singleTableData.length);
          })
        ) {
          return data.map((datum) => ({
            runningExtremes: datum.runningExtremes,
            singleTableData: _.flatten(datum.singleTableData) as ScatterPlotSingleTableData,
          }));
        }
        return data;
      })
      .value();

    this.scatterPlotData = data.map((datum) => datum.singleTableData);
    this.incrementScatterDataChangeCount();

    const ySignals: ScatterPlotSignal[] = this.state.get('ySignals');
    const scatterPlotExtremes: XYPlotRegion = _.chain(data)
      .map((data) => data.runningExtremes)
      .thru((extremes) => {
        if (extremes.length === 0) {
          return EMPTY_XY_REGION;
        }

        const ys = {};
        _.forEach(extremes, (extreme, index) => {
          ys[ySignals[index].id ?? ySignals[0].id] = {
            min: extreme.yMin,
            max: extreme.yMax,
          };
        });
        return {
          // All x values should be the same
          x: { min: extremes[0].xMin, max: extremes[0].xMax },
          ys,
        };
      })
      .value();
    this.state.set('scatterPlotExtremes', scatterPlotExtremes);

    if (!this.state.get('isMarkerSizeCustom')) {
      this.state.set('markerSize', this.calculatePlotMarkerSize());
    }
  };

  /**
   * Update the Heat Map based on a table of values. Each row contains data for one cell on the plot. The third column
   * contains either duration values or the average values of a third signal while the x and y signals were in a
   * particular cell.
   * Table format:
   * x    y    value
   * 1.5  2.5  0.3
   * 1.7  2.1  0.5
   * The values in the third column are used to determine the color of a point.
   *
   * @param {Object} payload - An object container for the parameters
   * @param {Object[]} payload.data - contains an array with data
   */
  private setDensityPlotData = (payload) => {
    this.rawPlotData = payload;

    this.state.set('densityPlotData', payload.data);

    const xRange = this.getPlotXAxisRange();
    const yRange = this.getDensityPlotYAxisRange();

    const xBinSize = (xRange.end - xRange.start) / this.state.get('numXBins');
    const yBinSize = (yRange.end - yRange.start) / this.state.get('numYBins');
    this.state.set('xBinSize', xBinSize);
    this.state.set('yBinSize', yBinSize);
  };

  /**
   * Compute the configuration for time range coloring. This is used for coloring the points based on sample
   * timestamp.
   *
   * @return {Object} the object with values for attributes split1, split2, selectorHidden.
   */
  private computeTimeRangeConfig = () => {
    const selector = this.state.get('selector');
    const regionSliderRange = sqDurationStore.displayRange;
    const start = regionSliderRange.start;
    const duration = regionSliderRange.end.valueOf() - start.valueOf();
    const split1 = selector.low * duration + start.valueOf();
    const split2 = selector.high * duration + start.valueOf();
    const selectorHidden =
      (selector.low < 0 && selector.high < 0) ||
      !_.chain(this.state.get('colorRanges'))
        .filter(({ range }) =>
          overlaps(range, {
            startTime: regionSliderRange.start,
            endTime: regionSliderRange.end,
          }),
        )
        .isEmpty()
        .value();

    return { split1, split2, selectorHidden };
  };

  /**
   * Compute the configuration for gradient coloring.
   * The base color for gradient is taken from the signal column specified in the header.
   *  - darkest color is the base color and it corresponds to the maximum value of the signal in the table data.
   *  - lightest color corresponds to the minimum value of the signal in the table data. To compute this color we
   * detect the maximum lightness factor that can be applied to the base color and the result is not #ffffff white.
   * Then we apply lightness with 90% of this factor so that the color is easily visible on screen.
   *  - there are 100 intermediate nuances between lightest and darkest color. This will allow fast computation of
   *  color for many values without the need to apply 'tinygradient' algorithm for each point.
   *
   * @param tableHeader - The table header of raw data received from backend. The function looks for a column with
   * format 'Signal(signalId)'
   * @param tableData - The data array from raw data received from backend. This is used to determine the min and
   * max value for the signal and set min and max color for the gradient.
   * @return {Object|undefined} the configuration for gradient coloring
   */
  private computeGradientConfig = (tableHeader, tableData) => {
    const { startIndex, itemIds } = this.findItemsInTableHeader(tableHeader, /Signal\((.*?)\)/);

    // single column expected
    if (itemIds.length === 0) {
      return;
    }

    if (itemIds.length > 1) {
      throw new Error(`Expected one signal column in table header and got ${itemIds.length}`);
    }

    const { minValue, maxValue } = this.findTableMinMaxValues(tableData, startIndex);
    const maxColor = sqTrendSeriesStore.findItem(itemIds[0]).color;

    const gradient = this.computeGradientForColorConfig(minValue, maxValue, maxColor);

    return {
      ...gradient,
      columnIndex: startIndex,
      itemId: itemIds[0],
    };
  };

  /**
   * Compute the configuration for coloring based on the values of the conditions
   *
   * @param tableHeader - The table header of raw data received from backend. The function looks for columns with
   * format 'Condition(conditionId)'
   * @return {Object|undefined} the configuration for coloring
   */
  private computeConditionColorsConfig = (tableHeader) => {
    const { startIndex, endIndex, itemIds } = this.findItemsInTableHeader(tableHeader, /Condition\((.*?)\)/);

    if (itemIds.length > 0) {
      const colors = _.chain(itemIds)
        .map((id) => sqTrendConditionStore.findItem(id).color)
        .value();
      return { colors, startIndex, endIndex, itemIds };
    }
  };

  /**
   * Compute the configuration for coloring based on the values of the capsule properties
   *
   * @param tableHeader - The table header of raw data received from backend. The function looks for a column with
   * format 'Property(propertyName)'
   * @param tableData - The table data. The function determines the colors for different table values, and ignores
   * null property values.
   * @return the configuration for coloring
   */
  private computeCapsulePropertyColorsConfig = (tableHeader, tableData): CapsulePropertyColorsConfig | undefined => {
    const currentColorConfig = this.state.get('capsulePropertyColorsConfig');
    const { itemIds, startIndex } = this.findItemsInTableHeader(tableHeader, /Property\((.*?)\)/);
    if (itemIds.length === 0) {
      return;
    }
    const propertyName = itemIds[0];
    let transformValue = (x) => x;
    // Convert duration from seconds => milliseconds
    if (propertyName === SeeqNames.CapsuleProperties.Duration) {
      transformValue = (value) => value * 1000;
    }
    // Save start/end times in the map as a string of the millisecond value
    if (propertyName === SeeqNames.CapsuleProperties.Start || propertyName === SeeqNames.CapsuleProperties.End) {
      transformValue = (value) => nanosToMillis(value).toString();
    }

    const propertyValues = _.chain(tableData)
      .reject((row) => _.isNull(row[startIndex]))
      .map(`[${startIndex}]`)
      .map(transformValue)
      .value();
    if (propertyValues.length === 0) {
      return;
    }

    const isStringProperty =
      (tableHeader[startIndex].type === 'string' ||
        tableHeader[startIndex].type === 'date' ||
        propertyName === SeeqNames.CapsuleProperties.Start ||
        propertyName === SeeqNames.CapsuleProperties.End) &&
      propertyName !== SeeqNames.CapsuleProperties.Duration;

    if (isStringProperty) {
      const sortedUniqueValues = _.chain(propertyValues)
        .uniq()
        .sortBy((value) => value.toLowerCase())
        .value();
      const valueColorMap =
        _.chain(currentColorConfig?.valueColorMap)
          .clone()
          // only keep values that are still in view
          .pickBy((value, key) => _.includes(sortedUniqueValues, key))
          .value() ?? {};
      let index = 0;
      // Try to keep colors the same even if the display range is changed
      _.forEach(sortedUniqueValues, (value) => {
        if (!_.includes(_.keys(valueColorMap), value)) {
          let newColor = TREND_COLORS[index % TREND_COLORS.length];
          index++;
          while (_.includes(_.values(valueColorMap), newColor) && index < TREND_COLORS.length) {
            newColor = TREND_COLORS[index % TREND_COLORS.length];
            index++;
          }
          valueColorMap[value] = newColor;
        }
      });
      return {
        isStringProperty,
        propertyIndex: startIndex,
        valueColorMap,
        transformValue,
      };
    } else {
      // Compute gradient for numeric properties
      const minValue = _.min(propertyValues);
      const maxValue = _.max(propertyValues);
      // Keep the same color if possible
      const maxColor = currentColorConfig?.maxColor ?? TREND_COLORS[Math.floor(Math.random() * TREND_COLORS.length)];

      const gradient = this.computeGradientForColorConfig(minValue, maxValue, maxColor);

      return {
        isStringProperty,
        propertyIndex: startIndex,
        transformValue,
        ...gradient,
      };
    }
  };

  /**
   * Compute a gradient to use for coloring by capsule property or signal
   *
   * @param minValue - lowest value for gradient
   * @param maxValue - highest value for gradient
   * @param maxColor - base color for the gradient (darkest color used)
   */
  private computeGradientForColorConfig = (minValue, maxValue, maxColor) => {
    let maxDiff = minValue != null && maxValue != null ? maxValue - minValue : null;
    let minColor;
    if (maxDiff === 0) {
      maxDiff = 1;
      minColor = maxColor;
    } else {
      minColor = computeLightestColor(maxColor, 0.1);
    }

    const colors = tinygradient([
      { color: tinycolor(minColor).setAlpha(SCATTER_PLOT_OPACITY), pos: 0 },
      { color: tinycolor(maxColor).setAlpha(SCATTER_PLOT_OPACITY), pos: 1 },
    ]).rgb(100);

    return {
      minValue,
      maxValue,
      minColor,
      maxColor,
      diffValue: maxValue - minValue,
      colors,
    };
  };

  /**
   * Find the minimum and the maximum value of a table column
   *
   * @param {any[any[]]} table - the table with data.
   * @param {number} columnIndex - the column index
   * @return {Object} the minimum and maximum values for specified column
   */
  private findTableMinMaxValues = (table, columnIndex: number) => {
    const minMax = _.reduce(
      table,
      (accumulator, row) => {
        const value = row[columnIndex];
        accumulator[0] = accumulator[0] === undefined || value < accumulator[0] ? value : accumulator[0];
        accumulator[1] = accumulator[1] === undefined || value > accumulator[1] ? value : accumulator[1];
        return accumulator;
      },
      [],
    );

    return { minValue: minMax[0], maxValue: minMax[1] };
  };

  /**
   * Processes a table header and extract the location of item ids that match the regex
   *
   * @param {any[]} tableHeader - table header array like the one below
   * {name: "key", type: "number", units: ""}
   * {name: "x", type: "number", units: "°F"}
   * {name: "y", type: "number", units: "kW"}
   * {name: "Condition(9685D581-0990-4ACB-9EC1-3BF610950428)', type: "number", units: "'}
   * {name: "Condition(0C0BCF3F-1CB7-423B-B41D-27FBEF603585)', type: "number", units: "'}
   * {name: "Signal(4DBAE94D-AE77-421F-BE28-431EB021EAB3)', type: "number", units: "°F'}
   * @param regex - the regex to search for in column name
   * @return {Object} - information about the start and end column matching the regex and collected ids
   */
  private findItemsInTableHeader = (tableHeader: any[], regex) => {
    return _.reduce(
      tableHeader,
      (memo, cell, index) => {
        const match = regex.exec(cell.name);
        if (!_.isNull(match)) {
          memo.itemIds.push(match[1]);
          if (memo.startIndex === -1) {
            memo.startIndex = index;
          }
          memo.endIndex = index;
        }
        return memo;
      },
      { startIndex: -1, endIndex: -1, itemIds: [] },
    );
  };

  /**
   * If the provided color array contains a color at the index, then it returns a more transparent version of that
   * color.
   *
   * @param itemColors - The list of potential colors
   * @param index - The color index to use
   * @return The corresponding transparent color
   */
  private getColorUsingItemColor = (itemColors: string[], index: number): string | undefined => {
    const maybeColor = itemColors[index];
    return maybeColor ? tinycolor(maybeColor).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString() : undefined;
  };

  /**
   * Calculates the color for the specified {@code time} based on a selector with three time ranges
   *
   * @param {number} time - the time of the sample for which to calculate the color
   * @param {Object} timeRangeConfig - the time range color configuration
   * @param reduceOpacity - whether the opacity for this color should be reduced from the default
   * @return {string|undefined} the corresponding color for the specified timestamp
   */
  private getColorUsingDisplayRange = (time, timeRangeConfig, reduceOpacity = false) => {
    if (!timeRangeConfig) {
      return;
    }

    let color;
    if (+time <= timeRangeConfig.split1 || timeRangeConfig.selectorHidden) {
      color = SCATTER_PLOT_COLORS.LOW;
    } else if (+time <= timeRangeConfig.split2) {
      color = SCATTER_PLOT_COLORS.MID;
    } else {
      color = SCATTER_PLOT_COLORS.HIGH;
    }

    if (reduceOpacity) {
      color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
    }

    return color;
  };

  /**
   * Calculates a color for a point by determining it falls within one of the configured color ranges.
   *
   * @param time - the time of the sample for which to calculate the color
   * @param colorRanges - the color ranges
   * @param reduceOpacity - whether the opacity for this color should be reduced from the default
   * @return {string|undefined} the corresponding color for the specified sample
   */
  private getColorUsingColorRanges = (time: number, colorRanges: ScatterPlotColorRange[], reduceOpacity = false) => {
    let color = _.chain(colorRanges)
      .find((cr) => time >= cr.range.startTime && time <= cr.range.endTime)
      .get('color')
      .value();
    if (color && reduceOpacity) {
      color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
    }
    return color;
  };

  /**
   * Compute the corresponding color for the specified value
   *
   * @param {Object} row - the data row from the table data received from backend
   * @param {Object} gradientConfig - The gradient color configuration
   * @param reduceOpacity - whether the opacity for this color should be reduced from the default
   * @return {string|undefined} the corresponding color for the specified sample
   */
  private getColorUsingSignalGradient = (row, gradientConfig, reduceOpacity = false) => {
    if (!gradientConfig) {
      return;
    }
    const value = row[gradientConfig.columnIndex];
    if (value != null) {
      let colorIndex;
      if (gradientConfig.diffValue === 0) {
        colorIndex = 0;
      } else {
        colorIndex = Math.round(((value - gradientConfig.minValue) / gradientConfig.diffValue) * 99);
      }
      if (reduceOpacity) {
        colorIndex = Math.round(colorIndex / 3);
      }
      return gradientConfig.colors[colorIndex].toString();
    }
  };

  /**
   * Calculates the color of a point based on the values from condition columns. The color is taken from the first
   * condition with value 1.
   *
   * @param {Object} row - the data row from the table data received from backend
   * time,           x                 y          cond1, cond2
   * 1558631487601, 79.65147202067725, 0.0029228,     0,     1
   * @param {Object} colorsConfig - The color configuration with condition information
   * @param reduceOpacity - whether the opacity for this color should be reduced from the default
   * @return {string|undefined} the corresponding color for the specified row
   */
  private getColorUsingCondition = (row, colorsConfig, reduceOpacity = false) => {
    if (!colorsConfig) {
      return;
    }

    let color;
    for (let i = colorsConfig.startIndex; i <= colorsConfig.endIndex; i++) {
      if (row[i] === 1) {
        color = colorsConfig.colors[i - colorsConfig.startIndex];
        break;
      }
    }

    if (color && reduceOpacity) {
      color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
    }

    return color;
  };

  /**
   * Calculates the color for a scatter plot point based on capsule property value
   *
   * @param row - row of data
   * @param colorsConfig - if coloring by capsule property, object containing either a map from string values to
   * colors or gradient info
   * @param reduceOpacity - whether the opacity for this color should be reduced from the default
   * @returns if relevant, the point color from the capsule property; otherwise, undefined
   */
  private getColorUsingCapsuleProperty = (
    row: any[],
    colorsConfig: CapsulePropertyColorsConfig | undefined,
    reduceOpacity = false,
  ): string | undefined => {
    if (!colorsConfig) {
      return;
    }

    const propertyValue = row[colorsConfig.propertyIndex];
    if (_.isNil(propertyValue)) return;
    return this.getCapsulePropertyColorForPlot(colorsConfig.transformValue(propertyValue), colorsConfig, reduceOpacity);
  };

  /**
   * Gets the point color from the capsule property config and property value
   *
   * @param propertyValue - the value of the property for this point
   * @param colorsConfig - the config containing coloring information
   * @param reduceOpacity - whether the opacity of this color should be reduced from the default
   * @returns the color to use for this point, or undefined if the point shouldnt be colored with this method
   */
  private getCapsulePropertyColorForPlot = (
    propertyValue: number | string,
    colorsConfig: CapsulePropertyColorsConfig,
    reduceOpacity = false,
  ): string | undefined => {
    if (!colorsConfig || _.isNil(propertyValue)) return;

    let color: string;
    if (colorsConfig.isStringProperty) {
      if (!_.isString(propertyValue)) {
        throw Error('Expected string-valued capsule property, but property value is not a string');
      }
      colorsConfig = colorsConfig as StringCapsulePropertyColorsConfig;
      color = colorsConfig.valueColorMap[propertyValue];
      if (reduceOpacity) {
        color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
      }
    } else if (!colorsConfig.isStringProperty) {
      if (!_.isNumber(propertyValue) && !_.isBoolean(propertyValue)) {
        throw Error('Expected numeric capsule property, but property value is not a number');
      }
      colorsConfig = colorsConfig as NumericCapsulePropertyColorsConfig;
      let colorIndex;
      if (colorsConfig.diffValue === 0) {
        colorIndex = 0;
      } else {
        colorIndex = Math.round((((propertyValue as number) - colorsConfig.minValue) / colorsConfig.diffValue) * 99);
      }
      if (reduceOpacity) {
        colorIndex = Math.round(colorIndex / 3);
      }
      color = colorsConfig.colors[colorIndex]?.toString();
    }

    return color;
  };

  /**
   * Calculates the color of a point based on selected capsules. The color of the first capsule which contains the
   * {@code time} is returned.
   *
   * @param time - the time of the sample for which we calculate the color
   * @param {Capsule[]} selectedCapsules - an array with selected capsules
   * @param [capsuleProperty] - a capsule property we're using to color, if present
   * @param [capsulePropertyColorsConfig] - the coloring info for the capsule property coloring
   * @return {string|undefined} the corresponding color for the specified timestamp, if found
   */
  private getColorUsingSelectedCapsules = (
    time: number,
    selectedCapsules,
    capsuleProperty?: string,
    capsulePropertyColorsConfig?: CapsulePropertyColorsConfig,
  ) => {
    const capsule = _.find(selectedCapsules, (capsule) => time >= capsule.startTime && time < capsule.endTime);
    if (!capsule) {
      return;
    }
    let color = capsule.color;
    // If we're coloring by a capsule property, use that color for coloring selected capsules
    if (!_.isNil(capsuleProperty) && !!capsulePropertyColorsConfig) {
      const propertyValue = this.getColorPropertyValueFromCapsuleForPlot(capsule);
      if (propertyValue) {
        color = capsulePropertyColorsConfig.isStringProperty
          ? this.getCapsulePropertyColorForPlot(propertyValue, capsulePropertyColorsConfig)
          : (capsulePropertyColorsConfig as NumericCapsulePropertyColorsConfig).maxColor;
      }
    }
    return color;
  };

  /**
   * Clear the scatter plot series
   */
  private clearScatterData = () => {
    this.scatterPlotData = [];
    this.state.set('scatterPlotExtremes', EMPTY_XY_REGION);
    this.incrementScatterDataChangeCount();
  };

  /**
   * Clear the heat map data
   */
  private clearDensityPlotData = () => {
    this.state.set('densityPlotData', []);
  };

  /**
   * Adds a function of x line for display on the scatter plot chart.
   *
   * @param {Object} payload - An object container for parameters
   * @param {String} payload.id - The item id
   * @param {String} payload.color - The color of the signal
   * @param {String} payload.ySignalId - The Y-axis ID
   */
  private addFxLine = (payload) => {
    this.state.push('fxLines', _.pick(payload, ['id', 'color', 'ySignalId', 'lineWidth']));
  };

  /**
   * Update a function of x line for display on the scatter plot chart.
   *
   * @param {Object} payload - An object container for parameters
   */
  private updateFxLine = (payload: ScatterPlotFXLineUpdate) => {
    const itemData = _.pick(payload.item, ['id', 'color', 'ySignalId', 'lineWidth']);
    const fxLineIndex = _.findIndex(this.state.get('fxLines'), ({ id }) => id === itemData.id);
    if (fxLineIndex >= 0) {
      this.state.set(['fxLines', fxLineIndex, payload.key], payload.newValue);
    }
  };

  getItemLineWidth = (item: { id: string; lineWidth?: number }) => {
    const fxLineIndex = _.findIndex(this.state.get('fxLines'), ({ id }) => id === item.id);
    if (fxLineIndex >= 0) {
      return this.state.get(['fxLines', fxLineIndex, 'lineWidth']);
    }
    return item.lineWidth ?? 1;
  };

  /**
   * Removes a function of x line from the display.
   *
   * @param {Object} payload - An object container for parameters
   * @param {String} payload.id - The item id
   */
  private removeFxLine = (payload) => {
    const index = _.findIndex(this.state.get('fxLines'), ['id', payload.id]);
    if (index > -1) {
      this.state.splice('fxLines', [index, 1]);
    }
  };

  /**
   * Associates the fx line with the given id with the given y signal
   *
   * @param payload
   * @param payload.id - The item id the fx line is based on
   * @param payload.ySignalId - The id of the y signal being associated
   */
  private setFxLineYSignalId = (payload: { id: string; ySignalId: string }) => {
    const index = _.findIndex(this.state.get('fxLines'), ['id', payload.id]);
    if (index > -1) {
      this.state.set(['fxLines', index, 'ySignalId'], payload.ySignalId);
    }
  };

  /**
   * Adds a condition for colorizing the scatter plot chart
   *
   * @param {Object} payload - An object container for parameters
   * @param {String} payload.id - The item id
   */
  private addColorCondition = (payload) => {
    this.state.push('colorConditionIds', payload.id);
  };

  /**
   * Removes a condition for colorizing the scatter plot chart
   *
   * @param {Object} payload - An object container for parameters
   * @param {String} payload.id - The item id
   */
  private removeColorCondition = (payload) => {
    const index = _.indexOf(this.state.get('colorConditionIds'), payload.id);
    if (index > -1) {
      this.state.splice('colorConditionIds', [index, 1]);
    }
  };

  /**
   * Sets the capsule property with which to color scatter plot points
   * Also, resets the color config
   *
   * @param payload - object container for parameters
   * @param payload.property - name of capsule property
   */
  private setColorCapsuleProperty = (payload: { property: string }) => {
    this.state.set('colorCapsuleProperty', payload.property);
    this.state.unset('capsulePropertyColorsConfig');
  };

  private setColorForCapsuleProperty = (payload: { color: string }) => {
    const currentColorsConfig = this.state.get('capsulePropertyColorsConfig');
    if (
      !currentColorsConfig ||
      currentColorsConfig.isStringProperty ||
      payload.color === currentColorsConfig.maxColor
    ) {
      return;
    }

    const gradient = this.computeGradientForColorConfig(
      currentColorsConfig.minValue,
      currentColorsConfig.maxValue,
      payload.color,
    );
    const newColorsConfig = { ...currentColorsConfig, ...gradient };
    this.state.set('capsulePropertyColorsConfig', newColorsConfig);
  };

  /**
   * Removes coloring by capsule property
   */
  private removeColorCapsuleProperty = () => {
    this.state.unset('colorCapsuleProperty');
    this.state.unset('capsulePropertyColorsConfig');
  };

  /**
   * Sets a signal to use to colorize the chart.
   *
   * @param {Object} payload - An object container for parameters
   * @param {String} payload.id - The item id
   */
  private setColorSignal = (payload) => {
    this.state.set('colorSignalId', payload.id);
  };

  /**
   * Removes the signal used for colorizing the scatter plot chart
   */
  private removeColorSignal = () => {
    this.state.unset('colorSignalId');
  };

  /**
   * Sets whether or not to use the item color to color the points
   *
   * @param payload - An object container for the parameters
   * @param payload.colorByItemColor - Whether or not to use the item color to color the points
   * @param payload.refreshView - Whether or not to refresh the view
   */
  private setColorByItemColor = (payload: { colorByItemColor: boolean }) => {
    this.state.set('colorByItemColor', payload.colorByItemColor);
  };

  /**
   * Adds a color and time range to be used for colorizing the scatter plot chart based on the time ranges.
   *
   * @param {ScatterPlotColorRange} payload - The color range
   */
  private addColorRange = (payload: ScatterPlotColorRange) => {
    const ranges = this.state.get('colorRanges').concat(payload);
    this.state.set('colorRanges', _.sortBy(ranges, 'range.startTime'));
    this.refreshView();
  };

  /**
   * Updates a color range.
   *
   * @param {ScatterPlotColorRange} payload - The color range to update
   */
  private updateColorRange = (payload: ScatterPlotColorRange) => {
    const ranges = [...this.state.get('colorRanges')];
    const index = _.findIndex(ranges, { id: payload.id });
    if (index > -1) {
      ranges.splice(index, 1, payload);
      this.state.set('colorRanges', _.sortBy(ranges, 'range.startTime'));
      this.refreshView();
    }
  };

  /**
   * Removes a color and time range from the scatter plot chart
   *
   * @param {Object} payload - An object container for parameters
   * @param {string} payload.id - The id of the range to remove
   */
  private removeColorRange = (payload) => {
    const index = _.findIndex(this.state.get('colorRanges'), {
      id: payload.id,
    });
    if (index > -1) {
      this.state.splice('colorRanges', [index, 1]);
      this.refreshView();
    }
  };

  /**
   * Removes items that are used in this store but have been removed from the details pane.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} payload.items - An array of items to remove
   */
  private removeItem = (payload) => {
    let rawDataUpdated = false;
    _.forEach(payload.items, (item) => {
      this.removeFxLine({ id: item.id });
      this.removeColorCondition({ id: item.id });
      if (item.id === this.state.get('colorSignalId')) {
        this.state.unset('colorSignalId');
      }
      _.chain(this.state.get('ySignals'))
        .map((signal) => ({
          signal,
          removalCallback: () => this.removeYSignal({ id: signal.id }),
        }))
        .concat([
          {
            signal: this.state.get('xSignal'),
            removalCallback: () => this.removeXSignal(),
          },
        ])
        .forEach((data) => {
          if (item.id === data.signal?.id) {
            data.removalCallback();
            this.clearScatterData();
            this.clearDensityPlotData();
          }
        })
        .value();
      // Item was removed from detail pane, so we remove corresponding data from our raw data
      const rawDataColumnRemoved = this.removeRawDataColumn(item.id);
      rawDataUpdated = rawDataUpdated || rawDataColumnRemoved;
    });

    if (rawDataUpdated) {
      this.refreshView();
    }
  };

  /**
   * Removes the corresponding column and values from the raw data
   *
   * @param itemId - Column identifier. Match is done with include, not exact match
   * @return {boolean} true if the column was found and data removed
   */
  private removeRawDataColumn = (itemId) => {
    const columnIndex = _.findIndex(this.rawPlotData?.headers, (column: any) => column.name.includes(itemId));
    if (columnIndex === -1) {
      return false;
    }

    const newRawData = { headers: this.rawPlotData.headers, data: [] };
    newRawData.headers.splice(columnIndex, 1);
    newRawData.data = _.chain(this.rawPlotData.data)
      .cloneDeep()
      .map((row: any[]) => {
        row.splice(columnIndex, 1);
        return row;
      })
      .value();
    this.rawPlotData = newRawData;

    return true;
  };

  /**
   * Changes the color of any items used in scatter plot that reference the item color
   *
   * @param {Object} payload - An object container for parameters
   * @param {String} payload.id - The item id
   * @param {String} payload.color - The color
   */
  private handleItemColorChange = (payload) => {
    const index = _.findIndex(this.state.get('fxLines'), ['id', payload.id]);
    if (index > -1) {
      this.state.set(['fxLines', index, 'color'], payload.color);
    }

    const minimapIndex = _.findIndex(
      this.state.get('minimapSignals'),
      (signal: ScatterPlotSignal) => signal.id === payload.id,
    );
    if (minimapIndex > -1) {
      this.state.set(['minimapSignals', index, 'color'], payload.color);
    }

    if (_.includes(this.state.get('colorConditionIds'), payload.id) || this.state.get('colorSignalId') === payload.id) {
      this.refreshView();
    }
  };

  /**
   * Swaps old items with new items in the internal state of the store. This method does NOT refresh data. The
   * action should call fetch data after swapItems to get fresh data from backend.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
   *   values are the corresponding swapped in ids.
   * @param {Object} payload.outAsset - Asset that was swapped out
   * @param {String} payload.outAsset.id - The ID of the asset to swapped out
   * @param {String} payload.inAsset.name - The name of the asset that was swapped out
   * @param {Object} payload.inAsset - Asset that was swapped in
   * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
   * @param {String} payload.inAsset.name - The name of the asset that was swapped in
   */
  private swapItems = (payload) => {
    const swaps = payload.swaps;

    const newXSignalId = swaps[_.get(this.state.get('xSignal'), 'id')];
    if (newXSignalId) {
      this.state.set(['xSignal', 'id'], newXSignalId);
    }

    _.forEach(this.state.get('ySignals'), (signal, index) => {
      const maybeSignalId = swaps[signal.id];
      if (maybeSignalId) {
        this.state.set(['ySignals', index, 'id'], maybeSignalId);
      }
    });

    _.forEach(['viewRegion', 'selectedRegion'], (regionKey) => {
      const regionYs = this.state.get(regionKey, 'ys');
      _.forEach(regionYs, (value, id) => {
        if (swaps[id]) {
          this.state.unset([regionKey, 'ys', id]);
          this.state.set([regionKey, 'ys', swaps[id]], value);
        }
      });
    });

    const newColorSignalId = swaps[this.state.get('colorSignalId')];
    if (newColorSignalId) {
      this.state.set('colorSignalId', newColorSignalId);
    }

    this.state.set(
      'colorConditionIds',
      _.map(this.state.get('colorConditionIds'), (id) => (swaps[id] ? swaps[id] : id)),
    );

    const newFxLines = _.map(this.state.get('fxLines'), (fxLine) => ({
      ...fxLine,
      id: swaps[fxLine.id] ?? fxLine.id,
      ySignalId: swaps[fxLine.ySignalId] ?? fxLine.ySignalId,
    }));
    this.state.set('fxLines', newFxLines);
  };

  /**
   * Adds the sample data for a function of x line in a manner suitable for display on the scatter plot chart.
   *
   * @param {Object} payload - An object container for parameters
   * @param {String} payload.id - The item id
   * @param {Object[]} payload.samples - Samples where the key corresponds to the x-axis and the value corresponds to
   * the y-axis
   * @param {FxLineMetadata} payload.metadata - Additional metadata from the computation
   */
  private setFxLineData = (payload) => {
    const index = _.findIndex(this.state.get('fxLines'), ['id', payload.id]);
    if (index > -1) {
      this.state.set(
        ['fxLines', index, 'data'],
        _.map(payload.samples, (sample) => [sample.key, sample.value]),
      );
      this.state.set(['fxLines', index, 'metadata'], payload.metadata);
      if (payload.numberFormat) {
        this.state.set(['fxLines', index, 'numberFormat'], payload.numberFormat);
      }
    }
  };

  /**
   * Triggers color recalculation and refresh view
   */
  private refreshView = () => {
    if (!this.rawPlotData) {
      return;
    }

    if (this.state.get('plotView') === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      this.setScatterData(this.rawPlotData);
    } else if (this.state.get('plotView') === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      this.setDensityPlotData(this.rawPlotData);
    }
  };

  /**
   * Sets a minimap signal
   *
   * @param  payload - An object container for parameters
   * @parma payload.signals - The minimap signals being set
   */
  private setMinimapSignals = (payload: { signals: { id: string; color: string; data: any[] }[] }) => {
    if (_.some(payload.signals, (signal) => !signal.id)) {
      throw Error('A Scatter Plot minimap signal must have an ID property');
    }

    this.state.set('minimapSignals', payload.signals);
  };

  /**
   * Clears the current set of minimap signals
   */
  private clearMinimapSignals = () => {
    this.state.set('minimapSignals', []);
  };

  /**
   * Sets the series on the plot's x-axis
   *
   * @param payload - An object container for the parameters
   */
  private setXSignal = (payload: { xSignal: { id: string; formatOptions: { format: any } } }) => {
    this.state.set('xSignal', _.pick(payload.xSignal, ['id', 'formatOptions.format']));
  };

  private removeXSignal = () => {
    this.state.set('xSignal', null);
  };

  /**
   * Sets a signal on the plot's y-axis
   *
   * @param payload - An object container for the parameters
   */
  private setYSignal = (payload: { ySignal: { id: string; formatOptions: { format: any } } }) => {
    const ySignal = _.pick(payload.ySignal, ['id', 'formatOptions.format']);
    if (!ySignal.id) {
      throw Error('A Scatter Plot ySignal must have an ID property');
    }
    const index = _.findIndex(this.state.get('ySignals'), ['id', ySignal.id]);
    if (index >= 0) {
      this.state.set(['ySignals', index], ySignal);
    } else {
      this.state.push('ySignals', ySignal);
    }
  };

  private removeYSignal = (payload: { id: string }) => {
    const index = _.findIndex(this.state.get('ySignals'), ['id', payload.id]);

    if (index >= 0) {
      this.state.splice('ySignals', [index, 1]);
      this.state
        .get('fxLines')
        .filter(({ ySignalId }) => ySignalId === payload.id)
        .forEach(({ id }) => {
          if (this.state.get('ySignals').length > 0) {
            this.setFxLineYSignalId({ id, ySignalId: this.state.get('ySignals')[0].id });
          } else {
            this.removeFxLine({ id });
          }
        });
    }
  };

  private setSignals = (payload: { ySignals: ScatterPlotSignal[]; xSignal: ScatterPlotSignal }) => {
    this.state.set('xSignal', payload.xSignal ? _.pick(payload.xSignal, ['id', 'formatOptions.format']) : null);
    this.state.set(
      'ySignals',
      _.map(payload.ySignals, (signal) => _.pick(signal, ['id', 'formatOptions.format'])) || [],
    );
  };

  /**
   * Flips the x and y signals, for both the axes and the data.
   */
  private flipAxes = () => {
    const plotView = this.state.get('plotView');

    if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT && this.isScatterPlotDataEmpty()) {
      return;
    }

    if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT && this.isDensityPlotDataEmpty()) {
      return;
    }

    const ySignals = this.state.get('ySignals');

    if (ySignals.length > 1) {
      // We don't support axis flipping when more than one signal is present
      return;
    }
    const oldYSignal = ySignals[0];
    this.state.set('ySignals', [this.state.get('xSignal')]);
    this.state.set('xSignal', oldYSignal);
    this.state
      .get('fxLines')
      .filter(({ ySignalId }) => ySignalId === oldYSignal.id)
      .forEach(({ id }) => {
        this.setFxLineYSignalId({ id, ySignalId: this.state.get('ySignals')[0].id });
      });

    if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      const tempBins = this.state.get('numYBins');
      this.state.set('numYBins', this.state.get('numXBins'));
      this.state.set('numXBins', tempBins);
    }

    const xId = this.state.get('xSignal').id;
    const yId = this.state.get('ySignals')[0].id;
    if (this.isPlotViewRegionSet()) {
      const view: XYPlotRegion = this.state.get('viewRegion');
      const y = view.ys[xId];

      // noinspection JSSuspiciousNameCombination
      this.state.set('viewRegion', {
        x: { min: y.min, max: y.max },
        ys: {
          [yId]: { min: view.x.min, max: view.x.max },
        },
      });
    }

    if (this.isPlotRegionSelected()) {
      const region: XYPlotRegion = this.state.get('selectedRegion');
      const y = region.ys[xId];

      // noinspection JSSuspiciousNameCombination
      this.state.set('selectedRegion', {
        x: { min: y.min, max: y.max },
        ys: {
          [yId]: { min: region.x.min, max: region.x.max },
        },
      });
    }

    if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      this.setScatterData({
        results: [
          {
            headers: this.rawPlotData.results[0].headers,
            data: _.chain(this.rawPlotData.results[0].data)
              .cloneDeep()
              .map((row: any[]) => {
                [row[1], row[2]] = [row[2], row[1]];
                return row;
              })
              .value(),
          },
        ],
      });
    } else if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      this.setDensityPlotData({
        headers: this.rawPlotData.headers,
        data: _.chain(this.rawPlotData.data)
          .cloneDeep()
          .map((row: any[]) => {
            [row[0], row[1]] = [row[1], row[0]];
            return row;
          })
          .value(),
      });
    }
  };

  /**
   * Helper method to determine if there is a selected region.
   */
  private isPlotRegionSelected = () => {
    return !_.isEqual(this.state.get('selectedRegion'), EMPTY_XY_REGION);
  };

  /**
   * Helper method to determine if there is a view region.
   */
  private isPlotViewRegionSet = () => {
    return !_.isEqual(this.state.get('viewRegion'), EMPTY_XY_REGION);
  };

  private isScatterPlotDataEmpty = () => {
    return _.isEmpty(this.scatterPlotData);
  };

  private isDensityPlotDataEmpty = () => {
    return _.isEmpty(this.state.get('densityPlotData'));
  };

  private setNumXBins = (payload) => {
    this.state.set('numXBins', payload.numXBins);
  };

  private setNumYBins = (payload) => {
    this.state.set('numYBins', payload.numYBins);
  };

  private isValidNumber = (value) => {
    return _.isNumber(value) && !_.isNaN(value);
  };

  private getPlotXAxisRange(): Range {
    const plotView = this.state.get('plotView');
    if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      return this.getScatterPlotXAxisRange();
    } else if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      return this.getDensityPlotXAxisRange();
    }
  }

  private getViewRegionXAxisRange = (): Range => {
    const viewRegion: XYPlotRegion = this.state.get('viewRegion');
    return {
      start: viewRegion.x.min,
      end: viewRegion.x.max,
    };
  };

  private getScatterPlotXAxisRange = (): Range => {
    return this.isPlotViewRegionSet()
      ? this.getViewRegionXAxisRange()
      : {
          start: this.state.get('scatterPlotExtremes').x.min,
          end: this.state.get('scatterPlotExtremes').x.max,
        };
  };

  private getDensityPlotXAxisRange = (): Range => {
    if (this.isPlotViewRegionSet()) {
      return this.getViewRegionXAxisRange();
    } else {
      const dataRange = _.reduce(
        this.state.get('densityPlotData'),
        (memo, datum) => ({
          start: !this.isValidNumber(memo.start) || memo.start > datum[0] ? datum[0] : memo.start,
          end: !this.isValidNumber(memo.end) || memo.end < datum[0] ? datum[0] : memo.end,
        }),
        { start: undefined, end: undefined },
      );
      // Since the data contains the min of each bin, we're actually missing one binsize of length
      const endDivisor = this.state.get('numXBins') > 1 ? this.state.get('numXBins') - 1 : 1;
      return {
        start: dataRange.start,
        end: dataRange.end + (dataRange.end - dataRange.start) / endDivisor,
      };
    }
  };

  private getPlotYAxisRange = (id: string): Range => {
    const plotView = this.state.get('plotView');
    if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      return this.getScatterPlotYAxisRange(id);
    } else if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      return this.getDensityPlotYAxisRange();
    }
  };

  private getScatterPlotYAxisRange = (id: string): Range => {
    if (_.isUndefined(id)) {
      throw new Error('ID must be provided when fetching y axis range for scatter plot');
    }
    const viewRegion: XYPlotRegion = this.state.get('viewRegion');
    const y = viewRegion.ys[id];
    return this.isPlotViewRegionSet()
      ? {
          start: y.min,
          end: y.max,
        }
      : {
          start: this.state.get('scatterPlotExtremes').ys[id]?.min,
          end: this.state.get('scatterPlotExtremes').ys[id]?.max,
        };
  };

  private getDensityPlotYAxisRange = (): Range => {
    const viewRegion: XYPlotRegion = this.state.get('viewRegion');
    // Not ideal, but we don't always know which range we're looking for (setting density plot data requires
    // getting the range but we don't have signal metadata)
    const y = _.chain(viewRegion.ys).toArray().head().value();
    if (this.isPlotViewRegionSet()) {
      return {
        start: y.min,
        end: y.max,
      };
    } else {
      const dataRange = _.reduce(
        this.state.get('densityPlotData'),
        (memo, datum) => ({
          start: !this.isValidNumber(memo.start) || memo.start > datum[1] ? datum[1] : memo.start,
          end: !this.isValidNumber(memo.end) || memo.end < datum[1] ? datum[1] : memo.end,
        }),
        { start: undefined, end: undefined },
      );
      // Since the data contains the min of each bin, we're actually missing one binsize of length
      const endDivisor = this.state.get('numYBins') > 1 ? this.state.get('numYBins') - 1 : 1;
      return {
        start: dataRange.start,
        end: dataRange.end + (dataRange.end - dataRange.start) / endDivisor,
      };
    }
  };

  private getPlotColorAxisRange = () => {
    return _.reduce(
      this.state.get('densityPlotData'),
      (memo, datum) => ({
        start:
          !this.isValidNumber(memo.start) || (memo.start > datum[2] && this.isValidNumber(datum[2]))
            ? datum[2]
            : memo.start,
        end:
          !this.isValidNumber(memo.end) || (memo.end < datum[2] && this.isValidNumber(datum[2])) ? datum[2] : memo.end,
      }),
      { start: undefined, end: undefined },
    );
  };

  /**
   * Sets the marker size from user input, and sets a flag to indicate that the marker size is custom.
   * Setting markerSize to undefined will unset the flag and revert control of the
   * marker size back to Seeq.
   *
   * @param {Object} payload - An object container for the parameters
   * @param {number} [payload.markerSize] - the radius of the markers
   */
  private setMarkerSize = (payload) => {
    const markerSize = payload.markerSize;
    if (_.isUndefined(markerSize)) {
      this.state.set('markerSize', this.calculatePlotMarkerSize());
      this.state.set('isMarkerSizeCustom', false);
    } else {
      this.state.set('markerSize', markerSize);
      this.state.set('isMarkerSizeCustom', true);
    }
  };

  /**
   * Calculates a size for the scatter plot markers based on how much data is on the chart.
   * More data points => smaller markers.
   * Let n be the number of samples.
   * If n < MIN_SAMPLES_FOR_BOOST, then this will return a value of MAX_MARKER_SIZE_CALCULATED.
   * If n > MAX_MARKER_SIZE_CALCULATED * MIN_SAMPLES_FOR_BOOST samples,
   *    then this will return a value of MIN_MARKER_SIZE_CALCULATED.
   * If MIN_SAMPLES_PER_BOOST < n < MAX_MARKER_SIZE_CALCULATED * MIN_SAMPLES_FOR_BOOST,
   *     then this will return a value in between MIN_MARKER_SIZE_CALCULATED and
   *     MAX_MARKER_SIZE_CALCULATED that decreases like 1/n.
   *
   *  @returns markerSize
   */
  private calculatePlotMarkerSize = (): number => {
    if (isBoostedData(this.scatterPlotData)) {
      const flatScatterPlotData = _.flatMap(this.scatterPlotData);
      const numPoints = _.reduce(flatScatterPlotData, (memo, dataList) => memo + dataList.length, 0);
      return Math.max(
        MAX_MARKER_SIZE_CALCULATED * Math.min(MIN_SAMPLES_FOR_BOOST / numPoints, 1),
        MIN_MARKER_SIZE_CALCULATED,
      );
    } else {
      const numPoints = _.sumBy(this.scatterPlotData, (group) => group.length);
      return Math.max(
        MAX_MARKER_SIZE_CALCULATED * Math.min(MIN_SAMPLES_FOR_BOOST / numPoints, 1),
        MIN_MARKER_SIZE_CALCULATED,
      );
    }
  };

  /**
   * Increments a store variable whenever we update scatterData.
   * Because scatterData is not kept in the baobab store, changes to scatterData
   * won't directly cause an event to be emitted, so we keep a var in the baobab store
   * that we update whenever scatterData changes.
   */
  private incrementScatterDataChangeCount = () => {
    this.state.set('scatterDataChangeCount', this.state.get('scatterDataChangeCount') + 1);
  };

  setShowColorModal = (payload) => {
    this.state.set('showColorModal', payload.showColorModal);
  };

  /**
   * Get the value of the color capsule property from a capsule
   *
   * @param capsule - capsule to check for the capsule property value
   * @param [timezone] - the user's current timezone, used for start time/end time
   * @returns property value if it exists, or undefined
   */
  private getColorPropertyValueFromCapsuleForPlot = (capsule, timezone?) => {
    const propertyName = this.state.get('colorCapsuleProperty');
    if (propertyName === SeeqNames.CapsuleProperties.Start && !_.isNil(capsule.startTime)) {
      return capsule.startTime.toString();
    }
    if (propertyName === SeeqNames.CapsuleProperties.End && !_.isNil(capsule.endTime)) {
      return capsule.endTime.toString();
    }
    if (propertyName === SeeqNames.CapsuleProperties.Duration && !_.isNil(capsule.duration)) {
      return capsule.duration;
    }
    if (propertyName === SeeqNames.CapsuleProperties.Similarity && !_.isNil(capsule.similarity)) {
      return capsule.similarity;
    }
    return capsule.properties?.[propertyName];
  };

  protected readonly handlers = {
    SCATTER_PLOT_SET_X_SIGNAL: this.setXSignal,
    SCATTER_PLOT_REMOVE_X_SIGNAL: this.removeXSignal,
    SCATTER_PLOT_SET_Y_SIGNAL: this.setYSignal,
    SCATTER_PLOT_REMOVE_Y_SIGNAL: this.removeYSignal,
    SCATTER_PLOT_SET_SIGNALS: this.setSignals,
    SCATTER_PLOT_FLIP_AXES: this.flipAxes,
    SCATTER_PLOT_SET_DATA: this.setScatterData,
    SCATTER_PLOT_CLEAR_DATA: this.clearScatterData,
    SCATTER_PLOT_ADD_FX_LINE: this.addFxLine,
    SCATTER_PLOT_UPDATE_FX_LINE: this.updateFxLine,
    SCATTER_PLOT_REMOVE_FX_LINE: this.removeFxLine,
    SCATTER_PLOT_SET_FX_LINE_Y_SIGNAL_ID: this.setFxLineYSignalId,
    SCATTER_PLOT_SET_COLOR_BY_ITEM_COLOR: this.setColorByItemColor,
    SCATTER_PLOT_ADD_COLOR_CONDITION: this.addColorCondition,
    SCATTER_PLOT_REMOVE_COLOR_CONDITION: this.removeColorCondition,
    SCATTER_PLOT_SET_COLOR_CAPSULE_PROPERTY: this.setColorCapsuleProperty,
    SCATTER_PLOT_SET_COLOR_FOR_CAPSULE_PROPERTY: this.setColorForCapsuleProperty,
    SCATTER_PLOT_REMOVE_COLOR_CAPSULE_PROPERTY: this.removeColorCapsuleProperty,
    SCATTER_PLOT_SET_COLOR_SIGNAL: this.setColorSignal,
    SCATTER_PLOT_REMOVE_COLOR_SIGNAL: this.removeColorSignal,
    SCATTER_PLOT_ADD_COLOR_RANGE: this.addColorRange,
    SCATTER_PLOT_UPDATE_COLOR_RANGE: this.updateColorRange,
    SCATTER_PLOT_REMOVE_COLOR_RANGE: this.removeColorRange,
    TREND_REMOVE_ITEMS: this.removeItem,
    SCATTER_PLOT_REFRESH_VIEW: this.refreshView,
    TREND_SET_COLOR: this.handleItemColorChange,
    TREND_SWAP_ITEMS: this.swapItems,
    SCATTER_PLOT_SET_FX_DATA: this.setFxLineData,
    SCATTER_PLOT_SET_MINIMAP_SIGNALS: this.setMinimapSignals,
    SCATTER_PLOT_CLEAR_MINIMAP_SIGNALS: this.clearMinimapSignals,
    SCATTER_PLOT_SELECTORS: this.setSelectors,
    SCATTER_PLOT_SET_PLOT_MODE: this.setPlotMode,
    SCATTER_PLOT_SET_PLOT_VIEW: this.setPlotView,
    SCATTER_PLOT_SET_CONNECT: this.setConnect,
    SCATTER_PLOT_SET_SELECTED_REGION: this.setSelectedRegion,
    SCATTER_PLOT_SET_VIEW_REGION: this.setViewRegion,
    SCATTER_PLOT_SET_SHOW_TOOLTIPS: this.setShowTooltips,
    SCATTER_PLOT_SET_MARKER_SIZE: this.setMarkerSize,
    SCATTER_PLOT_SET_SHOW_COLOR_MODAL: this.setShowColorModal,
    DENSITY_PLOT_SET_NUM_X_BINS: this.setNumXBins,
    DENSITY_PLOT_SET_NUM_Y_BINS: this.setNumYBins,
    DENSITY_PLOT_SET_DATA: this.setDensityPlotData,
    DENSITY_PLOT_CLEAR_DATA: this.clearDensityPlotData,
  };
}
