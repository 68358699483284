import React from 'react';
import { Button, TextField, InputGroup } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { FormError } from '@/core/FormError.atom';
import { SearchWidget } from '@/search/SearchWidget.organism';
import { SearchPanes } from '@/search/search.constants';
import _ from 'lodash';

export interface SearchWidgetIF extends ValidatingFormComponent<string> {
  component: 'SearchWidgetFormComponent';
  customErrorText?: string;
  onCloseClick: () => void;
  pane: SearchPanes;
  searchTypes: string[];
  insideModal?: boolean;
  allowAssetReplacement?: boolean;
  searchResultsClassNames?: string;
  onSearchItemClick: (item: any) => void;
}

export const SearchWidgetFormComponent: React.FunctionComponent<SearchWidgetIF> = (props) => {
  const {
    name,
    testId = 'searchWidget',
    validation,
    extendValidation,
    customErrorText,
    onCloseClick,
    onSearchItemClick,
    searchTypes,
    insideModal,
    pane,
    allowAssetReplacement = false,
    value,
    searchResultsClassNames,
  } = props;

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && _.has(formState.dirtyFields, name);

  const defaultValidation = (value: string) => !value;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <div className="flexRowContainer pb15" data-testid={testId}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { value, onChange } = getFormFieldProps(formState, input, meta, props);

          return value ? (
            <InputGroup
              testId="parameterIdentifier"
              autoComplete="off"
              type="text"
              name="commonName"
              value={value}
              readonly={true}
              append={[
                {
                  variant: 'button',
                  buttonProps: {
                    label: '',
                    icon: 'fa-close',
                    iconStyle: 'theme',
                    testId: `${testId}Close`,
                    onClick: () => {
                      onCloseClick();
                      onChange('');
                    },
                  },
                },
              ]}
            />
          ) : (
            <SearchWidget
              pane={pane}
              searchTypes={searchTypes}
              allowAssetReplacement={allowAssetReplacement}
              onItemClick={(item) => {
                onSearchItemClick(item);
                onChange(item.name);
              }}
              searchResultsClassNames={searchResultsClassNames}
            />
          );
        }}
      </Field>
      {showError && !value && customErrorText && (
        <FormError errorText={customErrorText} dataTestId={`${testId}Error`} extraClassNames="pt5" />
      )}
    </div>
  );
};
